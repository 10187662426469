import { DATE, SELECT, TEXTAREA } from "../../../components/DynamicForm";

export const productionFormConfig = [
  {
    sectionTitle: "Production",
    key: "Production",
    description:
      "This information includes rudimentary and technical information regarding your production",
    formFields: [
      {
        type: DATE,
        label: "Date of Close Down & Debrief meeting",
        id: "closeDownDate",
        value: "",
        columnCount: 6,
        disabled: false,
      },
      {
        type: SELECT,
        label: "Production Status",
        id: "productionStatus",
        columnCount: 6,
        required: false,
        options: [
          {
            label: "Open",
            value: "open",
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "Production Team",
        id: "productionTeam",
        value: undefined,
        placeholder:
          "Outline of key personnel Roles/Responsibilities. Was the team (Editorial, Production Management and Technical) structure and responsibilities fit for purpose?",
        subTitle:
          "Discussion Point: Any relevant notes from Talent Team wrap chat? *For more information please speak to HOP / Talent Team contact as detailed above.",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Technical Workflow (capture to delivery)",
        id: "technicalWorkflow",
        value: undefined,
        placeholder: "Was this fit for purpose?",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Location(s)",
        id: "locations",
        value: undefined,
        placeholder:
          "Did the show film in any external locations? Were they suitable ? Any issues?",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
];
