import { TEXTAREA } from "../../../components/DynamicForm";

export const budgetAndScheduleLearningFormConfig = [
  {
    sectionTitle: "Budget & Schedule Learnings",
    key: "Budget & Schedule Learnings",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Design (if applicable)",
        placeholder: "Rig time, Designer/fee, Cost of set/build",
        id: "design",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Final Cost Report Variance and Explanation",
        id: "finalCost",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Procurement/deals",
        id: "procurement",
        placeholder: "Resource provider/savings made",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Key Resources",
        id: "keyResources",
        placeholder: "Costs v Quotes, Quality, Any internal resources used?",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "On Screen Talent",
        id: "onScreenTalent",
        placeholder: "Where there any unusual Contracts, Support costs, Rights",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Rights",
        id: "rights",
        value: undefined,
        placeholder:
          "Where there any unusal rights required & acquired - Are the intended position for the rights and clearances agreed during production true to what was actually delivered? ",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
];
