import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { type SecuritySectionV3 } from "../yupSchemas/securitySchemaV3";
import { NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { RadioField } from "../formFields/RadioField";
import { CheckboxField } from "../formFields/CheckboxField";
import { MultiLineTextField } from "../formFields/MultiLineTextField";
import { MultiSelectField } from "../formFields/MultiSelectField";
import { type DropdownMetadata } from "../../../types/types";
import { UseUpdateRiskArgsNew } from "../../../hooks";

interface SecurityPageProps {
  dropdownMetadata: DropdownMetadata;
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

const additionalSecurityOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

const suppliersOptions = [
  {
    label: "Athena Security Solutions",
    value: "AthenaSecuritySolutions",
  },
  {
    label: "Capricorn Security",
    value: "CapricornSecurity",
  },
  {
    label: "GuardForce Security",
    value: "GuardForceSecurity",
  },
  {
    label: "Location Secure",
    value: "LocationSecure",
  },
  {
    label: "Minimal Risk",
    value: "MinimalRisk",
  },
  {
    label: "Peregrine",
    value: "Peregrine",
  },
  {
    label: "Rock Security Solutions",
    value: "RockSecuritySolutions",
  },
  {
    label: "Safe & Sound Group",
    value: "SafeAndSoundGroup",
  },
  {
    label: "SRM Security",
    value: "SRMSecurity",
  },
  {
    label: "Titan Risk Management",
    value: "TitanRiskManagement",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const riskOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];
const provocationOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

export function SecurityPageV3({
  dropdownMetadata,
  autosaveForm,
}: SecurityPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.security || {};
  const errors = formik.errors.security || {};

  const checkboxesChangeHandler = (fieldName: keyof SecuritySectionV3) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`security.${fieldName}`).setValue(values);
    };
  };

  const handleAtRiskCountryChange = (values: Array<string>) => {
    formik.getFieldHelpers("security.atRiskCountries").setValue(values);
  };

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      security: {
        ...formData.security,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Security</Typography>

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "security",
                  fieldName: "additionalSecurity",
                }}
                label="Do you require security provision as part of your production?"
                error={errors.additionalSecurity}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.additionalSecurity}
                options={additionalSecurityOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.additionalSecurity === "Yes" ? (
                <CheckboxField
                  version="v3"
                  fieldInfo={{ sectionId: "security", fieldName: "suppliers" }}
                  label="Please select the security suppliers you will be using:"
                  error={
                    Array.isArray(errors.suppliers)
                      ? errors.suppliers[0]
                      : errors.suppliers
                  }
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={checkboxesChangeHandler("suppliers")}
                  isRequired={true}
                  value={values.suppliers}
                  options={suppliersOptions}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              {values.additionalSecurity === "Yes" &&
              values.suppliers?.includes("Other") ? (
                <MultiLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "security",
                    fieldName: "securityProvider",
                  }}
                  label="Please list the other provider(s) that you will be using:"
                  error={errors.securityProvider}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={false}
                  value={values.securityProvider}
                  placeholder="List providers here"
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <RadioField
                version="v3"
                fieldInfo={{ sectionId: "security", fieldName: "risk" }}
                label="Is there a direct security risk to employees, contributors or contractors based on the locations that they are filming?"
                error={errors.risk}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.risk}
                options={riskOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.risk === "Yes" ? (
                <MultiLineTextField
                  version="v3"
                  fieldInfo={{ sectionId: "security", fieldName: "riskDetail" }}
                  label="Please detail the direct security risk:"
                  error={errors.riskDetail}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.riskDetail}
                  placeholder="Detail security risk here"
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <MultiSelectField
                version="v3"
                fieldInfo={{
                  sectionId: "security",
                  fieldName: "atRiskCountries",
                }}
                label="Will the production or any person associated with the production be required to travel to any of the following countries?"
                error={
                  Array.isArray(errors.atRiskCountries)
                    ? errors.atRiskCountries[0]
                    : errors.atRiskCountries
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={handleAtRiskCountryChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.atRiskCountries}
                options={dropdownMetadata.securityCountries.sort((a, b) => {
                  if (a === "None of these countries will be visited")
                    return -1;
                  if (b === "None of these countries will be visited") return 1;
                  return a.localeCompare(b);
                })}
                autosaveForm={wrappedAutosaveForm}
              />

              <RadioField
                version="v3"
                fieldInfo={{ sectionId: "security", fieldName: "provocation" }}
                label="Will the content of the production cover any potentially sensitive subject areas which could provoke a significant public response, such as a protest at a production site or harassment (both online or in-person) targeted at ITV personnel?"
                error={errors.provocation}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.provocation}
                options={provocationOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.provocation === "Yes" ? (
                <MultiLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "security",
                    fieldName: "provocationDetail",
                  }}
                  label="Please detail these potentially sensitive subject areas"
                  error={errors.provocationDetail}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.provocationDetail}
                  placeholder="Type answer here"
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
