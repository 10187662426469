import { FormHelperText, OutlinedInput } from "@mui/material";
import { FormikHandlers } from "formik";
import { FormFieldLabel } from "../../../componentsV2/FormFieldLabel";
import { NotificationFormFieldProps } from "../../../types/types";
import { UseUpdateRiskArgsNew } from "../../../hooks";
import { DEFAULT_NPNF_VERSION } from "../notificationForm.helper";

interface Props {
  label: string;
  error: string | undefined;
  shouldDisplayErrors: boolean;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  isRequired: boolean;
  value: number | undefined;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

type NumberFieldProps = NotificationFormFieldProps<Props>;

export function NumberField({
  version,
  fieldInfo,
  label,
  error,
  shouldDisplayErrors,
  handleChange,
  handleBlur,
  isRequired,
  value,
  placeholder,
  startAdornment,
  autosaveForm,
}: NumberFieldProps) {
  const inputId = `${fieldInfo.sectionId}.${fieldInfo.fieldName}`;
  return (
    <FormFieldLabel inputId={inputId} label={label} required={isRequired}>
      <OutlinedInput
        disabled={version !== DEFAULT_NPNF_VERSION}
        type="number"
        id={inputId}
        name={inputId}
        required={isRequired}
        multiline={false}
        fullWidth
        value={value || ""}
        onChange={handleChange}
        onBlur={(event) => {
          handleBlur(event);

          const valueAsNumber = parseFloat(event.target.value);

          if (!isNaN(valueAsNumber)) {
            autosaveForm({
              [fieldInfo.sectionId]: {
                [fieldInfo.fieldName]: valueAsNumber,
              },
            });
          }
        }}
        aria-describedby={`${inputId}-error`}
        error={shouldDisplayErrors && Boolean(error)}
        placeholder={placeholder}
        startAdornment={startAdornment}
      />
      {shouldDisplayErrors && error ? (
        <FormHelperText id={`${inputId}-error`} error={true}>
          {error}
        </FormHelperText>
      ) : null}
    </FormFieldLabel>
  );
}
