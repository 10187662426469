import { FC, useState } from "react";
import { LinearProgress, Radio, Stack, Typography } from "@mui/material";
import { PreviewVideo } from "./PreviewVideo";

import { useSelectedVideoUrl, useVideoAssets } from "../../../../../../hooks";
import { VideoMetadataResponsePayload } from "../../../../../../types/types";
import { TimeRange, TimeCodeEditor } from "../TimeCodeEditor";
import theme from "../../../../../../utils/theme";

interface VersionsListProps {
  titleCcid: string;
  selectedVersionId: string;
  isLoadingVersion: boolean;
  videoMetaData: VideoMetadataResponsePayload | undefined;
  onChange(versionCcid: string): void;
  timeRange: TimeRange;
  hasFixedTimecodeLength: boolean;
  onTimeRangeChange(timeRange: TimeRange): void;
  timecodeError: string;
  onError(msg: string): void;
}

export const VersionsList: FC<VersionsListProps> = ({
  titleCcid,
  selectedVersionId,
  isLoadingVersion,
  videoMetaData,
  onChange,
  timeRange,
  hasFixedTimecodeLength,
  onTimeRangeChange,
  timecodeError,
  onError,
}) => {
  const { data: videoAssets } = useVideoAssets(titleCcid);
  const [selectedVideoVersionCcid, setSelectedVideoVersionCcid] =
    useState<string>("");
  const { data: selectedVideoUrl } = useSelectedVideoUrl(
    selectedVideoVersionCcid,
  );

  const handleFetchVideoUrl = (videoTitleCcid: string) =>
    setSelectedVideoVersionCcid(videoTitleCcid);

  if (isLoadingVersion) {
    return <LinearProgress sx={{ marginTop: "1rem" }} />;
  }

  return (
    <>
      {videoMetaData?.versions.map(
        ({ productionId, editReasons, versionCcid }) => (
          <Stack
            bgcolor={selectedVersionId === versionCcid ? "#EEF4F4" : "none"}
            key={versionCcid}
            sx={{
              "& .preview-time-code, button": {
                transform:
                  selectedVersionId === versionCcid ? "scale(0.9)" : "none",
              },
            }}
          >
            <Stack direction="row" marginTop={1} paddingX={2}>
              <Radio
                id={`version-${versionCcid}`}
                key={versionCcid}
                sx={{
                  "&:hover": {
                    background: "none",
                  },
                }}
                disableRipple
                checked={selectedVersionId === versionCcid}
                data-testid={`version-${versionCcid}`}
                onChange={() => {
                  onChange(versionCcid);
                }}
              />
              <PreviewVideo
                className="preview-time-code"
                url={
                  selectedVideoVersionCcid === versionCcid
                    ? selectedVideoUrl
                    : undefined
                }
                handleFetchVideoUrl={handleFetchVideoUrl}
                videoMetaData={{
                  productionId,
                  versionCcid,
                  editReasons,
                }}
                hasVideo={videoAssets?.assets?.some(
                  (el) => el.versionCcid === versionCcid,
                )}
              />
            </Stack>
            {versionCcid === selectedVersionId && (
              <>
                <TimeCodeEditor
                  titleCcid={titleCcid}
                  selectedVersionId={selectedVersionId}
                  timeRange={timeRange}
                  hasFixedTimecodeLength={hasFixedTimecodeLength}
                  onTimeRangeChange={onTimeRangeChange}
                  onError={onError}
                />
                <Typography color={theme.palette.warmRed}>
                  {timecodeError}
                </Typography>
              </>
            )}
          </Stack>
        ),
      )}
    </>
  );
};
