const americanLabels: string[] = [
  "Bedrock Entertainment",
  "Blumhouse TV",
  "Circle Of Confusion",
  "Good Caper",
  "High Noon Entertainment",
  "ITV America",
  "ITV Entertainment",
  "ITV Studios America",
  "Kimmelot",
  "Kingdom Reign",
  "Kirkstall Road Enterprises",
  "Leftfield Pictures",
  "Nobodys Hero",
  "Outpost Entertainment",
  "Poison Pen Studios",
  "Sirens Media",
  "Spoke",
  "Talpa North America (US)",
  "Thinkfactory Media",
  "Tomorrow Studios",
  "Work Friends",
];

export default americanLabels;
