import * as React from "react";
import { ChangeEvent, useState } from "react";
import {
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Dialog,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useMutation, useQueryClient } from "react-query";
import { formatBytes } from "react-dropzone-uploader";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  downloadImage,
  getBlob,
  FileEntitlementsResponse,
  UploadedImage,
  UploadedFont,
  UploadedScript,
} from "../../../../../api/images";
import Thumbnail from "../Thumbnail/Thumbnail";
import {
  LevelType,
  SeriesDetails,
  TitleDetailsProps,
  UploadType,
} from "../../../../../types/types";
import { ModalWrapper } from "../../../../../components";
import { ModalService } from "../../../../../services";
import theme from "../../../../../utils/theme";
import { useDeleteFiles } from "../../../../../hooks";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";

interface CommonProps {
  handleFileSelect: (args: { fileId: string; isSelected: boolean }) => void;
  handleSelectAllChange: (args: {
    fileIds: Array<string>;
    isSelected: boolean;
  }) => void;
  ccid: string;
  level: LevelType;
  entitlements: FileEntitlementsResponse | undefined;
  seriesDetails?: SeriesDetails;
  titleDetails?: TitleDetailsProps;
  brandName?: string;
  disableView?: boolean;
  selectedFiles: Record<string, boolean>;
}

type UploadedImagesProps = CommonProps & {
  deliverables: Array<UploadedImage> | undefined;
  type: "images";
};

type UploadedFontsProps = CommonProps & {
  deliverables: Array<UploadedFont> | undefined;
  type: "fonts";
};

type UploadedScriptsProps = CommonProps & {
  deliverables: Array<UploadedScript> | undefined;
  type: "scripts";
};

type UploadedFilesProps = CommonProps & {
  downloadFiles: () => void;
  isDownloadingFiles: boolean;
  deleteFiles: () => void;
} & (UploadedImagesProps | UploadedFontsProps | UploadedScriptsProps);

function getFileId(
  file: UploadedImage | UploadedFont | UploadedScript,
): string {
  if ("imageId" in file) {
    return file.imageId;
  }

  if ("fontId" in file) {
    return file.fontId;
  }

  return file.scriptId;
}

function useDownloadFiles(showErrorMessage: (message: string) => void) {
  return useMutation(
    async ({
      files,
      level,
      ccid,
      deliverableType,
    }: {
      files: Array<UploadedImage> | Array<UploadedFont> | Array<UploadedScript>;
      level: string;
      ccid: string;
      deliverableType: UploadType;
    }) => {
      const zip = new JSZip();
      const folder = zip.folder(deliverableType);

      const zippedFiles = await Promise.allSettled(
        files.map(async (file) => {
          const dowloadedData = await downloadImage(
            getFileId(file),
            level,
            ccid,
            deliverableType,
          );
          const blobData = await getBlob(dowloadedData);
          return folder?.file(file.uploadFilename, blobData);
        }),
      );

      const hasError = zippedFiles.some(
        (filePromise) => filePromise.status === "rejected",
      );

      if (hasError) {
        throw new Error("failed to download files");
      }

      folder?.generateAsync({ type: "blob" }).then((blobV2) => {
        saveAs(blobV2, `${deliverableType}.zip`);
      });
    },
    {
      onError: () => {
        showErrorMessage("Error during download. Please try again");
      },
    },
  );
}

export function UploadedImages({
  deliverables,
  selectedFiles,
  ccid,
  level,
  ...props
}: UploadedImagesProps) {
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState<
    string | undefined
  >(undefined);
  const { mutate: downloadImages, isLoading: isDownloadingImages } =
    useDownloadFiles(setSnackBarErrorMessage);
  const queryClient = useQueryClient();

  const { mutate: deleteImages } = useDeleteFiles();

  if (!deliverables) {
    return (
      <Alert severity="error">
        There was an error retrieving the images for this production
      </Alert>
    );
  }

  const selectedImages = deliverables.filter(
    (file) => selectedFiles[file.imageId] === true,
  );

  const downloadSelectedImages = () => {
    downloadImages({
      files: selectedImages,
      ccid,
      level,
      deliverableType: "images",
    });
  };

  const deleteSelectedImages = () => {
    deleteImages(
      {
        fileIds: selectedImages.map((image) => image.imageId),
        ccid,
        level,
        deliverableType: "images",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("uploadedImages");
        },
        onError: () => {
          enqueueSnackbar("Failed to delete file(s). Please try again", {
            action: SnackbarDismiss,
            persist: true,
            variant: "error",
          });
        },
      },
    );
  };

  return (
    <>
      <UploadedFiles
        deliverables={deliverables}
        selectedFiles={selectedFiles}
        downloadFiles={downloadSelectedImages}
        isDownloadingFiles={isDownloadingImages}
        deleteFiles={deleteSelectedImages}
        ccid={ccid}
        level={level}
        {...props}
      />

      {snackBarErrorMessage ? (
        <Snackbar
          open={!!snackBarErrorMessage}
          autoHideDuration={6000}
          onClose={() => setSnackBarErrorMessage(undefined)}
        >
          <Alert
            onClose={() => setSnackBarErrorMessage(undefined)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snackBarErrorMessage}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
}

export function UploadedFonts({
  deliverables,
  selectedFiles,
  ccid,
  level,
  ...props
}: UploadedFontsProps) {
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState<
    string | undefined
  >(undefined);
  const { mutate: downloadFonts, isLoading: isDownloadingFonts } =
    useDownloadFiles(setSnackBarErrorMessage);
  const queryClient = useQueryClient();

  const { mutate: deleteFonts } = useDeleteFiles();

  if (!deliverables) {
    return (
      <Alert severity="error">
        There was an error retrieving the images for this production
      </Alert>
    );
  }

  const selectedFonts = deliverables.filter(
    (file) => selectedFiles[file.fontId] === true,
  );

  const downloadSelectedFonts = () => {
    downloadFonts({
      files: selectedFonts,
      ccid,
      level,
      deliverableType: "fonts",
    });
  };

  const deleteSelectedFonts = () => {
    deleteFonts(
      {
        fileIds: selectedFonts.map((font) => font.fontId),
        ccid,
        level,
        deliverableType: "fonts",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("uploadedImages");
        },
        onError: () => {
          enqueueSnackbar("Failed to delete file(s). Please try again", {
            action: SnackbarDismiss,
            persist: true,
            variant: "error",
          });
        },
      },
    );
  };

  return (
    <>
      <UploadedFiles
        deliverables={deliverables}
        selectedFiles={selectedFiles}
        downloadFiles={downloadSelectedFonts}
        isDownloadingFiles={isDownloadingFonts}
        deleteFiles={deleteSelectedFonts}
        ccid={ccid}
        level={level}
        {...props}
      />

      {snackBarErrorMessage ? (
        <Snackbar
          open={!!snackBarErrorMessage}
          autoHideDuration={6000}
          onClose={() => setSnackBarErrorMessage(undefined)}
        >
          <Alert
            onClose={() => setSnackBarErrorMessage(undefined)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snackBarErrorMessage}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
}

export function UploadedScripts({
  deliverables,
  selectedFiles,
  ccid,
  level,
  ...props
}: UploadedScriptsProps) {
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState<
    string | undefined
  >(undefined);
  const { mutate: downloadScripts, isLoading: isDownloadingScripts } =
    useDownloadFiles(setSnackBarErrorMessage);
  const queryClient = useQueryClient();

  const { mutate: deleteScripts } = useDeleteFiles();

  if (!deliverables) {
    return (
      <Alert severity="error">
        There was an error retrieving the images for this production
      </Alert>
    );
  }

  const selectedScripts = deliverables.filter(
    (file) => selectedFiles[file.scriptId] === true,
  );

  const downloadSelectedScripts = () => {
    downloadScripts({
      files: selectedScripts,
      ccid,
      level,
      deliverableType: "scripts",
    });
  };

  const deleteSelectedScripts = () => {
    deleteScripts(
      {
        fileIds: selectedScripts.map((script) => script.scriptId),
        ccid,
        level,
        deliverableType: "scripts",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("uploadedImages");
        },
        onError: () => {
          enqueueSnackbar("Failed to delete file(s). Please try again", {
            action: SnackbarDismiss,
            persist: true,
            variant: "error",
          });
        },
      },
    );
  };

  return (
    <>
      <UploadedFiles
        deliverables={deliverables}
        selectedFiles={selectedFiles}
        downloadFiles={downloadSelectedScripts}
        isDownloadingFiles={isDownloadingScripts}
        deleteFiles={deleteSelectedScripts}
        ccid={ccid}
        level={level}
        {...props}
      />

      {snackBarErrorMessage ? (
        <Snackbar
          open={!!snackBarErrorMessage}
          autoHideDuration={6000}
          onClose={() => setSnackBarErrorMessage(undefined)}
        >
          <Alert
            onClose={() => setSnackBarErrorMessage(undefined)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snackBarErrorMessage}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
}

function UploadedFiles({
  deliverables,
  handleFileSelect,
  handleSelectAllChange,
  entitlements,
  seriesDetails,
  titleDetails,
  brandName,
  disableView = false,
  selectedFiles,
  type,
  downloadFiles,
  isDownloadingFiles,
  deleteFiles,
}: UploadedFilesProps) {
  const [isHovered, setIsHovered] = useState<string>("");
  const [isSelected, setIsSelected] = useState<string>("");

  const [isUploadedModalOpen, setIsUploadedModalOpen] =
    useState<boolean>(false);

  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const numberOfSelectedFiles = Object.values(selectedFiles).filter(
    (isSelected) => isSelected,
  ).length;

  const areAllFilesSelected = numberOfSelectedFiles === deliverables?.length;

  const onImageLoaded = (imgId: string) => {
    if (loadedImages.includes(imgId)) {
      return;
    }
    setLoadedImages((prevState) => [...prevState, imgId]);
  };

  const canDoWriteThings =
    entitlements?.canUpload ||
    entitlements?.canDelete ||
    entitlements?.canView ||
    entitlements?.canDownload;

  const hasEntitlements = { canDoWriteThings };

  const isFileChecked = (fileId: string): boolean => {
    return Boolean(selectedFiles[fileId]);
  };

  if (!deliverables || deliverables.length === 0) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginRight: "10px",
          }}
        >
          {canDoWriteThings && (
            <FormControlLabel
              control={
                <Checkbox
                  id="embargo-images-select-all-checkbox"
                  checked={areAllFilesSelected}
                  onChange={(_, checked) =>
                    handleSelectAllChange({
                      fileIds: deliverables.map((file) => getFileId(file)),
                      isSelected: checked,
                    })
                  }
                />
              }
              label="Select All"
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "373px",
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            loading={isDownloadingFiles}
            loadingIndicator={
              isDownloadingFiles ? <div>Downloading...</div> : ""
            }
            variant="contained"
            color="primary"
            onClick={downloadFiles}
            disabled={!entitlements?.canDownload || numberOfSelectedFiles < 1}
          >
            Download Selected
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={() => {
              ModalService.getInstance()
                .setTitle(`Delete selected ${type}?`)
                .setComponent(`Are you sure you want to delete these ${type}?`)
                .setCentered(true)
                .setConfirmCallback(deleteFiles)
                .setClickOutsideToClose(true)
                .setShowFooter(true)
                .setConfirmationLabel("Delete")
                .showModal();
            }}
            disabled={!entitlements?.canDelete || numberOfSelectedFiles < 1}
          >
            <DeleteIcon />
            Delete Selected
          </LoadingButton>
        </div>
      </div>

      <Stack flexDirection="row" flexWrap="wrap" columnGap={2}>
        {deliverables.map((file) => {
          const fileId = getFileId(file);

          const embargoDate =
            "embargoDateTime" in file && file.embargoDateTime
              ? new Date(file.embargoDateTime).toLocaleDateString()
              : null;

          return (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                display: "flex",
                position: "relative",
                flexDirection: "column",
                width: "276px",
                height: "inherit",
                marginBottom: "24",
                marginRight: "12",
                ":hover": {
                  cursor: hasEntitlements ? "pointer" : "initial",
                },
              }}
              key={fileId}
              onMouseEnter={() => {
                setIsHovered(fileId);
              }}
              onMouseLeave={() => {
                setIsHovered("");
              }}
              onClick={() => {
                if (!disableView) {
                  setIsUploadedModalOpen(true);
                }

                setIsSelected(fileId);
              }}
              onContextMenu={(event) =>
                !canDoWriteThings && event.preventDefault()
              }
            >
              <Thumbnail
                fileUploadId={fileId}
                filename={file.uploadFilename}
                size={{
                  width: "285px",
                  height: "180px",
                }}
                loadingImg={() => onImageLoaded(fileId)}
                contentType={file.contentType}
                isUploaded
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "8px",
                  fontSize: "13px",
                }}
              >
                <p
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    margin: "0 5px 0 0",
                    fontWeight: "bold",
                  }}
                  className="bytes"
                >
                  {file.uploadFilename}
                </p>
                <p
                  style={{
                    color: `595B63`,
                    margin: "0",
                  }}
                >
                  {formatBytes(file.size)}
                </p>
              </div>
              {embargoDate ? (
                <div
                  style={{
                    backgroundColor: `#FFCC26`,
                    fontSize: `0.833em`,
                    height: "12%",
                    marginTop: "12px",
                    right: "4%",
                    borderRadius: "4px",
                    padding: "2px 5px 0",
                    position: "absolute",
                  }}
                >
                  <p style={{ margin: 0 }}>{embargoDate}</p>
                </div>
              ) : null}
              {canDoWriteThings && loadedImages.includes(fileId) && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.hummingBird,
                    position: loadedImages ? "absolute" : "relative",
                    height: loadedImages ? "180px" : "150px",
                    width: loadedImages ? "19%" : "48px",
                    marginTop: loadedImages ? "0" : "-154px",
                    right: loadedImages ? "81%" : "0",
                    float: "left",
                    borderRadius: "6px",
                    visibility:
                      isHovered || isFileChecked(fileId) || areAllFilesSelected
                        ? "visible"
                        : "hidden",
                    opacity:
                      isHovered || isFileChecked(fileId) || areAllFilesSelected
                        ? "0.7"
                        : "0",
                    transition:
                      "opacity 0.25s ease-in-out, visibility 0.25s ease-out",
                  }}
                >
                  <Checkbox
                    id="embargo-image-checkbox"
                    checked={isFileChecked(fileId)}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                      checked,
                    ) => {
                      event.stopPropagation();
                      handleFileSelect({
                        fileId,
                        isSelected: checked,
                      });
                    }}
                  />
                </Box>
              )}
            </Stack>
          );
        })}
        {isUploadedModalOpen && canDoWriteThings && (
          <Dialog
            open={isUploadedModalOpen}
            onClose={() => setIsUploadedModalOpen(false)}
            maxWidth="lg"
            sx={{}}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              height={80}
              paddingTop={2}
              paddingX={4}
            >
              <Typography
                display="flex"
                flexDirection="row"
                marginTop={2}
                marginLeft={1}
              >
                {brandName}
                {seriesDetails && (
                  <>
                    <KeyboardArrowRightIcon sx={{ position: "relative" }} />
                    Series {seriesDetails.seriesNumber}
                  </>
                )}
                {titleDetails && (
                  <>
                    <KeyboardArrowRightIcon sx={{ position: "relative" }} />
                    Episode {titleDetails.episodeNumber} - {titleDetails.name}
                  </>
                )}
              </Typography>

              <CloseIcon
                sx={{ marginTop: 2 }}
                onClick={() => setIsUploadedModalOpen(false)}
              />
            </Stack>
            {deliverables.map((file) => {
              const fileId = getFileId(file);

              const embargoDate =
                "embargoDateTime" in file && file.embargoDateTime
                  ? new Date(file.embargoDateTime).toLocaleDateString()
                  : null;

              if (fileId !== isSelected) {
                return null;
              }

              return (
                <React.Fragment key={fileId}>
                  <Box>
                    <Thumbnail
                      key={fileId}
                      fileUploadId={fileId}
                      filename={file.uploadFilename}
                      size={{
                        width: "auto",
                        height: "500px",
                      }}
                      loadingImg={() => onImageLoaded(fileId)}
                      contentType={file.contentType}
                      style={{ maxWidth: "inherit" }}
                      isUploaded
                    />
                  </Box>
                  <Stack padding={4} maxHeight={132} width={900}>
                    {embargoDate ? (
                      <>
                        <Stack flexDirection="row">
                          <Typography variant="h5">Embargo date:</Typography>
                          &nbsp; &nbsp;
                          <Box bgcolor="#FFCC26" borderRadius={2} padding={1}>
                            {embargoDate}
                          </Box>
                        </Stack>
                        <Typography>
                          This image is under embargo until the date shown,
                          please be aware that this image should not be released
                          to the public until after this date.
                        </Typography>
                      </>
                    ) : null}
                  </Stack>
                </React.Fragment>
              );
            })}
          </Dialog>
        )}
        <ModalWrapper />
      </Stack>
    </div>
  );
}
