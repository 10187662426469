import { FormHelperText, OutlinedInput } from "@mui/material";
import { FormikHandlers } from "formik";
import { FormFieldLabel } from "../../../componentsV2/FormFieldLabel";
import { UseUpdateRiskArgsNew } from "../../../hooks";
import { NotificationFormFieldProps } from "../../../types/types";
import { DEFAULT_NPNF_VERSION } from "../notificationForm.helper";

interface Props {
  label: string;
  error: string | undefined;
  shouldDisplayErrors: boolean;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  isRequired: boolean;
  value: string | undefined;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  helperText?: string;
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

type SingleLineTextFieldProps = NotificationFormFieldProps<Props>;

export function SingleLineTextField({
  version,
  fieldInfo,
  label,
  error,
  shouldDisplayErrors,
  handleChange,
  handleBlur,
  isRequired,
  value,
  placeholder,
  startAdornment,
  helperText,
  autosaveForm,
}: SingleLineTextFieldProps) {
  const inputId = `${fieldInfo.sectionId}.${fieldInfo.fieldName}`;
  const helperTextId = `${inputId}-helper`;

  return (
    <FormFieldLabel inputId={inputId} label={label} required={isRequired}>
      <OutlinedInput
        disabled={version !== DEFAULT_NPNF_VERSION}
        type="text"
        id={inputId}
        name={inputId}
        required={isRequired}
        multiline={false}
        fullWidth
        value={value || ""}
        onChange={handleChange}
        onBlur={(event) => {
          handleBlur(event);
          autosaveForm({
            [fieldInfo.sectionId]: {
              [fieldInfo.fieldName]: event.target.value,
            },
          });
        }}
        aria-describedby={helperTextId}
        error={shouldDisplayErrors && Boolean(error)}
        placeholder={placeholder}
        startAdornment={startAdornment}
      />

      <HelperText
        error={error}
        shouldDisplayErrors={shouldDisplayErrors}
        id={helperTextId}
        helperText={helperText}
      />
    </FormFieldLabel>
  );
}

type HelperTextProps = Pick<
  SingleLineTextFieldProps,
  "shouldDisplayErrors" | "error" | "helperText"
> & { id: string };

function HelperText({
  shouldDisplayErrors,
  error,
  helperText,
  id,
}: HelperTextProps) {
  if (shouldDisplayErrors && error) {
    return (
      <FormHelperText id={id} error={true}>
        {error}
      </FormHelperText>
    );
  }

  if (helperText) {
    return (
      <FormHelperText id={id} error={false}>
        {helperText}
      </FormHelperText>
    );
  }

  return null;
}
