import * as Yup from "yup";

export const dutyOfCareSchema = Yup.object().shape({
  degreeOfControl: Yup.string().required("This is a required field"),
  exposure: Yup.string().required("This is a required field"),
  livingArrangements: Yup.string().required("This is a required field"),
  mentalHealthAdvisor: Yup.string().required("This is a required field"),
  mentalHealthAdvisorEmail: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),
  participantLocation: Yup.string().required("This is a required field"),
  popularityProfile: Yup.string().required("This is a required field"),
  participantDemographic: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
});

export type DutyOfCareSection = Yup.InferType<typeof dutyOfCareSchema>;
