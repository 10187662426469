import { useQuery, UseQueryResult } from "react-query";
import { getVideoAssets } from "../../api/browseassets";
import { VideoAssetsResponsePayload } from "../../types/types";

export const useVideoAssets = (
  titlesCcid: string | undefined,
): UseQueryResult<VideoAssetsResponsePayload> =>
  useQuery(
    ["getBrowseAssets", titlesCcid],
    () => getVideoAssets(titlesCcid as string),
    { enabled: Boolean(titlesCcid) },
  );
