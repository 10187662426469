import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getMetadata } from "../../api/portal";
import { Metadata } from "../../types/types";

export const useMetadata = (
  ccid: string | undefined,
): UseQueryResult<Metadata, AxiosError> =>
  useQuery(["metadata", ccid], () => getMetadata(ccid as string), {
    enabled: ccid !== undefined,
  });
