import { FC, useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { SearchedUser } from "../../../../types/types";
import { ListUsers } from "../ListUsers";

interface RemoveCollaboratorProps {
  selectedIds: string[];
  users: SearchedUser[];
  onRemove(users: SearchedUser[]): void;
  onCancel(): void;
}

export const RemoveCollaborator: FC<RemoveCollaboratorProps> = ({
  selectedIds,
  users,
  onRemove,
  onCancel,
}) => {
  const [removedUsers, setRemovedUsers] = useState<SearchedUser[]>([]);

  useEffect(() => {
    setRemovedUsers(users.filter(({ id }) => selectedIds.includes(id)));
  }, [selectedIds, users]);

  return (
    <Stack spacing={2}>
      <Typography>
        Are you sure that you would like to remove the following people from
        being able to edit productions for this label on Studios Portal?
      </Typography>
      <ListUsers
        selectedUsers={removedUsers}
        onRemove={(user) => {
          setRemovedUsers(removedUsers.filter(({ id }) => id !== user.id));
        }}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={removedUsers?.length <= 0}
          onClick={() => {
            onRemove(removedUsers);
          }}
        >
          Remove
        </Button>
      </Stack>
    </Stack>
  );
};
