import { FC, useState } from "react";
import {
  Typography,
  Container,
  Stack,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
  Link,
  Box,
  Tooltip,
  IconButton,
  Dialog,
  Alert,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Close } from "@mui/icons-material";
import { useFormikContext, Field } from "formik";
import { ProductionPrinciplesFormDataFormik } from "../../types/types";
import { useProductionPrinciplesFormSubmission } from "../../hooks";
import {
  transformFieldForServer,
  transformFormDataToServer,
} from "./transformFormDataToServer";

interface ProductionPrinciplesPageOneFormikProps {
  ccid: string;
  provisional: boolean;
}

export const ProductionPrinciplesPage1: FC<
  ProductionPrinciplesPageOneFormikProps
> = ({ ccid, provisional }) => {
  const [open, setOpen] = useState(false);
  const form = useFormikContext<ProductionPrinciplesFormDataFormik>();
  const { mutate: updateProductionPrinciplesForm } =
    useProductionPrinciplesFormSubmission();
  const handleCheckboxDataChange = (
    fieldName: keyof ProductionPrinciplesFormDataFormik,
    event: React.ChangeEvent<HTMLInputElement>,
    currentValue: string[],
  ) => {
    const { checked } = event.target;
    const changedValue = event.target.value;
    let newValue: string[] = [...currentValue];
    if (checked) {
      newValue.push(changedValue);
    } else {
      newValue = newValue.filter((v) => v !== changedValue);
    }
    form.setFieldValue(fieldName, newValue);
    updateProductionPrinciplesForm({
      ccid,
      formData: transformFieldForServer(fieldName, newValue, true),
      autosave: true,
      provisional,
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={{ p: 2 }}>
          <Alert severity="info">
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography variant="body2">
                If you would like additional help with this, please email{" "}
                <strong>Nahrein.Kemp@itv.com</strong> or{" "}
                <strong>Sam.Tatlow@itv.com</strong>
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                color="primary"
              >
                <Close />
              </IconButton>
            </Stack>
          </Alert>
        </Box>
      </Dialog>
      <Container sx={{ background: "white", my: 2 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">
            Diamond Data
            <Tooltip title="help">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography>
            Diamond is a single online system used by ITV, BBC, Channel 4,
            Channel 5, Sky and UKTV to obtain consistent diversity data of their
            programems. ITV requires suppliers to collect diversity data for
            anyone working on an ITV production, both on and off-screen.
          </Typography>
          <Typography>
            Diamond training modules can be found{" "}
            <Link
              href="https://creativediversitynetwork.com/diamond/"
              target="_blank"
              rel="noreferrer"
              color="secondary"
            >
              here
            </Link>
          </Typography>
          <FormControl
            error={!!form.errors.diamondDataConfirmation}
            required
            component="fieldset"
            sx={{ m: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">
              Please confirm that your production has access to the Diamond Data
              form from Silvermouse and that you will encourage all cast and
              crew to fill it in.
            </FormLabel>
            <Box>
              <Field
                name="diamondDataConfirmation"
                type="checkbox"
                as={FormControlLabel}
                control={<Checkbox />}
                label="Yes, I can confirm that we have access to the form and that we will encourage all cast and crew to fill it in."
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateProductionPrinciplesForm({
                    ccid,
                    formData: transformFieldForServer(
                      "diamondDataConfirmation",
                      !form.values.diamondDataConfirmation,
                      true,
                    ),
                    autosave: true,
                    provisional,
                  });
                  form.setFieldValue(
                    "diamondDataConfirmation",
                    !form.values.diamondDataConfirmation,
                  );
                }}
              />
            </Box>
          </FormControl>
        </Stack>
      </Container>
      <Container sx={{ background: "white", my: 2 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">
            Speaking up policy
            <Tooltip title="help">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography>
            ITV's Speaking Up policy is available for all staff and freelancers
            working for ITV’s production labels and on all ITV commissions.
          </Typography>
          <Typography>
            <Link
              href="https://www.itvplc.com/~/media/Files/I/ITV-PLC/Policy%20updates%202022/2023_Speaking%20Up%20Policy_2023%20versiondocx.pdf"
              target="_blank"
              rel="noreferrer"
              color="secondary"
            >
              Click here for more information
            </Link>
          </Typography>
          <Typography>
            The Speaking Up policy sets out the channels that individuals can
            use to raise concerns responsibly and effectively. All ITV
            freelancers and staff have access to Safecall which is an impartial,
            externally run service that enables you to report issues related to
            the working environment in a safe and secure manner. Communications
            to Safecall are treated in the strictest confidence. You can contact
            Safecall by phone on 0800 915 1571 or reports can be made online via{" "}
            <Link
              href="https://www.safecall.co.uk/itv"
              target="_blank"
              rel="noreferrer"
              color="secondary"
            >
              www.safecall.co.uk/itv
            </Link>
          </Typography>
          <FormControl
            error={!!form.errors.speakingUpPolicy}
            required
            component="fieldset"
            sx={{ m: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">
              Please confirm that you have access to the ITV Speaking Up Policy
              and/or Safecall and that you will circulate details to all crew
              and cast (including on callsheets).
            </FormLabel>
            <Box>
              <Field
                name="speakingUpPolicy"
                type="checkbox"
                as={FormControlLabel}
                control={<Checkbox />}
                label="Yes I can confirm we have access to this and will circulate the details"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateProductionPrinciplesForm({
                    ccid,
                    formData: transformFieldForServer(
                      "speakingUpPolicy",
                      !form.values.speakingUpPolicy,
                      true,
                    ),
                    autosave: true,
                    provisional,
                  });
                  form.setFieldValue(
                    "speakingUpPolicy",
                    !form.values.speakingUpPolicy,
                  );
                }}
              />
            </Box>
          </FormControl>
        </Stack>
      </Container>
      <Container sx={{ background: "white", my: 2 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">
            ITV Code of Conduct
            <Tooltip title="help">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography>
            As part of your acceptance to your contract with ITV we ask all of
            our suppliers to adhere to our Code of Conduct.
          </Typography>
          <Typography>
            <Link
              href="https://www.itvplc.com/~/media/Files/I/ITV-PLC/documents/governance/ITV%20Supplier%20Code%20Conduct.pdf"
              target="_blank"
              rel="noreferrer"
              color="secondary"
            >
              Click here for more information
            </Link>
          </Typography>
          <FormControl
            error={!!form.errors.codeOfConduct}
            required
            component="fieldset"
            sx={{ m: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">
              Have you read and understood ITV's Code of Conduct?
            </FormLabel>
            <FormGroup aria-labelledby="conde-of-conduct-checkbox-group">
              <Box>
                <Field
                  name="codeOfConduct"
                  type="checkbox"
                  as={FormControlLabel}
                  control={<Checkbox />}
                  label="Yes I can confirm we have access to this and will circulate the details"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateProductionPrinciplesForm({
                      ccid,
                      formData: transformFieldForServer(
                        "codeOfConduct",
                        !form.values.codeOfConduct,
                        true,
                      ),
                      autosave: true,
                      provisional,
                    });
                    form.setFieldValue(
                      "codeOfConduct",
                      !form.values.codeOfConduct,
                    );
                  }}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Stack>
      </Container>
      <Container sx={{ background: "white", my: 2 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">
            Anti Bullying & harrassment
            <Tooltip title="help">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography sx={{ mr: 0, pr: 0, pb: 0, mb: 0 }}>
            We also ask that all our suppliers are committed to Anti Bullying
            and Harassment. The BFI/BAFTA have published these{" "}
            <Link
              href="https://www.bfi.org.uk/inclusion-film-industry/bullying-harassment-racism-prevention-screen-industries"
              target="_blank"
              rel="noreferrer"
              color="secondary"
            >
              guidelines
            </Link>
          </Typography>
          <FormControl
            error={!!form.errors.antibullyCommitment}
            required
            component="fieldset"
            sx={{ m: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">
              Please select all that apply:
            </FormLabel>
            <FormGroup aria-labelledby="anti-bullying-checkbox-group">
              <FormControlLabel
                key="Confirm"
                control={
                  <Checkbox
                    size="small"
                    name="antibullyCommitment"
                    value="CONFIRM"
                    checked={form.values.antibullyCommitment.includes(
                      "CONFIRM",
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleCheckboxDataChange(
                        "antibullyCommitment",
                        event,
                        form.values.antibullyCommitment,
                      );
                    }}
                    sx={{ ml: 1 }}
                  />
                }
                label="Yes, I can confirm that we have read the guidelines to the BFI/BAFTA Anti Bullying and Harassment Policy"
              />
              <FormControlLabel
                key="HaveOwn"
                control={
                  <Checkbox
                    size="small"
                    name="antibullyCommitment"
                    value="HAVE_OWN"
                    checked={form.values.antibullyCommitment.includes(
                      "HAVE_OWN",
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleCheckboxDataChange(
                        "antibullyCommitment",
                        event,
                        form.values.antibullyCommitment,
                      );
                    }}
                    sx={{ ml: 1 }}
                  />
                }
                label="We have our own Anti Bullying and Harassment Policy"
              />
              <FormControlLabel
                key="Signposted"
                control={
                  <Checkbox
                    size="small"
                    name="antibullyCommitment"
                    value="SIGNPOSTED"
                    checked={form.values.antibullyCommitment.includes(
                      "SIGNPOSTED",
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleCheckboxDataChange(
                        "antibullyCommitment",
                        event,
                        form.values.antibullyCommitment,
                      );
                    }}
                    sx={{ ml: 1 }}
                  />
                }
                label="We have signposted all resources to our cast and crew of the policy"
              />
              <FormControlLabel
                key="Whistleblower"
                control={
                  <Checkbox
                    size="small"
                    name="antibullyCommitment"
                    value="WHISTLEBLOWER"
                    checked={form.values.antibullyCommitment.includes(
                      "WHISTLEBLOWER",
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleCheckboxDataChange(
                        "antibullyCommitment",
                        event,
                        form.values.antibullyCommitment,
                      );
                    }}
                    sx={{ ml: 1 }}
                  />
                }
                label="We have a whistleblower policy which allows people to bring issues to our attention"
              />
              <FormControlLabel
                key="Helpline"
                control={
                  <Checkbox
                    size="small"
                    name="antibullyCommitment"
                    value="HELPLINE"
                    checked={form.values.antibullyCommitment.includes(
                      "HELPLINE",
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleCheckboxDataChange(
                        "antibullyCommitment",
                        event,
                        form.values.antibullyCommitment,
                      );
                    }}
                    sx={{ ml: 1 }}
                  />
                }
                label="We have also added the Film & TV Charity Helpline to our callsheets"
              />
            </FormGroup>
          </FormControl>
        </Stack>
      </Container>
      <Container sx={{ background: "white", my: 2 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">
            Disability inclusion
            <Tooltip title="help">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography>
            ITV is a member of the TV Access Project and is committed to
            embedding Disability equity and accessibility in all of our
            programmes. We expect all of our productions to actively work with
            Deaf, Disabled and/or Neurodivergent people on their production in
            front of and behind the camera. We also ask that all productions
            embed the <strong>5 A's</strong> into their production process.
          </Typography>
          <Typography>
            The <strong>5 A's</strong> are a set of production guidelines for
            Disability inclusion. By adopting the <strong>5 A's</strong> ITV
            asks that you commit to <strong>Anticipating</strong>,{" "}
            <strong>Asking</strong>, <strong>Assessing</strong>,{" "}
            <strong>Adjusting</strong> and <strong>Advocating</strong> when it
            comes to working with disabled talent and providing their access
            requirements. To find out what actions you need to take to implement
            the <strong>5 A's</strong> visit our{" "}
            <Link
              href="https://www.itv.com/inclusion/articles/tv-access-projects-5-as-standards-for-disability-inclusion-in-the-television"
              target="_blank"
              rel="noreferrer"
              color="secondary"
            >
              website
            </Link>
          </Typography>
          <Typography>
            More information about the TV Access Project and the{" "}
            <strong>5 A's</strong> can be found on the{" "}
            <Link
              href="https://www.itv.com/inclusion/articles/broadcasters-back-the-launch-of-tv-access-project"
              target="_blank"
              rel="noreferrer"
              color="secondary"
            >
              {" "}
              website
            </Link>
          </Typography>
          <FormControl
            error={!!form.errors.disabilityConfirmation}
            required
            component="fieldset"
            sx={{ m: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">
              Please confirm that you have understood the actions that need to
              be taken to meet ITV's commitment to ensuring disability inclusion
              is embedded into all parts of the production process and that you
              will adopt the
              <strong> 5 A's</strong> into your production
            </FormLabel>
            <FormGroup aria-labelledby="disability-confirmation-checkbox-group">
              <Box>
                <Field
                  name="disabilityConfirmation"
                  type="checkbox"
                  as={FormControlLabel}
                  control={<Checkbox />}
                  label={
                    <Typography>
                      Yes, I confirm that we have understood this and we are
                      committed to embedding the <strong>5 A's</strong> into our
                      production
                    </Typography>
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateProductionPrinciplesForm({
                      ccid,
                      formData: transformFieldForServer(
                        "disabilityConfirmation",
                        !form.values.disabilityConfirmation,
                        true,
                      ),
                      autosave: true,
                      provisional,
                    });
                    form.setFieldValue(
                      "disabilityConfirmation",
                      !form.values.disabilityConfirmation,
                    );
                  }}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Stack>
      </Container>
      <Container sx={{ background: "white", my: 2 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">
            Diversity Commissioning Fund
            <Tooltip title="help">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography>
            In 2022 we launched our Diversity Commissioning Fund reserving £80m
            of our content commissioning budget over the next three years to
            drive change towards racial equity and disability equity in whose
            stories get told and who gets opportunities in TV production. Of
            this amount, at least £20m will be reserved for content made by
            Black, Asian and minority ethnic-led and deaf, disabled and
            neurodiverse-led production companies. More information about the
            Diversity Commissioning Fund can be found on our{" "}
            <Link
              href="https://www.itv.com/inclusion/articles/itvs-gbp80m-diversity-commissioning-fund"
              target="_blank"
              rel="noreferrer"
              color="secondary"
            >
              {" "}
              website
            </Link>
          </Typography>
          <FormControl
            error={!!form.errors.diversityCommissioningFund}
            required
            component="fieldset"
            sx={{ m: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">
              Does your production qualify for the Diversity Commissioning Fund?
            </FormLabel>
            <RadioGroup
              aria-labelledby="my-radio-group"
              name="diversityCommissioningFund"
              value={form.values.diversityCommissioningFund}
              onChange={(event) => {
                form.handleChange(event);
                updateProductionPrinciplesForm({
                  ccid,
                  formData: transformFormDataToServer({
                    ...form.values,
                    diversityCommissioningFund: event.target.value,
                  }),
                  autosave: true,
                  provisional,
                });
              }}
              sx={{ ml: 1 }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio size="small" />}
                label="Yes, this commission is eligible for the Fund"
              />
              <FormControlLabel
                value="No"
                control={<Radio size="small" />}
                label="No, this commission is not eligible"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Container>
    </>
  );
};
