/* eslint-disable no-prototype-builtins */
import React, { FC, useMemo } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Chip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useBrandMetadata } from "../../hooks";
import { Brand } from "../../types/types";
import studioLogos from "../../logos/logos";

const BrandCard: FC<{
  programme: Brand;
  linkToCreateCcid?: boolean;
  onClickFn?: () => void;
}> = ({ programme, linkToCreateCcid = false, onClickFn }) => {
  const navigate = useNavigate();
  const { data: brand, isLoading } = useBrandMetadata(
    programme.brandCcid,
    true,
  );
  const metaDataProductionYears = useMemo(() => {
    if (brand?.productionYears?.earliest && brand.productionYears?.latest) {
      return `${brand.productionYears?.earliest} - ${brand.productionYears?.latest}`;
    }
    return (
      brand?.productionYears?.latest ||
      brand?.productionYears?.earliest ||
      "N/A"
    );
  }, [brand?.productionYears]);

  return (
    <>
      <Card sx={{ my: 1 }}>
        <CardActionArea
          onClick={() => {
            if (onClickFn !== undefined) {
              onClickFn();
            }
            if (brand?.status === "OPPORTUNITY")
              navigate(`/programmes/${programme.brandCcid}/update-series`);
            else
              navigate(
                `/programmes/${programme.brandCcid}${
                  linkToCreateCcid ? "/create-series" : ""
                }`,
              );
          }}
        >
          <CardContent>
            <Grid container spacing="2">
              <Grid item xs={8}>
                <Typography variant="h6">{programme.brandName}</Typography>
                {isLoading || !brand ? (
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={
                      <Divider
                        sx={{ bgcolor: "black" }}
                        orientation="vertical"
                        flexItem
                      />
                    }
                  >
                    <Skeleton variant="text" sx={{ width: 40 }} />
                    <Skeleton variant="text" sx={{ width: 65 }} />
                    <Skeleton variant="text" sx={{ width: 40 }} />
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={
                      <Divider
                        sx={{ bgcolor: "black" }}
                        orientation="vertical"
                        flexItem
                      />
                    }
                  >
                    <Typography variant="caption">
                      {brand.programmeId}
                    </Typography>
                    <Typography variant="caption">
                      {brand.productionYears ? metaDataProductionYears : null}
                    </Typography>
                    <Typography variant="caption">
                      {brand.seriesCount} series
                    </Typography>
                    <Typography variant="caption">
                      {brand.specialsCount} specials
                    </Typography>
                    {brand?.status === "OPPORTUNITY" ? (
                      <Chip
                        sx={{ margin: 1 }}
                        label="Requires Update"
                        variant="outlined"
                      />
                    ) : (
                      <></>
                    )}
                  </Stack>
                )}
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {brand?.productionLabels[0] !== undefined &&
                  studioLogos.hasOwnProperty(brand?.productionLabels[0]) && (
                    <Box
                      maxHeight={50}
                      maxWidth={90}
                      component="img"
                      src={studioLogos[brand?.productionLabels[0]]}
                      alt={brand?.productionLabels[0]}
                    />
                  )}
                {brand?.productionLabels[1] !== undefined &&
                  brand?.productionLabels[0] !== brand?.productionLabels[1] &&
                  studioLogos.hasOwnProperty(brand?.productionLabels[1]) && (
                    <Box
                      maxHeight={50}
                      maxWidth={90}
                      component="img"
                      src={studioLogos[brand?.productionLabels[1]]}
                      alt={brand?.productionLabels[1]}
                      ml={2}
                    />
                  )}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default BrandCard;
