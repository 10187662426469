/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getCloseDownFormData } from "../../api/storyboard";

export const useCloseDown = (
  ccid: string | undefined,
  type = "series",
): UseQueryResult<Record<string, any>, AxiosError> =>
  useQuery(
    ["closeDown", type, ccid],
    () => getCloseDownFormData(ccid as string, type),
    { enabled: Boolean(ccid) },
  );
