import {
  Typography,
  Stack,
  Card,
  CardContent,
  InputAdornment,
  Link,
} from "@mui/material";
import { useFormikContext } from "formik";
import { NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { CyberSecuritySectionV3 } from "../yupSchemas/cyberSecuritySchemaV3";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { MailOutline, PersonOutline } from "@mui/icons-material";
import { EmailField } from "../formFields/EmailField";
import { MultiLineTextField } from "../formFields/MultiLineTextField";
import { CheckboxField } from "../formFields/CheckboxField";
import { YesNoField } from "../formFields/YesNoField";
import { UseUpdateRiskArgsNew } from "../../../hooks";

const appListOptions = [
  {
    label: "Whatsapp",
    value: "Whatsapp",
  },
  {
    label: "Signal",
    value: "Signal",
  },
  {
    label: "Dropbox",
    value: "Dropbox",
  },
  {
    label: "Google Drive",
    value: "GoogleDrive",
  },
  {
    label: "One Drive",
    value: "OneDrive",
  },
  {
    label: "Box",
    value: "Box",
  },
  {
    label: "WeTransfer",
    value: "WeTransfer",
  },
  {
    label: "ETribez",
    value: "ETribez",
  },
  {
    label: "Zoom",
    value: "Zoom",
  },
  {
    label: "Microsoft Teams",
    value: "MicrosoftTeams",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const dataStorageOptions = [
  {
    label: "Adobe Sign",
    value: "AdobeSign",
  },
  {
    label: "Iconik",
    value: "Iconik",
  },
  {
    label: "PIX",
    value: "PIX",
  },
  {
    label: "Prodicle",
    value: "Prodicle",
  },
  {
    label: "Scenechronize",
    value: "Scenechronize",
  },
  {
    label: "Set Keeper",
    value: "SetKeeper",
  },
  {
    label: "Team Engine",
    value: "TeamEngine",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const dataAccessOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not Yet Known",
    value: "TBC",
  },
];

const aiToolsOptions = [
  {
    label: "ChatGPT",
    value: "ChatGPT",
  },
  {
    label: "Adobe Firefly",
    value: "AdobeFirefly",
  },
  {
    label: "Trint",
    value: "Trint",
  },
  {
    label: "Papercup",
    value: "Papercup",
  },
  {
    label: "Autopod",
    value: "Autopod",
  },
  {
    label: "ModeOn Pro",
    value: "ModeOnPro",
  },
  {
    label: "Valossa",
    value: "Valossa",
  },
  {
    label: "Midjourney",
    value: "Midjourney",
  },
  {
    label: "Miro",
    value: "Miro",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "No AI tools used",
    value: "None",
  },
];

const aiOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

interface CyberSecurityPageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function CyberSecurityPageV3({ autosaveForm }: CyberSecurityPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.cyberSecurity || {};
  const errors = formik.errors.cyberSecurity || {};

  const checkboxesChangeHandler = (fieldName: keyof CyberSecuritySectionV3) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`cyberSecurity.${fieldName}`).setValue(values);
    };
  };

  const yesNoChangeHandler = (fieldName: keyof CyberSecuritySectionV3) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`cyberSecurity.${fieldName}`).setValue(isYes);
    };
  };

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      cyberSecurity: {
        ...formData.cyberSecurity,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Cyber Risk</Typography>

              <Typography variant="body1">
                This information will help us make sure we are managing the
                cyber risks involved in our production activities.
              </Typography>

              <SingleLineTextField
                version="v3"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "cyberResponsible",
                }}
                label="Who is responsible for reporting cyber security incidents or data breaches on this production?"
                error={errors.cyberResponsible}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.cyberResponsible}
                placeholder="Name"
                startAdornment={
                  <InputAdornment position="start">
                    <PersonOutline />
                  </InputAdornment>
                }
                helperText="There is a 72 hour window to report breaches to cybersecurity@itv.com. The contact should be the person on the production responsible for reporting this."
                autosaveForm={wrappedAutosaveForm}
              />

              <EmailField
                version="v3"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "cyberResponsibleEmail",
                }}
                label="What is the email of the person responsible for cyber security on this production?"
                error={errors.cyberResponsibleEmail}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.cyberResponsibleEmail}
                placeholder="example@itv.com"
                startAdornment={
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                }
                autosaveForm={wrappedAutosaveForm}
              />

              <CheckboxField
                version="v3"
                fieldInfo={{ sectionId: "cyberSecurity", fieldName: "appList" }}
                label="Please identify any existing and new key technology, mobile application or online internet services which will be used on this production and its purpose."
                error={
                  Array.isArray(errors.appList)
                    ? errors.appList[0]
                    : errors.appList
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("appList")}
                isRequired={true}
                value={values.appList}
                options={appListOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.appList && values.appList.includes("Other") && (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "cyberSecurity",
                    fieldName: "appListDetail",
                  }}
                  label="Please list any technology not included in the options above:"
                  error={errors.appListDetail}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={false}
                  value={values.appListDetail}
                  placeholder="Please list"
                  autosaveForm={wrappedAutosaveForm}
                />
              )}

              <CheckboxField
                version="v3"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "dataStorage",
                }}
                label="Will any existing or new key suppliers or third parties be used to access or store data/content?"
                error={
                  Array.isArray(errors.dataStorage)
                    ? errors.dataStorage[0]
                    : errors.dataStorage
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("dataStorage")}
                isRequired={true}
                value={values.dataStorage}
                options={dataStorageOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              <SingleLineTextField
                version="v3"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "dataStorageSupplier",
                }}
                label="Please list any existing or new suppliers or third parties who will be used to access or store data/content:"
                error={errors.dataStorageSupplier}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.dataStorageSupplier}
                placeholder="Please list"
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "incidentResponse",
                }}
                label="Does the production have a clear understanding of its responsibilities and procedures in the event of a cyber incident?"
                error={errors.incidentResponse}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("incidentResponse")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.incidentResponse}
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "cyberResponseGuidelines",
                }}
                label="Have you read ITV's Cyber Incident Response Guidelines?"
                error={errors.cyberResponseGuidelines}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("cyberResponseGuidelines")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.cyberResponseGuidelines}
                autosaveForm={wrappedAutosaveForm}
              />

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "dataAccess",
                }}
                label="Will data or content be edited, stored or accessed from a different country to which it is stored?"
                error={errors.dataAccess}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.dataAccess}
                options={dataAccessOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.dataAccess === "Yes" ? (
                <MultiLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "cyberSecurity",
                    fieldName: "dataStorageCountry",
                  }}
                  label="Where is the data/content being stored, by whom and in which country?"
                  error={errors.dataStorageCountry}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.dataStorageCountry}
                  placeholder="Please list"
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <RadioField
                version="v3"
                fieldInfo={{ sectionId: "cyberSecurity", fieldName: "ai" }}
                label="Will you be using generative AI products during any production stages?"
                error={errors.ai}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.ai}
                options={aiOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.ai === "Yes" ? (
                <CheckboxField
                  version="v3"
                  fieldInfo={{
                    sectionId: "cyberSecurity",
                    fieldName: "aiTools",
                  }}
                  label="What AI Tools will be used as part of this production?"
                  error={
                    Array.isArray(errors.aiTools)
                      ? errors.aiTools[0]
                      : errors.aiTools
                  }
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={checkboxesChangeHandler("aiTools")}
                  isRequired={true}
                  value={values.aiTools}
                  options={aiToolsOptions}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              {values.aiTools && values.aiTools.includes("Other") ? (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "cyberSecurity",
                    fieldName: "aiProducts",
                  }}
                  label="Please list the other AI Products:"
                  error={errors.aiProducts}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={false}
                  value={values.aiProducts}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <div>
                <Typography variant="body1">
                  Please read the following guidance on AI:
                </Typography>
                <ul>
                  <li>
                    <Link
                      href="https://my.itv.com/Home/Index/2be716f9-42e7-4723-b840-802e8497ed6c"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How to use AI safely and responsibly at ITV
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://docs.google.com/document/d/1gBF1FwGJxts5oRFRogqr9dAlU1mxmRBwo-X4vkVe6xE/edit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Exploring Generative AI (GenAI) Opportunities Safely &
                      Responsibly
                    </Link>
                  </li>
                </ul>
              </div>

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "aiGuidance",
                }}
                label="Have you read the above guidance on AI?"
                error={errors.aiGuidance}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("aiGuidance")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.aiGuidance}
                autosaveForm={wrappedAutosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
