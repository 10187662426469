/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getIconikUsers } from "../../api/iconik";
import { IconikUserGroupResponsePayload } from "../../types/types";

export const useGetIconikUsers = (
  ccid: string,
): UseQueryResult<IconikUserGroupResponsePayload, AxiosError> =>
  useQuery(["getIconikUsers", ccid], () => getIconikUsers(ccid));
