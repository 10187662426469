import * as React from "react";
import { Box, FormLabel, styled } from "@mui/material";
import theme from "../utils/theme";

interface FormFieldLabelProps {
  inputId: string;
  label: string;
  required: boolean | undefined;
  children: React.ReactNode;
}

export const StyledFormLabel = styled(FormLabel)(() => ({
  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main,
  },
}));

export function FormFieldLabel({
  inputId,
  label,
  required,
  children,
}: FormFieldLabelProps) {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <StyledFormLabel
        htmlFor={inputId}
        required={required}
        sx={{ marginBottom: "4px" }}
      >
        {label}
      </StyledFormLabel>

      {children}
    </Box>
  );
}
