/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";

import { useQueryClient } from "react-query";

import { useParams } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  TextField,
  Typography,
  Container,
  Grid,
  Button,
  Alert,
  Stack,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FormHeader } from "../../../../../components";
import {
  NotificationService,
  CONTRIBUTORS_CHANGE,
} from "../../../../../services";
import { ContributorUpdateProps } from "../../../../../types/types";
import {
  useUpdateSynopsis,
  useUpdateContributors,
} from "../../../../../hooks/mutations";
import { clone } from "../../../../../utils/appHelper";
import { cleanContributors } from "../../Contributors/contributor.helper";
import {
  useContributors,
  useSynopsis,
  useTitleMetadata,
} from "../../../../../hooks";
import { BillingsSideNav } from "./BillingsSideNav";
import BillingsContributors from "./BillingsContributors";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";
import { Form, Formik } from "formik";

const synopsisSchema = Yup.object().shape({
  synopsisShort: Yup.string().required(),
  synopsisMedium: Yup.string().defined().default(""),
  synopsisLong: Yup.string().defined().default(""),
  narrative: Yup.string().defined().default(""),
});

export type SynopsisValues = Yup.InferType<typeof synopsisSchema>;

export const BillingsNew: FC = () => {
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [billingsStep, setBillingsStep] = useState(0);
  const [contributors, setContributors] = useState<ContributorUpdateProps[]>();
  const [elsewhereContributors, setElsewhereContributors] = useState<
    ContributorUpdateProps[]
  >([]);
  const [contributorsChanged, setContributorsChanged] =
    useState<boolean>(false);
  const [latestContributors, setLatestContributors] = useState<any>();
  const { programmeCcid, seriesCcid, titleCcid } = useParams<string>();
  const activeCcid = titleCcid || seriesCcid || programmeCcid;
  const brandsOrSeriesUrl = programmeCcid === activeCcid ? "brands" : "series";
  const synopsisUrlLevel =
    titleCcid === activeCcid ? "titles" : brandsOrSeriesUrl;

  const queryClient = useQueryClient();

  const { data: titleData } = useTitleMetadata(titleCcid);

  const { data: contributorData, isLoading: isContributorsDataLoading } =
    useContributors(titleCcid);

  const { data: synopsisData, isLoading: isLoadingSynopsisData } = useSynopsis(
    activeCcid,
    synopsisUrlLevel,
  );

  const { mutate: saveContributors, isLoading: savingContributors } =
    useUpdateContributors(
      activeCcid as string,
      cleanContributors(clone(contributorData?.contributors || [])),
      cleanContributors(
        contributors as ContributorUpdateProps[],
        true,
        elsewhereContributors,
      ),
      () => {
        queryClient.invalidateQueries("getContributors");
        setTimeout(() => {
          setContributorsChanged(false);
        }, 500);
        enqueueSnackbar("Billings data has been saved", { variant: "success" });
      },
      () => {
        enqueueSnackbar("Error saving Billings", {
          variant: "error",
          action: SnackbarDismiss,
          persist: true,
        });
      },
    );

  const { mutate: saveSynopsis, isLoading: savingSynopsis } = useUpdateSynopsis(
    synopsisUrlLevel,
    activeCcid as string,
    () => {
      queryClient.invalidateQueries(synopsisUrlLevel);
    },
    () => {
      enqueueSnackbar("Error saving synopsis", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );

  const saveBillings = (values: SynopsisValues) => {
    saveSynopsis({
      synopsisShort: values.synopsisShort.trim(),
      synopsisMedium: values.synopsisMedium.trim(),
      synopsisLong: values.synopsisLong.trim(),
      narrative: values.narrative.trim(),
    });
    saveContributors({});
  };

  useEffect(() => {
    const handleContributorsChange = ({
      contributors: newContributors,
    }: any) => {
      setContributors(newContributors);
      setContributorsChanged(true);
    };

    NotificationService.sub(CONTRIBUTORS_CHANGE, handleContributorsChange);

    return () => {
      NotificationService.destroy([CONTRIBUTORS_CHANGE]);
    };
  }, []);

  useEffect(() => {
    if (contributorData) {
      setLatestContributors(contributorData as any);
      setContributors(
        (contributorData?.contributors || []) as ContributorUpdateProps[],
      );
    }
  }, [contributorData, contributorsChanged]);

  if (isLoadingSynopsisData || isContributorsDataLoading) {
    return (
      <>
        <FormHeader formTitle="Billings" showFormStatus={false} />
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 192px)",
            overflowY: "auto",
          }}
        >
          <Container fixed disableGutters sx={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          </Container>
        </Box>
      </>
    );
  }

  if (!synopsisData) {
    return (
      <>
        <FormHeader formTitle="Billings" showFormStatus={false} />
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 192px)",
            overflowY: "auto",
          }}
        >
          <Container fixed disableGutters sx={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Alert severity="error">
                There was an error retrieving the synopsis data
              </Alert>
            </Box>
          </Container>
        </Box>
      </>
    );
  }

  if (!contributorData) {
    return (
      <>
        <FormHeader formTitle="Billings" showFormStatus={false} />
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 192px)",
            overflowY: "auto",
          }}
        >
          <Container fixed disableGutters sx={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Alert severity="error">
                There was an error retrieving the contributors
              </Alert>
            </Box>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <FormHeader formTitle="Billings" showFormStatus={false} />
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 192px)",
          overflowY: "auto",
        }}
      >
        <Container fixed disableGutters>
          <Formik<SynopsisValues>
            initialValues={{
              synopsisShort: synopsisData.synopsisShort || "",
              synopsisMedium: synopsisData.synopsisMedium || "",
              synopsisLong: synopsisData.synopsisLong || "",
              narrative: synopsisData.narrative || "",
            }}
            onSubmit={(values) => {
              saveBillings(values);
            }}
            validationSchema={synopsisSchema}
          >
            {({ values, errors, handleChange, validateForm, submitCount }) => {
              const shouldDisplayErrors = submitCount > 0 || hasAttemptedSubmit;

              return (
                <Form>
                  <Grid container>
                    <Grid item xs={3}>
                      <BillingsSideNav
                        setBillingsStep={setBillingsStep}
                        saveBillings={saveBillings}
                        savingContributors={savingContributors}
                        savingSynopsis={savingSynopsis}
                        synopsisValues={values}
                        validateSynopsisForm={validateForm}
                        handleSubmitAttempt={() => {
                          setHasAttemptedSubmit(true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} sx={{ px: 3 }}>
                      <Stack spacing={1} alignItems="left">
                        <Typography variant="h4">
                          Section {billingsStep + 1} of 2:{" "}
                          {billingsStep === 0 ? "Synopsis" : "Contributors"}
                        </Typography>
                        {billingsStep === 0 ? (
                          <Alert
                            severity="info"
                            action={
                              <Button
                                variant="outlined"
                                size="small"
                                endIcon={<LaunchIcon />}
                                onClick={() =>
                                  window.open(
                                    "https://docs.google.com/document/d/1QI1QG0SlbzpYu5iJRfFvDSMHXGa8-rtK/edit",
                                    "_blank",
                                  )
                                }
                              >
                                Guidance & Examples
                              </Button>
                            }
                          >
                            Find helpful guidance on how to fill out your
                            synopsis here!
                          </Alert>
                        ) : (
                          <></>
                        )}
                      </Stack>

                      {billingsStep === 0 ? (
                        <Stack alignItems="space-between">
                          <Container sx={{ bgcolor: "white", py: 2, my: 2 }}>
                            <Stack spacing={1}>
                              <Typography variant="h5">
                                Programme billing synopsis
                              </Typography>
                              <Typography variant="body2">
                                Please ensure that all three billing synopses
                                are filled in accurately. These may be used
                                across print listings, EPG, and on demand.
                              </Typography>
                            </Stack>

                            <Stack>
                              <FormLabel
                                sx={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                  pt: 2,
                                }}
                              >
                                Short
                              </FormLabel>
                              <TextField
                                name="synopsisShort"
                                error={
                                  shouldDisplayErrors &&
                                  Boolean(errors.synopsisShort)
                                }
                                placeholder="Enter description"
                                value={values.synopsisShort}
                                onChange={handleChange}
                                inputProps={{ maxLength: 90 }}
                                helperText={
                                  shouldDisplayErrors &&
                                  values.synopsisShort.length === 0
                                    ? "Short Synopsis is required"
                                    : `${values.synopsisShort.length} / 90 characters`
                                }
                                FormHelperTextProps={{
                                  sx: { textAlign: "right", width: "98%" },
                                }}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                              />
                            </Stack>

                            <Stack>
                              <FormLabel
                                sx={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                  pt: 2,
                                }}
                              >
                                Medium
                              </FormLabel>
                              <TextField
                                name="synopsisMedium"
                                error={
                                  shouldDisplayErrors &&
                                  Boolean(errors.synopsisMedium)
                                }
                                placeholder="Enter description"
                                value={values.synopsisMedium}
                                onChange={handleChange}
                                inputProps={{ maxLength: 180 }}
                                helperText={`${values.synopsisMedium.length} / 180 characters`}
                                FormHelperTextProps={{
                                  sx: { textAlign: "right", width: "98%" },
                                }}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={5}
                              />
                            </Stack>
                          </Container>
                          <Container sx={{ bgcolor: "white", py: 2, my: 2 }}>
                            <Stack>
                              <Typography variant="body2">
                                Note: This longer synopsis is typically intended
                                for press-related consumption.
                              </Typography>
                              <FormLabel
                                sx={{
                                  display: "block",
                                  marginBottom: "0.5rem",
                                  pt: 2,
                                }}
                              >
                                Long
                              </FormLabel>
                              <TextField
                                name="synopsisLong"
                                error={
                                  shouldDisplayErrors &&
                                  Boolean(errors.synopsisLong)
                                }
                                placeholder="Enter description"
                                value={values.synopsisLong}
                                onChange={handleChange}
                                inputProps={{ maxLength: 1000 }}
                                helperText={`${values.synopsisLong.length} / 1000 characters`}
                                FormHelperTextProps={{
                                  sx: { textAlign: "right", width: "98%" },
                                }}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={7}
                              />
                            </Stack>

                            {titleData?.productionLabels.includes(
                              "ITV Sport",
                            ) && (
                              <Stack>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    marginBottom: "0.5rem",
                                  }}
                                >
                                  Narrative
                                </FormLabel>
                                <TextField
                                  name="narrative"
                                  error={
                                    shouldDisplayErrors &&
                                    Boolean(errors.narrative)
                                  }
                                  placeholder="Enter description"
                                  value={values.narrative}
                                  onChange={handleChange}
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={7}
                                />
                              </Stack>
                            )}
                          </Container>
                        </Stack>
                      ) : (
                        <BillingsContributors
                          inModal
                          isContributorsDataLoading={isContributorsDataLoading}
                          contributorsData={latestContributors}
                          hasContributorsChanged={contributorsChanged}
                          setElsewhereContributors={setElsewhereContributors}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Box>
    </>
  );
};
