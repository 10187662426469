import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("recentProductions", "");
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 6 }}>
      <Box textAlign="center">
        <Typography variant="h3">Page not found</Typography>
        <Typography sx={{ my: 2 }}>
          Try navigating to the page again from the homepage or contact us if
          you still have issues.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("/")}
        >
          Return Home
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
