import { UseQueryResult, useQuery } from "react-query";
import { AxiosError } from "axios";
import { getTreatments } from "../../api/portal";
import { Treatments } from "../../types/types";

export const useGetTreatments = (
  ccid: string,
): UseQueryResult<Treatments, AxiosError> => {
  return useQuery(["treatments", ccid], () => getTreatments(ccid));
};
