/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Box, FormLabel, Stack, TextField } from "@mui/material";
import { synopsisConfigs } from "./synopsisConfigs";
import { getNumberOfCharacters } from "../../../../utils/stringHelper";

export type SynopsisTypes = "synopsisShort" | "synopsisMedium" | "synopsisLong";

interface SynopsisProps {
  synopsis: SynopsisTypes;
  setSaveBarIsVisible: (bool: boolean) => void;
  synopsisValue: string;
  handleTextChange: (synopsis: SynopsisTypes, value: string) => void;
  userCanEditSynopsis: boolean;
  hasExceedLimit: boolean;
}

const Synopsis: FC<SynopsisProps> = ({
  setSaveBarIsVisible,
  synopsis,
  synopsisValue,
  handleTextChange,
  userCanEditSynopsis,
  hasExceedLimit,
}) => (
  <>
    {synopsisConfigs[synopsis]?.headerText({ userCanEditSynopsis })}
    <Stack>
      <FormLabel
        error={hasExceedLimit}
        sx={{ display: "block", marginBottom: "0.5rem" }}
      >
        {synopsisConfigs[synopsis].title}
      </FormLabel>
      <TextField
        id={synopsis}
        multiline
        maxRows={synopsisConfigs[synopsis].textAreaHeight}
        minRows={synopsisConfigs[synopsis].textAreaHeight}
        fullWidth
        disabled={!userCanEditSynopsis}
        value={synopsisValue}
        placeholder={
          userCanEditSynopsis
            ? synopsisConfigs[synopsis].placeHolderDescription
            : undefined
        }
        error={hasExceedLimit}
        onChange={(event: any) => {
          handleTextChange(synopsis, event.target.value);
          setSaveBarIsVisible(true);
        }}
        helperText={
          <Stack direction="row" justifyContent="space-between">
            <span>{synopsisConfigs[synopsis].toolTip}</span>

            {userCanEditSynopsis && (
              <Box>
                <Box>
                  {`${getNumberOfCharacters(synopsisValue)} / ${
                    synopsisConfigs[synopsis].lengthLimit
                  } characters`}
                </Box>
              </Box>
            )}
          </Stack>
        }
      />
    </Stack>
  </>
);

export default Synopsis;
