/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { noop } from "../../utils/appHelper";
import { putNotifications } from "../../api/notifications";

export const usePutNotifications = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(
    ({ notificationId, payload }: any) =>
      putNotifications(notificationId, payload),
    {
      onSuccess,
      onError,
    },
  );
