/* eslint-disable @typescript-eslint/no-explicit-any */

export const timeCodeConfig: any = {
  preview: {
    title: "Preview Timecodes",
    description:
      "Please provide the best timecodes for auto-playing previews on streaming platforms.",
  },
  preTitleSequence: {
    title: "Pre Title Sequence Timecodes",
    description: "Please provide the best timecodes for pre title sequence",
  },
  episodeClipTimecode: {
    title: "Episode Clip",
    description:
      "Please provide timecode for an extract clip from this episode",
    hideWarning: true,
    versionSelectionDescription:
      "Which version are you selecting the clip from?",
    hideDropdown: true,
    queryTag: "preview",
  },
  marketingPromoClip: {
    title: "Marketing Promo Clip",
    description: "Please provide the best timecodes for a marketing promo clip",
  },
};
