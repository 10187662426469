import { Close } from "@mui/icons-material";
import { Card, List, ListItem, IconButton, ListItemText } from "@mui/material";
import { SearchedUser } from "../../types/types";

export const ListSelectedUsers = ({
  selectedUsers,
  deslectUser,
}: {
  selectedUsers: SearchedUser[];
  deslectUser: (user: SearchedUser) => void;
}): JSX.Element => (
  <List>
    {selectedUsers.map((user) => (
      <Card key={user.id} sx={{ my: 1 }}>
        <ListItem
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => deslectUser(user)}
            >
              <Close />
            </IconButton>
          }
        >
          <ListItemText
            primary={`${user.firstName} ${user.lastName}`}
            secondary={user.email}
          />
        </ListItem>
      </Card>
    ))}
  </List>
);
