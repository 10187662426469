import { useMutation } from "react-query";
import { ImageState, patchDeleteDeliverableState } from "../../api/images";
import { LevelType, UploadType } from "../../types/types";

interface DeleteFilesArgs {
  level: LevelType;
  ccid: string;
  fileIds: Array<string>;
  deliverableType: UploadType;
}

export function useDeleteFiles() {
  return useMutation(
    ({ fileIds, level, ccid, deliverableType }: DeleteFilesArgs) => {
      const requests = fileIds.map((fileId) => {
        return patchDeleteDeliverableState(
          level,
          ccid,
          fileId,
          ImageState.DELETED,
          undefined,
          deliverableType,
        );
      });

      return Promise.all(requests);
    },
  );
}
