import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { type InsuranceSectionV3 } from "../yupSchemas/insuranceSchemaV3";
import { type NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { YesNoField } from "../formFields/YesNoField";
import { CheckboxField } from "../formFields/CheckboxField";
import { UseUpdateRiskArgsNew } from "../../../hooks";

const insuranceCoverOptions = [
  { label: "ITV", value: "ITV" },
  { label: "Broadcaster / Network", value: "Broadcaster" },
  { label: "TBC", value: "TBC" },
  { label: "Other", value: "Other" },
];

const holdingsOptions = [
  {
    label: "Jewellery, furs or antiques with a value of more than 100,000",
    value: "Jewellery",
  },
  {
    label: "An individual holding more than 2,500 cash at any one time",
    value: "SmallCash",
  },
  {
    label: "Holding more than 75,000 cash on location",
    value: "LargeCash",
  },
  {
    label: "None of these",
    value: "None",
  },
];

interface InsurancePageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function InsurancePageV3({ autosaveForm }: InsurancePageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.insurance || {};
  const errors = formik.errors.insurance || {};

  const checkboxesChangeHandler = (fieldName: keyof InsuranceSectionV3) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`insurance.${fieldName}`).setValue(values);
    };
  };
  const yesNoChangeHandler = (fieldName: keyof InsuranceSectionV3) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`insurance.${fieldName}`).setValue(isYes);
    };
  };

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      insurance: {
        ...formData.insurance,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">
                Cast Insurance (Production Budget Insurance)
              </Typography>

              <Typography variant="body1">
                Cast insurance is in place to provide cover to the budget for
                the additional costs incurred if cast or crew are unable to work
                due to sickness or injury (this does not need to be
                work-related) in extreme cases this could include abandonment of
                a Production due to reliance on key individuals.
              </Typography>

              <Typography variant="body1">
                A higher level of medical confirmation of fitness than the
                standard process is required for more significant financial
                exposure.
              </Typography>

              <Typography variant="body1">
                If you have cast or crew involved in your Production that could
                have a greater financial impact than 500,000 to your production
                if they are unable to work or cause you to abandon you will need
                to declare them below and they will require a medical. The
                insurance team will be in touch to discuss how this will work.
              </Typography>

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "insurance",
                  fieldName: "insuranceCover",
                }}
                label="Which insurance cover will you be using?"
                error={errors.insuranceCover}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.insuranceCover}
                options={insuranceCoverOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.insuranceCover === "Other" ? (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "insurance",
                    fieldName: "otherInsuranceCover",
                  }}
                  label="Other insurance cover - please specify"
                  error={errors.otherInsuranceCover}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={false}
                  value={values.otherInsuranceCover}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <YesNoField
                version="v3"
                fieldInfo={{ sectionId: "insurance", fieldName: "talentLoss" }}
                label="Could losing key talent cost over 500,000 ($/£/€) more than the production budget?"
                error={errors.talentLoss}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("talentLoss")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.talentLoss}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.talentLoss === true ? (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "insurance",
                    fieldName: "talentLossList",
                  }}
                  label="Please list the names of those key individuals"
                  error={errors.talentLossList}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.talentLossList}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <YesNoField
                version="v3"
                fieldInfo={{ sectionId: "insurance", fieldName: "shutdown" }}
                label="Could losing key talent shut down production and make the network abandon the project?"
                error={errors.shutdown}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("shutdown")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.shutdown}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.shutdown === true ? (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "insurance",
                    fieldName: "shutdownDetails",
                  }}
                  label="Please list the names of the key individuals and confirm their roles"
                  error={errors.shutdownDetails}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.shutdownDetails}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <YesNoField
                version="v3"
                fieldInfo={{ sectionId: "insurance", fieldName: "age" }}
                label="Will any cast members be over the age of 75 or under 6?"
                error={errors.age}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("age")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.age}
                autosaveForm={wrappedAutosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing={2}>
              <CheckboxField
                version="v3"
                fieldInfo={{
                  sectionId: "insurance",
                  fieldName: "holdings",
                }}
                label="Will the production be conducting any of the following activities?"
                error={
                  Array.isArray(errors.holdings)
                    ? errors.holdings[0]
                    : errors.holdings
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("holdings")}
                isRequired={true}
                value={values.holdings}
                options={holdingsOptions}
                autosaveForm={wrappedAutosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
