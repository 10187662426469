import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getGroupUsers } from "../../api/accessPermissions";
import { User } from "../../types/types";

export const useGroupUsers = (
  labelId: string | undefined,
): UseQueryResult<User[], AxiosError> =>
  useQuery(["label users", labelId], () => getGroupUsers(labelId as string), {
    enabled: Boolean(labelId),
  });
