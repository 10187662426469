import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import { ContentCopyOutlined, Download } from "@mui/icons-material";

export interface Item {
  id: string;
  label: string;
  link: string;
  active?: boolean;
  onItemClick?: (x: Item) => void;
  type?: "MUI-BUTTON" | "DOWNLOAD-BUTTON";
  disabled?: boolean;
}

export interface SubNavProps {
  sectionTitle?: string;
  items?: Item[];
  onItemClick?(item: Item): void;
}

export const SubNav: FC<SubNavProps> = ({
  sectionTitle,
  items,
  onItemClick,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (item: Item) => {
      if (item.onItemClick) {
        item.onItemClick(item);
      } else if (onItemClick) {
        onItemClick(item);
      } else {
        navigate(item.link);
      }
    },
    [navigate, onItemClick],
  );

  return (
    <Box minWidth={320}>
      <Typography
        fontWeight="bold"
        fontSize="1rem"
        lineHeight="1.5rem"
        marginBottom="1rem"
      >
        {sectionTitle}
      </Typography>
      <Box style={{ flexDirection: "column" }}>
        {items?.map((item) => {
          if (item.type === "MUI-BUTTON") {
            return (
              <Button
                key={item.id}
                variant="text"
                startIcon={<ContentCopyOutlined />}
                sx={{
                  justifyContent: "flex-start",
                }}
                onClick={() => {
                  handleClick(item);
                }}
                disabled={item.disabled}
              >
                {item.label}
              </Button>
            );
          }

          if (item.type === "DOWNLOAD-BUTTON") {
            return (
              <Button
                key={item.id}
                color="secondary"
                variant="outlined"
                startIcon={<Download />}
                sx={{
                  alignSelf: "flex-start",
                }}
                onClick={() => {
                  handleClick(item);
                }}
                disabled={item.disabled}
              >
                {item.label}
              </Button>
            );
          }

          return (
            <Button
              fullWidth
              sx={{
                justifyContent: "flex-start",
                pl: 2,
                borderRadius: 0,
                borderLeft: item.active ? 2 : 0,
                fontWeight: "light",
              }}
              key={item.id}
              onClick={() => {
                handleClick(item);
              }}
            >
              {item.label}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
