import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import {
  DriveResponse,
  ListDriveResponse,
  CreateMyDrivePayload,
} from "../types/types";

const API_BASE = getAPI(SERVICES.google_drive);

export const createDrive = (
  ccid: string,
  level: string,
  driveType: string,
): Promise<DriveResponse> => {
  const url = `${API_BASE}/drives/${driveType}/${level}/${ccid}`;

  return handleRequest.put(url, "").then(({ data }) => data);
};

export const getAllDrives = (
  ccid: string,
  level: string,
): Promise<ListDriveResponse> => {
  const url = `${API_BASE}/drives/${level}/${ccid}`;

  return handleRequest.get(url).then(({ data }) => data);
};

export const createMyDrive = (
  ccid: string,
  payload: CreateMyDrivePayload,
): Promise<AxiosResponse> =>
  handleRequest
    .post(`${API_BASE}/mydrive/series/${ccid}`, payload)
    .then(({ data }) => data);
