import { useState } from "react";
import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
} from "react-dropzone-uploader";
import { HelpOutline } from "@mui/icons-material";
import {
  CircularProgress,
  Tooltip,
  Button,
  Stack,
  Container,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { uploadConfig, UploadConfigKey } from "./upload.config";
import { NoAccess } from "../../../../components/NoAccess";

import CustomInput from "./CustomInput/CustomInput";
import {
  UploadedFonts,
  UploadedImages,
  UploadedScripts,
} from "./UploadedImages/UploadedImages";
import { PreviewModal } from "./PreviewModal/PreviewModal";
import {
  useListDeliverables,
  useImagesEntitlements,
  useSynopsis,
  useBrandMetadata,
  useTitles,
  useSeries,
} from "../../../../hooks/queries";
import theme from "../../../../utils/theme";
import { UploadType } from "../../../../types/types";
import { useUploadFile } from "../../../../hooks";

export type Level = "version" | "titles" | "series" | "brands";

const supportedTypes: Record<string, string> = {
  psb: "image/vnd.adobe.photoshop",
  psd: "image/vnd.adobe.photoshop",
};

export const getFileExtension = (fileName: string): string =>
  fileName.split(".").pop() || "";

const getUploadType = (tabName: UploadConfigKey): UploadType => {
  if (tabName === "scripts") {
    return "scripts";
  }

  if (tabName === "fontOneOff" || tabName === "fontSeries") {
    return "fonts";
  }

  return "images";
};

const dropZoneAcceptedBrandLevel = `image/jpeg,image/png,image/tiff,image/webp,image/x-eps,application/illustrator",application/postscript,image/vnd.adobe.photoshop,image/svg+xml,application/vnd.3gpp.pic-bw-small,.psb,.psd,.ttf,.otf,.fnt`;

const uploadFileTypes: Record<UploadType, string> = {
  images: ".jpeg, .jpg, .png, .tiff, .ai, .psd, .eps",
  fonts: ".ttf, .otf, .fnt",
  scripts: ".doc, .docx",
};

const dropzoneStyles: IDropzoneProps["styles"] = {
  dropzone: {
    width: "100%",
    height: "252px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    border: `1px dashed ${theme.palette.frenchGray}`,
    borderRadius: "16px",
  },
  input: { display: "none" },
  dropzoneReject: {
    border: `1px dashed ${theme.palette.charade}`,
    background: theme.palette.midGray,
  },
  inputLabel: (
    _files: unknown,
    extra: { reject: boolean },
  ): { color: string } | Record<string, never> =>
    extra.reject ? { color: theme.palette.charade } : {},
};

interface ImagesProps {
  uploadConfigKey: UploadConfigKey;
}

function extractFileIdFromPresignedUrl(presignedUrl: string) {
  const url = new URL(presignedUrl);

  const path = url.pathname;

  if (path.startsWith("/")) {
    return path.slice(1);
  }

  return path;
}

export default function Images({ uploadConfigKey }: ImagesProps) {
  const config = uploadConfig[uploadConfigKey];

  const { programmeCcid, seriesCcid, titleCcid } = useParams<string>();
  const activeCcid = titleCcid || seriesCcid || programmeCcid || "";
  const brandsOrSeriesUrl = programmeCcid === activeCcid ? "brands" : "series";
  const level = titleCcid === activeCcid ? "titles" : brandsOrSeriesUrl;

  const deliverableType = getUploadType(uploadConfigKey);

  const { data: entitlementsData, isLoading: entitlementsLoading } =
    useImagesEntitlements(activeCcid, level, deliverableType);

  const { data: deliverableList, isLoading: deliverableListLoading } =
    useListDeliverables(activeCcid, level, config.imageTag, deliverableType);

  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile();

  const { data: synopsisData } = useSynopsis(activeCcid, level);

  const { data: brandsMetaData } = useBrandMetadata(programmeCcid);
  const { data: brandTitlesData } = useTitles(programmeCcid, "brands");
  const { data: seriesData } = useSeries(programmeCcid, false);
  const { data: titlesData } = useTitles(seriesCcid, "series");

  const [selectedFiles, setSelectedFiles] = useState<Record<string, boolean>>(
    {},
  );

  const [filesBeingUploaded, setFilesBeingUploaded] = useState<
    Array<IFileWithMeta>
  >([]);

  const [uploadIds, setUploadIds] = useState<Record<string, string>>({});

  const seriesDetails = seriesData?.seriesDetails.find(
    (series) => series.ccid === seriesCcid,
  );

  const titleDetails =
    titlesData?.titleDetails.find((title) => title.ccid === titleCcid) ||
    brandTitlesData?.titleDetails.find((title) => title.ccid === titleCcid);

  const brandName = brandsMetaData?.brandName;

  const synopsisDescription =
    synopsisData?.synopsisMedium ||
    synopsisData?.synopsisShort ||
    synopsisData?.synopsisLong ||
    "";

  const isLoading = entitlementsLoading || deliverableListLoading;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" marginTop={10}>
        <CircularProgress data-testid="spinner" />
      </Box>
    );
  }

  const handleFileSelect = ({
    fileId,
    isSelected,
  }: {
    fileId: string;
    isSelected: boolean;
  }) => {
    setSelectedFiles((prevSelections) => {
      return { ...prevSelections, [fileId]: isSelected };
    });
  };

  const handleSelectAllChange = ({
    fileIds,
    isSelected,
  }: {
    fileIds: Array<string>;
    isSelected: boolean;
  }) => {
    const newSelections: Record<string, boolean> = {};

    for (const id of fileIds) {
      newSelections[id] = isSelected;
    }

    setSelectedFiles(newSelections);
  };

  const title = config.headerTitle || "Upload Images";

  const showWrongFormatErrorMessage = false;

  const getUploadParams: IDropzoneProps["getUploadParams"] = async (file) => {
    try {
      const contentType =
        supportedTypes[getFileExtension(file.meta.name)] || file.meta.type;

      const response = await uploadFile({
        level,
        ccid: activeCcid,
        deliverableType,
        file: {
          contentType,
          objectName: file.meta.name,
          imageTag: config.imageTag,
        },
      });

      const fileId =
        response.imageId ||
        extractFileIdFromPresignedUrl(response.presignedUrl);

      if (fileId) {
        setUploadIds((previousIds) => ({
          ...previousIds,
          [file.meta.id]: fileId,
        }));
      }

      return {
        method: "PUT",
        url: response.presignedUrl,
        body: file.file,
        headers: { "Content-Type": contentType },
      };
    } catch (err) {
      return { url: "" };
    }
  };

  return (
    <Container sx={{ py: 2 }}>
      {synopsisDescription ? (
        <Card sx={{ marginBottom: "1rem", marginTop: "0.5rem" }}>
          <CardContent>
            <Typography variant="h5">Synopsis</Typography>

            <Typography marginTop="1rem">{synopsisDescription}</Typography>
          </CardContent>
        </Card>
      ) : null}

      {entitlementsData?.canUpload ? (
        <>
          <Typography variant="h4">{title}</Typography>
          <Stack direction="row" alignItems="baseline" marginBottom={1}>
            Drag &amp; drop a file(s) into the box below to upload files.{" "}
            <Tooltip
              title={`Upload any files in the following formats: ${uploadFileTypes[deliverableType]}`}
              placement="top"
            >
              <Button
                size="small"
                sx={{
                  padding: 0,
                  position: "relative",
                  top: "5px",
                }}
              >
                <HelpOutline
                  aria-label="Help icon"
                  // eslint-disable-next-line jsx-a11y/aria-role
                  role="graphics-symbol"
                  color="primary"
                />
              </Button>
            </Tooltip>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Box minWidth={350} minHeight={210}>
              <Dropzone
                accept={config.acceptFormat || dropZoneAcceptedBrandLevel}
                getUploadParams={getUploadParams}
                onChangeStatus={(_file, _status, allFiles) => {
                  setFilesBeingUploaded(
                    allFiles.filter((file) => file.meta.status !== "removed"),
                  );
                }}
                PreviewComponent={() => <></>}
                InputComponent={CustomInput}
                styles={dropzoneStyles}
              />
            </Box>
            {config.titleCopy1 && (
              <Box>
                <Typography variant="h5" marginY={2}>
                  {config.titleCopy1}
                </Typography>
                <div>{config.descriptionCopy1}</div>

                <Typography variant="h5" marginY={2}>
                  {config.titleCopy2}
                </Typography>
                <div>{config.descriptionCopy2}</div>
              </Box>
            )}
          </Stack>

          <PreviewModal
            isUploading={isUploading}
            isModalOpen={filesBeingUploaded.length > 0}
            filesBeingUploaded={filesBeingUploaded}
            uploadIds={uploadIds}
            onClose={() => {
              for (const file of filesBeingUploaded) {
                file.remove();
              }
              setUploadIds({});
            }}
            deliverableType={deliverableType}
            ccid={activeCcid}
            level={level}
          />
        </>
      ) : null}

      {entitlementsData?.canView ? (
        <>
          <Typography
            style={{ display: showWrongFormatErrorMessage ? "block" : "none" }}
          >
            {config.formatErrorMessage}
          </Typography>
          <div style={{ marginBottom: "16px" }}>
            <Typography variant="h4" marginTop={2}>
              {config.disableView ? "" : "View"}
              {entitlementsData?.canDownload
                ? `${!config.disableView ? " & download" : "Download"}`
                : ""}{" "}
              {config.viewTitle || "images"}
            </Typography>
            <p
              style={{
                margin: " 0 0 10px",
                lineHeight: "24px",
              }}
            >
              {config.viewAndDownloadCopy ||
                "Browse the images for this episode below. You can remove, download, and edit embargo information for each image below."}
            </p>
          </div>

          <Box marginTop="10px">
            {deliverableType === "images" && (
              <UploadedImages
                ccid={activeCcid}
                level={level}
                deliverables={
                  deliverableList &&
                  deliverableType === "images" &&
                  "images" in deliverableList
                    ? deliverableList.images
                    : undefined
                }
                handleFileSelect={handleFileSelect}
                handleSelectAllChange={handleSelectAllChange}
                entitlements={entitlementsData}
                seriesDetails={seriesDetails}
                titleDetails={titleDetails}
                brandName={brandName}
                disableView={Boolean(config.disableView)}
                selectedFiles={selectedFiles}
                type="images"
              />
            )}

            {deliverableType === "fonts" && (
              <UploadedFonts
                ccid={activeCcid}
                level={level}
                deliverables={
                  deliverableList &&
                  deliverableType === "fonts" &&
                  "fonts" in deliverableList
                    ? deliverableList.fonts
                    : undefined
                }
                handleFileSelect={handleFileSelect}
                handleSelectAllChange={handleSelectAllChange}
                entitlements={entitlementsData}
                seriesDetails={seriesDetails}
                titleDetails={titleDetails}
                brandName={brandName}
                disableView={Boolean(config.disableView)}
                selectedFiles={selectedFiles}
                type="fonts"
              />
            )}

            {deliverableType === "scripts" && (
              <UploadedScripts
                ccid={activeCcid}
                level={level}
                deliverables={
                  deliverableList &&
                  deliverableType === "scripts" &&
                  "scripts" in deliverableList
                    ? deliverableList.scripts
                    : undefined
                }
                handleFileSelect={handleFileSelect}
                handleSelectAllChange={handleSelectAllChange}
                entitlements={entitlementsData}
                seriesDetails={seriesDetails}
                titleDetails={titleDetails}
                brandName={brandName}
                disableView={Boolean(config.disableView)}
                selectedFiles={selectedFiles}
                type="scripts"
              />
            )}
          </Box>
        </>
      ) : (
        <NoAccess />
      )}
    </Container>
  );
}
