/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useMemo, useState } from "react";
import { Card, Checkbox, Divider, Stack, Typography } from "@mui/material";
import { ContributorListRoleProps } from "../../../../../../types/types";
import { noop } from "../../../../../../utils/appHelper";
import { roleToDisplayName } from "../../contributor.helper";
import theme from "../../../../../../utils/theme";

interface ListItemProps {
  selected: boolean;
  label: string;
  numberSelected: number;
  labelOfNumberSelected: string;
  total?: number;
  withCheckbox?: boolean;
  onChange?(selected: boolean): void;
}

export const ListItem: FC<ListItemProps> = ({
  selected = false,
  label = "Roles currently included on billings documents",
  numberSelected = 0,
  labelOfNumberSelected = "selected",
  total,
  withCheckbox = true,
  onChange = noop,
}) => (
  <Stack
    justifyContent="spacing-between"
    alignItems="center"
    direction="row"
    sx={{
      padding: "3px 12px",
      background: "white",
    }}
  >
    {withCheckbox && (
      <Checkbox
        checked={selected}
        id={label.split(" ").join("-")}
        onChange={(event: any) => {
          onChange(event.target.checked);
        }}
      />
    )}
    <Stack flexDirection="column">
      <Typography fontSize="subtitle1">{label}</Typography>
      <Typography fontSize="subtitle1">
        {numberSelected}
        {total === undefined ? "" : `/${total}`} {labelOfNumberSelected}
      </Typography>
    </Stack>
  </Stack>
);

export interface SelectableRole extends ContributorListRoleProps {
  selected?: boolean;
}

interface BillingListProps {
  contributorsMap: Record<string, number>;
  selectedRoles: string[];
  allRoles: ContributorListRoleProps[];
  titleLabel: string;
  onChange?(roles: SelectableRole[]): void;
  [key: string]: any;
}

export const BillingList: FC<BillingListProps> = ({
  contributorsMap,
  selectedRoles,
  allRoles,
  titleLabel,
  onChange = noop,
  ...rest
}) => {
  const [filteredRoles, setFilteredRoles] = useState<SelectableRole[]>([]);

  useEffect(
    () =>
      setFilteredRoles(
        allRoles.filter(({ role }) => selectedRoles.includes(role)),
      ),
    [allRoles, selectedRoles],
  );

  const numberSelected = useMemo(
    () => filteredRoles.filter(({ selected }) => selected).length,
    [filteredRoles],
  );

  return (
    <Card sx={{ height: "fit-content", mb: 2 }} {...rest}>
      <ListItem
        selected={false}
        label={titleLabel}
        numberSelected={numberSelected}
        labelOfNumberSelected="selected"
        total={filteredRoles.length}
        withCheckbox={false}
      />
      <Divider sx={{ height: 4, background: theme.palette.midGray }} />
      {filteredRoles.map((role, index) => (
        <ListItem
          key={role.role}
          selected={Boolean(role.selected)}
          label={roleToDisplayName(role.role)}
          numberSelected={contributorsMap[role.role]}
          labelOfNumberSelected="listed"
          onChange={(selected) => {
            const newRole = {
              ...role,
              selected,
            };

            filteredRoles[index] = newRole;
            const newFilteredRoles = [...filteredRoles];
            setFilteredRoles(newFilteredRoles);
            onChange(newFilteredRoles);
          }}
        />
      ))}
    </Card>
  );
};
