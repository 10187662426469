import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getBrands } from "../../api/portal";
import { ProgrammeSearchPayload } from "../../types/types";

interface UseBrandsArgs {
  searchTerm: string | undefined;
  limit?: number;
  includeOpportunity?: boolean;
  filterByUserLabels?: boolean;
  keepPreviousResults?: boolean;
}

export const useBrands = ({
  searchTerm,
  limit,
  includeOpportunity = false,
  keepPreviousResults = false,
  filterByUserLabels = false,
}: UseBrandsArgs): UseQueryResult<ProgrammeSearchPayload, AxiosError> =>
  useQuery(
    ["brands", searchTerm, limit],
    () =>
      getBrands(
        searchTerm as string,
        limit,
        includeOpportunity,
        filterByUserLabels,
      ),
    { enabled: Boolean(searchTerm), keepPreviousData: keepPreviousResults },
  );
