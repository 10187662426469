import React from "react";
import { ListItemText } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { NotificationProps } from "../../types/types";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
  },
);

const NotificationCard: React.FC<NotificationProps> = ({
  primaryText,
  secondaryText,
  notificationLink,
  icon,
}) => (
  <ListItemButton component={Link} divider to={notificationLink}>
    {icon}
    <ListItemText primary={primaryText} secondary={secondaryText} />
  </ListItemButton>
);

export default NotificationCard;
