import { Card, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import TaskButton from "./TaskButton";
import {
  inProductionTasks,
  postProductionTasks,
  preProductionTasks,
} from "./TasksList";
import { ProductionStage, Task } from "../types/types";
import { createRushesURL } from "../api/iconik";
import { SnackbarDismiss } from "../componentsV2/SnackBarDismiss";

const TasksSection = ({
  tasks,
  productionStage,
}: {
  tasks: Task[];
  productionStage: ProductionStage;
}): JSX.Element => {
  const navigate = useNavigate();
  const { seriesCcid } = useParams();
  const sectionTitle = {
    preProduction: "Pre-production",
    inProduction: "In-production",
    postProduction: "Post-production",
  };
  const taskLists = {
    preProduction: preProductionTasks,
    inProduction: inProductionTasks,
    postProduction: postProductionTasks,
  };

  const noTaskToShow = tasks.filter(
    (item) => taskLists[productionStage][item.taskName],
  );
  return (
    <Card sx={{ px: 2, py: 1 }}>
      <Stack spacing={1}>
        <Typography variant="h6" fontWeight="regular">
          {sectionTitle[productionStage]}
        </Typography>
        {noTaskToShow.length === 0 ? <Typography>No Tasks</Typography> : <></>}
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          sx={{ py: 1 }}
        >
          {tasks.map((item) => {
            if (taskLists[productionStage][item.taskName]) {
              taskLists[productionStage][item.taskName].taskStatusUrl =
                item.taskStatusUrl;
              if (item.taskName === "ACCESS-ICONIK") {
                return (
                  <TaskButton
                    key={item.taskName}
                    task={taskLists[productionStage][item.taskName]}
                    handleClick={() => {
                      enqueueSnackbar(
                        "Checking iconik - a new tab will open shortly",
                        { variant: "info" },
                      );
                      if (seriesCcid)
                        createRushesURL("series", seriesCcid)
                          .then((data) => window.open(data.url, "_blank"))
                          .catch(() =>
                            enqueueSnackbar(
                              "There was an error opening iconik",
                              { variant: "error", action: SnackbarDismiss },
                            ),
                          );
                    }}
                  />
                );
              }
              return (
                <TaskButton
                  key={item.taskName}
                  task={taskLists[productionStage][item.taskName]}
                  handleClick={() =>
                    navigate(
                      taskLists[productionStage][item.taskName].taskRoute,
                    )
                  }
                />
              );
            }

            return null;
          })}
        </Stack>
      </Stack>
    </Card>
  );
};

export default TasksSection;
