/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { FormControlLabel, Checkbox, Stack, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import theme from "../../utils/theme";

export interface GroupCheckboxesProps {
  label: string;
  value?: string[];
  error?: boolean;
  required?: boolean;
  options?: { label: string; value: string }[];
  onChange(value: string[]): void;
  vertical?: boolean;
}

export const GroupCheckboxes: FC<GroupCheckboxesProps> = ({
  label,
  value,
  error,
  options,
  onChange,
  required,
  vertical = false,
}) => (
  <div>
    <Stack display="inline-block">
      {label}
      {required && (
        <Typography
          color={theme.palette.sunsetOrange}
          display="inline-block"
          marginLeft={1}
        >
          *
        </Typography>
      )}
      {error && (
        <span>
          <ErrorOutline
            color="error"
            aria-label="Error alert icon"
            sx={{ marginLeft: "0.5rem" }}
          />
        </span>
      )}
    </Stack>
    <Stack direction={vertical ? "column" : "row"} display="inline-block">
      {options?.map(({ label: optionLabel, value: optionValue }) => (
        <Stack key={optionValue}>
          <FormControlLabel
            control={
              <Checkbox
                id={optionValue}
                checked={Boolean(value?.includes(optionValue))}
                name={optionValue}
                value={optionValue}
                onChange={(event: any) => {
                  const { checked } = event.target;
                  let newValue = [...(value || [])];

                  if (checked) {
                    newValue.push(optionValue);
                  } else {
                    newValue = newValue.filter((v) => v !== optionValue);
                  }

                  onChange(newValue);
                }}
              />
            }
            label={optionLabel}
          />
        </Stack>
      ))}
    </Stack>
  </div>
);
