import * as Yup from "yup";

export const cyberSecuritySchemaV3 = Yup.object().shape({
  spotId: Yup.string(),
  cyberResponsible: Yup.string(),
  cyberResponsibleEmail: Yup.string().email("Invalid email"),
  appList: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  appListDetail: Yup.string(),
  dataStorage: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  dataStorageSupplier: Yup.string(),
  dataAccess: Yup.string().required("This is a required field"),
  dataStorageCountry: Yup.string().when(
    "$cyberSecurity.dataAccess",
    ([dataAccess], schema) => {
      if (dataAccess === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  incidentResponse: Yup.boolean().required("This is a required field"),
  cyberResponseGuidelines: Yup.boolean().required("This is a required field"),
  ai: Yup.string().required("This is a required field"),
  aiTools: Yup.array()
    .of(Yup.string().required())
    .when("$cyberSecurity.ai", ([ai], schema) => {
      if (ai === "Yes") {
        return schema
          .required("This is a required field")
          .min(1, "This is a required field");
      }
      return schema;
    }),
  aiProducts: Yup.string(),
  aiGuidance: Yup.boolean().required("This is a required field"),
});

export type CyberSecuritySectionV3 = Yup.InferType<
  typeof cyberSecuritySchemaV3
>;
