import { SyntheticEvent, useCallback, FC, useState } from "react";
import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchLabelUsers } from "../../hooks";
import { SearchedUser } from "../../types/types";
import { ListSelectedUsers } from "../../componentsV2/ListSelectedUsers/ListSelectedUsers";

interface SearchUserProps {
  setUserOverride: (override: SearchedUser | undefined) => void;
  isOverridden: boolean;
}

export const SearchUser: FC<SearchUserProps> = ({
  setUserOverride,
  isOverridden,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: usersFromSearch, isLoading } = useSearchLabelUsers(
    searchTerm,
    15,
  );
  const [selectedUsers, setSelectedUsers] = useState<SearchedUser[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputChangeHandler = useCallback((event: any) => {
    if (event?.target?.value) {
      setSearchTerm(event.target.value);
    } else {
      setSearchTerm("");
    }
  }, []);

  const handleSelect = (
    _event: SyntheticEvent<Element, Event>,
    value: { label: string; user: SearchedUser } | null,
  ) => {
    if (value?.user) {
      setSelectedUsers([value.user]);
      setUserOverride(value.user);
    }
    setSearchTerm("");
  };

  return (
    <Stack spacing={2}>
      {!isOverridden ? (
        <Stack spacing={2}>
          <Typography variant="h4">Override User</Typography>
          <Typography>
            Select a User to experience the App as if you were them.
          </Typography>
          <Autocomplete
            id="search-users"
            inputValue={searchTerm}
            clearIcon={null}
            popupIcon={null}
            options={
              usersFromSearch
                ? usersFromSearch.map((user) => ({
                    label: `${user.firstName} ${user.lastName} ${user.role ? `(${user.role})` : ""}`,
                    user,
                  }))
                : []
            }
            disablePortal
            onInputChange={inputChangeHandler}
            onChange={handleSelect}
            loading={isLoading}
            renderInput={(params) => (
              <TextField {...params} label="Search for a user" />
            )}
            sx={{ pt: "2%" }}
          />
          <ListSelectedUsers
            selectedUsers={selectedUsers}
            deslectUser={(user) => {
              setSelectedUsers(
                selectedUsers.filter(({ id }) => id !== user.id),
              );
              setUserOverride(undefined);
            }}
          />
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h4">Remove override</Typography>
          <Typography>
            Click the button below to remove the override and return to your own
            user.
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setUserOverride(undefined);
            }}
          >
            Remove Override
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
