import { useState, FC } from "react";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Search as SearchIcon } from "@mui/icons-material";

export interface SearchProps {
  searchTerm?: string;
}

export const Search: FC<SearchProps> = ({ searchTerm = "" }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState<string>(searchTerm);
  const handleSearch = () =>
    navigate({
      pathname: "/search",
      search: `?${createSearchParams({ q: value })}`,
    });

  return (
    <Box display="flex" gap={1} width={500}>
      <TextField
        value={value}
        fullWidth
        size="small"
        label=""
        id="search"
        type="search"
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setValue(event.target.value)
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        placeholder="Search programme name"
        sx={{ background: "white" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        disabled={!value}
      >
        Search
      </Button>
    </Box>
  );
};
