/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, SVGProps } from "react";

/** @component */
export const ImageIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 10H32V34H8V22"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 16V5M8 5L4 9M8 5L12 9"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3045 22.6894L26.0662 28H13.8921L16.5274 24.1349L17.6299 25.6468C18.2214 26.4581 19.4276 26.4704 20.0356 25.6713L22.3045 22.6894Z"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
