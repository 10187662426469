import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { FieldInputProps } from "formik";
import { OfcomRegion } from "../../types/types";
import { regionOptions } from "./RegionOptions";

interface MultipleSelectRegionCheckboxProps {
  field: FieldInputProps<OfcomRegion[]>;
}

export const MultipleSelectRegionCheckbox: React.FC<
  MultipleSelectRegionCheckboxProps
> = ({ field }: MultipleSelectRegionCheckboxProps) => (
  <FormGroup aria-labelledby={`${field.name}-checkbox-group`}>
    {regionOptions.map((option) => (
      <FormControlLabel
        key={option.value}
        control={
          <Checkbox
            size="small"
            name={field.name}
            value={option.value}
            checked={field.value.includes(option.value)}
            onChange={field.onChange}
            sx={{ ml: 1 }}
          />
        }
        label={option.label}
      />
    ))}
  </FormGroup>
);
