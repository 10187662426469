import { CircularProgress, Stack } from "@mui/material";

import { useParams } from "react-router-dom";
import { useRiskSummaryNew } from "../../hooks";
import { NotificationFormV2 } from "./NotificationFormV2";
import { NotificationFormV3 } from "./NotificationFormV3";
import { NotificationFormError } from "./NotificationFormError";
import { FC } from "react";

interface NotificationFormContainerProps {
  version?: string;
}

export const NotificationFormContainer: FC<NotificationFormContainerProps> = ({
  version,
}) => {
  const { seriesCcid, titleCcid } = useParams<string>();
  const ccid = titleCcid || seriesCcid;
  const { data: summary, isLoading, error } = useRiskSummaryNew({ ccid });

  if (isLoading) {
    return (
      <>
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  if (!isLoading && (!summary || !summary?.version)) {
    return (
      <>
        <Stack alignItems="center" m={4}>
          <NotificationFormError riskError={error} />
        </Stack>
      </>
    );
  }

  const finalVersion = version ?? summary?.version;

  return (
    <>
      {finalVersion === "2" || finalVersion === "1" ? (
        <NotificationFormV2 />
      ) : (
        <></>
      )}
      {finalVersion === "3" ? <NotificationFormV3 /> : <></>}
    </>
  );
};
