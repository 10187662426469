import React, { useState, FC } from "react";
import {
  Stack,
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
  Box,
  InputAdornment,
  Grid,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import { useCcidDropdown } from "../../hooks";
import { CreateCcidFormDataOld, ProductionType } from "../../types/types";
import { WhoIsThisForEnum, WhoIsThisForLabels } from "../../constants";

export interface CcidFormProps {
  productionType: ProductionType | null;
  productionName?: string;
  setProductionName?: (productionName: string) => void;
  formData: CreateCcidFormDataOld;
  setFormdata: (formData: CreateCcidFormDataOld) => void;
}

export const CcidCreationForm: FC<CcidFormProps> = ({
  productionType,
  productionName,
  setProductionName,
  formData,
  setFormdata,
}) => {
  const [titleInputSelected, setTitleInputSelected] = useState<boolean>(false);
  const { data: ccidDropdownData, isLoading: isLoadingDropdown } =
    useCcidDropdown();
  const currentYear = new Date().getFullYear();

  // show list of years +-5 years either side of this year
  const productionYearOptions = () => {
    const listOfYears: { label: string; value: number }[] = [];
    for (let i = 0; i < 16; i += 1) {
      listOfYears.push({
        label: (currentYear - 5 + i).toString(),
        value: currentYear - 5 + i,
      });
    }
    return listOfYears;
  };

  const whoIsThisForOptions = [
    WhoIsThisForEnum.BROADCAST,
    WhoIsThisForEnum.ONLINE,
    WhoIsThisForEnum.GLOBAL_DISTRIBUTION,
  ];

  const formatOption = (option: string) => {
    const regex = /[^a-zA-Z0-9]/g;
    const lowercaseStr = option.toLowerCase();
    const capitalizedStr =
      lowercaseStr.charAt(0).toUpperCase() + lowercaseStr.slice(1);
    return capitalizedStr.replace(regex, " ");
  };

  const genres = ccidDropdownData?.genres || [];
  const filteredGenreOptions =
    productionType === "film"
      ? genres
      : genres.filter((genre) => genre !== "FILM");

  const subgenres = ccidDropdownData?.subgenres || [];
  const filteredSubgenreOptions =
    productionType === "film"
      ? subgenres
      : subgenres.filter((subgenre) => subgenre !== "FILM");

  return (
    <>
      {isLoadingDropdown ? (
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box m={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {productionName === undefined ||
            setProductionName === undefined ? null : (
              <Stack direction="row">
                {titleInputSelected ? (
                  <TextField
                    value={productionName}
                    onChange={(e) => {
                      setProductionName(e.target.value);
                    }}
                    type="text"
                    size="small"
                    label="Production name"
                    onBlur={() => setTitleInputSelected(!titleInputSelected)}
                  />
                ) : (
                  <Typography
                    onClick={() => {
                      setTitleInputSelected(true);
                    }}
                    variant="h4"
                  >
                    {productionName}
                  </Typography>
                )}
                <CreateIcon
                  sx={{
                    mx: 1,
                    height: "auto",
                  }}
                  fontSize="small"
                  onClick={() => setTitleInputSelected(!titleInputSelected)}
                />
              </Stack>
            )}
          </Stack>

          <Grid container rowSpacing={4} columnSpacing={2} my={0}>
            {/* Producer */}
            <Grid item xs={6}>
              <Autocomplete
                id="id"
                multiple
                onChange={(_, option) => {
                  setFormdata({
                    ...formData,
                    productionLabels: Array.from(
                      option.values(),
                      (item) => item.value,
                    ),
                  });
                }}
                disableClearable
                size="small"
                options={ccidDropdownData?.producers || []}
                renderInput={(params) => (
                  <TextField {...params} label="Producer" />
                )}
              />
            </Grid>

            {/* Commissioner */}
            <Grid item xs={6}>
              <Autocomplete
                id="id"
                onChange={(_, option) => {
                  setFormdata({ ...formData, commissioner: option });
                }}
                disableClearable
                size="small"
                options={ccidDropdownData?.commissioners || []}
                renderInput={(params) => (
                  <TextField {...params} label="Commissioner" />
                )}
                getOptionLabel={(option) => {
                  if (option === "TBC") {
                    return "Unknown at this time";
                  }

                  return option;
                }}
              />
            </Grid>

            {/* Who is this for */}
            {formData.commissioner === "ITV" ? (
              <Grid item xs={6}>
                <Stack>
                  <Autocomplete
                    id="id"
                    onChange={(_, option) => {
                      setFormdata({ ...formData, whoIsThisFor: option.value });
                    }}
                    disableClearable
                    size="small"
                    options={whoIsThisForOptions.map((who) => ({
                      label: WhoIsThisForLabels[who],
                      value: who,
                    }))}
                    renderInput={(params) => (
                      <TextField {...params} label="Who is this for?" />
                    )}
                  />
                </Stack>
              </Grid>
            ) : (
              <></>
            )}

            {/* Production Year */}
            <Grid item xs={6}>
              <Stack>
                <Autocomplete
                  id="id"
                  onChange={(_, option) => {
                    setFormdata({ ...formData, productionYear: option.value });
                  }}
                  disableClearable
                  size="small"
                  value={{
                    label: formData.productionYear?.toString(),
                    value: formData.productionYear,
                  }}
                  options={productionYearOptions()}
                  renderInput={(params) => (
                    <TextField {...params} label="Production Year" />
                  )}
                />
              </Stack>
            </Grid>

            {/* Special Title */}
            {productionType === "special" && (
              <Grid item xs={6}>
                <Stack>
                  <TextField
                    id="id"
                    onChange={(event) => {
                      setFormdata({
                        ...formData,
                        specialName: event.target.value,
                      });
                    }}
                    size="small"
                    label="Special title"
                    autoComplete="off"
                  />
                </Stack>
              </Grid>
            )}

            {/* Series Number */}
            {(productionType === "series" || productionType === "pilot") && (
              <Grid item xs={6}>
                <Stack>
                  <TextField
                    id="id"
                    type="number"
                    disabled={productionType === "pilot"}
                    value={formData.sequence}
                    onChange={(event) => {
                      setFormdata({
                        ...formData,
                        sequence: event.target.value,
                      });
                    }}
                    InputProps={{
                      inputProps: { min: 0 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography color="disabled">Series</Typography>
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    label="Series Number"
                    autoComplete="off"
                  />
                </Stack>
              </Grid>
            )}

            {/* Episode Count */}
            {(productionType === "series" || productionType === "pilot") && (
              <Grid item xs={6}>
                <Stack>
                  <TextField
                    id="id"
                    type="number"
                    disabled={productionType === "pilot"}
                    value={formData.count}
                    onChange={(event) => {
                      setFormdata({ ...formData, count: event.target.value });
                    }}
                    size="small"
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography color="disabled">Episodes</Typography>
                        </InputAdornment>
                      ),
                    }}
                    label="Episode Count"
                    autoComplete="off"
                  />
                </Stack>
              </Grid>
            )}

            {/* Intended Slot length */}
            <Grid item xs={6}>
              <Stack>
                <TextField
                  id="id"
                  type="number"
                  onChange={(event) => {
                    setFormdata({
                      ...formData,
                      intendedSlotLength: Number(event.target.value),
                    });
                  }}
                  size="small"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography color="disabled">minutes</Typography>
                      </InputAdornment>
                    ),
                  }}
                  label="Intended Slot length"
                  autoComplete="off"
                />
              </Stack>
            </Grid>

            {/* Genre */}
            <Grid item xs={6}>
              <Stack>
                <Autocomplete
                  id="id"
                  onChange={(_, option) => {
                    setFormdata({ ...formData, genre: option.value });
                  }}
                  disableClearable
                  size="small"
                  options={filteredGenreOptions.map((instance) => ({
                    label: formatOption(instance),
                    value: instance,
                  }))}
                  renderInput={(params) => (
                    <TextField {...params} label="Genre" />
                  )}
                />
              </Stack>
            </Grid>

            {/* subgenre */}
            <Grid item xs={6}>
              <Stack>
                <Autocomplete
                  id="id"
                  multiple
                  size="small"
                  onChange={(_, option) => {
                    setFormdata({
                      ...formData,
                      subgenres: Array.from(
                        option.values(),
                        (item) => item.value,
                      ),
                    });
                  }}
                  options={
                    filteredSubgenreOptions.map((instance) => ({
                      label: formatOption(instance),
                      value: instance,
                    })) || []
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Subgenre" />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
