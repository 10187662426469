import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { RiskLevel } from "../../types/types";
import theme from "../../utils/theme";

const riskColors: Record<RiskLevel, string> = {
  [RiskLevel.Insignificant]: theme.palette.success.main,
  [RiskLevel.Low]: theme.palette.success.main,
  [RiskLevel.Medium]: theme.palette.warning.main,
  [RiskLevel.High]: theme.palette.error.main,
};

const ProductionCardRisk: FC<{
  productionRiskRating: RiskLevel | null | undefined;
}> = ({ productionRiskRating }) => {
  return productionRiskRating ? (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      sx={{ color: riskColors[productionRiskRating] || theme.palette.primary }}
    >
      <Box component="span" fontSize="22px" display="flex" alignItems="center">
        <WarningAmberIcon fontSize="inherit" />
      </Box>
      <Typography variant="body1" fontWeight={500}>
        {productionRiskRating} Risk
      </Typography>
    </Stack>
  ) : (
    <Stack direction="row" justifyContent="flex-start">
      <Typography>-</Typography>
    </Stack>
  );
};

export default ProductionCardRisk;
