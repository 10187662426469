/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { updateRiskVersion } from "../../api/spotlight";

export const useUpdateRiskVersion = (): UseMutationResult<
  unknown,
  unknown,
  { ccid: string; version: "v2" | "v3" }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ccid, version }) => updateRiskVersion({ ccid, version }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("risk summary");
      },
    },
  );
};
