import React from "react";
import { Stack, Box } from "@mui/material";
import ProductionCard from "./ProductionCardNew";

const ActiveProductionsList: React.FC<{ ccids: string[] }> = ({ ccids }) => {
  return (
    <Box mt={2} sx={{ maxHeight: 600, overflow: "auto" }}>
      <Stack direction="column" spacing={2}>
        {ccids.map((productionCcid: string) => (
          <ProductionCard key={productionCcid} ccid={productionCcid} />
        ))}
      </Stack>
    </Box>
  );
};

export default ActiveProductionsList;
