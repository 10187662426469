/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { updateTaskGroup } from "../../api/portal";
import { noop } from "../../utils/appHelper";

export const useUpdateTaskGroup = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(
    ({ ccid, users, taskGroup }: any) =>
      updateTaskGroup(ccid, users, taskGroup),
    { onSuccess, onError },
  );
