import { FC } from "react";
import { Typography, Box } from "@mui/material";

export const OfcomFormTitleStep2: FC = () => (
  <Box>
    <Typography marginY={2}>
      There are three criteria for a production to qualify as a Regional
      Production and contribute to the Regional Production Quota. For a
      programme to qualify as a regional programme ‘Made Outside of London’, the
      relevant production must be made in the UK*, not be either news or
      self-promotional material, and meet at least 2 of the following 3
      criteria. Once a programme has been identified as a Regional Production,
      the next step is to allocate a Nation or English macro-Region based on
      their location. These are: North of England, South of England, Midlands
      "&" East of England, Scotland, Wales, Northern Ireland or Multi
      Nation/Region.
    </Typography>
    <Typography marginY={2}>
      Multi Nation/Region is used for Regional Productions from London producers
      which do not meet both 70% of spend and 50% of talent in any one
      particular region)In the event that the production is classed as a multi
      region, you should list the region either (i) in which the majority of the
      budget and/or talent criteria were met; or (ii) In the event that there is
      no single region in which such majority of budget or talent can be
      allocated to, the region where the largest proportion of the production
      budget and/or talent costs are spent. With regard to Question 4.3(a) and
      production spend, ‘production spend’ should refer to the entire production
      expenditure, excluding only the cost of on-screen talent, archive, sports
      rights, prize-money, copyright costs and production fees.Please refer to
      the Ofcom Guidelines and joint PSB guidance to answer any further queries
      and for assistance in responding to the questions below.*Ofcom states that
      a programme is considered to be made outside the UK where it meets at
      least two out of the three criteria set out in Part 3 - European
      Compliance & Made in the UK Qualification.With respect to Programmes that
      do not meet the Regional Production criteria, ITV invites producers to
      make a voluntary declaration of the data for such programmes (by
      completing questions 4.2 to 4.4) to help build up a full picture of
      regional TV production in the UK today – but this of course would be at
      producers’ discretion.
    </Typography>
  </Box>
);
