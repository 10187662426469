import { useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
} from "@mui/material";
import { CheckCircle, Close, Error as ErrorIcon } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { getAltThumbnail } from "../Thumbnail/Thumbnail";
import { IFileWithMeta, IMeta, StatusValue } from "react-dropzone-uploader";
import { LoadingButton } from "@mui/lab";
import { useQueryClient } from "react-query";
import { LevelType, UploadType } from "../../../../../types/types";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";
import {
  useDeleteFiles,
  useUpdateImageEmbargoDates,
} from "../../../../../hooks";

interface PreviewModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  filesBeingUploaded: Array<IFileWithMeta>;
  uploadIds: Record<string, string>;
  deliverableType: UploadType;
  isUploading: boolean;
  ccid: string;
  level: LevelType;
}

const typeLabelMap: Record<UploadType, string> = {
  images: "Images",
  scripts: "Scripts",
  fonts: "Fonts",
};

const uploadErrorIds: Array<StatusValue> = [
  "rejected_file_type",
  "error_upload",
  "exception_upload",
  "error_upload_params",
  "error_file_size",
  "error_validation",
];

export const PreviewModal: React.FC<PreviewModalProps> = ({
  isModalOpen,
  onClose,
  filesBeingUploaded,
  uploadIds,
  deliverableType,
  isUploading,
  ccid,
  level,
}) => {
  const queryClient = useQueryClient();

  const { mutate: updateEmbargoDate, isLoading: isUpdatingEmbargo } =
    useUpdateImageEmbargoDates();

  const { mutate: deleteFiles, isLoading: isDeletingFiles } = useDeleteFiles();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<Record<string, boolean>>(
    {},
  );
  const [embargoDates, setEmbargoDates] = useState<
    Record<string, dayjs.Dayjs | null>
  >({});

  const fileHasError = (meta: IMeta): boolean => {
    return uploadErrorIds.includes(meta.status);
  };

  const isLoading =
    isUploading ||
    isDeletingFiles ||
    isUpdatingEmbargo ||
    filesBeingUploaded.some(
      (file) => file.meta.status !== "done" && !fileHasError(file.meta),
    );

  const canSetEmbargoDate =
    deliverableType === "images" || deliverableType === "scripts";

  const getEmbargoDate = (meta: IMeta): dayjs.Dayjs | null => {
    return embargoDates[meta.id] || null;
  };

  const selectedFileIds = Object.entries(selectedFiles)
    .filter(([_, isSelected]) => isSelected)
    .map(([fileId]) => fileId);

  const numberOfSelectedFiles = selectedFileIds.length;

  const areAllFilesSelected =
    filesBeingUploaded.length > 0 &&
    filesBeingUploaded.length === numberOfSelectedFiles;

  const isFileSelected = (meta: IMeta) => {
    return Boolean(selectedFiles[meta.id]);
  };

  const handleDeleteFiles = () => {
    deleteFiles(
      {
        level,
        ccid,
        deliverableType,
        fileIds: selectedFileIds.map((fileId) => uploadIds[fileId]),
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Successfully deleted file(s)", {
            action: SnackbarDismiss,
            persist: true,
            variant: "success",
          });

          for (const file of filesBeingUploaded) {
            if (isFileSelected(file.meta)) {
              file.remove();
            }
          }

          setSelectedFiles((prevSelections) => {
            const newSelections = { ...prevSelections };
            for (const id of selectedFileIds) {
              delete newSelections[id];
            }
            return newSelections;
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to delete file(s). Please try again", {
            action: SnackbarDismiss,
            persist: true,
            variant: "error",
          });
        },
      },
    );
  };

  const handleSelectAllChange = (isSelected: boolean) => {
    const newSelections: Record<string, boolean> = {};

    for (const file of filesBeingUploaded) {
      newSelections[file.meta.id] = isSelected;
    }

    setSelectedFiles(newSelections);
  };

  const handleCheckboxChange = ({
    fileId,
    isSelected,
  }: {
    fileId: string;
    isSelected: boolean;
  }) => {
    setSelectedFiles((prevSelections) => {
      return { ...prevSelections, [fileId]: isSelected };
    });
  };

  const handleSetEmbargoDate = () => {
    if (selectedDate) {
      setEmbargoDates((previousDates) => {
        const newDates = { ...previousDates };

        for (const fileId of selectedFileIds) {
          newDates[fileId] = selectedDate;
        }

        return newDates;
      });
    }
  };

  const handleRemoveEmbargoDate = () => {
    if (selectedDate) {
      setEmbargoDates((previousDates) => {
        const newDates = { ...previousDates };

        for (const fileId of selectedFileIds) {
          newDates[fileId] = null;
        }

        return newDates;
      });
    }
  };

  const getFileStatusIcon = (meta: IMeta) => {
    if (meta.status === "done") {
      return <CheckCircle color="success" />;
    }

    if (fileHasError(meta)) {
      return <ErrorIcon color="error" />;
    }

    return <CircularProgress size={16} />;
  };

  const previewUrlCheck = (meta: IMeta) =>
    meta.previewUrl ? (
      <img src={meta.previewUrl} alt={meta.name} loading="lazy" />
    ) : (
      <img src={getAltThumbnail(meta.name)} alt={meta.name} loading="lazy" />
    );

  const handleImagePreview = (meta: IMeta) => {
    if (meta.type === "image/tiff") {
      if (meta.status !== "done") {
        return <CircularProgress sx={{ mx: 6 }} />;
      }
      return (
        <img src={getAltThumbnail(meta.name)} alt={meta.name} loading="lazy" />
      );
    }
    return previewUrlCheck(meta);
  };

  const handleClose = () => {
    if (!isLoading) {
      setSelectedFiles({});
      setEmbargoDates({});
      queryClient.invalidateQueries("uploadedImages");
      onClose();
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={isModalOpen} onClose={handleClose}>
      <DialogTitle data-testid="previewModal">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography display="inline" variant="h5">
            Upload {typeLabelMap[deliverableType]}
          </Typography>
          <IconButton
            aria-label="close"
            disabled={isLoading}
            onClick={handleClose}
            color="primary"
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <ImageList sx={{ height: 500 }} gap={16} cols={3} rowHeight={250}>
          {filesBeingUploaded.map(({ meta }) => {
            const embargoDate = getEmbargoDate(meta);

            return (
              <ImageListItem key={meta.id} sx={{ height: "100% !important" }}>
                <ImageListItemBar
                  sx={{ background: "none" }}
                  title={
                    embargoDate ? (
                      <Chip
                        label={`Embargo until: ${embargoDate.toDate().toLocaleDateString()}`}
                        color="error"
                        size="small"
                      />
                    ) : undefined
                  }
                  position="top"
                  actionIcon={
                    <Checkbox
                      disabled={isLoading}
                      checked={isFileSelected(meta)}
                      onChange={(_, checked) =>
                        handleCheckboxChange({
                          fileId: meta.id,
                          isSelected: checked,
                        })
                      }
                    />
                  }
                  actionPosition="left"
                />
                {handleImagePreview(meta)}
                <ImageListItemBar
                  title={
                    <>
                      <span>{meta.name}</span> {getFileStatusIcon(meta)}
                    </>
                  }
                  subtitle={
                    fileHasError(meta) ? (
                      <Typography variant="caption" color="error">
                        Error while uploading
                      </Typography>
                    ) : (
                      <Typography variant="caption">
                        {(meta.size / 1000).toFixed(0)} kB
                      </Typography>
                    )
                  }
                  position="below"
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </DialogContent>
      <DialogContent>
        <Stack direction="row" justifyContent="space-between" py={2}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isLoading}
                checked={areAllFilesSelected}
                id="embargo-images-select-all-checkbox"
                onChange={(_, checked) => handleSelectAllChange(checked)}
              />
            }
            label="Select All"
          />
          <Stack direction="row" spacing={2} alignItems="center">
            <DatePicker
              disabled={!canSetEmbargoDate}
              slotProps={{ textField: { size: "small" } }}
              disablePast
              value={selectedDate}
              referenceDate={dayjs()}
              onChange={setSelectedDate}
            />
            <Button
              disabled={
                !canSetEmbargoDate ||
                isLoading ||
                numberOfSelectedFiles === 0 ||
                selectedDate === null
              }
              variant="contained"
              color="secondary"
              onClick={handleSetEmbargoDate}
            >
              Apply Embargo to Selected
            </Button>
            <Button
              variant="outlined"
              color="error"
              disabled={
                !canSetEmbargoDate ||
                isLoading ||
                !filesBeingUploaded.some(
                  (f) =>
                    Boolean(getEmbargoDate(f.meta)) && isFileSelected(f.meta),
                )
              }
              onClick={handleRemoveEmbargoDate}
            >
              Remove embargo
            </Button>
            <Button
              disabled={isLoading || numberOfSelectedFiles === 0}
              variant="outlined"
              color="error"
              onClick={handleDeleteFiles}
            >
              Delete Selected
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              onClick={() => {
                // if any embargo dates were added, we'll save them before closing
                const imagesToUpdate = Object.entries(embargoDates)
                  .filter(([fileId, date]) => {
                    return Boolean(date) && Boolean(uploadIds[fileId]);
                  })
                  .map(([fileId, date]) => {
                    const imageId = uploadIds[fileId];
                    return { imageId, embargoDate: date as dayjs.Dayjs };
                  });

                if (imagesToUpdate.length > 0) {
                  updateEmbargoDate(
                    {
                      images: imagesToUpdate,
                      ccid,
                      level,
                    },
                    {
                      onSuccess: () => {
                        enqueueSnackbar("Embargo date(s) updated", {
                          action: SnackbarDismiss,
                          persist: true,
                          variant: "success",
                        });
                        queryClient.invalidateQueries("uploadedImages");
                        setSelectedDate(null);
                        onClose();
                      },
                      onError: () => {
                        enqueueSnackbar(
                          "Failed to update embargo date(s). Please try again",
                          {
                            action: SnackbarDismiss,
                            persist: true,
                            variant: "error",
                          },
                        );
                      },
                    },
                  );
                } else {
                  handleClose();
                }
              }}
            >
              Complete
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
