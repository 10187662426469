import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { useSearchLabelUsers } from "../hooks";
import { SearchedUser } from "../types/types";
import { ListSelectedUsers } from "./ListSelectedUsers/ListSelectedUsers";

type SelectedUser = Pick<
  SearchedUser,
  "id" | "firstName" | "lastName" | "role" | "email"
>;

interface ProductionLeadSelectProps {
  productionLead: SelectedUser | null;
  handleProductionLeadSelect: (productionLead: SelectedUser | null) => void;
  handleBlur?: React.FocusEventHandler<HTMLDivElement>;
  label: string;
  size?: "small" | "medium";
  name?: string;
}

function getUserLabel(user: SelectedUser): string {
  const label = [user.firstName, user.lastName];

  if (user.role) {
    label.push(`(${user.role})`);
  }

  return label.join(" ");
}

export function ProductionLeadSelect({
  productionLead,
  handleProductionLeadSelect,
  handleBlur,
  label,
  size,
  name,
}: ProductionLeadSelectProps) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: usersFromSearch, isLoading } = useSearchLabelUsers(
    searchTerm,
    15,
  );

  const handleSelect = (
    value: { label: string; user: SelectedUser } | null,
  ) => {
    if (value?.user) {
      handleProductionLeadSelect(value.user);
    }
    setSearchTerm("");
  };

  const selectOptions =
    usersFromSearch?.map((user) => {
      return { label: getUserLabel(user), user };
    }) || [];

  return (
    <>
      <Autocomplete
        id="search-users"
        size={size}
        inputValue={searchTerm}
        value={
          productionLead
            ? { user: productionLead, label: getUserLabel(productionLead) }
            : null
        }
        isOptionEqualToValue={(option, value) => {
          return option.user.id === value.user.id;
        }}
        clearIcon={null}
        popupIcon={null}
        options={selectOptions}
        onInputChange={(_, value, reason) => {
          if (reason !== "reset") {
            setSearchTerm(value);
          }
        }}
        onChange={(_, user) => handleSelect(user)}
        onBlur={handleBlur}
        loading={isLoading}
        renderInput={(params) => (
          <TextField {...params} label={label} name={name} />
        )}
      />
      <ListSelectedUsers
        selectedUsers={productionLead ? [productionLead] : []}
        deslectUser={() => {
          handleProductionLeadSelect(null);
        }}
      />
    </>
  );
}
