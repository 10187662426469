import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { BrandMetadata } from "../../types/types";
import { getBrandMetadata } from "../../api/portal";

export const useBrandMetadata = (
  programmeCcid: string | undefined,
  includeOpportunity?: boolean,
): UseQueryResult<BrandMetadata, AxiosError> =>
  useQuery(
    ["brandMetadata", programmeCcid, includeOpportunity],
    () =>
      getBrandMetadata(programmeCcid as string, includeOpportunity).then(
        (data) => ({ ...data, programmeCcid }),
      ),
    { enabled: !!programmeCcid && programmeCcid !== "0" },
  );
