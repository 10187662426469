import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Stepper,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FormHeader } from "../../components";
import {
  useMetadataDropdownNew,
  useRiskNew,
  useRiskScoreNew,
  useRiskSummaryNew,
} from "../../hooks";
import { basicInfoSchema } from "./yupSchemas/basicInfoSchema";
import { Form, Formik, FormikErrors } from "formik";
import { BasicInfoPage } from "./NotificationFormPages/BasicInfoPage";
import { CompliancePage } from "./NotificationFormPages/CompliancePage";
import { complianceSchema } from "./yupSchemas/complianceSchema";
import { dutyOfCareSchema } from "./yupSchemas/dutyOfCareSchema";
import { DutyOfCarePage } from "./NotificationFormPages/DutyOfCarePage";
import { InsurancePage } from "./NotificationFormPages/InsurancePage";
import { insuranceSchema } from "./yupSchemas/insuranceSchema";
import { healthAndSafetySchema } from "./yupSchemas/healthAndSafetySchema";
import { HealthAndSafetyPage } from "./NotificationFormPages/HealthAndSafetyPage";
import { DataPrivacyPage } from "./NotificationFormPages/DataPrivacyPage";
import { dataPrivacySchema } from "./yupSchemas/dataPrivacySchema";
import { cyberSecuritySchema } from "./yupSchemas/cyberSecuritySchema";
import { CyberSecurityPage } from "./NotificationFormPages/CyberSecurityPage";
import { sustainabilitySchema } from "./yupSchemas/sustainabilitySchema";
import { SustainabilityPage } from "./NotificationFormPages/SustainabilityPage";
import { securitySchema } from "./yupSchemas/securitySchema";
import { SecurityPage } from "./NotificationFormPages/SecurityPage";
import { getInitialNotificationFormValues } from "./getInitialNotificationFormValues";
import { RiskContact } from "./RiskContact";
import { RiskData } from "../../types/types";
import { DEFAULT_NPNF_VERSION, getLatestDate } from "./notificationForm.helper";
import dayjs from "dayjs";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { NotificationFormError } from "./NotificationFormError";
import { useUpdateRiskVersion } from "../../hooks/mutations/useUpdateRiskVersion";
import { NotificationFormSteps } from "./NotificationFormSteps";
interface FormStep {
  id:
    | "basicInfo"
    | "healthAndSafety"
    | "dutyOfCare"
    | "security"
    | "cyberSecurity"
    | "sustainability"
    | "dataPrivacy"
    | "insurance"
    | "compliance";
  title: string;
}

const notificationFormSchema = Yup.object({
  basicInfo: basicInfoSchema,
  compliance: complianceSchema,
  dutyOfCare: dutyOfCareSchema,
  insurance: insuranceSchema,
  healthAndSafety: healthAndSafetySchema,
  dataPrivacy: dataPrivacySchema,
  cyberSecurity: cyberSecuritySchema,
  sustainability: sustainabilitySchema,
  security: securitySchema,
});

export type NotificationFormSubmitValues = Yup.InferType<
  typeof notificationFormSchema
>;

const STEPS: Array<FormStep> = [
  { id: "basicInfo", title: "Production" },
  { id: "compliance", title: "Compliance" },
  { id: "dutyOfCare", title: "Duty of Care" },
  { id: "insurance", title: "Insurance & Procurement" },
  { id: "healthAndSafety", title: "Health & Safety" },
  { id: "dataPrivacy", title: "Data Privacy" },
  { id: "cyberSecurity", title: "Cyber Security" },
  { id: "sustainability", title: "Sustainability" },
  { id: "security", title: "Security" },
];

export function NotificationFormV2() {
  const { seriesCcid, titleCcid } = useParams<string>();
  const [isChangeVersionOpen, setIsChangeVersionOpen] = useState(false);

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const currentStep = STEPS[currentStepIndex];
  const stepNumber = currentStepIndex + 1;

  const ccid = titleCcid || seriesCcid;

  const { data: dropdownMetadata, isLoading: dropdownMetadataLoading } =
    useMetadataDropdownNew();
  const { data: summary } = useRiskSummaryNew({ ccid });
  const {
    data: formData,
    isLoading: formDataLoading,
    error: riskError,
  } = useRiskNew({
    ccid,
    version: "v2",
  });
  const { data: riskScore, isLoading: riskScoreLoading } = useRiskScoreNew({
    ccid,
    version: "v2",
  });

  const { mutate: updateVersion } = useUpdateRiskVersion();

  const autosaveForm = () => {
    return;
  };

  const isLoading =
    dropdownMetadataLoading || formDataLoading || riskScoreLoading;

  if (isLoading) {
    return (
      <>
        <FormHeader
          formTitle="New Production Notification Form"
          showFormStatus
          summary={summary}
        />
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  if (!dropdownMetadata || !formData) {
    return (
      <>
        <FormHeader
          formTitle="New Production Notification Form"
          showFormStatus
          summary={summary}
        />
        <Stack alignItems="center" m={4}>
          <Alert severity="error">
            There was an error retrieving the data for this form. Please contact
            us if this persists
          </Alert>
        </Stack>
      </>
    );
  }

  const renderFormStepContent = () => {
    switch (currentStep.id) {
      case "basicInfo":
        return (
          <BasicInfoPage
            dropdownMetadata={dropdownMetadata}
            autosaveForm={autosaveForm}
          />
        );
      case "compliance":
        return (
          <CompliancePage
            dropdownMetadata={dropdownMetadata}
            autosaveForm={autosaveForm}
          />
        );
      case "dutyOfCare":
        return <DutyOfCarePage autosaveForm={autosaveForm} />;
      case "insurance":
        return <InsurancePage autosaveForm={autosaveForm} />;
      case "healthAndSafety":
        return <HealthAndSafetyPage autosaveForm={autosaveForm} />;
      case "dataPrivacy":
        return <DataPrivacyPage autosaveForm={autosaveForm} />;
      case "cyberSecurity":
        return <CyberSecurityPage autosaveForm={autosaveForm} />;
      case "sustainability":
        return <SustainabilityPage autosaveForm={autosaveForm} />;
      case "security":
        return (
          <SecurityPage
            dropdownMetadata={dropdownMetadata}
            autosaveForm={autosaveForm}
          />
        );
      default:
        return null;
    }
  };

  const riskData: RiskData | null =
    currentStep.id === "basicInfo" || !riskScore
      ? null
      : riskScore[currentStep.id] || null;

  const autosaveDate = getLatestDate(formData);

  function FormSteps({
    currentStepIndex,
    handleStepChange,
    autosaveDate,
    isSubmitting,
    onFormValidate,
  }: {
    currentStepIndex: number;
    handleStepChange: (stepIndex: number) => void;
    autosaveDate: string | undefined;
    isSubmitting: boolean;
    onFormValidate: (
      values?: NotificationFormSubmitValues,
    ) => Promise<FormikErrors<NotificationFormSubmitValues>>;
  }) {
    return (
      <Box position="sticky" top={0} padding={1}>
        <Stack spacing={2}>
          <Stepper
            orientation="vertical"
            nonLinear
            activeStep={currentStepIndex}
            sx={{ minWidth: "270px" }}
          >
            <NotificationFormSteps
              version="v2"
              riskScore={riskScore}
              onStepChange={handleStepChange}
              flagError={false}
              currentStepIndex={currentStepIndex}
            />
          </Stepper>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={() => setIsChangeVersionOpen(true)}
          >
            Open form for editing
          </LoadingButton>

          <Stack spacing={1} direction="row">
            <IconButton
              aria-label="Previous step"
              disabled={currentStepIndex === 0}
              color="primary"
              onClick={() => {
                const newStepIndex = Math.max(0, currentStepIndex - 1);
                handleStepChange(newStepIndex);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                const newStepIndex = Math.min(
                  STEPS.length - 1,
                  currentStepIndex + 1,
                );
                handleStepChange(newStepIndex);
              }}
              disabled={currentStepIndex === STEPS.length - 1}
              sx={{ fontWeight: "light" }}
              endIcon={<ArrowForward />}
            >
              Next Section
            </Button>
          </Stack>

          <Box>
            {autosaveDate && (
              <Typography sx={{ fontSize: "14px", fontWeight: "light" }}>
                Autosaved: {dayjs(autosaveDate).format("DD/MM/YYYY HH:mm a")}
              </Typography>
            )}
          </Box>
        </Stack>
      </Box>
    );
  }

  const Content = (
    <>
      <FormHeader
        formTitle="New Production Notification Form"
        showFormStatus
        summary={summary}
      />

      <Container disableGutters>
        <Formik
          initialValues={getInitialNotificationFormValues(formData)}
          validationSchema={notificationFormSchema}
          validateOnChange={false}
          validateOnMount={true}
          onSubmit={() => {
            return;
          }}
        >
          {({ values, validateForm }) => (
            <>
              <Form>
                <Stack alignItems="left" spacing={5} direction="row">
                  <Box sx={{ maxWidth: "100%", p: 1, position: "sticky" }}>
                    <FormSteps
                      onFormValidate={validateForm}
                      currentStepIndex={currentStepIndex}
                      handleStepChange={setCurrentStepIndex}
                      autosaveDate={autosaveDate}
                      isSubmitting={false}
                    />
                  </Box>
                  <Box width="100%">
                    <Stack spacing={2}>
                      <Typography variant="h5">
                        {`Section ${stepNumber} of ${STEPS.length}: ${currentStep.title}`}
                      </Typography>

                      {riskData && riskData.assessor ? (
                        <RiskContact
                          riskAssessor={riskData.assessor}
                          riskLevel={riskData.rating}
                          preMitgationLevel={riskData.preMitigationRating}
                          preMitgationNotes={riskData.preMitigationNotes}
                          postMitgationLevel={riskData.postMitigationRating}
                          postMitgationNotes={riskData.postMitigationNotes}
                        />
                      ) : null}

                      {currentStepIndex === 0 ? (
                        <>
                          <Typography variant="body1">
                            This form brings all elements of risk together in
                            one form, helping us review, manage, escalate and
                            report on risk across key areas of risk.
                          </Typography>
                          <Typography variant="body1">
                            You should get the form completed as soon as
                            possible - ideally before the start-up meeting - to
                            help us ensure you have the right support to manage
                            potential risks. All the information you provide in
                            the form will help us maintain a log of all current
                            and ongoing production.
                          </Typography>
                          <Typography variant="body1">
                            We are constantly updating and improving this form,
                            so if you have any questions or feedback - please
                            let us know by emailing risk@itv.com.
                          </Typography>
                        </>
                      ) : null}

                      <Typography fontStyle="italic" marginTop={2}>
                        <Typography component="span" color="error">
                          *
                        </Typography>{" "}
                        Indicates a required field
                      </Typography>
                      {renderFormStepContent()}
                    </Stack>
                  </Box>
                </Stack>
              </Form>
              <Dialog open={isChangeVersionOpen} maxWidth="xs">
                <DialogTitle>Edit Form</DialogTitle>
                <DialogContent>
                  <Typography gutterBottom>
                    Please confirm to enable editing:
                  </Typography>

                  <Typography
                    textAlign="justify"
                    fontWeight="bold"
                    fontStyle="italic"
                  >
                    Clicking on confirm will update to show the latest set of
                    questions required by Group Risk. You will be required to
                    add additional information to the form before submitting or
                    re-submitting.
                  </Typography>
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => setIsChangeVersionOpen(false)}
                    variant="outlined"
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={() => {
                      updateVersion({
                        ccid: ccid as string,
                        version: DEFAULT_NPNF_VERSION,
                      });
                      setIsChangeVersionOpen(false);
                    }}
                    variant="contained"
                  >
                    Confirm &amp; open form for editing
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
  return (
    <>
      {isLoading}
      {!isLoading && riskError && (
        <NotificationFormError riskError={riskError} />
      )}
      {!formDataLoading && !riskError && Content}
    </>
  );
}
