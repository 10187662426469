import React, { FC, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ReactComponent as ItvLogo } from "../../svg/itv-logo.svg";

const LoginPage: FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const login = async () => oktaAuth.signInWithRedirect();

  // disable scrolling on login page but enable when unmounted
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  // user is loading
  if (!authState) return null;

  // user is logged in so redirect to home page
  if (authState.isAuthenticated) return <Navigate to="/" />;

  // user is logged out so show login page
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundImage:
          "linear-gradient(to bottom right, #22252F, #282D39, #191B22);",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
      }}
    >
      <Stack spacing={8} sx={{ alignItems: "center" }}>
        <ItvLogo width="14vw" />
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <Typography variant="h6" textAlign="center">
            Sign in to your ITV Account
          </Typography>
          <Button
            onClick={login}
            variant="contained"
            color="info"
            sx={{ width: "75%" }}
          >
            Sign-in
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LoginPage;
