import React from "react";
import { Button } from "@mui/material";
import { closeSnackbar, SnackbarAction } from "notistack";

export const SnackbarDismiss: SnackbarAction = (snackbarId) => (
  <>
    <Button
      sx={{ color: "white" }}
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      Dismiss
    </Button>
  </>
);
