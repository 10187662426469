import { Box, Stack, Typography, Button } from "@mui/material";
import { Visibility } from "@mui/icons-material";

export interface ViewOnlyBannerProps {
  requestAccessBtnClick: (e: React.SyntheticEvent) => void;
}

export const ViewOnlyBanner: React.FC<ViewOnlyBannerProps> = ({
  requestAccessBtnClick,
}) => (
  <Box
    sx={{
      width: "100%",
      backgroundColor: "secondary.main",
      color: "white",
      px: 4,
      py: 1,
    }}
  >
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row">
        <Visibility
          sx={{
            mr: 1,
          }}
        />
        <Typography>View-only mode</Typography>
      </Stack>
      <Button
        onClick={requestAccessBtnClick}
        variant="outlined"
        sx={{
          color: "white",
          borderColor: "knockedWhite",
          "&:hover": {
            borderColor: "white",
            backgroundColor: "secondary.dark",
          },
        }}
      >
        Request full access
      </Button>
    </Stack>
  </Box>
);
