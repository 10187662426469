import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { updateEpisode } from "../../api/portal";
import { TitleDetailsToUpdate } from "../../types/types";

export const useUpdateEpisode = (): UseMutationResult<
  unknown,
  unknown,
  { ccid: string; payload: TitleDetailsToUpdate }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ ccid, payload }) => updateEpisode(ccid, payload), {
    onSettled: () => {
      queryClient.invalidateQueries("titles");
    },
  });
};
