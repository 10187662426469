import { useQuery, UseQueryResult } from "react-query";
import { getSeriesMetadata, getSeriesMetadataMultiple } from "../../api/portal";
import { SeriesMetadata } from "../../types/types";

export const useSeriesMetadata = (
  seriesCcid: string | undefined,
): UseQueryResult<SeriesMetadata> =>
  useQuery<SeriesMetadata>(
    ["seriesMetadata", seriesCcid],
    () => getSeriesMetadata(seriesCcid as string),
    { enabled: !!seriesCcid && seriesCcid !== "0" },
  );

export const useSeriesMetadataMultiple = (
  seriesCcids: string[],
): UseQueryResult<SeriesMetadata[]> =>
  useQuery<SeriesMetadata[]>(
    ["seriesMetadataMultiple", seriesCcids.join("-")],
    () => getSeriesMetadataMultiple(seriesCcids),
    { enabled: !!seriesCcids.length },
  );
