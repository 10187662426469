/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { createMyDrive } from "../../api/googledrive";
import { noop } from "../../utils/appHelper";

export const useCreateMyDrive = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(({ ccid, payload }: any) => createMyDrive(ccid, payload), {
    onSuccess,
    onError,
  });
