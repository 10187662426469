/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";
import { FC, useState, useEffect, useMemo } from "react";
import { Button, Typography, IconButton, Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Steps, StepItem } from "../Steps";
import { getStepsItems as getNotificationFormStepsItems } from "../../containers/NotificationForm/notificationForm.helper";
import { RiskScore } from "../../types/types";

interface SideNavProps {
  formData: any;
  groupMenuSetting: any[];
  onStepChange(tab: string): void;
  activeStep: string;
  onSubmitForm(): void;
  isSubmitting: boolean;
  autoSaveAt?: string;
  riskScore?: RiskScore;
  submitButtonDisabled?: boolean;
  getStepsItems?: (
    formData?: any,
    groupMenuSetting?: any,
    currentTab?: string,
  ) => StepItem[];
  submitButtonLabel?: string;
}

export const SideNav: FC<SideNavProps> = ({
  formData,
  groupMenuSetting,
  onStepChange,
  activeStep,
  onSubmitForm,
  isSubmitting,
  autoSaveAt,
  riskScore,
  submitButtonDisabled = false,
  getStepsItems = getNotificationFormStepsItems,
  submitButtonLabel,
}) => {
  const [stepItems, setStepItems] = useState<StepItem[]>([]);
  const currentStepIndex = useMemo(
    () => groupMenuSetting.findIndex((item) => item.id === activeStep),
    [groupMenuSetting, activeStep],
  );
  const isFirstStep = useMemo(() => currentStepIndex === 0, [currentStepIndex]);
  const isLastStep = useMemo(
    () => currentStepIndex === groupMenuSetting.length - 1,
    [currentStepIndex, groupMenuSetting.length],
  );
  const submitButtonLabelStr = submitButtonLabel || "Submit form";

  useEffect(() => {
    setStepItems(
      getStepsItems(formData, groupMenuSetting, activeStep, riskScore),
    );
  }, [formData, groupMenuSetting, activeStep, riskScore, getStepsItems]);

  return (
    <Box position="sticky" top={0}>
      <Stack spacing={0}>
        <Steps
          items={stepItems}
          onItemClick={(item: StepItem) => {
            onStepChange(item.id);
          }}
        />

        <Stack spacing={2} sx={{ m: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <IconButton
              data-testid="preButton"
              color="primary"
              disabled={isFirstStep}
              onClick={() => {
                onStepChange(groupMenuSetting[currentStepIndex - 1]?.id);
              }}
            >
              <ArrowBack />
            </IconButton>

            <Button
              variant="outlined"
              color="primary"
              data-testid="nextButton"
              endIcon={<ArrowForward />}
              onClick={() => {
                onStepChange(groupMenuSetting[currentStepIndex + 1]?.id);
              }}
              disabled={isLastStep}
              sx={{ fontWeight: "light" }}
            >
              Next section
            </Button>
          </Stack>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmitForm}
            disabled={isSubmitting || submitButtonDisabled}
            loading={isSubmitting}
            data-testid="submitBtn"
            sx={{ fontWeight: "light" }}
          >
            {submitButtonLabelStr}
          </LoadingButton>

          {autoSaveAt && (
            <Typography sx={{ fontSize: "14px", fontWeight: "light" }}>
              Autosaved: {dayjs(autoSaveAt).format("DD/MM/YYYY HH:mm a")}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
