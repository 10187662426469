import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { BooleanAsYesNo } from "../../types/types";

interface YesNoRadioProps {
  name: string;
  label: string;
  value: BooleanAsYesNo;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
}

export const YesNoRadio: React.FC<YesNoRadioProps> = ({
  name,
  label,
  value,
  onChange,
  error,
}: YesNoRadioProps) => (
  <FormControl
    error={error}
    required
    component="fieldset"
    sx={{ m: 1 }}
    variant="standard"
  >
    <FormLabel component="legend">{label}</FormLabel>
    <RadioGroup
      aria-labelledby="my-radio-group"
      name={name}
      value={value}
      onChange={onChange}
      sx={{ ml: 1 }}
    >
      <FormControlLabel
        value="Yes"
        control={<Radio size="small" />}
        label="Yes"
      />
      <FormControlLabel
        value="No"
        control={<Radio size="small" />}
        label="No"
      />
    </RadioGroup>
  </FormControl>
);
