import { FC, useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Paper,
  Container,
  Skeleton,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import {
  useBrandMetadata,
  useCcidDropdown,
  useCreateBrandedSpecial,
  useCreateSeries,
  useSeries,
} from "../../hooks";
import {
  CcidCreateBrandedSpecialPayload,
  CcidCreateSeriesPayload,
  CreateCcidFormData,
  ProductionType,
} from "../../types/types";
import { CcidCreationFormFormik } from "../CreateCcid/CcidCreationFormFormik";
import { CreateSeriesDialog } from "./CreateSeriesDialog";
import axios from "axios";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

const currentYear = new Date().getFullYear();
const snackbarDuration = 3000;

const registrationSchema = Yup.object().shape({
  productionType: Yup.string<ProductionType>().required(),
  productionLabels: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, "This is a required field"),
  commissioner: Yup.string().required("This is a required field"),
  whoIsThisFor: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().when("$commissioner", ([commissioner], schema) => {
      if (commissioner === "ITV") {
        return Yup.string().required("This is a required field");
      }
      return schema;
    }),
  }),
  productionYear: Yup.string().required("This is a required field"),
  specialName: Yup.string().when("productionType", {
    is: (productionType: string) => productionType === "special",
    then: () => Yup.string().required("This is a required field"),
  }),
  sequence: Yup.string().when("productionType", {
    is: (productionType: string) => productionType === "series",
    then: () =>
      Yup.string()
        .required("This is a required field")
        .matches(/^[0-9]+$/, "Must be only numerical digits"),
  }),
  count: Yup.string().when("productionType", {
    is: (productionType: string) => productionType === "series",
    then: () =>
      Yup.string()
        .required("This is a required field")
        .matches(/^[0-9]+$/, "Must be only numerical digits"),
  }),
  intendedSlotLength: Yup.string()
    .required("This is a required field")
    .matches(/^[0-9]+$/, "Must be only numerical digits"),
  genre: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().required("This is a required field"),
  }),
  subgenres: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, "This is a required field"),
  productionLead: Yup.object()
    .shape({
      id: Yup.string(),
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string(),
      role: Yup.string(),
    })
    .notRequired(),
});

export const CreateSeries: FC = () => {
  const navigate = useNavigate();
  const [seriesCcidtoRedirect, setSeriesCcidtoRedirect] = useState<string>("");
  const [seriesNumberToRedirect, setSeriesNumberToRedirect] =
    useState<number>(1);
  const { programmeCcid } = useParams<string>();
  const queryClient = useQueryClient();
  const { data: brand, isLoading: brandLoading } = useBrandMetadata(
    programmeCcid,
    true,
  );
  const { data: seriesData } = useSeries(programmeCcid, true);

  const { mutateAsync: createSeries } = useCreateSeries((data) => {
    enqueueSnackbar(`Series created`, {
      variant: "success",
      autoHideDuration: snackbarDuration,
    });
    if (data) {
      queryClient.invalidateQueries("series");
      navigate(`../series/${data.ccid}`);
    }
  });
  const { mutateAsync: createSpecial } = useCreateBrandedSpecial((data) => {
    enqueueSnackbar(`Special created`, {
      variant: "success",
      autoHideDuration: snackbarDuration,
    });
    if (data) navigate(`../series/0/titles/${data.ccid}`);
  });

  useEffect(() => {
    if (seriesData?.seriesDetails) {
      seriesData.seriesDetails.forEach((element) => {
        if (element.status && element.status === "OPPORTUNITY") {
          setSeriesCcidtoRedirect(element.ccid);
          setSeriesNumberToRedirect(Number(element.seriesNumber));
        }
      });
    }
  }, [seriesData]);

  const { data: dropdownOptions } = useCcidDropdown();

  const handleError = (
    error: unknown,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldError: (field: string, message: string) => void,
  ) => {
    if (
      axios.isAxiosError(error) &&
      error.response &&
      error.response.status === 403
    ) {
      enqueueSnackbar(
        `You do not have permission to create a production for this producer. Please get in touch if you feel you should.`,
        { variant: "error", action: SnackbarDismiss },
      );
      setFieldError(
        "productionLabels",
        "You do not have permission to create a production for this producer. Please get in touch if you feel you should.",
      );
      setSubmitting(false);
    } else {
      enqueueSnackbar(
        `There was an error creating your series, please try again.`,
        { variant: "error", action: SnackbarDismiss },
      );
      navigate(`../`);
    }
  };

  const onSubmit = async (
    values: CreateCcidFormData,
    { setSubmitting, setFieldError }: FormikHelpers<CreateCcidFormData>,
  ) => {
    if (values.productionType === "series") {
      const formattedSeriesData: CcidCreateSeriesPayload = {
        productionLabels: values.productionLabels
          ? Array.from(values.productionLabels, (p) => p.value)
          : [],
        commissioner: values.commissioner,
        sequence: Number(values.sequence),
        count: Number(values.count),
        whoIsThisFor:
          values.commissioner === "ITV" ? values.whoIsThisFor.value : "OFF_ITV",
        productionYear: Number(values.productionYear),
        intendedSlotLength: Number(values.intendedSlotLength),
        genre: values.genre.value,
        subgenres: values.subgenres
          ? Array.from(values.subgenres, (subgenre) => subgenre.value)
          : [],
        productionLead: values.productionLead
          ? {
              name: `${values.productionLead.firstName} ${values.productionLead.lastName}`,
              email: values.productionLead.email,
            }
          : undefined,
      };
      if (programmeCcid !== undefined) {
        try {
          await createSeries({
            ccid: programmeCcid,
            formData: formattedSeriesData,
          });
        } catch (error) {
          handleError(error, setSubmitting, setFieldError);
        }
      }
    }

    if (values.productionType === "special") {
      const formattedSpecialData: CcidCreateBrandedSpecialPayload = {
        specialName: values.specialName || "",
        productionLabels: values.productionLabels
          ? Array.from(values.productionLabels, (p) => p.value)
          : [],
        commissioner: values.commissioner,
        whoIsThisFor:
          values.commissioner === "ITV" ? values.whoIsThisFor.value : "OFF_ITV",
        genre: values.genre.value,
        subgenres: values.subgenres
          ? Array.from(values.subgenres, (subgenre) => subgenre.value)
          : [],
        productionYear: Number(values.productionYear),
        intendedSlotLength: Number(values.intendedSlotLength),
        productionLead: values.productionLead
          ? {
              name: `${values.productionLead.firstName} ${values.productionLead.lastName}`,
              email: values.productionLead.email,
            }
          : undefined,
      };
      if (programmeCcid !== undefined)
        try {
          await createSpecial({
            ccid: programmeCcid,
            formData: formattedSpecialData,
          });
        } catch (error) {
          handleError(error, setSubmitting, setFieldError);
        }
    }
  };

  return (
    <Container>
      <Paper>
        <Box my={2} p={2}>
          <Box mb={1}>
            <Typography variant="h4" display="inline">
              Create Series -{" "}
            </Typography>
            {brandLoading ? (
              <Skeleton sx={{ display: "inline-block" }}>
                <Typography variant="h4">Loading brand</Typography>
              </Skeleton>
            ) : (
              <Typography variant="h4" display="inline">
                {brand?.brandName}
              </Typography>
            )}
          </Box>

          <Stack direction="row" spacing={4} my={1}>
            <Box>
              <Typography display="inline">Production Year(s): </Typography>
              {brandLoading ? (
                <Skeleton sx={{ display: "inline-block" }}>
                  <Typography>2020-2023</Typography>
                </Skeleton>
              ) : (
                <Typography display="inline">
                  {brand?.productionYears?.earliest} -{" "}
                  {brand?.productionYears?.latest}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography display="inline">Programme CCID: </Typography>
              {brandLoading ? (
                <Skeleton sx={{ display: "inline-block" }}>
                  <Typography>brandccid</Typography>
                </Skeleton>
              ) : (
                <Typography display="inline"> {brand?.brandCcid}</Typography>
              )}
            </Box>
          </Stack>
          {dropdownOptions?.producers && brand ? (
            <Formik
              initialValues={{
                productionType: "",
                productionLabels: brand.productionLabels
                  ? dropdownOptions.producers.filter((producer) =>
                      brand.productionLabels.includes(producer.label),
                    )
                  : [],
                commissioner: "",
                whoIsThisFor: { label: "", value: "" },
                productionYear: currentYear.toString(),
                specialName: "",
                sequence: (brand.seriesCount + 1).toString(),
                count: "",
                intendedSlotLength: "",
                genre: { label: "", value: "" },
                subgenres: [],
              }}
              validationSchema={registrationSchema}
              onSubmit={onSubmit}
            >
              {({ submitForm, isSubmitting, values }) =>
                isSubmitting ? (
                  <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Form>
                    <FormControl>
                      <FormLabel id="production-type">
                        Production Type
                      </FormLabel>
                      <Field as={RadioGroup} row name="productionType">
                        <FormControlLabel
                          value="series"
                          control={<Radio size="small" />}
                          label="Series"
                        />
                        <FormControlLabel
                          value="special"
                          control={<Radio size="small" />}
                          label="Special"
                        />
                      </Field>
                    </FormControl>

                    {values.productionType ? (
                      <>
                        <Box my={2}>
                          <Typography>
                            We only need a few more details to add a new{" "}
                            {values.productionType} to this brand:
                          </Typography>
                        </Box>

                        <CcidCreationFormFormik
                          useUpdateSeries={false}
                          prepopulatedData={{}}
                        />
                        <br />
                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={4}
                        >
                          <Button onClick={() => navigate("..")}>Cancel</Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            Submit
                          </Button>
                        </Stack>
                      </>
                    ) : null}
                  </Form>
                )
              }
            </Formik>
          ) : (
            <></>
          )}
        </Box>
        <CreateSeriesDialog
          ccidToRedirect={seriesCcidtoRedirect}
          seriesNumber={seriesNumberToRedirect}
        />
      </Paper>
    </Container>
  );
};
