import { FC, useState } from "react";
import {
  Typography,
  IconButton,
  Button,
  Stack,
  Dialog,
  Alert,
  Divider,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FormikTouched, FormikErrors } from "formik";
import {
  ProductionPrinciplesFormData,
  ProductionPrinciplesFormDataFormik,
} from "../../types/types";

const validationErrorMap: { [key: string]: string } = {
  underrepresentedActor:
    "Answer is required for inclusion of an under represented actor",
  underrepresentedGroup:
    "Answer is required for inclusion of an under represented group",
  underrepresentedStory:
    "Answer is required for inclusion of an under represented story",
  underrepresentedCreative:
    "Answer is required for inclusion of an under represented creative team",
  underrepresentedCreativeDetails:
    "As you have selected yes for inclusion of under represented creative team, you need to provide additionl details",
  underrepresentedProductionTeam:
    "Answer is required for inclusion of an under represented prodcution team",
  underrepresentedProductionTeamDetails:
    "As you have selected yes for inclusion of under represented production team, you need to provide additionl details",
  underrepresentedCrew:
    "Answer is required for inclusion of an under represented crew ",
  underrepresentedCrewDetails:
    "As you have selected yes for inclusion of under represented crew, you need to provide additionl details",
  PoCHairAndMakeup:
    "If any of your actors are People of Colour, we expect that they will be consulted in relation to their Hair/Make Up and that specialised Hair/Make Up people are hired?",
  stepUpSixty: "step up sixty answer is required",
  stepUpSixtyDetails:
    "please provide more details about step up sixty implementation",
  paidTrainee:
    "Answer is required to if your production include a paid trainee",
  diversityCommissioningFund:
    "please confirm your answer to Diversity Commissioning Fund",
  antibullyCommitment:
    "please select at least one checkbox for anti bullying commitment",
};

export const ProductionPrinciplesFormSteps: FC<{
  onStepChange: (step: number) => void;
  onSubmitForm: () => void;
  onFormValidate: (
    values?: ProductionPrinciplesFormDataFormik,
  ) => Promise<FormikErrors<ProductionPrinciplesFormDataFormik>>;
  setTouched: (
    touched: FormikTouched<ProductionPrinciplesFormData>,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<ProductionPrinciplesFormDataFormik>>;
  modifiedTime?: string;
  submittedBy?: string;
}> = ({
  onStepChange,
  onSubmitForm,
  onFormValidate,
  setTouched,
  modifiedTime,
  submittedBy,
}) => {
  const [step, setStep] = useState<number>(1);
  const [declarationOpen, setDeclarationOpen] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [page1ValidationErrors, setpage1ValidationErrors] = useState<string[]>(
    [],
  );
  const [page2ValidationErrors, setpage2ValidationErrors] = useState<string[]>(
    [],
  );
  const handleSubmitButtonClick = () =>
    onFormValidate().then(
      (errors: FormikErrors<ProductionPrinciplesFormDataFormik>) => {
        if (Object.keys(errors).length === 0) {
          setpage2ValidationErrors([]);
          setDeclarationOpen(true);
        } else {
          const validationErrors = Object.keys(errors).map((key) => key);
          setpage2ValidationErrors(validationErrors);
          setErrorDialogOpen(true);

          setTouched({
            underrepresentedActor: true,
            underrepresentedGroup: true,
            underrepresentedStory: true,
            PoCHairAndMakeup: true,
            underrepresentedCreative: true,
            underrepresentedProductionTeam: true,
            underrepresentedCrew: true,
            stepUpSixty: true,
            paidTrainee: true,
          });
        }
      },
    );

  const handleStepChange = (newStep: number) =>
    onFormValidate().then(
      (errors: FormikErrors<ProductionPrinciplesFormDataFormik>) => {
        if (errors.antibullyCommitment || errors.diversityCommissioningFund) {
          const validationErrors = Object.keys(errors).map((key) => key);
          const errorsArray = [];
          if (validationErrors.includes("antibullyCommitment")) {
            errorsArray.push("antibullyCommitment");
          }
          if (validationErrors.includes("diversityCommissioningFund")) {
            errorsArray.push("diversityCommissioningFund");
          }
          setpage1ValidationErrors(errorsArray);
          setErrorDialogOpen(true);
          setTouched({
            antibullyCommitment: true,
            diversityCommissioningFund: true,
          });
        } else {
          setStep(newStep);
          onStepChange(newStep);
        }
      },
    );

  const dotColor = step === 2 ? "green" : "black";

  return (
    <>
      <Timeline sx={{ width: "270px" }}>
        <TimelineItem>
          <TimelineOppositeContent sx={{ display: "none" }} />
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: dotColor }} variant="filled" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleStepChange(1)}
          >
            <Typography component="span">Inclusion Policies</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent sx={{ display: "none" }} />
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              color="primary"
              variant={step === 2 ? "filled" : "outlined"}
            />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleStepChange(2)}
          >
            <Stack>
              <Divider sx={{ p: 1 }} />
              <Typography position="relative" component="span">
                Inclusive Programming
              </Typography>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <Stack spacing={1} direction="row">
        <IconButton
          disabled={step === 1}
          color="primary"
          onClick={() => handleStepChange(step - 1)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Button
          disabled={step === 2}
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => handleStepChange(step + 1)}
        >
          Next Section <ArrowForwardIcon />
        </Button>
      </Stack>
      <Button
        fullWidth
        sx={{ p: 1, m: 1 }}
        disabled={step === 1}
        variant="contained"
        color="primary"
        onClick={handleSubmitButtonClick}
      >
        Submit Form
      </Button>
      <Typography
        variant="caption"
        display="block"
        textAlign="left"
        color="gray"
        sx={{ px: 2 }}
      >
        {`Autosaved at: ${modifiedTime || "Time Unavailable"} ${submittedBy ? `by ${submittedBy}` : ""}`}
      </Typography>
      <Dialog open={declarationOpen} maxWidth="xs">
        <Stack direction="column" justifyContent="center" sx={{ m: 2 }}>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            Provisional Form Declaration
          </Typography>
          <Typography textAlign="center">
            This form is currently in a provisional status and you will be
            required to review all this information before submitting the final
            version post-production. By clicking submit you declare that all the
            information provided is accurate and complies with the Equality Act
            2010.
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          style={{ color: "white", padding: "2%" }}
        >
          <Button onClick={() => setDeclarationOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={onSubmitForm}
            variant="contained"
            sx={{
              background: "white",
              color: "black",
              "&:hover": {
                background: "#e0e0e0",
                color: "black",
              },
            }}
          >
            Submit Form
          </Button>
        </Stack>
      </Dialog>
      <Dialog open={errorDialogOpen} maxWidth="xs">
        <Stack direction="column" justifyContent="center" sx={{ m: 2 }}>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            There's a few things not quite right....
          </Typography>

          {step === 1
            ? page1ValidationErrors.map((err) => (
                <Alert key={err} sx={{ py: 2 }} severity="error">
                  {validationErrorMap[err]}
                </Alert>
              ))
            : page2ValidationErrors.map((err) => (
                <Alert key={err} sx={{ py: 2 }} severity="error">
                  {validationErrorMap[err]}
                </Alert>
              ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          style={{ color: "white", padding: "2%" }}
        >
          <Button onClick={() => setErrorDialogOpen(false)} color="secondary">
            Back
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};
