import * as Yup from "yup";

export const healthAndSafetySchemaV3 = Yup.object().shape({
  spotId: Yup.string(),
  activities: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  otherActivities: Yup.string(),
  filmingLocation: Yup.boolean().required("This is a required field"),
  responsibleNone: Yup.boolean().required("This is a required field"),
  responsible: Yup.string().when(
    "$healthAndSafety.responsibleNone",
    ([nobodyResponsible], schema) => {
      if (nobodyResponsible !== true) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  responsibleEmail: Yup.string()
    .email("Invalid email")
    .when("$healthAndSafety.responsibleNone", ([nobodyResponsible], schema) => {
      if (nobodyResponsible !== true) {
        return schema.required("This is a required field");
      }
      return schema;
    }),
  setBuild: Yup.boolean().required("This is a required field"),
  training: Yup.string(),
  trainingCourse: Yup.string(),
  vulnerable: Yup.string().required("This is a required field"),
  externalProvider: Yup.boolean().required("This is a required field"),
});

export type HealthAndSafetySectionV3 = Yup.InferType<
  typeof healthAndSafetySchemaV3
>;
