/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { updateGroupAdminPermission } from "../../api/accessPermissions";
import { noop } from "../../utils/appHelper";

export const useUpdateGroupPermissions = (
  onSuccess = noop,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onError = (error: any) => {},
): UseMutationResult =>
  useMutation(
    (payload: any) =>
      updateGroupAdminPermission(
        payload.email,
        payload.groupId,
        payload.permissions,
        payload.permission,
      ),
    { onSuccess, onError },
  );
