/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { DoneOutlined } from "@mui/icons-material";

import {
  StyledItem,
  StyledIconWrapper,
  StyledSubTitle,
} from "./TaskItem.style";
import { useTaskStatus } from "../../../../../hooks/queries";

const checkIcon = (
  <DoneOutlined
    color="inherit"
    sx={{
      fontSize: 40,
      strokeWidth: 1.5,
      color: "white",
    }}
  />
);

export interface TaskItemProps {
  icon: React.ReactNode;
  description: string | React.ReactNode;
  id: string | string[];
  taskStatusUrl: string | null;
  subTitle?: string;
  onItemSelect(id: string | string[]): void;
}

export const TaskItem: FC<TaskItemProps> = ({
  icon,
  description,
  id,
  taskStatusUrl,
  subTitle,
  onItemSelect,
}) => {
  const { data: taskStatus } = useTaskStatus(taskStatusUrl);
  return (
    <StyledItem
      onClick={() => {
        if (onItemSelect) {
          onItemSelect(id);
        }
      }}
      completed={taskStatus?.completed}
    >
      <div>
        <StyledIconWrapper>
          {taskStatus?.completed ? checkIcon : icon}
        </StyledIconWrapper>
      </div>
      <div>
        <div>
          {description}
          {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
        </div>
      </div>
    </StyledItem>
  );
};
