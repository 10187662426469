import * as Yup from "yup";

export const complianceSchema = Yup.object().shape({
  corruption: Yup.boolean().when(
    "$basicInfo.outsideLocation",
    ([outsideLocation], schema) => {
      if (outsideLocation) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  countries: Yup.array()
    .of(Yup.string().required())
    .when("$basicInfo.outsideLocation", ([outsideLocation], schema) => {
      if (outsideLocation) {
        return schema
          .required("This is a required field")
          .min(1, "This is a required field");
      }
      return schema;
    }),
  tax: Yup.boolean().when(
    "$basicInfo.outsideLocation",
    ([outsideLocation], schema) => {
      if (outsideLocation) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  vat: Yup.boolean().when(
    "$basicInfo.outsideLocation",
    ([outsideLocation], schema) => {
      if (outsideLocation) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  sanctions: Yup.boolean().when(
    "$basicInfo.outsideLocation",
    ([outsideLocation], schema) => {
      if (outsideLocation) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  slavery: Yup.boolean().when(
    "$basicInfo.outsideLocation",
    ([outsideLocation], schema) => {
      if (outsideLocation) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
});

export type ComplianceSection = Yup.InferType<typeof complianceSchema>;
