import { useMutation } from "react-query";
import { startImageUpload } from "../../api/images";
import { LevelType, UploadType } from "../../types/types";

interface UploadFileArgs {
  level: LevelType;
  ccid: string;
  deliverableType: UploadType;
  file: {
    contentType: string;
    objectName: string;
    imageTag: string;
  };
}

export function useUploadFile() {
  return useMutation(
    ({ level, ccid, file, deliverableType }: UploadFileArgs) => {
      return startImageUpload(level, ccid, file, deliverableType);
    },
  );
}
