/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-restricted-syntax */
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import {
  AUTOCOMPLETE_WITH_SINGLE_SELECTION,
  CONTRIBUTORS,
  DATE,
  EMPTY,
  FormField,
  GROUP_CHECKBOXES,
  RADIO,
  SELECT,
} from "../../components";
import { shouldShowField } from "../../components/DynamicForm/DynamicForm.helper";
import { cleanContributors } from "../Synopsis/Edit/Contributors/contributor.helper";
import { StepItemStatus, StepItem } from "../../components/Steps";
import {
  DropdownMetadata,
  DropdownMetadataKey,
  RiskScore,
  RiskScoreId,
} from "../../types/types";
import { clone } from "../../utils/appHelper";

export const transFormDataBaseOnTypeAndValue = (
  { type }: FormField,
  value: any,
) => {
  let newValue = value;

  if (type === DATE) {
    if (newValue) {
      const day = dayjs(newValue);
      newValue = day.format("YYYY-MM-DD");
    }
  } else if (type === AUTOCOMPLETE_WITH_SINGLE_SELECTION) {
    if (newValue) {
      newValue = newValue.map((data: any) => data.value);
    }
  } else if (type === CONTRIBUTORS) {
    newValue = cleanContributors(newValue);
  }

  return newValue;
};

export const sanitizeFormData = (
  formData: Record<string, any>,
  groupMenuSetting: any[],
  setDataForUI = false,
) => {
  const copyData = clone(formData);
  groupMenuSetting.forEach(({ formConfig, id: tabId }) => {
    formConfig.forEach(({ formFields }: any) => {
      formFields.forEach(
        ({
          type,
          id,
          mirrorTo,
          visibleDependencies,
          defaultToFalse,
          groupBy,
        }: any) => {
          const parentField = groupBy
            ? copyData[tabId][groupBy]
            : copyData[tabId];
          if (type === DATE) {
            // dateFields.push(id);
            if (parentField?.[id]) {
              const day = dayjs(parentField[id]);
              parentField[id] = setDataForUI
                ? day.toDate()
                : day.format("YYYY-MM-DD");
            }
          } else if (type === AUTOCOMPLETE_WITH_SINGLE_SELECTION) {
            if (parentField?.[id]) {
              parentField[id] = parentField[id]
                .map((data: any) =>
                  setDataForUI ? { value: data, label: data } : data.value,
                )
                .filter(Boolean);
            }
          } else if (type === CONTRIBUTORS) {
            if (parentField && !setDataForUI) {
              parentField[id] = cleanContributors(parentField[id]);

              if (parentField?.[id]?.length === 0) {
                delete copyData[id];
              }
            }
          }

          if (mirrorTo) {
            const mirrorToField = formFields.find(
              (field: any) => field.id === mirrorTo,
            );
            if (setDataForUI) {
              // for group checkbox, if value is null, then should not select any radio
              if (parentField?.[mirrorTo] === null) {
                parentField[id] = null;
              } else {
                parentField[id] = Boolean(
                  parentField[mirrorTo] instanceof Array
                    ? parentField[mirrorTo].length
                    : parentField[mirrorTo],
                );
              }
            } else {
              if (!parentField?.[id]) {
                if (mirrorToField.type === GROUP_CHECKBOXES) {
                  parentField[mirrorToField.id] = [];
                } else {
                  parentField[mirrorTo] = null;
                }
              }
            }
          }

          if (defaultToFalse && type === RADIO && parentField[id] === null) {
            parentField[id] = false;
          }

          if (
            visibleDependencies &&
            !setDataForUI &&
            !shouldShowField(formData[tabId], visibleDependencies) &&
            type !== GROUP_CHECKBOXES
          ) {
            parentField[id] = null;
          }
        },
      );
    });
  });

  return copyData;
};

export const initSelectOptions = (
  metaData: DropdownMetadata,
  groupMenuSetting: any[],
): void => {
  groupMenuSetting.forEach(({ formConfig }) => {
    formConfig.forEach(({ formFields }: any) => {
      formFields.forEach((formField: any) => {
        const metaDataKey = (formField.optionsMapTo ||
          formField.id) as DropdownMetadataKey;

        if (formField.type === SELECT && metaData[metaDataKey]) {
          formField.options = metaData[metaDataKey].map((data) => ({
            value: data,
            label: data,
          }));
        } else if (
          formField.type === AUTOCOMPLETE_WITH_SINGLE_SELECTION &&
          metaData[metaDataKey]
        ) {
          formField.options = metaData[metaDataKey].map((data) => ({
            value: data,
            label: data,
          }));
        }
      });
    });
  });
};

export const requiredFieldsCompleted = (fields: any[], formData: any) => {
  for (const filed of fields) {
    const value = formData?.[filed.id];

    if (filed.fields) {
      const completed = requiredFieldsCompleted(filed.fields, formData);

      if (!completed) {
        return false;
      }
    }

    if (
      filed.required &&
      (value === undefined ||
        value === "" ||
        value === null ||
        (value instanceof Array && !value.length))
    ) {
      return false;
    }
  }

  return true;
};

export const areFieldsCompleted = (fields: any[], formData: any) => {
  for (const filed of fields) {
    const value = formData?.[filed.id];

    if (filed.fields) {
      const completed = areFieldsCompleted(filed.fields, formData);

      if (!completed) {
        return false;
      }
    }

    if (
      !filed.excludedFromCompletion &&
      (value === undefined ||
        value === "" ||
        value === null ||
        (value instanceof Array && !value.length))
    ) {
      return false;
    }
  }

  return true;
};

export const fieldsContainError = (formFields: any[]) => {
  let hasError = false;

  for (const field of formFields) {
    if (field.error) {
      hasError = true;
      break;
    }
  }

  return hasError;
};

export const filterEmptyFields = (fields: any[]) =>
  fields.filter(({ type }) => type !== EMPTY);

export const areFieldsInComplete = (formFields: any[], formData: any) => {
  for (const field of filterEmptyFields(formFields)) {
    const parentField = field.groupBy ? formData?.[field.groupBy] : formData;

    const value = parentField?.[field.id];

    if (value !== undefined && value !== null) {
      return false;
    }
  }

  return true;
};

export const getStepsItems = (
  formData: any,
  groupMenuSetting: any[],
  currentTab: string,
  riskScore?: RiskScore,
) => {
  const stepItems: any = [];

  for (const { formConfig: newFormConfig, id, label } of groupMenuSetting) {
    let status;
    let newLabel = label;

    if (formData[id]?.submitted && riskScore?.[id as RiskScoreId]?.rating) {
      status = riskScore?.[id as RiskScoreId]?.rating;
      newLabel = (
        <>
          {newLabel} <br />
          <Typography variant="subtitle1" color="secondary" fontSize={13}>
            {riskScore?.[id as RiskScoreId]?.rating} Risk
          </Typography>
        </>
      );
    } else {
      const allFields =
        newFormConfig?.reduce(
          (acc: any[], current: any) => acc.concat(current.formFields),
          [],
        ) || [];

      if (fieldsContainError(allFields)) {
        status = StepItemStatus.error;
      } else if (areFieldsInComplete(allFields, formData[id])) {
        status = StepItemStatus.InComplete;
      } else if (requiredFieldsCompleted(allFields, formData[id])) {
        status = StepItemStatus.Complete;
      } else {
        status = StepItemStatus.PartialInComplete;
      }
    }

    stepItems.push({
      id,
      label: newLabel,
      status,
      isActive: currentTab === id,
    });
  }

  return stepItems;
};

export const getStepsItemsNoRisk = (
  formData: any,
  groupMenuSetting: any[],
  currentTab = "",
) => {
  const stepItems: StepItem[] = [];

  for (const { formConfig: newFormConfig, id, label } of groupMenuSetting) {
    let status;
    const allFields =
      newFormConfig?.reduce(
        (acc: any[], current: any) => acc.concat(current.formFields),
        [],
      ) || [];

    if (fieldsContainError(allFields)) {
      status = StepItemStatus.error;
    } else if (areFieldsInComplete(allFields, formData[id])) {
      status = StepItemStatus.InComplete;
    } else if (areFieldsCompleted(allFields, formData[id])) {
      status = StepItemStatus.Complete;
    } else {
      status = StepItemStatus.PartialInComplete;
    }
    stepItems.push({
      id,
      label,
      status,
      isActive: currentTab === id,
    });
  }

  return stepItems;
};

export const getLatestDate = (formData: any) =>
  Object.values(formData)
    .filter((item: any) => Boolean(item.lastUpdated))
    .map((item: any) => item.lastUpdated)
    .sort((date1, date2) => +dayjs(date1) - +dayjs(date2))
    .pop();

export const DEFAULT_NPNF_VERSION = "v3";
