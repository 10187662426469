/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikValues } from "formik";
import { FC } from "react";

import {
  StyledCheckboxContainer,
  StyledSectionTitle,
} from "../../../../components";

export interface TechnicalProductionPlanProps {
  values: FormikValues;
  blurHandler: (id: any, newValue: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
}

export const TechnicalProductionPlanForm: FC<TechnicalProductionPlanProps> = ({
  values,
  blurHandler,
  setFieldValue,
}) => (
  <>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <StyledSectionTitle>
          <Typography variant="h5">Deliverables</Typography>
        </StyledSectionTitle>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={10}>
            {/* Delivery format */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Delivery format
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.deliveryFormat}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.deliveryFormat",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.deliveryFormat",
                  event.target.value,
                );
              }}
            />

            {/* Broadcast Clearances */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Broadcast Clearances
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.broadcastClearances}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.broadcastClearances",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.broadcastClearances",
                  event.target.value,
                );
              }}
            />

            {/* Versions required */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Versions required
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.versions}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.versions",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.versions",
                  event.target.value,
                );
              }}
            />

            {/* International Versions Required? */}
            <StyledCheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="technicalProductionPlan.internationalVersionsRequired"
                    checked={
                      values.technicalProductionPlan
                        .internationalVersionsRequired
                    }
                    name="technicalProductionPlan.internationalVersionsRequired"
                    value={
                      values.technicalProductionPlan
                        .internationalVersionsRequired
                    }
                    onChange={(event) => {
                      setFieldValue(
                        "technicalProductionPlan.internationalVersionsRequired",
                        event.target.checked,
                      );
                      blurHandler(
                        "technicalProductionPlan.internationalVersionsRequired",
                        event.target.checked,
                      );
                    }}
                  />
                }
                label="Are international versions required?"
              />
            </StyledCheckboxContainer>

            {/* Production Bible Required? */}
            <StyledCheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="technicalProductionPlan.productionBibleRequired"
                    checked={
                      values.technicalProductionPlan.productionBibleRequired
                    }
                    name="technicalProductionPlan.productionBibleRequired"
                    value={
                      values.technicalProductionPlan.productionBibleRequired
                    }
                    onChange={(event) => {
                      setFieldValue(
                        "technicalProductionPlan.productionBibleRequired",
                        event.target.checked,
                      );
                      blurHandler(
                        "technicalProductionPlan.productionBibleRequired",
                        event.target.checked,
                      );
                    }}
                  />
                }
                label="Is a production bible required?"
              />
            </StyledCheckboxContainer>

            {/* Press and publicity  / press campaign */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Press and publicity / press campaign
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.pressAndPublicity}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.pressAndPublicity",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.pressAndPublicity",
                  event.target.value,
                );
              }}
            />

            {/* Interactive Involvement */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Involvement with Interactive (who is contact?)
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.interactiveInvolvement}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.interactiveInvolvement",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.interactiveInvolvement",
                  event.target.value,
                );
              }}
            />

            {/* Prizes and PRTS info */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Prizes and PRTS info
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.prizesAndPrtsInfo}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.prizesAndPrtsInfo",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.prizesAndPrtsInfo",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={10}>
            <StyledSectionTitle>
              <Typography variant="h5">Media</Typography>
            </StyledSectionTitle>

            {/* Technology Workflow */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Technology Workflow
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.technologyWorkflow}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.technologyWorkflow",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.technologyWorkflow",
                  event.target.value,
                );
              }}
            />

            {/* Media Retention */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Media retention & digital production library
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.mediaRetention}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.mediaRetention",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.mediaRetention",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={10}>
            {/* Digital */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Digital
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.digital}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.digital",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.digital",
                  event.target.value,
                );
              }}
            />

            {/* Compliance */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Compliance
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.compliance}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.compliance",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.compliance",
                  event.target.value,
                );
              }}
            />

            {/* Music */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Music
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.music}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.music",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.music",
                  event.target.value,
                );
              }}
            />

            {/* Procurement */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Procurement
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.procurement}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.procurement",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.procurement",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={10}>
            {/* Additional Information */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              <div>Additional Information:</div>
            </FormLabel>
            <TextField
              value={values.technicalProductionPlan.additionalNotes}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "technicalProductionPlan.additionalNotes",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "technicalProductionPlan.additionalNotes",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>
);
