export const setRecentlyActiveProductions = (
  programmeCcid?: string,
  seriesCcid?: string,
  titleCcid?: string,
): null => {
  const recentlyActiveProductionList = JSON.parse(
    localStorage.getItem("recentlyActiveProductions") || "[]",
  );

  const pathname = (): string => {
    if (programmeCcid && seriesCcid) {
      if (seriesCcid === "0")
        return `/programmes/${programmeCcid}/series/${seriesCcid}/titles/${titleCcid}`;
      return `/programmes/${programmeCcid}/series/${seriesCcid}`;
    }
    return `/special/${titleCcid}`;
  };

  const recentlyActiveShow = {
    programmeCcid,
    seriesCcid,
    titleCcid,
    dateActive: new Date().toString(),
    pathname: pathname(),
  };

  if (seriesCcid || titleCcid) {
    Object.keys(recentlyActiveProductionList).forEach(
      (recentlyActive: string, key: number) => {
        if (
          (recentlyActiveProductionList[recentlyActive]?.programmeCcid ===
            programmeCcid &&
            recentlyActiveProductionList[recentlyActive]?.titleCcid ===
              titleCcid) ||
          (recentlyActiveProductionList[recentlyActive]?.seriesCcid !== "0" &&
            recentlyActiveProductionList[recentlyActive]?.seriesCcid ===
              seriesCcid)
        ) {
          recentlyActiveProductionList.splice(key, 1);
        }
      },
    );
    recentlyActiveProductionList.push(recentlyActiveShow);

    localStorage.setItem(
      "recentlyActiveProductions",
      JSON.stringify(recentlyActiveProductionList),
    );

    if (recentlyActiveProductionList.length > 3) {
      recentlyActiveProductionList.shift();
      localStorage.setItem(
        "recentlyActiveProductions",
        JSON.stringify(recentlyActiveProductionList),
      );
    }
  }

  return null;
};
