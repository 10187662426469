import { FC, useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
  LoadingButton,
} from "@mui/lab";
import { IconButton, Stack, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { SynopsisValues } from "./BillingsNew";
import { FormikErrors } from "formik";

export interface SideNavProps {
  setBillingsStep: (step: number) => void;
  saveBillings: (values: SynopsisValues) => void;
  savingContributors?: boolean;
  savingSynopsis?: boolean;
  synopsisValues: SynopsisValues;
  validateSynopsisForm: () => Promise<FormikErrors<SynopsisValues>>;
  handleSubmitAttempt: () => void;
}

export const BillingsSideNav: FC<SideNavProps> = ({
  setBillingsStep,
  saveBillings,
  savingSynopsis,
  savingContributors,
  synopsisValues,
  validateSynopsisForm,
  handleSubmitAttempt,
}) => {
  const [step, setStep] = useState<number>(0);

  const handleNextButtonClick = async () => {
    handleSubmitAttempt();

    const validationErrors = await validateSynopsisForm();

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setStep(step + 1);
    setBillingsStep(step + 1);
    saveBillings(synopsisValues);
  };

  return (
    <>
      <Stack spacing={1} direction="column">
        <Timeline
          sx={{
            pt: 4,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              {step === 0 ? (
                <TimelineDot variant="outlined" color="primary" />
              ) : (
                <TimelineDot variant="outlined" />
              )}
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>Synopsis</TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              {step === 1 ? (
                <TimelineDot variant="outlined" color="primary" />
              ) : (
                <TimelineDot variant="outlined" />
              )}
            </TimelineSeparator>
            <TimelineContent>Contributors</TimelineContent>
          </TimelineItem>
        </Timeline>

        <Stack spacing={2} direction="column">
          <Stack spacing={1} direction="row">
            <IconButton
              disabled={step === 0}
              color="primary"
              onClick={() => {
                setStep(step - 1);
                setBillingsStep(step - 1);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Button
              type="button"
              fullWidth
              color="primary"
              variant="outlined"
              onClick={handleNextButtonClick}
              disabled={step === 1}
            >
              Next Section <ArrowForwardIcon />
            </Button>
          </Stack>
          <LoadingButton
            type="submit"
            fullWidth
            loading={savingContributors || savingSynopsis}
            color="primary"
            variant="contained"
          >
            Save & Submit Form
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};
