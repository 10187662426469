import React from "react";
import {
  Navigate,
  Params,
  RelativeRoutingType,
  useParams,
} from "react-router-dom";

const updateTo = (to: string, params: Readonly<Params<string>>) => {
  const entries = Object.entries(params);
  let path = `${to}`;

  entries.forEach(([key, value]) => {
    path = path.replace(`:${key}`, `${value}`);
  });

  return path;
};

export interface RedirectProps {
  to: string;
  state?: unknown;
  relative?: RelativeRoutingType;
}

/**
 * Wraps the <Navigate> component and replaces "/:<paramName>" with "/<paramValue"
 */
export const Redirect: React.FC<RedirectProps> = ({ to, ...rest }) => {
  const params = useParams();
  return <Navigate to={updateTo(to, params)} {...rest} replace />;
};
