/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useParams } from "react-router-dom";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Card,
  CardContent,
  Stack,
  Checkbox,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Select,
  Typography,
  Autocomplete as AutocompleteWithSingleSelection,
  Box,
  Switch,
  Autocomplete,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Help, InfoOutlined } from "@mui/icons-material";
import { JsxElement } from "typescript";
import { DatePicker } from "@mui/x-date-pickers";
import { TableRadios } from "./components/TableRadios";
import { GroupCheckboxes, StyledCheckboxContainer } from "../GroupCheckboxes";
import {
  StyledSectionTitle,
  StyledSectionDescription,
  StyledFormFieldContainer,
  StyledFormFiledWrapper,
} from "./DynamicForm.styles";
import {
  StyledTextAreaContainer,
  StyledInputContainer,
} from "../../global.styles";

import { uuidv4 } from "../../utils/appHelper";
// to do need to move to dynamic form folder
import { shouldShowField } from "./DynamicForm.helper";
import { Contributors } from "./components/Contributors";
import { DeliveryDate } from "./components/DiliveryDate/DeliveryDate";
import theme from "../../utils/theme";
import { useSeries, useSeriesMetadata } from "../../hooks";
import americanLabels from "./americanLabels";

export const TEXT = "TEXT";
export const DATE = "DATE";
export const RADIO = "RADIO";
export const SELECT = "SELECT";
export const TEXTAREA = "TEXTAREA";
export const EMPTY = "EMPTY";
export const TABLE_RADIO = "TABLE_RADIO";
export const GROUP_CHECKBOXES = "GROUP_CHECKBOXES";
export const CHECKBOX = "CHECKBOX";
export const CONTRIBUTORS = "CONTRIBUTORS";
export const DELIVERY_DATE = "DELIVERY_DATE";
export const MULTI_DELIVERY_DATE = "MULTI_DELIVERY_DATE";
export const AUTOCOMPLETE_WITH_SINGLE_SELECTION =
  "AUTOCOMPLETE_WITH_SINGLE_SELECTION";

export const EMPTY_FORM_FIELD = {
  type: EMPTY,
  label: "",
  id: "",
  value: "",
  columnCount: 6,
};

export const getEmptyField = (columnCount = 6) => ({
  ...EMPTY_FORM_FIELD,
  id: uuidv4(),
  columnCount,
});

export interface FormConfig {
  sectionTitle: any;
  key: string;
  description: string;
  visibleDependencies?: Record<string, any>;
  formFields: FormField[];
}

export interface Option {
  label: string;
  value: any;
}

export interface FormField {
  type: string;
  label?: string;
  groupBy?: string;
  fields?: {
    id: string;
    label: string;
    required: boolean;
  }[];
  id: string;
  options?: Option[];
  value?: any;
  columnCount: number;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  inputType?: string;
  error?: boolean;
  icon?: React.ReactNode;
  visibleDependencies?: Record<string, any>;
  startFrom?: number;
  vertical?: boolean;
  questionBubble?: string;
  subTitle?: React.ReactNode;
  endAdornment?: string;
  isRow?: boolean;
  isMultiple?: boolean;
  variant?: "outlined" | "filled" | "standard";
  helperText?: string;
  notes?: JsxElement;
  children?: React.ReactNode;
}

export interface DynamicFromProps {
  config: FormConfig[];
  onValueChange(id: string, newValue: any): void;
  formData: Record<string, any>;
  onBlur?(id: string, newValue: any): void;
  parent?: string;
  inModal?: boolean;
}

const QuestionBubbleTooltip: FC<{ questionBubble?: string }> = ({
  questionBubble,
}) => {
  if (!questionBubble) {
    return <></>;
  }

  return (
    <Tooltip
      title={
        <span style={{ fontSize: "1rem", lineHeight: "1.5rem" }}>
          {questionBubble}
        </span>
      }
      placement="top"
    >
      <IconButton>
        <Help color="primary" />
      </IconButton>
    </Tooltip>
  );
};

export const ShowDisgraceQuestion: () => boolean = () => {
  const { seriesCcid } = useParams<string>();
  const { data: seriesMetaData } = useSeriesMetadata(seriesCcid);
  return (
    seriesMetaData?.productionLabels.some((label) =>
      americanLabels.includes(label),
    ) || false
  );
};

export const FormFiledWrapper: FC<{
  formField: FormField;
  onChange(id: string, value: any): void;
  value: any;
  formData: Record<string, any>;
  onBlur?(id: string, value: any): void;
}> = ({
  formField: {
    type,
    label = "",
    id = "",
    options,
    columnCount,
    required,
    disabled,
    placeholder,
    inputType = "text",
    error,
    icon,
    fields,
    visibleDependencies,
    startFrom = 1,
    vertical = false,
    questionBubble,
    subTitle,
    endAdornment,
    isRow = false,
    isMultiple = false,
    variant,
    helperText,
    notes,
  },
  onChange,
  onBlur,
  value,
  formData,
}) => {
  let content;
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const internalValueChanged = useRef<boolean>(false);
  const [internalValue, setInternalValue] = useState<any>();
  const [additionalSeriesInternalValue, setAdditionalSeriesInternaltValue] =
    useState<any>([]);
  const { programmeCcid } = useParams<string>();
  const { data: seriesData } = useSeries(programmeCcid, false);
  const optionsList =
    seriesData?.seriesDetails.map((series) => ({
      label: `Series ${series.seriesNumber} - ${series.name}`,
      value: series.ccid,
    })) ?? [];

  useEffect(() => {
    if (!internalValueChanged.current) {
      setInternalValue(value);
    }
  }, [id, value]);

  const handleChange = useCallback(
    (newValue: any) => {
      internalValueChanged.current = true;

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        onChange(id, newValue);
      }, 300);
    },
    [id, onChange],
  );

  const handleBlur = useCallback(
    (newValue: any) => {
      if (onBlur) {
        onBlur(id, newValue);
      }
    },
    [id, onBlur],
  );

  if (visibleDependencies && !shouldShowField(formData, visibleDependencies)) {
    return <></>;
  }

  if (id === "disgraceCover") {
    if (!ShowDisgraceQuestion()) return <></>;
  }

  if (type === TEXT) {
    content = (
      <>
        <FormLabel
          error={error}
          required={required}
          sx={{ display: "block", marginBottom: "0.5rem" }}
        >
          {label}
        </FormLabel>
        <TextField
          value={internalValue}
          fullWidth
          size="small"
          label=""
          id={id}
          variant={variant || "outlined"}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          type={inputType}
          onBlur={(event) => {
            handleBlur(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography color="disabled">{endAdornment}</Typography>
              </InputAdornment>
            ),
          }}
          error={error}
          helperText={helperText}
          onChange={(event) => {
            setInternalValue(event.target.value);
            handleChange(event.target.value);
          }}
        />
      </>
    );
  } else if (type === CONTRIBUTORS) {
    const contributors = internalValue || [];

    content = (
      <Contributors
        contributors={contributors}
        disabled={Boolean(disabled)}
        onChange={(newContributors) => {
          setInternalValue(newContributors);
          handleChange(newContributors);
          handleBlur(newContributors);
        }}
      />
    );
  } else if (type === DELIVERY_DATE) {
    content = (
      <DeliveryDate
        value={internalValue}
        onChange={(newValue) => {
          setInternalValue(newValue);
          handleChange(newValue);
          handleBlur(newValue);
        }}
      />
    );
  } else if (type === MULTI_DELIVERY_DATE) {
    content = (
      <div>
        <FormControlLabel
          control={
            <Switch
              onChange={() => {
                setInternalValue(!internalValue);
                handleChange(!internalValue);
                handleBlur(!internalValue);
              }}
              checked={internalValue}
              inputProps={{ "aria-label": `${label} Switch` }}
            />
          }
          label={label}
          value={value}
        />
      </div>
    );
  } else if (type === TEXTAREA) {
    content = (
      <StyledTextAreaContainer hasError={error}>
        <FormLabel
          error={error}
          required={required}
          sx={{ display: "block", marginBottom: "0.5rem" }}
        >
          {label}
          {subTitle && <Typography variant="body2">{subTitle}</Typography>}
          {/* <QuestionBubbleTooltip questionBubble={questionBubble} /> */}
        </FormLabel>
        <TextField
          value={internalValue || ""}
          id={id}
          multiline
          fullWidth
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          error={error}
          maxRows={8}
          minRows={8}
          data-testid={id}
          onChange={(event) => {
            setInternalValue(event.target.value);
            handleChange(event.target.value);
          }}
          onBlur={(event) => {
            handleBlur(event.target.value);
          }}
        />
      </StyledTextAreaContainer>
    );
  } else if (id === "additionalSeries") {
    content = (
      <>
        <FormLabel
          id={`dynamic-form-${label}`}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <>
            <Typography display="inline">
              {required && (
                <Typography color={theme.palette.sunsetOrange} display="inline">
                  {" "}
                  *
                </Typography>
              )}
            </Typography>

            <QuestionBubbleTooltip questionBubble={questionBubble} />
          </>
        </FormLabel>
        {error && (
          <InfoOutlined
            color="error"
            fontSize="small"
            sx={{ marginLeft: "0.5rem", top: "0.25rem", position: "relative" }}
          />
        )}
        {notes}
        <Autocomplete
          multiple
          id="additionalSeries"
          value={additionalSeriesInternalValue as any}
          options={optionsList}
          filterSelectedOptions
          isOptionEqualToValue={(option, selectValue) =>
            option.value === selectValue.value
          }
          onChange={(event, newValue) => {
            const selectedValues = newValue.map((option) => option.value);

            setAdditionalSeriesInternaltValue(newValue);
            handleChange(selectedValues);
            handleBlur(selectedValues);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Series Or Title" />
          )}
        />
      </>
    );
  } else if (type === RADIO) {
    content = (
      <>
        <Box display="flex" alignItems="center">
          <FormLabel
            id={`dynamic-form-${label}`}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <>
              <Typography display="inline">
                {label}
                {required && (
                  <Typography
                    color={theme.palette.sunsetOrange}
                    display="inline"
                  >
                    {" "}
                    *
                  </Typography>
                )}
              </Typography>

              <QuestionBubbleTooltip questionBubble={questionBubble} />
            </>
          </FormLabel>
          {error && (
            <InfoOutlined
              color="error"
              fontSize="small"
              sx={{
                marginLeft: "0.5rem",
                top: "0.25rem",
                position: "relative",
              }}
            />
          )}
        </Box>
        {notes}
        <RadioGroup
          aria-labelledby={`dynamic-form-${label}`}
          name={label}
          row={isRow}
        >
          {options?.map(({ label: labelText, value: dataValue }) => (
            <FormControlLabel
              id={dataValue}
              key={dataValue}
              control={<Radio />}
              label={labelText}
              checked={internalValue === dataValue}
              disabled={disabled}
              onChange={() => {
                setInternalValue(dataValue);
                handleChange(dataValue);
                handleBlur(dataValue);
              }}
            />
          ))}
        </RadioGroup>
      </>
    );
  } else if (type === CHECKBOX) {
    content = (
      <StyledCheckboxContainer>
        <FormControlLabel
          control={
            <Checkbox
              id={id}
              checked={internalValue === true}
              name={id}
              value={internalValue}
              onChange={(event: any) => {
                setInternalValue(event.target.checked);
                handleChange(event.target.checked);
                handleBlur(event.target.checked);
              }}
            />
          }
          label={
            <>
              {label} <QuestionBubbleTooltip questionBubble={questionBubble} />
            </>
          }
        />
      </StyledCheckboxContainer>
    );
  } else if (type === TABLE_RADIO) {
    content = (
      <TableRadios
        fields={fields}
        formData={formData}
        options={options}
        onChange={(fieldId, newValue) => {
          if (onChange) {
            onChange(fieldId, newValue);
          }

          if (onBlur) {
            onBlur(fieldId, newValue);
          }
        }}
        startFrom={startFrom}
        id={id}
      />
    );
  } else if (type === GROUP_CHECKBOXES) {
    content = (
      <GroupCheckboxes
        label={label}
        value={internalValue}
        error={error}
        options={options}
        required={required}
        vertical={vertical}
        onChange={(newValue) => {
          setInternalValue(newValue);
          handleChange(newValue);
          handleBlur(newValue);
        }}
      />
    );
  } else if (type === SELECT) {
    const inputLabelStr = `dropdownLabel-${label}`;
    const selectValue = internalValue ?? "";

    content = (
      <StyledInputContainer hasError={error}>
        <FormLabel
          error={error}
          required={required}
          sx={{ display: "block", marginBottom: "0.5rem" }}
        >
          {label}
        </FormLabel>
        <Select
          labelId={inputLabelStr}
          id={id}
          error={error}
          sx={{ width: "100%" }}
          onChange={(event) => {
            setInternalValue(event.target.value);
            handleChange(event.target.value);
            handleBlur(event.target.value);
          }}
          size="small"
          disabled={disabled}
          required={required}
          value={selectValue}
        >
          {options?.map((optionValue) => (
            <MenuItem key={optionValue.label} value={optionValue.value}>
              {optionValue.label}
            </MenuItem>
          ))}
        </Select>
      </StyledInputContainer>
    );
  } else if (type === AUTOCOMPLETE_WITH_SINGLE_SELECTION) {
    content = (
      <>
        <FormLabel
          error={error}
          required={required}
          sx={{ display: "block", marginBottom: "0.5rem" }}
        >
          {label}
        </FormLabel>
        <AutocompleteWithSingleSelection
          id={id}
          sx={{ width: "100%" }}
          onChange={(_, option) => {
            if (option) {
              setInternalValue(option);
              handleChange(option);
              handleBlur(option);
            }
          }}
          size="small"
          multiple={isMultiple}
          disabled={disabled}
          options={(options as Option[]) || []}
          value={internalValue || []}
          renderInput={(params) => <TextField error={error} {...params} />}
        />
      </>
    );
  } else if (type === DATE) {
    content = (
      <>
        <FormLabel error={error} required={required} sx={{ mb: 1 }}>
          {label}
        </FormLabel>
        <br />
        <DatePicker
          value={dayjs(internalValue || null)}
          disabled={disabled}
          onChange={(newDate) => {
            if (dayjs(newDate).isValid() || newDate === null) {
              const newValue = newDate ?? "";
              setInternalValue(newValue);
              handleChange(newValue);
              handleBlur(newValue);
            }
          }}
        />
      </>
    );
  } else {
    content = <div />;
  }

  return (
    <StyledFormFiledWrapper columnCount={columnCount}>
      {error && <div className="error" />}
      {content}
    </StyledFormFiledWrapper>
  );
};

export const DynamicForm: FC<DynamicFromProps> = ({
  config,
  onValueChange,
  formData,
  onBlur,
  parent = "",
  inModal = false,
}) => {
  const SectionContainer: FC<any> = inModal ? Box : Card;
  const ContentHolder: FC<any> = inModal ? Box : CardContent;
  return (
    <Stack spacing={inModal ? 0 : 3}>
      {config.map(
        (
          { sectionTitle, description, formFields, key, visibleDependencies },
          index,
        ) =>
          visibleDependencies &&
          !shouldShowField(formData, visibleDependencies) ? (
            <></>
          ) : (
            <SectionContainer
              key={`${sectionTitle}-${description}-${key}-${index}`}
              sx={{ overflow: "visible" }}
            >
              <ContentHolder sx={{ padding: inModal ? "0rem" : "1rem" }}>
                <StyledSectionTitle>{sectionTitle}</StyledSectionTitle>
                <StyledSectionDescription>
                  {description}
                </StyledSectionDescription>
                <StyledFormFieldContainer>
                  {formFields.map((formField) => {
                    const value = formField.groupBy
                      ? formData?.[formField.groupBy]?.[formField.id]
                      : formData?.[formField.id];
                    return (
                      <FormFiledWrapper
                        key={`${parent}${formField.id}`}
                        formField={formField}
                        onChange={onValueChange}
                        onBlur={onBlur}
                        value={value}
                        formData={formData}
                      />
                    );
                  })}
                </StyledFormFieldContainer>
              </ContentHolder>
            </SectionContainer>
          ),
      )}
    </Stack>
  );
};
