/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from "styled-components";
import theme from "../../../../../utils/theme";

const lightTropical = "#EEF4F4";

export const StyledIconWrapper = styled("div")<{ completed?: boolean }>`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledItem = styled("div")<{ completed?: boolean }>`
  display: flex;
  width: 153px;
  height: 186px;
  flex-direction: column;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 0.75rem;

  ${StyledIconWrapper} {
    background: ${({ completed }: any) =>
      completed ? theme.palette.secondary.main : lightTropical};
  }

  &:hover {
    background: ${lightTropical};
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      ${lightTropical};

    box-shadow: none;
  }

  &:hover,
  &:active {
    ${StyledIconWrapper} {
      background: ${({ completed }: any) =>
        completed ? theme.palette.secondary.main : theme.palette.white};
    }
  }

  & > div {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
    line-height: 1.5rem;
    padding: 0 1rem;
  }

  & > div:first-child {
    align-items: flex-end;
  }
`;

export const StyledSubTitle = styled("div")`
  font-size: 12px;
  color: rgba(85, 86, 94, 0.8);
  line-height: 166%;
  font-weight: 400;
`;
