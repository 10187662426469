import { FC } from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { Skeleton } from "@mui/material";
import { DownloadImageResponse, getThumbnail } from "../../../../../api/images";

import SVGThumbnail from "../Icons/icon-svg.png";
import AIThumbnail from "../Icons/icon-ai.png";
import EPSThumbnail from "../Icons/icon-eps.png";
import PSDThumbnail from "../Icons/icon-psd.png";
import PSBThumbnail from "../Icons/icon-psb.png";
import PSThumbnail from "../Icons/icon-ps.png";
import PNGThumbnail from "../Icons/icon-png.png";
import JPEGThumbnail from "../Icons/icon-jpeg.png";
import ZIPThumbnail from "../Icons/icon-zip.png";
import DOCXThumbnail from "../Icons/icon-docx.jpg";
import DOCThumbnail from "../Icons/icon-doc.jpg";
import OTFThumbnail from "../Icons/icon-otf.jpg";
import TTFThumbnail from "../Icons/icon-ttf.jpg";
import TIFFThumbnail from "../Icons/icon-tiff.png";
import PPTXThumbnail from "../Icons/icon-pptx.svg";
import PPTThumbnail from "../Icons/icon-ppt.svg";

interface ThumbnailSize {
  width: string;
  height: string;
}
interface ThumbnailProps {
  fileUploadId: string;
  fileId?: string;
  filename: string;
  contentType?: string;
  size: ThumbnailSize;
  loadingImg?: (imgId?: string) => void;
  isUploaded?: boolean;
  maxRetries?: number;
  style?: React.CSSProperties | undefined;
}

const Thumbnail: FC<ThumbnailProps> = ({
  fileUploadId,
  filename,
  contentType,
  size,
  loadingImg,
  isUploaded,
  maxRetries,
  style,
}) => {
  const {
    isLoading,
    data: thumbnailUri,
    error: errorGettingThumbnail,
  } = useQuery<DownloadImageResponse, AxiosError>(
    ["thumbnailUri", fileUploadId],
    () => getThumbnail(fileUploadId, maxRetries),
  );

  const contentTypeConditions = [
    contentType === "image/svg+xml" && !isUploaded,
    contentType === "image/vnd.adobe.photoshop" && !isUploaded,
    contentType === "application/postscript" && !isUploaded,
    contentType === "image/jpeg",
    contentType === "image/png",
    contentType === "image/tiff",
    contentType === "font/otf",
    contentType === "font/ttf",
    contentType === "font/fnt",
    contentType === "script/doc",
    contentType === "script/docx",
  ];

  if (
    isLoading &&
    !errorGettingThumbnail &&
    contentTypeConditions.some((type) => type)
  ) {
    return (
      <Skeleton variant="rectangular" width={size.width} height={size.height} />
    );
  }

  const getThumb = (thumbSrc: string) => (
    <img
      // isEmbargoDateSelected={date !== undefined}
      src={thumbSrc}
      alt={filename}
      width={size.width}
      height={size.height}
      onLoad={() => loadingImg && loadingImg()}
      // isFullSizeImage={isFullSizeImage}
      style={style}
    />
  );
  switch (true) {
    case filename.includes(".docx"):
      return getThumb(DOCXThumbnail);
    case filename.includes(".doc"):
      return getThumb(DOCThumbnail);
    case filename.includes(".svg") && contentType === "image/svg+xml":
      return getThumb(SVGThumbnail);
    case filename.includes(".ai"):
      return getThumb(AIThumbnail);
    case filename.includes(".ttf"):
      return getThumb(TTFThumbnail);
    case filename.includes(".otf"):
      return getThumb(OTFThumbnail);
    case filename.includes(".fnt"):
      return getThumb(ZIPThumbnail);
    case filename.includes(".eps") && contentType === "application/postscript":
      return getThumb(EPSThumbnail);
    case filename.includes(".psd") &&
      contentType === "image/vnd.adobe.photoshop":
      return errorGettingThumbnail
        ? getThumb(PSDThumbnail)
        : getThumb(thumbnailUri?.downloadUri || PSDThumbnail);
    case filename.includes(".psb") &&
      contentType === "image/vnd.adobe.photoshop":
      return errorGettingThumbnail
        ? getThumb(PSBThumbnail)
        : getThumb(thumbnailUri?.downloadUri || PSBThumbnail);
    case filename.includes(".ps") && contentType === "application/postscript":
      return getThumb(PSThumbnail);
    case contentType === "image/tiff":
      return errorGettingThumbnail
        ? getThumb(TIFFThumbnail)
        : getThumb(thumbnailUri?.downloadUri || "");
    case contentType === "image/jpeg":
      return errorGettingThumbnail
        ? getThumb(JPEGThumbnail)
        : getThumb(thumbnailUri?.downloadUri || "");
    case contentType === "image/png":
      return errorGettingThumbnail
        ? getThumb(PNGThumbnail)
        : getThumb(thumbnailUri?.downloadUri || "");
    default:
      return getThumb(thumbnailUri?.downloadUri || "");
  }
};

export const getAltThumbnail = (filename: string): string => {
  if (filename.includes(".docx")) return DOCXThumbnail;
  if (filename.includes(".doc")) return DOCThumbnail;
  if (filename.includes(".svg")) return SVGThumbnail;
  if (filename.includes(".ai")) return AIThumbnail;
  if (filename.includes(".ttf")) return TTFThumbnail;
  if (filename.includes(".otf")) return OTFThumbnail;
  if (filename.includes(".fnt")) return ZIPThumbnail;
  if (filename.includes(".eps")) return EPSThumbnail;
  if (filename.includes(".psd")) return PSDThumbnail;
  if (filename.includes(".psb")) return PSBThumbnail;
  if (filename.includes(".ps")) return PSThumbnail;
  if (filename.includes(".jpg") || filename.includes(".jpeg"))
    return JPEGThumbnail;
  if (filename.includes(".tif") || filename.includes(".tiff"))
    return TIFFThumbnail;
  if (filename.includes(".png")) return PNGThumbnail;
  if (filename.includes(".pptx")) return PPTXThumbnail;
  if (filename.includes(".ppt")) return PPTThumbnail;
  return ZIPThumbnail;
};

export default Thumbnail;
