import { useQuery, UseQueryResult } from "react-query";
import { ProductionCardResponse } from "../../types/types";
import { getProductionCardDetails } from "../../api/portal";

export const useProductionCardDetails = (
  ccid: string | undefined,
): UseQueryResult<ProductionCardResponse> =>
  useQuery(["productionCardDetails", ccid], () =>
    getProductionCardDetails(ccid as string),
  );
