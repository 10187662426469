import * as Yup from "yup";

export const sustainabilitySchemaV3 = Yup.object().shape({
  spotId: Yup.string(),
  calculator: Yup.string().required("This is a required field"),
  calculatorDetails: Yup.string().when(
    "$sustainability.calculator",
    ([calculator], schema) => {
      if (calculator === "Other") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  airTravel: Yup.string().required("This is a required field"),
  flying: Yup.string().when(
    "$sustainability.airTravel",
    ([airTravel], schema) => {
      if (airTravel === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  gen: Yup.string().required("This is a required field"),
  generatorType: Yup.string().when("$sustainability.gen", ([gen], schema) => {
    if (gen === "Yes") {
      return schema.required("This is a required field");
    }
    return schema;
  }),
  hazard: Yup.string().required("This is a required field"),
  naturalHazard: Yup.array()
    .of(Yup.string().required())
    .when("$sustainability.hazard", ([hazard], schema) => {
      if (hazard === "Yes") {
        return schema
          .required("This is a required field")
          .min(1, "This is a required field");
      }
      return schema;
    }),
});

export type SustainabilitySectionV3 = Yup.InferType<
  typeof sustainabilitySchemaV3
>;
