import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { addUserOverride } from "../../api/accessPermissions";

export const useAddUserOverride = (): UseMutationResult<
  unknown,
  unknown,
  { userEmail: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { userEmail: string }) => addUserOverride(params.userEmail),
    {
      onSettled: () => {
        queryClient.invalidateQueries("useAddUserOverride");
      },
    },
  );
};
