import dayjs from "dayjs";
import { useMutation } from "react-query";
import { patchImageEmbargoDate } from "../../api/images";
import { LevelType } from "../../types/types";

interface UpdateImageEmbargoArgs {
  level: LevelType;
  ccid: string;
  images: Array<{
    imageId: string;
    embargoDate: dayjs.Dayjs;
  }>;
}

export function useUpdateImageEmbargoDates() {
  return useMutation(({ level, ccid, images }: UpdateImageEmbargoArgs) => {
    const requests = images.map(({ imageId, embargoDate }) => {
      return patchImageEmbargoDate(
        level,
        ccid,
        imageId,
        embargoDate.toISOString(),
      );
    });

    return Promise.all(requests);
  });
}
