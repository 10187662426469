import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { removeCollaborator } from "../../api/accessPermissions";

export const useRemoveCollaborator = (): UseMutationResult<
  unknown,
  unknown,
  { ccid: string; userEmail: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { ccid: string; userEmail: string }) =>
      removeCollaborator(params.ccid, params.userEmail),
    {
      onSettled: () => {
        queryClient.invalidateQueries("useCollaborators");
      },
    },
  );
};
