import { FC } from "react";
import { Typography, Stack } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import ArticleIcon from "@mui/icons-material/Article";
import { FormStep, NotificationFormSubmitValuesV3 } from "./NotificationFormV3";
import RiskIcon from "./RiskIcon";
import { RiskScore, RiskScoreId } from "../../types/types";
import { useFormikContext } from "formik";
import { NotificationFormSubmitValues } from "./NotificationFormV2";
import theme from "../../utils/theme";

const STEPS: Array<FormStep> = [
  { id: "basicInfo", title: "1. Production" },
  { id: "locationsAndTravel", title: "2. Locations & Travel" },
  { id: "participantWelfare", title: "3. Participant Welfare" },
  { id: "insurance", title: "4. Insurance" },
  { id: "procurement", title: "5. Procurement" },
  { id: "healthAndSafety", title: "6. Health & Safety" },
  { id: "dataPrivacy", title: "7. Data Privacy" },
  { id: "cyberSecurity", title: "8. Cyber Security" },
  { id: "sustainability", title: "9. Sustainability" },
  { id: "security", title: "10. Security" },
];

const STEPSV2: Array<FormStep> = [
  { id: "basicInfo", title: "1. Production" },
  { id: "compliance", title: "2. Compliance" },
  { id: "dutyOfCare", title: "3. Duty of Care" },
  { id: "insurance", title: "4. Insurance & Procurement" },
  { id: "healthAndSafety", title: "5. Health & Safety" },
  { id: "dataPrivacy", title: "6. Data Privacy" },
  { id: "cyberSecurity", title: "7. Cyber Security" },
  { id: "sustainability", title: "8. Sustainability" },
  { id: "security", title: "9. Security" },
];

const getRiskColour = (rating: string) => {
  if (rating === "Insignificant") return theme.palette.success.main;
  if (rating === "Medium") return theme.palette.warning.main;
  if (rating === "High") return theme.palette.error.main;
  else return "black";
};

export const NotificationFormSteps: FC<{
  onStepChange: (step: number) => void;
  version: string;
  riskScore?: RiskScore;
  flagError: boolean;
  currentStepIndex: number;
}> = ({ onStepChange, version, riskScore, flagError, currentStepIndex }) => {
  const handleStepChange = (newStep: number) => {
    onStepChange(newStep);
  };

  const STEPSTOMAP = version === "v2" ? STEPSV2 : STEPS;

  const formik = useFormikContext<
    NotificationFormSubmitValues | NotificationFormSubmitValuesV3
  >();

  return (
    <>
      <Timeline sx={{ width: "270px", justifyContent: "space-between" }}>
        {STEPSTOMAP.map((step, index) => {
          let riskRating;
          const errors = formik.errors;
          const errorFields = Object.keys(errors);
          const hasErrors = errorFields.includes(step.id);
          const riskIndex = step.id as unknown as RiskScoreId;
          const riskDataKeys = riskScore ? Object.keys(riskScore) : null;
          if (riskDataKeys?.includes(riskIndex)) {
            riskRating = riskScore ? riskScore[riskIndex] : null;
          }
          const riskRatingString = riskRating?.rating as string;
          const hasRiskNotes = riskRating?.notes;
          const riskColour = getRiskColour(riskRatingString);
          return (
            <TimelineItem key={`step-${version}-${step.id}`}>
              <TimelineOppositeContent sx={{ display: "none" }} />
              <TimelineSeparator>
                <RiskIcon
                  rating={riskRatingString}
                  completed={!hasErrors}
                  showError={flagError}
                  highlighted={currentStepIndex === index}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "	#d0d8d8",
                  },
                }}
                onClick={() => handleStepChange(index)}
              >
                <Stack direction="row">
                  <Stack>
                    <Typography
                      color={flagError && hasErrors ? "error" : "primary"}
                      component="span"
                    >
                      {step.title}
                    </Typography>
                    {riskRatingString ? (
                      <Typography sx={{ color: riskColour }} variant="caption">
                        Risk: {riskRatingString || "Not found"}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  {hasRiskNotes && <ArticleIcon />}
                </Stack>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </>
  );
};
