import { Typography } from "@mui/material";
import {
  TEXTAREA,
  DATE,
  MULTI_DELIVERY_DATE,
  DELIVERY_DATE,
} from "../../../../../components";

/* TODO:
  Deprecate this file as we no longer user it for loading the start up form component.
  It is only used in the sanitizeFormData method which utilizes the types of each field
  and based on that, it sanitizes the data before passing them to the components.
  The solution here is to either fix the backend to return the data in the right format or
  do the clean up at the component level like we did with the contributors component in ContributorsAndTalent.tsx
*/
export const businessFormConfig = [
  {
    sectionTitle: <Typography variant="h5">Budget</Typography>,
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Any Co Funding or other additional income? ",
        id: "coFundingOrAdditionalIncome",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: DATE,
        label: "Dates for Cost form Submission",
        id: "costFormSubmissionDate",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Programme Title clearance / trademark registration",
        id: "programmeTitleClearance",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Bribery Act",
        id: "bribery",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Sanctions",
        id: "sanctions",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Format",
        id: "format",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Release Forms",
        id: "releaseForms",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Contestants",
        id: "contestants",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Rights",
        id: "rights",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Broadcast Clearances",
        id: "broadcastClearances",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: (
      <Typography variant="h5">Contracted delivery dates</Typography>
    ),
    description: "",
    formFields: [
      {
        type: MULTI_DELIVERY_DATE,
        id: "datesPerEpisode",
        label: "Dates per Episode",
        excludedFromCompletion: true,
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: DATE,
        label: "",
        id: "seriesDeliveryDate",
        value: undefined,
        columnCount: 6,
        required: false,
        disabled: false,
        excludedFromCompletion: true,
        visibleDependencies: {
          datesPerEpisode: false,
        },
      },
      {
        type: DELIVERY_DATE,
        label: "",
        id: "deliveryDates",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
        excludedFromCompletion: true,
        visibleDependencies: {
          datesPerEpisode: true,
        },
      },
    ],
  },
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Additional Information:",
        id: "additionalNotes",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        excludedFromCompletion: true,
      },
    ],
  },
];
