/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { IInputProps } from "react-dropzone-uploader";
import { FileUploadOutlined } from "@mui/icons-material";
import { InputLabel, Stack, Typography } from "@mui/material";
import theme from "../../../../../utils/theme";

const CustomInput: FC<IInputProps> = ({
  accept,
  onFiles,
  files,
  getFilesFromEvent,
  extra,
}: IInputProps) => {
  const text = files?.length > 0 ? "Add more files" : "Browse local files";
  return (
    <Stack alignItems="center">
      {extra.reject ? (
        <h2>Only image files</h2>
      ) : (
        <>
          <FileUploadOutlined
            aria-label="Upload Icon"
            strokeWidth={3}
            color="disabled"
            sx={{ fontSize: 42 }}
          />
          <Typography
            variant="body2"
            color={theme.palette.osloGray}
            width={245}
            textAlign="center"
            lineHeight={2}
            marginBottom={1}
          >
            Drag &amp; drop file(s) here to get started OR
          </Typography>
          <InputLabel
            sx={{
              color: theme.palette.white,
              bgcolor: theme.palette.secondary.main,
              padding: 1,
              borderRadius: 20,
              width: 180,
              textAlign: "center",
              fontSize: 16,
              cursor: "pointer",
            }}
          >
            {text}
            <input
              style={{ display: "none" }}
              type="file"
              data-testid="img-upload-input"
              accept={accept}
              multiple
              onChange={(e) => {
                const files = getFilesFromEvent(e) as Promise<File[]> | File[];

                if ("then" in files) {
                  if (typeof files.then === "function") {
                    files.then((chosenFiles: File[]) => {
                      onFiles(chosenFiles);
                      e.target.value = ""; // this is to allow loading multiple times same file name
                    });
                  }
                } else {
                  onFiles(files);
                  e.target.value = ""; // this is to allow loading multiple times same file name
                }
              }}
            />
          </InputLabel>
        </>
      )}
    </Stack>
  );
};

export default CustomInput;
