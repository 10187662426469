import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    palette: any;
  }
}

const colours = {
  tropical: "#0D6166",
  surfieGreen: "#107B82",
  blueChill: "#138E96",
  java: "#19BEC8",
  hummingBird: "#E8FBFC",
  charade: "#22252F",
  midGray: "#595B63",
  osloGray: "#85878D",
  frenchGray: "#BDBEC1",
  iron: "#E9E9EA",
  athensGray: "#F4F4F5",
  white: "#FFFFFF",
  warmRed: "#EB1000",
  redOrange: "#FF2F21",
  sunsetOrange: "#FF4A3E",
  sunGlow: "#FFCC26",
  broom: "#FFE529",
  emerald: "#43DB58",
  screaminGreen: "#4FED6F",
  errorYellowBase: "#FFF9E7",
  mineShaft: "rgba(45, 45, 45, 0.6)",
  knockedWhite: "rgba(255, 255, 255, 0.6)",
  mintDarker: "#7E999B",
  mintDark: "#9EBFC2",
  mint: "#CFDFE0",
  mintPressed: "#E0EAEB",
  mintLight: "#F3F7F7",
  forest: "#277759",
};

const typography = {
  fontFamily: "ITV Reem, Arial",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const palette: any = {
  mode: "light",
  primary: {
    light: "rgb(78, 80, 88)",
    dark: "rgb(23, 25, 32)",
    contrastText: "#fff",
    main: "#22252f",
  },
  secondary: {
    light: "rgb(59, 119, 194)",
    dark: "rgb(7, 60, 125)",
    contrastText: "#fff",
    main: "#0b56b3",
  },
  background: {
    default: "#e8ecf0",
    paper: "#fff",
  },
  text: {
    primary: "#22252F",
    secondary: "#484952",
  },
  ...colours,
};

const baseTheme = createTheme({
  palette,
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 28,
        },
      },
    },
  },
});

export default baseTheme;
