import { useQuery, UseQueryResult } from "react-query";

import {
  getNotifications,
  NotificationQuerryResult,
} from "../../api/notifications";

export const useNotifications = (): UseQueryResult<NotificationQuerryResult> =>
  useQuery<NotificationQuerryResult>(["getNotifications"], async () => {
    const data = await getNotifications();

    return {
      notifications: data.notifications.sort((a, b) => {
        return Date.parse(b.created) - Date.parse(a.created);
      }),
    };
  });
