import React from "react";
import {
  Stack,
  TextField,
  Typography,
  FormLabel,
  Button,
  Box,
  Paper,
  Container,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useCreateMyDrive } from "../../../../../hooks";
import { CreateMyDrivePayload } from "../../../../../types/types";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";

export interface MyDriveProps {
  isMyDriveOpen: boolean;
  setMyDriveOpen: (isMyDriveOpen: boolean) => void;
}

export const MyDrive: React.FC = () => {
  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = React.useState(
    "Please provide the details of the drive you wish to create",
  );
  const [showCreateDrive, setShowCreateDrive] = React.useState(true);
  const [startEpisodeNum, setStartEpisodeNum] = React.useState<number>(0);
  const [endEpisodeNum, setEndEpisodeNum] = React.useState<number>(0);
  const [directorName, setDirectorName] = React.useState("");
  const [filmingUnit, setFilmingUnit] = React.useState(0);
  const { seriesCcid, titleCcid } = useParams<string>();
  const ccid = titleCcid || seriesCcid;
  const { mutate, isLoading } = useCreateMyDrive(
    () => {
      setShowCreateDrive(false);
      setModalTitle(
        "Your shared drive is being created. Only one block can be created at a time. You will be notified once this has completed",
      );
    },
    (error) => {
      if (error.message.includes("503")) {
        enqueueSnackbar(
          "There is a Google Drive currently being created. Please wait until this has been completed before trying to create another",
          { variant: "error", action: SnackbarDismiss },
        );
      } else {
        enqueueSnackbar("Failed to create shared drive", {
          variant: "error",
          action: SnackbarDismiss,
          persist: true,
        });
      }
    },
  );

  const isValid = (payloadObj: CreateMyDrivePayload) => {
    if (!payloadObj.filmingUnit) return false;
    if (!payloadObj.directorName) return false;
    if (payloadObj.startEpisode < 0) return false;
    if (payloadObj.endEpisode < 0) return false;
    if (payloadObj.startEpisode > payloadObj.endEpisode) return false;
    return true;
  };

  return (
    <Container sx={{ py: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography
            display="inline"
            variant="h5"
            sx={{ marginBottom: "0.5rem", px: 1 }}
          >
            {modalTitle}
          </Typography>
        </Stack>
        {showCreateDrive ? (
          <Box sx={{ p: 2 }}>
            <Stack direction="row" spacing={2} sx={{ py: 2 }}>
              <Stack>
                <FormLabel
                  required
                  sx={{ display: "block", marginBottom: "0.5rem", px: 1 }}
                >
                  Start Episode Number
                </FormLabel>
                <TextField
                  placeholder="start episode number"
                  id="input-name"
                  type="text"
                  name="name"
                  sx={{ width: "418px" }}
                  autoComplete="off"
                  value={startEpisodeNum > 0 ? startEpisodeNum : ""}
                  onChange={(e) => {
                    const input = Number(e.target.value);
                    if (!Number.isNaN(input))
                      setStartEpisodeNum(Number(e.target.value));
                  }}
                />
              </Stack>
              <Stack>
                <FormLabel
                  required
                  sx={{ display: "block", marginBottom: "0.5rem", px: 1 }}
                >
                  End Episode Number
                </FormLabel>
                <TextField
                  placeholder="end episode number"
                  id="input-name"
                  type="text"
                  sx={{ width: "418px" }}
                  name="name"
                  autoComplete="off"
                  value={endEpisodeNum > 0 ? endEpisodeNum : ""}
                  onChange={(e) => {
                    const input = Number(e.target.value);
                    if (!Number.isNaN(input))
                      setEndEpisodeNum(Number(e.target.value));
                  }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ py: 2 }}>
              <FormLabel
                required
                sx={{ display: "block", marginBottom: "0.5rem", px: 1 }}
              >
                Filming Unit
              </FormLabel>
              <TextField
                placeholder="Enter filming Unit"
                id="input-name"
                type="text"
                name="name"
                autoComplete="off"
                value={filmingUnit > 0 ? filmingUnit : ""}
                onChange={(e) => {
                  const input = Number(e.target.value);
                  if (!Number.isNaN(input))
                    setFilmingUnit(Number(e.target.value));
                }}
                sx={{ width: "418px" }}
              />
            </Stack>
            <Stack sx={{ py: 2 }}>
              <FormLabel
                required
                sx={{ display: "block", marginBottom: "0.5rem", px: 1 }}
              >
                Director's Name
              </FormLabel>
              <TextField
                placeholder="Enter director name"
                id="input-name"
                type="text"
                name="name"
                autoComplete="off"
                value={directorName}
                onChange={(e) => {
                  setDirectorName(e.target.value);
                }}
                sx={{ width: "418px" }}
              />
            </Stack>
          </Box>
        ) : (
          <></>
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ p: 2 }}
          spacing={1}
        >
          <LoadingButton
            loading={isLoading}
            disabled={
              !showCreateDrive ||
              !isValid({
                startEpisode: startEpisodeNum,
                endEpisode: endEpisodeNum,
                directorName,
                filmingUnit,
              })
            }
            variant="contained"
            color="secondary"
            onClick={() => {
              mutate({
                ccid,
                payload: {
                  startEpisode: startEpisodeNum,
                  endEpisode: endEpisodeNum,
                  directorName,
                  filmingUnit,
                },
              });
            }}
          >
            Create My Drive
          </LoadingButton>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("../")}
          >
            Return to tasks
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};
