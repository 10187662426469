import { OfcomRegion } from "../../types/types";

type RegionOptions = { value: OfcomRegion; label: string }[];

export const regionOptions: RegionOptions = [
  { value: OfcomRegion.NorthOfEngland, label: "North" },
  { value: OfcomRegion.NorthernIreland, label: "Northern Ireland" },
  { value: OfcomRegion.MidlandsAndAnglia, label: "Midlands and Anglia" },
  { value: OfcomRegion.Scotland, label: "Scotland" },
  { value: OfcomRegion.South, label: "South" },
  { value: OfcomRegion.Wales, label: "Wales" },
];
