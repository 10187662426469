import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import {
  EssenceTimecodeResponsePayload,
  TimecodeResponsePayload,
  VideoAssetsResponsePayload,
  VideoMetadataResponsePayload,
} from "../types/types";

const API_BASE = getAPI(SERVICES.browse_assets);

export const getVideoAssets = (
  titleCcid: string,
): Promise<VideoAssetsResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/titles/${titleCcid}/browseassets`)
    .then(({ data }: AxiosResponse) => data);

export const getSelectedVideoUrl = (versionCcid: string): Promise<string> =>
  handleRequest
    .get(`${API_BASE}/versions/${versionCcid}/browseassets/url`)
    .then(({ headers }) => headers.location);

export const getTitleVersions = (
  titleCcid: string,
): Promise<VideoMetadataResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/titles/${titleCcid}/versions`)
    .then(({ data }) => data);

export const getTimecode = (
  titleCcid: string | undefined,
  seriesCcid: string | undefined,
  tag: string | undefined,
): Promise<TimecodeResponsePayload> =>
  handleRequest
    .get(
      titleCcid
        ? `${API_BASE}/titles/${titleCcid}/timecodes?timecodeTag=${tag}`
        : `${API_BASE}/series/${seriesCcid}/timecodes?timecodeTag=${tag}`,
    )
    .then(({ data }) => data);

export const getEssenceTimecode = (
  versionCcid: string,
): Promise<EssenceTimecodeResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/versions/${versionCcid}/timecodes`)
    .then(({ data }) => data);

export const updateTimecode = (
  titleCcid: string,
  payload: TimecodeResponsePayload,
  seriesCcid: string | undefined,
): Promise<void> =>
  handleRequest
    .put<TimecodeResponsePayload>(
      titleCcid
        ? `${API_BASE}/titles/${titleCcid}/timecodes`
        : `${API_BASE}/series/${seriesCcid}/timecodes`,
      payload,
    )
    .then(() => undefined);
