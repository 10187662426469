import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { createEpisode } from "../../api/portal";
import { Title } from "../../types/types";

export const useCreateEpisode = (): UseMutationResult<
  unknown,
  unknown,
  { ccid: string; payload: Title },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: { ccid: string; payload: Title }) =>
      createEpisode(params.ccid, params.payload),
    {
      onSettled: () => queryClient.invalidateQueries("titles"),
    },
  );
};
