import { useQuery, UseQueryResult } from "react-query";
import {
  getImagesEntitlements,
  FileEntitlementsResponse,
} from "../../api/images";

export const useImagesEntitlements = (
  activeCcid: string | undefined,
  level: string,
  type: string,
): UseQueryResult<FileEntitlementsResponse> =>
  useQuery(
    ["entitlements", activeCcid],
    () => getImagesEntitlements(level, activeCcid as string, type as string),
    { enabled: activeCcid != null },
  );
