import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close, WarningAmber } from "@mui/icons-material";
import { TitleDetailsProps } from "../../types/types";
import { useCheckTitleAssets } from "../../hooks/queries/useCheckTitleAssets";
import theme from "../../utils/theme";
import { CanDeleteEpisode } from "./CanDeleteEpisode";
import { CannotDeleteEpisode } from "./CannotDeleteEpisode";

export const DeleteEpisodeDialog = ({
  open,
  setOpen,
  title,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: TitleDetailsProps | undefined;
}): JSX.Element => {
  const { data: titleAssets, isLoading: loadingAssets } = useCheckTitleAssets(
    title?.ccid,
  );

  const loadingUI = (
    <Box
      px={2}
      py={1}
      minHeight="160px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
  const [dialogContent, setDialogContent] = useState<JSX.Element>(loadingUI);

  useEffect(() => {
    if (
      titleAssets === undefined ||
      titleAssets.hasDerivedTitles ||
      titleAssets.hasFonts ||
      titleAssets.hasImages ||
      titleAssets.hasScripts ||
      titleAssets.hasVideoAssets ||
      title === undefined
    ) {
      setDialogContent(<CannotDeleteEpisode titleAssets={titleAssets} />);
    } else
      setDialogContent(<CanDeleteEpisode setOpen={setOpen} title={title} />);
  }, [title, setOpen, titleAssets]);

  return (
    <>
      {/* Button to open dialog */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        // onClose={() => setOpen(false)}
        aria-labelledby="Add collaborator"
      >
        <Stack
          bgcolor={theme.palette.primary.main}
          color="white"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <WarningAmber />
            <Typography display="inline" variant="h5">
              This action cannot be reversed
            </Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            style={{ color: "white" }}
          >
            <Close />
          </IconButton>
        </Stack>
        {loadingAssets || titleAssets === undefined ? loadingUI : dialogContent}
      </Dialog>
    </>
  );
};
