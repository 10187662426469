import { useMutation, UseMutationResult } from "react-query";
import { createSeries } from "../../api/portal";
import { noop } from "../../utils/appHelper";
import { CcidCreateSeriesPayload } from "../../types/types";
import { AxiosError } from "axios";

export const useCreateSeries = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult<
  void,
  AxiosError,
  { ccid: string; formData: CcidCreateSeriesPayload },
  unknown
> =>
  useMutation(({ ccid, formData }) => createSeries(ccid, formData), {
    onSuccess,
    onError,
  });
