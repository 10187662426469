import { AxiosError } from "axios";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import {
  bulkDownloadImages,
  getBulkImagesDownloadLink,
  TriggerBulkDownloadImagesPayload,
  TriggerBulkDownloadImagesResponse,
} from "../../api/images";

const RETRY_DELAY_IN_SECONDS = 3;

export const useTriggerBulkImagesDownload = (): UseMutationResult<
  TriggerBulkDownloadImagesResponse,
  unknown,
  TriggerBulkDownloadImagesPayload
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: TriggerBulkDownloadImagesPayload) =>
      bulkDownloadImages(payload),
    onSuccess: ({ jobId }, { seriesCcid }) => {
      queryClient.fetchQuery({
        queryKey: ["getBulkDownloadLink"],
        queryFn: () => getBulkImagesDownloadLink({ seriesCcid, jobId }),
        // we expect to get errors until the archiving job is complete. so we want to retry consistently
        retryDelay: RETRY_DELAY_IN_SECONDS * 1000,
        // a 404 is expected and we should keep retrying if we get one, anything else should be treated as a real error
        retry: (failureCount, error: AxiosError) => {
          const triesPerMinute = 60 / RETRY_DELAY_IN_SECONDS;
          const totalMinutes = 15;
          // given that we retry every 3 seconds, this equates to ~15 minutes
          // the Lambda can execute for up to 15 minutes, so if we reach this point, we can be sure something has gone wrong
          const maxFailures = triesPerMinute * totalMinutes;

          if (failureCount < maxFailures) {
            return error?.response?.status === 404;
          }

          return false;
        },
      });
    },
  });
};
