import { useState, useEffect, SyntheticEvent } from "react";
import {
  Dialog,
  Stack,
  Button,
  LinearProgress,
  Typography,
  Box,
  Container,
} from "@mui/material";
import noAccessGif from "../../svg/clouds.gif";
import requestedAccessImg from "../../svg/requestedAccessImg.png";
import {
  StyledNoAccessImg,
  StyledRequestedAccessImg,
} from "./NoAccessDialog.style";
import theme from "../../utils/theme";

export interface NoAccessDialogProps {
  open: boolean;
  viewOnlyBtnClick: () => void;
  requestAccessBtnClick: (e: React.SyntheticEvent) => void;
}

export const NoAccessDialog: React.FC<NoAccessDialogProps> = ({
  open,
  viewOnlyBtnClick,
  requestAccessBtnClick,
}) => {
  const [requestedAccess, setRequestedAccess] = useState(false);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // timer showing confirmation of requested access
    if (requestedAccess) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          // 103 so that the load bar goes the full width
          if (oldProgress === 103) {
            setRequestedAccess(false);
          }
          return oldProgress + 1;
        });
      }, 75);

      return () => {
        clearInterval(timer);
      };
    }

    return undefined;
  }, [requestedAccess]);

  const requestAccess = (e: React.SyntheticEvent) => {
    requestAccessBtnClick(e); // sets open to false
    setRequestedAccess(true);
  };

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <Container sx={{ bgcolor: "#0F72EC" }}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack direction="column" justifyContent="center">
              <Box color={theme.palette.white} paddingLeft={4}>
                <Typography variant="h3" paddingBottom={4}>
                  Yikes!
                </Typography>
                <Typography component="h3">
                  We don't recognise your account as having full access to this
                  material.
                </Typography>
                <Typography component="h3">
                  You can request access below or view the information we have
                  here with limited access.
                </Typography>
              </Box>
            </Stack>
            <StyledNoAccessImg src={noAccessGif} alt="no access" />
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            style={{ color: "white", padding: "2%" }}
          >
            <Button onClick={viewOnlyBtnClick} sx={{ color: "white" }}>
              View-only mode
            </Button>
            <Button
              onClick={requestAccess}
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                "&:hover": {
                  background: "#e0e0e0",
                  color: "black",
                },
              }}
            >
              Request full access
            </Button>
          </Stack>
        </Container>
      </Dialog>

      <Dialog open={requestedAccess} maxWidth="xs">
        <Container sx={{ bgcolor: "#0F72EC" }}>
          <Stack direction="row" justifyContent="center">
            <StyledRequestedAccessImg
              src={requestedAccessImg}
              alt="requested access"
            />
          </Stack>
        </Container>
        <LinearProgress variant="determinate" value={progress} />
        <Stack direction="column" justifyContent="center" sx={{ m: 2 }}>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            Access requested!
          </Typography>
          <Typography textAlign="center">
            Someone from our team will reach out to you once you have been
            granted access.
          </Typography>
        </Stack>
      </Dialog>
    </>
  );
};
