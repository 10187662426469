import { Typography } from "@mui/material";
import { TEXTAREA, CHECKBOX, TEXT } from "../../../../../components";

/* TODO:
  Deprecate this file as we no longer user it for loading the start up form component.
  It is only used in the sanitizeFormData method which utilizes the types of each field
  and based on that, it sanitizes the data before passing them to the components.
  The solution here is to either fix the backend to return the data in the right format or
  do the clean up at the component level like we did with the contributors component in ContributorsAndTalent.tsx
*/

export const policiesFormConfig = [
  {
    sectionTitle: <Typography variant="h5">Policies</Typography>,
    description: "",
    formFields: [
      {
        type: CHECKBOX,
        label: "Have all policies been shared with the team?",
        id: "policiesShared",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: CHECKBOX,
        label: "Has producers handbook been shared with the team?",
        id: "producersHandbookShared",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: <Typography variant="h5">Sustainability</Typography>,
    description: "",
    formFields: [
      {
        type: CHECKBOX,
        label: "Has the production been set up on Albert?",
        id: "albert",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXT,
        label: "Enter Albert ID",
        id: "albertId",
        placeholder: "Enter Id",
        value: "",
        variant: "filled",
        columnCount: 6,
        required: true,
        disabled: false,
        excludedFromCompletion: true,
        visibleDependencies: {
          albert: true,
        },
      },
      {
        type: CHECKBOX,
        label: <div>Has carbon action plan been started?</div>,
        id: "carbonActionPlanStarted",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: (
          <div>
            How can sustainable initatives be incorporated into production?
          </div>
        ),
        id: "sustainableInitiatives",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: <div>Green team contact</div>,
        id: "greenTeamContact",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: <div>Privacy and Data Protection</div>,
        id: "privacyAndData",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: <div>Additional Information:</div>,
        id: "additionalNotes",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        excludedFromCompletion: true,
      },
    ],
  },
];
