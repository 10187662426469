import { Typography } from "@mui/material";
import { TEXTAREA, CHECKBOX } from "../../../../../components";

/* TODO:
  Deprecate this file as we no longer user it for loading the start up form component.
  It is only used in the sanitizeFormData method which utilizes the types of each field
  and based on that, it sanitizes the data before passing them to the components.
  The solution here is to either fix the backend to return the data in the right format or
  do the clean up at the component level like we did with the contributors component in ContributorsAndTalent.tsx
*/
export const technicalProductionPlanFormConfig = [
  {
    sectionTitle: <Typography variant="h5">Deliverables</Typography>,
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Delivery format",
        id: "deliveryFormat",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Broadcast Clearances",
        id: "broadcastClearances",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Versions required",
        id: "versions",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: CHECKBOX,
        label: "Are international versions required?",
        id: "internationalVersionsRequired",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: CHECKBOX,
        label: "Is a production bible required?",
        id: "productionBibleRequired",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Press and publicity  / press campaign",
        id: "pressAndPublicity",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Involvement with Interactive (who is contact?)",
        id: "interactiveInvolvement",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Prizes and PRTS info",
        id: "prizesAndPrtsInfo",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: <Typography variant="h5">Media</Typography>,
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Technology Workflow",
        id: "technologyWorkflow",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Media retention & digital production library",
        id: "mediaRetention",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Digital",
        id: "digital",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Compliance",
        id: "compliance",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Music",
        id: "music",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Procurement",
        id: "procurement",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: <div>Additional Information:</div>,
        id: "additionalNotes",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        excludedFromCompletion: true,
      },
    ],
  },
];
