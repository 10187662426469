import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import {
  AddUserToIconikRequest,
  EntitlementsResponsePayload,
  IconikUserGroupResponsePayload,
  RushURLResponsePayload,
} from "../types/types";

const API_BASE = getAPI(SERVICES.iconik);

export const createRushesURL = (
  key: string,
  ccid: string,
): Promise<RushURLResponsePayload> =>
  handleRequest
    .put(`${API_BASE}/rushes/${key}/${ccid}`, null)
    .then(({ data }: AxiosResponse) => data);

export const getEntitlements = (): Promise<EntitlementsResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/entitlements`)
    .then(({ data }): Promise<EntitlementsResponsePayload> => data);

export const getIconikUsers = (
  ccid: string,
): Promise<IconikUserGroupResponsePayload> =>
  handleRequest
    .get(`${API_BASE}/ccid/${ccid}/users/group`)
    .then(({ data }: AxiosResponse) => data);

export const addIconikUser = (
  ccid: string,
  addUserToIconikRequest: AddUserToIconikRequest,
): Promise<void> =>
  handleRequest
    .put<AddUserToIconikRequest>(
      `${API_BASE}/ccid/${ccid}/users/group`,
      addUserToIconikRequest,
    )
    .then(() => undefined);
