import * as Yup from "yup";

export const dataPrivacySchemaV3 = Yup.object().shape({
  spotId: Yup.string(),
  novelData: Yup.string().required("This is a required field"),
  subjectsAware: Yup.string().required("This is a required field"),
  journalisticAims: Yup.string().required("This is a required field"),
  activities: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
});

export type DataPrivacySectionV3 = Yup.InferType<typeof dataPrivacySchemaV3>;
