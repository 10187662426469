import { useMutation, UseMutationResult } from "react-query";
import { enqueueSnackbar } from "notistack";
import { legalDeliverablesFormSubmission } from "../../api/legalDeliverables";
import { AutosaveProductionPrinciplesFormDataServer } from "../../types/types";
import { noop } from "../../utils/appHelper";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

export const useProductionPrinciplesFormSubmission = (
  onSuccess = noop,
): UseMutationResult<
  unknown,
  unknown,
  {
    ccid: string;
    formData: AutosaveProductionPrinciplesFormDataServer;
    autosave: boolean;
    provisional: boolean;
  },
  unknown
> =>
  useMutation(
    ({ ccid, formData, autosave, provisional }) =>
      legalDeliverablesFormSubmission(
        ccid,
        formData,
        autosave,
        provisional
          ? `PRODUCTION_PRINCIPLES_PROVISIONAL`
          : `PRODUCTION_PRINCIPLES_FINAL`,
      ),
    {
      onSuccess: () => {
        enqueueSnackbar("Content Saved", { variant: "success" });
        onSuccess();
      },
      onError: () => {
        enqueueSnackbar("error saving content!", {
          variant: "error",
          action: SnackbarDismiss,
          persist: true,
        });
      },
    },
  );
