import TWELVE_YARD from "./ITV_12 _Yard_Logo_Blue.png";
import DRAMA from "./ITV_Studios_ContinuingDrama_Primary_Blue_RGB.png";
import LIFTED_ENTERTAINMENT from "./Lifted_Logo_Colour_On_Black_transparent.png";
import MULTI_STORY_MEDIA from "./MultiStory_RGB_Primary_ITVS_Black.png";
import POTATO from "./POTATO_LOGO_colour_on_black.png";
import TWO_FOUR from "./Twofour_ITVS_RGB.png";
import SILVERPRINT from "./Silverprint_Pictures_logo_RichK.png";
import TALL_STORY from "./Tall_Story_Logo_RGB_Black.png";
import BIG_TALK from "./Big_Talk_Studios_Logo_Brandmark.png";
import WORLD_PRODUCTIONS from "./World_Productions_ITVS_Logo_B.png";
import ITV_DAYTIME from "./ITVS Daytime.png";
import ITV_AUSTRALIA from "./ITVS Australia.png";
import OXFORD_SCIENTIFIC_FILMS from "./Oxford_Scientific.jpg";
import THE_GARDEN from "./The_Garden_Logotype_RGB.png";
import MAMMOTH_SCREEN from "./Mammoth_Screen.png";
import HAPPY_PRINCE from "./Happy_Prince_ITVS.png";
import QUAY_STREET from "./Quay_Street_Productions.png";
import BOOM_CYMRU from "./Boom_Cymru_Logo_Yellow_Grey_CMYK.jpg";
import MONUMENTAL from "./Monumental_Television.png";
import MAINSTREET_PICTURES from "./Mainstreet.png";
import PLIMSOLL from "./Plimsoll_Productions_Logo_Black_Transparent.png";
import FIVE_ACTS from "./Five_Acts.png";
import APPLE_TREE from "./Apple_Tree_Productions_ITVS.png";
import ARMOZA_FORMATS from "./Armoza_Formats.png";
import BEDROCK_ENTERTAINMENT from "./Bedrock.png";
import BILDERGARTEN_ENTERTAINMENT from "./BGEntertainment_ITVS_Black_RGB.png";
import BLUMHOUSE_TV from "./Blumhouse_Productions.jpg";
import CATTLEYA from "./cattleya-logo.jpg";
import CATTLEYA_PRODUCCIONES from "./Cattleya_Producciones_Black.png";
import CIRCLE_OF_CONFUSION from "./Circle_of_Confusion.png";
import GOOD_CAPER from "./Good_Caper.png";
import HIGH_NOON from "./High_Noon_Entertainment_Web_02.png";
import ITV_STUDIOS_GERMANY from "./ITVS Germany.png";
import ITV_AMERICA from "./ITV America.png";
import ITV_STUDIOS_AMERICA from "./ITVS America.png";
import ITV_STUDIOS_FRANCE from "./ITVS France.png";
import ITV_STUDIOS_NETHERLANDS from "./ITVS Netherlands.png";
import LEFTFIELD_PICTURES from "./Leftfield_Pictures.jpg";
import LINGO_PICTURES from "./Lingo_Mono.png";
import NOHO_PRODUCTIONS from "./NOHO_ITVS.png";
import ITV_STUDIOS_FINLAND from "./ITVS Finland.png";
import ITV_STUDIOS_IBERIA from "./ITVS Iberia.png";
import ITV_STUDIOS_NORWAY from "./ITVS Norway.png";
import ITV_STUDIOS_SWEDEN from "./ITVS SWEDEN.png";
import POISON_PEN from "./Poison_Pen_Wordmark_RGB_Blk.png";
import ROLLERCOASTER from "./Rollercoaster_ITV_Portrait_Lockup_Black.png";
import SIRENS_MEDIA from "./Sirens_Logo_Blue_Gray.png";
import TETRA_MEDIA_FICTION from "./Tetra_02.jpg";
import THINKFACTORY_MEDIA from "./Thinkfactory_Media.png";
import TOMORROW_STUDIOS from "./Tomorrow_Studios.png";
import WINDLIGHT_PICTURES from "./Windlight_Productions_RGB_Logo-Lockup_RGB_BLK_01.png";
import WORK_FRIENDS from "./Work_Friends_Logo.png";

const studioLogos: Record<string, string> = {
  "12 Yard Productions": TWELVE_YARD,
  "ITV Drama (UK)": DRAMA,
  "Lifted Entertainment": LIFTED_ENTERTAINMENT,
  "MultiStory Media": MULTI_STORY_MEDIA,
  Potato: POTATO,
  "Twofour Studios": TWO_FOUR,
  "Silverprint Pictures": SILVERPRINT,
  "Tall Story Pictures": TALL_STORY,
  "Big Talk Studios": BIG_TALK,
  "World Productions": WORLD_PRODUCTIONS,
  "ITV Studios Daytime": ITV_DAYTIME,
  "ITV Studios Australia": ITV_AUSTRALIA,
  "ITV Studios Iberia": ITV_STUDIOS_IBERIA,
  "Oxford Scientific Films": OXFORD_SCIENTIFIC_FILMS,
  "The Garden Productions": THE_GARDEN,
  "Mammoth Screen": MAMMOTH_SCREEN,
  "Happy Prince": HAPPY_PRINCE,
  "Quay Street Productions": QUAY_STREET,
  "Boom Cymru": BOOM_CYMRU,
  "Monumental Television": MONUMENTAL,
  "Mainstreet Pictures": MAINSTREET_PICTURES,
  "Plimsoll Productions": PLIMSOLL,
  "5 Acts Productions": FIVE_ACTS,
  "Apple Tree Productions": APPLE_TREE,
  "Armoza Formats": ARMOZA_FORMATS,
  "Bedrock Entertainment": BEDROCK_ENTERTAINMENT,
  "Bildergarten Entertainment": BILDERGARTEN_ENTERTAINMENT,
  "Blumhouse TV": BLUMHOUSE_TV,
  Cattleya: CATTLEYA,
  "Cattleya Producciones": CATTLEYA_PRODUCCIONES,
  "Circle Of Confusion": CIRCLE_OF_CONFUSION,
  "Good Caper": GOOD_CAPER,
  "High Noon Entertainment": HIGH_NOON,
  "ITV Studios Germany": ITV_STUDIOS_GERMANY,
  "ITV America": ITV_AMERICA,
  "ITV Studios America": ITV_STUDIOS_AMERICA,
  "ITV Studios France": ITV_STUDIOS_FRANCE,
  "ITV Studios Netherlands": ITV_STUDIOS_NETHERLANDS,
  "Leftfield Pictures": LEFTFIELD_PICTURES,
  "Lingo Pictures": LINGO_PICTURES,
  "Noho Productions": NOHO_PRODUCTIONS,
  "ITV Studios Finland": ITV_STUDIOS_FINLAND,
  "ITV Studios Norway": ITV_STUDIOS_NORWAY,
  "ITV Studios Sweden": ITV_STUDIOS_SWEDEN,
  "Poison Pen Studios": POISON_PEN,
  "Rollercoaster Television": ROLLERCOASTER,
  "Sirens Media": SIRENS_MEDIA,
  "Tetra Media Fiction": TETRA_MEDIA_FICTION,
  "Thinkfactory Media": THINKFACTORY_MEDIA,
  "Tomorrow Studios": TOMORROW_STUDIOS,
  "Windlight Pictures": WINDLIGHT_PICTURES,
  "Work Friends": WORK_FRIENDS,
};

export default studioLogos;
