import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import { LevelType, UploadType } from "../types/types";

const API_BASE = getAPI(SERVICES.deliverables);

export interface ImagesAPIResponsePayload {
  presignedUrl: string;
  imageId: string;
}

export interface PatchImageEmbargoDateRequest {
  embargoDateTime?: string;
}

export interface PatchImageStateRequest {
  state: string;
}

export type ImageTag =
  | "logo"
  | "keyArtImage"
  | "keyArtContributorsOnSetGallery"
  | "keyArtContributorsSetUp"
  | "behindTheScenes"
  | "unitStills";

export interface ListImageSchema {
  uploadFilename: string;
  imageId: string;
  uploadTimestamp: string;
  uploadedBy?: string;
  embargoDateTime?: string;
  thumbnailUri: string;
  size: number;
  contentType?: string;
  imageTag?: ImageTag;
}

export interface UploadedImage {
  uploadFilename: string;
  imageId: string;
  uploadTimestamp: string;
  uploadedBy?: string;
  embargoDateTime?: string;
  thumbnailUri: string;
  size: number;
  contentType?: string;
  imageTag?: ImageTag;
}

export interface UploadedScript {
  scriptId: string;
  uploadFilename: string;
  uploadTimestamp: string;
  uploadedBy?: string;
  size: number;
  contentType?: string;
}

export interface UploadedFont {
  fontId: string;
  uploadFilename: string;
  uploadTimestamp: string;
  uploadedBy?: string;
  size: number;
  contentType?: string;
}

export interface ListImageResponse {
  images: UploadedImage[];
}

export interface ListScriptResponse {
  scripts: UploadedScript[];
}

export interface ListFontResponse {
  fonts: UploadedFont[];
}

export interface ListDeliverablesResponse {
  deliverables: UploadedImage[];
}

export interface DownloadImageResponse {
  downloadUri: string;
}

export enum ImageState {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export interface FileEntitlementsResponse {
  canDownload: boolean;
  canView: boolean;
  canUpload: boolean;
  canDelete: boolean;
}

export interface TriggerBulkDownloadImagesPayload {
  seriesCcid: string;
  titles: Array<{ titleCcid: string; numberInGroup: number }>;
  imageTags: Array<string>;
}

export interface TriggerBulkDownloadImagesResponse {
  jobId: string;
}

export interface GetBulkImagesDownloadLinkPayload {
  seriesCcid: string;
  jobId: string;
}

export interface GetBulkImagesDownloadLinkResponse {
  uri: string;
}

const patchImage = (
  key: string,
  ccid: string,
  imageId: string,
  payload: PatchImageEmbargoDateRequest | PatchImageStateRequest,
): Promise<ImagesAPIResponsePayload> =>
  handleRequest
    .patch(
      `${API_BASE}/${key}/${ccid}/images/${imageId}`,
      payload as Record<string, string | undefined>,
    )
    .then(({ data }: AxiosResponse) => data);

const deleteFont = (
  key: string,
  ccid: string,
  fontId: string,
): Promise<ImagesAPIResponsePayload> =>
  handleRequest
    .delete(`${API_BASE}/${key}/${ccid}/fonts/${fontId}`)
    .then(({ data }: AxiosResponse) => data);

const deleteScript = (
  key: string,
  ccid: string,
  scriptId: string,
): Promise<ImagesAPIResponsePayload> =>
  handleRequest
    .delete(`${API_BASE}/${key}/${ccid}/scripts/${scriptId}`)
    .then(({ data }: AxiosResponse) => data);

export const startImageUpload = async (
  level: LevelType,
  ccid: string,
  payload: {
    contentType: string;
    objectName: string;
    imageTag: string;
  },
  type: UploadType,
): Promise<ImagesAPIResponsePayload> =>
  handleRequest
    .post(`${API_BASE}/${level}/${ccid}/${type}/presignedUploadUrl`, null, {
      params: {
        contentType: payload.contentType,
        ccid,
        objectName: payload.objectName,
        imageTag: payload.imageTag,
      },
    })
    .then(({ data }: AxiosResponse<ImagesAPIResponsePayload>) => data);

export const patchImageEmbargoDate = (
  key: string,
  ccid: string,
  imageId: string,
  embargoDate?: string,
): Promise<ImagesAPIResponsePayload> =>
  patchImage(key, ccid, imageId, { embargoDateTime: embargoDate });

export const patchDeleteDeliverableState = (
  key: string,
  ccid: string,
  imageId: string,
  imageState: ImageState,
  embargoDate?: string,
  type?: UploadType,
): Promise<ImagesAPIResponsePayload> => {
  if (type === "scripts") return deleteScript(key, ccid, imageId);
  if (type === "fonts") return deleteFont(key, ccid, imageId);
  return patchImage(key, ccid, imageId, {
    state: imageState,
    embargoDateTime: embargoDate,
  });
};

export const listDeliverables = (
  key: string,
  ccid: string,
  imageTag: string,
  type: string,
): Promise<ListImageResponse | ListScriptResponse | ListFontResponse> =>
  handleRequest
    .get(`${API_BASE}/${key}/${ccid}/${type}?imageTag=${imageTag}`)
    .then(
      ({
        data,
      }: AxiosResponse<
        ListImageResponse | ListScriptResponse | ListFontResponse
      >) => data,
    );

export const getImagesEntitlements = (
  key: string,
  ccid: string,
  callType: string,
): Promise<FileEntitlementsResponse> =>
  handleRequest
    .get(`${API_BASE}/${key}/${ccid}/entitlements/${callType}`)
    .then(({ data }: AxiosResponse<FileEntitlementsResponse>) => data);

export const getThumbnail = (
  imageId: string,
  maxRetries?: number,
): Promise<DownloadImageResponse> =>
  handleRequest
    .get(`${API_BASE}/images/${imageId}/thumbnail`, {
      params: {
        maxRetries,
      },
    })
    .then(({ data }: AxiosResponse<DownloadImageResponse>) => data);

export const downloadImage = (
  imageId: string,
  key: string,
  ccid: string,
  callType: UploadType,
): Promise<DownloadImageResponse> =>
  handleRequest
    .get(`${API_BASE}/${key}/${ccid}/${callType}/${imageId}`)
    .then(({ data }: AxiosResponse<DownloadImageResponse>) => data);

export const getBlob = (
  downloadImageResponse: DownloadImageResponse,
): Promise<Blob> =>
  handleRequest
    .get(downloadImageResponse.downloadUri, {
      responseType: "blob",
      headers: {},
    })
    .then((res) => new Blob([res.data], { type: "image/*" }));

export const bulkDownloadImages = ({
  seriesCcid,
  titles,
  imageTags,
}: TriggerBulkDownloadImagesPayload): Promise<TriggerBulkDownloadImagesResponse> =>
  handleRequest
    .post(`${API_BASE}/series/${seriesCcid}/image-bulk-download`, {
      titles,
      imageTags,
    })
    .then(({ data }: AxiosResponse<TriggerBulkDownloadImagesResponse>) => data);

export const getBulkImagesDownloadLink = ({
  jobId,
  seriesCcid,
}: GetBulkImagesDownloadLinkPayload): Promise<GetBulkImagesDownloadLinkResponse> =>
  handleRequest
    .get(`${API_BASE}/series/${seriesCcid}/image-bulk-download/check/${jobId}`)
    .then(({ data }: AxiosResponse<GetBulkImagesDownloadLinkResponse>) => data);
