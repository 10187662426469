/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import {
  BrandTitleResponsePayload,
  TitlesResponsePayload,
} from "../../../../../../types/types";
import {
  combineTextParts,
  getSynopsisText,
  noop,
} from "../../../../../../utils/appHelper";

interface EpisodesDropdownProps {
  titlesData: TitlesResponsePayload | BrandTitleResponsePayload;
  onCcidChange?(ccid: string): void;
  titleCcid: string;
  disabled?: boolean;
}

export const EpisodesDropdown: FC<EpisodesDropdownProps> = ({
  titlesData,
  onCcidChange = noop,
  titleCcid,
  disabled = false,
}) => {
  const options = useMemo(
    () =>
      [...(titlesData?.titleDetails || [])]
        .sort((a, b) => a.episodeNumber - b.episodeNumber)
        .map(({ ccid: value, episodeNumber, name }) => ({
          value,
          label: `${combineTextParts(getSynopsisText(episodeNumber, "Episode"), name)}`,
        })),
    [titlesData],
  );

  const selectedOption = useMemo(
    () => options?.find(({ value }) => value === titleCcid),
    [options, titleCcid],
  );

  return (
    <Autocomplete
      disablePortal
      id="episodes-dropdown"
      disabled={disabled}
      options={options}
      value={selectedOption || null}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Which episode should the preview come from?"
        />
      )}
      onChange={(e, v) => {
        e.persist();
        const ccidValue = v?.value || "";
        onCcidChange(ccidValue);
      }}
    />
  );
};
