import { useQuery, UseQueryResult } from "react-query";
import { TaskStatusPayload } from "../../types/types";
import handleRequest from "../../lib/handleRequest";

export type TaskStatusQueryKey = ["task status", string | null];

export const useTaskStatus = (
  taskStatusUrl: string | null,
): UseQueryResult<TaskStatusPayload> => {
  const queryKey: TaskStatusQueryKey = ["task status", taskStatusUrl];

  return useQuery(
    queryKey,
    () => handleRequest.get(taskStatusUrl as string).then(({ data }) => data),
    { enabled: Boolean(taskStatusUrl) },
  );
};
