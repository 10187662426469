export const getNumberOfWords = (str: string): number =>
  str.trim().split(" ").filter(Boolean).length;

export const getNumberOfCharacters = (text: string): number => {
  // remove all trailing paragraph whitespace
  let newText = text.replace(/\s+$/gm, "");
  // remove all extra lines MAY NEED TO CHANGE IF CATALOGUE STARTS TO SUPPORT LINE BREAKS
  newText = text.replace(/(\r\n|\n|\r)/gm, "");
  return newText.trim().length;
};

export const upFirst = (str: string): string =>
  str.substring(0, 1).toUpperCase() + str.substring(1);

export const toWords = (name: string): string => {
  const words = name?.match(/[A-Za-z][a-z]*/g) || [];
  const toLowerCase = (word: string) => word.toLowerCase();
  return words.map(toLowerCase).join(" ");
};

export const toSectionAndWords = (errorKey: string): string =>
  errorKey
    .split(".")
    .map((words) => toWords(words))
    .join(" - ");
