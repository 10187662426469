import * as Yup from "yup";

export const procurementSchemaV3 = Yup.object().shape({
  spotId: Yup.string(),
  spend: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  otherSpend: Yup.string().when("$procurement.spend", ([spend], schema) => {
    return spend && spend.includes("Other")
      ? schema.required("This is a required field")
      : schema.notRequired();
  }),
  supplier: Yup.string().required("This is a required field"),
  supplierDetails: Yup.string().when(
    "$procurement.supplier",
    ([supplier], schema) => {
      if (supplier === "Yes") {
        return schema.required("This is a required field");
      }

      return schema;
    },
  ),
  intermediary: Yup.boolean().required("This is a required field"),
  dayLabourers: Yup.boolean().required("This is a required field"),
  contracting: Yup.array()
    .of(Yup.string().required())
    .when("$procurement.intermediary", ([intermediary], schema) => {
      return intermediary
        ? schema
            .min(1, "This is a required field")
            .required("This is a required field")
        : schema.notRequired();
    }),
  otherContracting: Yup.string().when(
    "$procurement.contracting",
    ([contracting], schema) => {
      return contracting && contracting.includes("Other")
        ? schema.required("This is a required field")
        : schema.notRequired();
    },
  ),
});

export type ProcurementSectionV3 = Yup.InferType<typeof procurementSchemaV3>;
