/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Container,
  Drawer,
  Stack,
  Button,
  CircularProgress,
  LinearProgress,
  Box,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Add, Search, Delete } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import {
  HIDE_DRAWER,
  NotificationService,
  OPEN_DRAWER,
} from "../../services/notificationService";
import {
  useAddGroupUsers,
  useGroupUsers,
  useGroups,
  useUpdateGroupPermissions,
} from "../../hooks";
import { UsersTable } from "./components/UsersTable";
import { Group, User } from "../../types/types";
import { GroupList } from "./components/GroupList/GroupList";
import { ModalService } from "../../services";
import { ModalWrapper } from "../../components";
import { AddNewCollaborator } from "./components/AddNewCollaborator/AddNewCollaborator";
import { useRemoveGroupUsers } from "../../hooks/mutations/useRemoveGroupUsers";
import { RemoveCollaborator } from "./components/RemoveCollaborator";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

export const AccessManagement: FC = () => {
  const { data: groups, isLoading } = useGroups();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  if (isLoading) {
    return (
      <Container fixed>
        <LinearProgress />
        <Drawer anchor="left" open={drawerOpen} onClose={closeDrawer}>
          <GroupList />
        </Drawer>
      </Container>
    );
  }

  return (
    <AccessManagementContent
      groups={groups || []}
      drawerOpen={drawerOpen}
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
    />
  );
};

const AccessManagementContent = ({
  groups,
  drawerOpen,
  openDrawer,
  closeDrawer,
}: {
  groups: Group[];
  drawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}) => {
  const [groupId, setGroupId] = useState<string>(
    groups.length === 1 ? groups[0].id : "",
  );
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [snackBarRemove, fireSnackBarRemove] = useState<boolean>(false);
  const [snackBarAdd, fireSnackBarAdd] = useState<boolean>(false);
  const {
    data: users,
    isLoading: loadingUsers,
    refetch,
  } = useGroupUsers(groupId || "");
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    let newUsers: User[] = [];

    if (searchTerm) {
      const searchTermLowerCase = searchTerm.toLocaleLowerCase();
      newUsers =
        users?.filter(
          (user) =>
            user?.firstName?.toLowerCase().includes(searchTermLowerCase) ||
            user?.lastName?.toLowerCase().includes(searchTermLowerCase) ||
            user?.email?.toLowerCase().includes(searchTermLowerCase) ||
            user?.role?.toLowerCase().includes(searchTermLowerCase),
        ) || [];
    } else {
      newUsers = users || [];
    }

    setFilteredUsers(newUsers);
  }, [searchTerm, users, groupId]);

  const handleRequestError = useCallback((error: { message: string }) => {
    enqueueSnackbar(error.message, {
      variant: "error",
      action: SnackbarDismiss,
      persist: true,
    });
  }, []);

  const { mutate: addUsers, isLoading: addingUsers } = useAddGroupUsers(
    () => refetch(),
    handleRequestError,
  );

  const { mutate: removeUsers, isLoading: removingUsers } = useRemoveGroupUsers(
    () => refetch(),
    handleRequestError,
  );

  const {
    mutate: updateAdminPermissions,
    isLoading: updatingGroupAdminPermission,
  } = useUpdateGroupPermissions(() => {
    refetch();
  }, handleRequestError);

  const splitOutRowPermissions = (rows: User[]) => {
    const splitOutUsers = rows.map((row) => {
      const accessManager = row.permissions.includes("ACCESS_MANAGER");
      const canEditCCID = row.permissions.includes("CCID_CREATOR");
      return { ...row, accessManager, canEditCCID };
    });
    return splitOutUsers;
  };

  useEffect(() => {
    if (snackBarAdd && !addingUsers) {
      enqueueSnackbar("User Added", { variant: "success" });

      fireSnackBarAdd(false);
    }
  }, [addingUsers, snackBarAdd]);

  useEffect(() => {
    if (snackBarRemove && !removingUsers) {
      enqueueSnackbar("User Removed");
      fireSnackBarRemove(false);
    }
  }, [removingUsers, snackBarRemove]);

  const loading =
    loadingUsers ||
    addingUsers ||
    removingUsers ||
    updatingGroupAdminPermission;

  const handleAddNewUser = useCallback(() => {
    ModalService.getInstance()
      .setShowHeader(true)
      .setTitle("Add collaborators")
      .setShowFooter(false)
      .setComponent(
        <AddNewCollaborator
          onAdd={(selectedUsers) => {
            addUsers({
              userIds: selectedUsers.map(({ id }) => id),
              groupId,
            });
            ModalService.getInstance().hideModal();
            fireSnackBarAdd(true);
          }}
          onCancel={() => {
            ModalService.getInstance().hideModal();
          }}
        />,
      )
      .showModal();
  }, [addUsers, groupId]);

  const handleRemoveUsers = useCallback(() => {
    ModalService.getInstance()
      .setShowHeader(true)
      .setTitle("Remove Selected Collaborators")
      .setShowFooter(false)
      .setWidth("444px")
      .setComponent(
        <RemoveCollaborator
          selectedIds={selectedIds}
          users={users || []}
          onRemove={(removedUsers) => {
            removeUsers({ userIds: removedUsers.map(({ id }) => id), groupId });
            ModalService.getInstance().hideModal();
            fireSnackBarRemove(true);
          }}
          onCancel={() => {
            ModalService.getInstance().hideModal();
          }}
        />,
      )
      .showModal();
  }, [groupId, removeUsers, selectedIds, users]);

  const toggleUserAdminPermission = useCallback(
    (user: User, groupIdInput: string, permission: string) => {
      updateAdminPermissions({
        ...user,
        groupId: groupIdInput,
        permission,
      });
    },
    [updateAdminPermissions],
  );

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    const { value } = event.target;

    timer.current = setTimeout(() => {
      setSearchTerm(value);
    }, 300);
  }, []);

  useEffect(() => {
    NotificationService.sub(OPEN_DRAWER, () => {
      openDrawer();
    });

    NotificationService.sub(HIDE_DRAWER, () => {
      closeDrawer();
    });

    return () => {
      NotificationService.destroy([OPEN_DRAWER]);
      NotificationService.destroy([HIDE_DRAWER]);
    };
  }, [openDrawer, closeDrawer]);

  const handleChange = (event: SelectChangeEvent) => {
    setGroupId(`${event.target.value as string}`);
  };

  return (
    <Container fixed>
      <Box sx={{ py: 4 }}>
        <FormControl fullWidth>
          <InputLabel
            id="select-group"
            color="secondary"
            data-testid="groups-select"
          >
            Group
          </InputLabel>
          <Select
            labelId="select-group"
            id="select-group"
            value={groupId}
            label="Group"
            onChange={handleChange}
            color="secondary"
          >
            {groups?.map((group) => (
              <MenuItem key={group.id} data-testid={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Stack spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
          alignItems="baseline"
        >
          <TextField
            label="Filter users"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            variant="standard"
          />

          <Box whiteSpace="nowrap">Total Users:&nbsp; {users?.length}</Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddNewUser}
          >
            Add a new collaborator
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            disabled={selectedIds.length <= 0}
            onClick={handleRemoveUsers}
          >
            <Delete />
            Remove Selected
          </Button>
        </Stack>
        <Box
          sx={{
            height: "calc(100vh - 370px)",
            width: "100%",
            background: "white",
            position: "relative",
          }}
        >
          {groupId ? (
            <UsersTable
              users={splitOutRowPermissions(filteredUsers) || []}
              groupId={groupId}
              onChangePermissionAdmin={toggleUserAdminPermission}
              onUsersSelect={setSelectedIds}
            />
          ) : (
            <Box
              sx={{ background: "#F4F4F5" }}
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography> Please Select a Group </Typography>
            </Box>
          )}
          {loading && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              sx={{
                background: "rgba(0, 0, 0, 0.2)",
              }}
            >
              <CircularProgress />
            </Stack>
          )}
        </Box>
      </Stack>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          top: "4rem",
          "& .MuiDrawer-paper": {
            top: "4rem",
            height: "calc(100% - 4rem)",
          },
        }}
      >
        <GroupList />
      </Drawer>
      <ModalWrapper />
    </Container>
  );
};
