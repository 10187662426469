import { Box, Stack } from "@mui/material";
import React, {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import {
  StyledContainer,
  StyledNavWrapper,
  StyledNav,
  StyledGroupMenu,
  StyledSubNavContainer,
} from "./GroupMenu.style";

import { SubNavProps, SubNav } from "./SubNav";

export interface GroupMenuProps {
  items: {
    label?: string;
    active?: boolean;
    id?: string;
    link?: string;
    icon?: ReactNode;
    subNavs?: SubNavProps[];
  }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemClick?(item: any): void;
  large?: boolean;
  style?: CSSProperties;
  smallFontSize?: boolean;
}

const isActive = (items: SubNavProps[]) =>
  items.some(({ items: subItems }) => subItems?.some((item) => item.active));

export const GroupMenu: FC<GroupMenuProps> = ({
  items,
  onItemClick,
  large,
  style,
  smallFontSize = false,
}) => {
  const navigate = useNavigate();
  const [focusIndex, setFocusIndex] = useState<number>(-1);
  const handleMouseEnter = useCallback(
    (newFocusIndex: React.SetStateAction<number>) => {
      setFocusIndex(newFocusIndex);
    },
    [],
  );

  return (
    <StyledGroupMenu large={large} style={style}>
      <StyledContainer hasItems={Boolean(items[focusIndex]?.subNavs)}>
        <Box display="flex" width={0.9} maxWidth="75rem" margin="0 auto">
          {items.map((item, index) => (
            <StyledNav
              onMouseEnter={() => handleMouseEnter(index)}
              key={item.label}
              active={item.active || isActive(item?.subNavs || [])}
              hasLink={Boolean(item.link || !item.subNavs)}
              large={large}
              smallFontSize={smallFontSize}
              onClick={() => {
                if (onItemClick) {
                  onItemClick(item);
                } else if (item.link) {
                  navigate(item.link);
                }
              }}
            >
              {item.icon}
              {item.label}
            </StyledNav>
          ))}
        </Box>

        <StyledSubNavContainer>
          <Box height={1} marginTop="1rem" marginBottom="1rem" />
          <StyledNavWrapper>
            <Stack direction="row">
              {items[focusIndex]?.subNavs?.map((item) => (
                <SubNav
                  key={item.sectionTitle}
                  sectionTitle={item.sectionTitle}
                  items={item.items}
                  onItemClick={onItemClick}
                />
              ))}
            </Stack>
          </StyledNavWrapper>
        </StyledSubNavContainer>
      </StyledContainer>
    </StyledGroupMenu>
  );
};
