import React from "react";
import { Grid, Radio, Typography } from "@mui/material";
import { ProductionType } from "../../types/types";

const IsExistingProgrammePage: React.FC<{
  productionType: ProductionType | null;
  existingProgramme: boolean | null;
  setExistingProgramme: (existingProgramme: boolean) => void;
}> = ({ productionType, existingProgramme, setExistingProgramme }) => {
  const handleChange = (radioChoice: boolean) => {
    setExistingProgramme(radioChoice);
  };

  return (
    <>
      <Typography>
        Tell us if this series belongs to an existing programme
      </Typography>
      <Grid container my={1}>
        <Grid item xs={1}>
          <Radio
            checked={existingProgramme === true}
            onChange={() => handleChange(true)}
            value="Yes"
            name="yes-radio-button"
            inputProps={{ "aria-label": "Yes" }}
          />
        </Grid>
        <Grid item xs={11} display="flex" alignItems="center">
          <Typography>Yes</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="caption" display="flex" alignItems="flex-start">
            If yes please search and select the programme this {productionType}{" "}
            belongs to.
          </Typography>
        </Grid>
      </Grid>

      <Grid container my={1}>
        <Grid item xs={1}>
          <Radio
            checked={existingProgramme === false}
            onChange={() => handleChange(false)}
            value="No"
            name="no-radio-button"
            inputProps={{ "aria-label": "No" }}
          />
        </Grid>
        <Grid item xs={11} display="flex" alignItems="center">
          <Typography>No</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="caption" display="flex" alignItems="flex-start">
            You will have to create a new programme for this {productionType}.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default IsExistingProgrammePage;
