import React from "react";
import { TextField, Typography } from "@mui/material";

const InputNamePage: React.FC<{
  productionName: string;
  setProductionName: (name: string) => void;
}> = ({ productionName, setProductionName }) => {
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductionName(event.target.value);
  };

  return (
    <>
      <Typography mb={2}>
        Let's start with the name of your new production
      </Typography>
      <TextField
        label="Production name"
        fullWidth
        size="small"
        value={productionName}
        onChange={handleNameChange}
      />
    </>
  );
};

export default InputNamePage;
