import * as Yup from "yup";

export const insuranceSchemaV3 = Yup.object().shape({
  spotId: Yup.string(),
  insuranceCover: Yup.string().required("This is a required field"),
  otherInsuranceCover: Yup.string(),
  talentLoss: Yup.boolean().required("This is a required field"),
  talentLossList: Yup.string().when(
    "$insurance.talentLoss",
    ([talentLoss], schema) => {
      if (talentLoss === true) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  shutdown: Yup.boolean().required("This is a required field"),
  shutdownDetails: Yup.string().when(
    "$insurance.shutdown",
    ([shutdown], schema) => {
      if (shutdown === true) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  age: Yup.boolean().required("This is a required field"),
  holdings: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
});

export type InsuranceSectionV3 = Yup.InferType<typeof insuranceSchemaV3>;
