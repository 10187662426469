/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, SVGProps } from "react";

/** @component */
export const TimePlayIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#22252f"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M16.7143 26.8571L10.1428 31.7143V6L27.5714 16.0612L30.1428 17.7143L27 20" />
    <g clipPath="url(#clip0_3248_415310)">
      <path
        d="M24.1429 34.5718C28.0877 34.5718 31.2857 31.3739 31.2857 27.429C31.2857 23.4841 28.0877 20.2861 24.1429 20.2861C20.198 20.2861 17 23.4841 17 27.429C17 31.3739 20.198 34.5718 24.1429 34.5718Z"
        strokeWidth={2}
      />
      <path d="M24.1428 23.1436V27.4293L27 28.8578" strokeWidth={2} />
    </g>
    <defs>
      <clipPath id="clip0_3248_415310">
        <rect
          width="17.1429"
          height="17.1429"
          transform="translate(15.5714 18.8574)"
        />
      </clipPath>
    </defs>
  </svg>
);
