/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { updateTimecode } from "../../api/browseassets";
import { noop } from "../../utils/appHelper";

export const useUpdateTimecode = (
  titleCcid: string,
  onSuccess = noop,
  onError = noop,
  seriesCcid: string | undefined,
): UseMutationResult =>
  useMutation(
    (payload: any) => updateTimecode(titleCcid, payload, seriesCcid),
    { onSuccess, onError },
  );
