import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { type SustainabilitySection } from "../yupSchemas/sustainabilitySchema";
import { type NotificationFormSubmitValues } from "../NotificationFormV2";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { CheckboxField } from "../formFields/CheckboxField";
import { DropdownField } from "../formFields/DropdownField";
import { YesNoField } from "../formFields/YesNoField";
import { UseUpdateRiskArgsNew } from "../../../hooks";

const calculatorOptions = [
  {
    label: "Albert",
    value: "Albert",
  },
  {
    label: "Ecoprod",
    value: "Ecoprod",
  },
  {
    label: "Green Shooting/MFG",
    value: "GreenShootingMFG",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const hazardOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

const naturalHazardOptions = [
  {
    label: "Floods",
    value: "Floods",
  },
  {
    label: "Excessive rain/snow",
    value: "RainSnow",
  },
  {
    label: "Excessive heat",
    value: "Heat",
  },
  {
    label: "Severe Thunderstorms",
    value: "Thunderstorms",
  },
  {
    label: "Hurricanes or Typhoons",
    value: "HurricanesTyphoons",
  },
  {
    label: "Earthquakes",
    value: "Earthquakes",
  },
  {
    label: "Volcanic Eruptions",
    value: "Volcanic",
  },
  {
    label: "Wildfires",
    value: "Wildfires",
  },
  {
    label: "Tsunamis",
    value: "Tsunami",
  },
  {
    label: "Landslides",
    value: "Landslides",
  },
  {
    label: "Drought / Water shortage",
    value: "Drought",
  },
];

const filmlocOptions = [
  {
    label: "All filming for this production will be done from a studio",
    value: "Studio",
  },
  {
    label:
      "The majority of filming for this production will be done from a studio with some on location shoots",
    value: "MajorityInStudio",
  },
  {
    label:
      "Around 50% of the filming for this production will be from a studio with the other 50% being done on location",
    value: "HalfInLocation",
  },
  {
    label:
      "The majority of filming for this production will be done on location",
    value: "MajorityInLocation",
  },
];

const lightingOptions = [
  {
    label: "All lighting for this production will be low energy lighting",
    value: "AllLowEnergy",
  },
  {
    label:
      "The majority of lighting for this production will be low energy lighting",
    value: "MajorityLowEnergy",
  },
  {
    label:
      "Around 50% of lighting for this production will be low energy lighting",
    value: "HalfLowEnergy",
  },
  {
    label:
      "The majority of light for this department will not be low energy lighting",
    value: "MajorityHighEnergy",
  },
  {
    label: "Not yet known",
    value: "NotYetKnown",
  },
];

const genOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

const flyingOptions = [
  {
    label: "This production will require over 51 flight bookings",
    value: "LargeAmount",
  },
  {
    label: "This production will require between 11 and 50 flight bookings",
    value: "MediumAmount",
  },
  {
    label: "This production will require between 1 and 10 flight bookings",
    value: "SmallAmount",
  },
  {
    label: "This production does not require any flight booking",
    value: "None",
  },
];

const mileageOptions = [
  {
    label: "This production will require over 1000 combined vehicle miles",
    value: "LargeDistance",
  },
  {
    label: "This production will require 500 - 999 combined vehicle miles",
    value: "MediumDistance",
  },
  {
    label: "This production will require 250 - 499 combined vehicle miles",
    value: "ShortDistance",
  },
  {
    label: "This production will require under 250 combined vehicles miles",
    value: "VeryShortDistance",
  },
];

interface SustainabilityPageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function SustainabilityPage({ autosaveForm }: SustainabilityPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValues>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.sustainability || {};
  const errors = formik.errors.sustainability || {};

  const checkboxesChangeHandler = (fieldName: keyof SustainabilitySection) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`sustainability.${fieldName}`).setValue(values);
    };
  };

  const yesNoChangeHandler = (fieldName: keyof SustainabilitySection) => {
    return function createChangeHandler(isYes: boolean) {
      return function handleYesNoChange() {
        formik.getFieldHelpers(`sustainability.${fieldName}`).setValue(isYes);
      };
    };
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">
                Locations (Set builds/Studios/Location etc)
              </Typography>

              <DropdownField
                version="v2"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "calculator",
                }}
                label="Which carbon calculator will be used?"
                error={errors.calculator}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.calculator}
                options={calculatorOptions}
                autosaveForm={autosaveForm}
              />

              {values.calculator === "Other" ? (
                <SingleLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "sustainability",
                    fieldName: "calculatorDetails",
                  }}
                  label="Other carbon calculator:"
                  error={errors.calculatorDetails}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.calculatorDetails}
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <SingleLineTextField
                version="v2"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "country",
                }}
                label="Please indicate all countries that you will be visiting:"
                error={errors.country}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.country}
                placeholder="Enter locations here"
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "sustainability", fieldName: "hazard" }}
                label="Will you be working in an area with a significant risk from extreme weather or natural hazards?"
                error={errors.hazard}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.hazard}
                options={hazardOptions}
                autosaveForm={autosaveForm}
              />

              {values.hazard === "Yes" ? (
                <CheckboxField
                  version="v2"
                  fieldInfo={{
                    sectionId: "sustainability",
                    fieldName: "naturalHazard",
                  }}
                  label="What natural hazard will the production be exposed to?"
                  error={
                    Array.isArray(errors.naturalHazard)
                      ? errors.naturalHazard[0]
                      : errors.naturalHazard
                  }
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={checkboxesChangeHandler("naturalHazard")}
                  isRequired={true}
                  value={values.naturalHazard}
                  options={naturalHazardOptions}
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <YesNoField
                version="v2"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "setbuild",
                }}
                label="Is set construction required for your production?"
                error={errors.setbuild}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("setbuild")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.setbuild}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "filmloc",
                }}
                label="Will you be filming in a studio?"
                error={errors.filmloc}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.filmloc}
                options={filmlocOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "lighting",
                }}
                label="If you have a lighting department on this production, will they be using low energy lighting?"
                error={errors.lighting}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.lighting}
                options={lightingOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "sustainability", fieldName: "gen" }}
                label="Are you using a generator on your production?"
                error={errors.gen}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.gen}
                options={genOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "sustainability", fieldName: "flying" }}
                label="Will anyone be taking flights as part of this production?"
                error={errors.flying}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.flying}
                options={flyingOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "mileage",
                }}
                label="Will your production be covering a significant number of vehicle miles?"
                error={errors.mileage}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.mileage}
                options={mileageOptions}
                autosaveForm={autosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
