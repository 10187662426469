/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { updateCloseDownFormData } from "../../api/storyboard";
import { noop } from "../../utils/appHelper";

export const useUpdateCloseDown = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(
    ({ ccid, formData, type }: any) =>
      updateCloseDownFormData(ccid, formData, type),
    { onSuccess, onError },
  );
