import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { TitleAssets } from "../../types/types";

export const CannotDeleteEpisode = ({
  titleAssets,
}: {
  titleAssets?: TitleAssets;
}): JSX.Element => {
  const [assetsText, setAssetsText] = useState<string>("");

  // set the text of what assets exist dynamically
  useEffect(() => {
    const assetsList = [];
    if (titleAssets) {
      if (titleAssets.hasDerivedTitles) assetsList.push("derrived title");
      if (titleAssets.hasImages) assetsList.push("image");
      if (titleAssets.hasFonts) assetsList.push("font");
      if (titleAssets.hasScripts) assetsList.push("script");
      if (titleAssets.hasVideoAssets) assetsList.push("video");

      const { length } = assetsList;
      if (length === 0) {
        setAssetsText("");
      } else if (length === 1) {
        setAssetsText(`${assetsList[0]}`);
      } else {
        // Combine all elements with commas, except the last two with an "and" between them
        const combinedString = `${assetsList.slice(0, length - 1).join(", ")} and ${assetsList[length - 1]}`;
        setAssetsText(combinedString);
      }
    }
  }, [titleAssets]);

  return (
    <Stack m={2} spacing={2}>
      <Typography>
        We are unable to remove the episode you have selected because
        <Typography display="inline" fontWeight="bold">
          {" "}
          {assetsText}{" "}
        </Typography>
        assets exist for this episode.
      </Typography>
      <Typography>
        If you would like to still remove this episode, please contact the
        Catalogue Operations team at <br />
        <Typography display="inline" fontWeight="bold">
          cim-editorialmetadata-group@itv.com
        </Typography>
      </Typography>
    </Stack>
  );
};
