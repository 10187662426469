import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Stack,
  Box,
  CircularProgress,
  Button,
  Dialog,
} from "@mui/material";
import { IconikUser, SearchedUser } from "../../../../../types/types";
import { useParams } from "react-router-dom";

import { useGetIconikUsers } from "../../../../../hooks/queries/useGetIconikUsers";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import { AddIconikUser } from "./AddIconikUser";
import { useAddUserToIconikGroup } from "../../../../../hooks/mutations/useAddUserToIconikGroup";

const PAGE_SIZE = 15;

const ManagedBy = {
  ICONIK: "Iconik",
  STUDIOS_PORTAL: "Studios Portal",
};

const IconikUserManagement: React.FC = () => {
  const { seriesCcid, titleCcid } = useParams();
  const levelCcid = titleCcid || seriesCcid;

  const {
    data: GetUsersData,
    isLoading: gettingUsers,
    refetch: refetchIconikUsers,
  } = useGetIconikUsers(levelCcid as string);

  const { mutate: addUsers, isLoading: addingUsers } = useAddUserToIconikGroup(
    () => refetchIconikUsers(),
  );

  const [otherUsers, setOtherUsers] = useState<IconikUser[]>([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [studiosUsers, setStudiosUsers] = useState<IconikUser[]>([]);

  const handleAddNewUser = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    if (GetUsersData) {
      const studiosUsers = GetUsersData.studiosPortalUsers.map((user) => ({
        ...user,
        managedBy: ManagedBy.STUDIOS_PORTAL,
      }));
      const otherUsers = GetUsersData.otherUsers.map((user) => ({
        ...user,
        managedBy: ManagedBy.ICONIK,
      }));
      setStudiosUsers(studiosUsers);
      setOtherUsers(otherUsers);
    }
  }, [GetUsersData]);

  const columns: GridColDef[] = [
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "managedBy",
      headerName: "Managed By",
      width: 150,
    },
  ];

  return (
    <Container fixed>
      <Stack direction="column" spacing={3}>
        <Box sx={{ py: 3 }}>
          <Typography variant="h5">Iconik User Management</Typography>
          <Typography>
            View and manage Iconik users, and add new ones.
          </Typography>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddNewUser}
          >
            Add a new user to iconik
          </Button>
        </Stack>

        <Box
          sx={{
            height: "calc(100vh - 370px)",
            width: "100%",
            background: "white",
            position: "relative",
          }}
        >
          {gettingUsers || addingUsers ? (
            <Stack alignItems="center" m={4}>
              <CircularProgress />
            </Stack>
          ) : (
            <DataGrid
              rows={studiosUsers.concat(otherUsers)}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: PAGE_SIZE } },
              }}
              checkboxSelection
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              isRowSelectable={(params: GridRowParams) =>
                params.row.managedBy === ManagedBy.STUDIOS_PORTAL
              }
              getRowClassName={(params: GridRowParams) =>
                params.row.managedBy === ManagedBy.STUDIOS_PORTAL
                  ? ""
                  : "non-studios-user"
              }
              sx={{
                "& .non-studios-user": {
                  backgroundColor: "#f0f0f0",
                  color: "#999",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important",
                },
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                  {
                    marginTop: "1em",
                    marginBottom: "1em",
                  },
              }}
            />
          )}
        </Box>
      </Stack>
      <Dialog fullWidth open={modalOpen} aria-labelledby="add-iconik-user">
        <AddIconikUser
          onAdd={(selectedUser: SearchedUser) => {
            const payload = {
              ccid: levelCcid as string,
              addUserToIconikRequest: {
                user: {
                  email: selectedUser.email,
                  firstName: selectedUser.firstName,
                  lastName: selectedUser.lastName,
                  id: selectedUser.id,
                },
              },
            };
            addUsers(payload);
            setModalOpen(false);
          }}
          onCancel={() => {
            setModalOpen(false);
          }}
        />
      </Dialog>
    </Container>
  );
};

export default IconikUserManagement;
