/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, SVGProps } from "react";

/** @component */
export const LogoIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 10H32V34H8V22"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 16V5M8 5L4 9M8 5L12 9"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5245 15.4635C19.6741 15.0029 20.3259 15.0029 20.4755 15.4635L21.6839 19.1824C21.7508 19.3884 21.9428 19.5279 22.1594 19.5279H26.0696C26.554 19.5279 26.7554 20.1477 26.3635 20.4324L23.2001 22.7307C23.0248 22.8581 22.9515 23.0838 23.0184 23.2898L24.2268 27.0086C24.3764 27.4693 23.8492 27.8523 23.4573 27.5676L20.2939 25.2693C20.1186 25.1419 19.8814 25.1419 19.7061 25.2693L16.5427 27.5676C16.1508 27.8523 15.6236 27.4693 15.7732 27.0086L16.9816 23.2898C17.0485 23.0838 16.9752 22.8581 16.7999 22.7307L13.6365 20.4324C13.2446 20.1477 13.446 19.5279 13.9304 19.5279H17.8406C18.0572 19.5279 18.2492 19.3884 18.3161 19.1824L19.5245 15.4635Z"
      stroke="#22252f"
      strokeWidth="2"
    />
  </svg>
);
