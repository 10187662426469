/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getTaskGroupsData } from "../../api/portal";
import { TaskGroupAssignmentResponse } from "../../types/types";

export const useGetTaskGroupsData = (
  ccid: string,
): UseQueryResult<TaskGroupAssignmentResponse, AxiosError> =>
  useQuery(["tasksGroupData", ccid], () => getTaskGroupsData(ccid as string), {
    enabled: Boolean(ccid),
  });
