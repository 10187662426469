/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, FC, useMemo } from "react";

import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";

import { NotificationService } from "../../services/notificationService";

import {
  ModalInterface,
  ModalService,
  UPDATE_MODAL,
} from "../../services/modalService";

export interface ModalWrapperProps {
  msgPrefix?: string;
}

export const ModalWrapper: FC<ModalWrapperProps> = ({ msgPrefix = "main" }) => {
  const [modalConfig, setModalConfig] = useState<ModalInterface | undefined>();

  useEffect(() => {
    const updateModalConfig = (newConfig: ModalInterface) => {
      setModalConfig(newConfig);
    };

    NotificationService.sub(`${msgPrefix}${UPDATE_MODAL}`, updateModalConfig);

    return () => {
      NotificationService.remove(
        `${msgPrefix}${UPDATE_MODAL}`,
        updateModalConfig,
      );
    };
  }, [msgPrefix]);

  const {
    show,
    title,
    component,
    showFooter,
    cancelLabel,
    confirmLabel,
    cancelCallback = () => {},
    confirmationCallback = () => {},
    width,
    description,
    clickOutsideToClose,
    showHeader,
    footerJustifyContent,
    contentPadding,
    headerBlack,
  } = modalConfig ?? {};

  const instance = ModalService.getInstance(msgPrefix);
  const dialogTitleStyle = useMemo(() => {
    const basicStyle: any = { width: width || "auto" };

    if (headerBlack) {
      basicStyle.background = "#22252F";
      basicStyle.color = "white";
    }

    return basicStyle;
  }, [width, headerBlack]);

  if (!modalConfig) {
    return <></>;
  }

  return (
    <Dialog
      open={Boolean(show)}
      onClose={() => clickOutsideToClose && instance.hideModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      {showHeader && (
        <DialogTitle id="alert-dialog-title" sx={dialogTitleStyle}>
          {title}
          {description && <Typography>{description}</Typography>}
        </DialogTitle>
      )}
      <DialogContent
        sx={{ width: width || "auto", padding: contentPadding || undefined }}
      >
        <DialogContentText id="alert-dialog-description">
          {component}
        </DialogContentText>
      </DialogContent>
      {showFooter && (
        <DialogActions>
          <Box justifyContent={footerJustifyContent}>
            <Button
              variant="outlined"
              onClick={() => {
                instance.hideModal();
                cancelCallback();
              }}
            >
              {cancelLabel}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                instance.hideModal();
                confirmationCallback();
              }}
            >
              {confirmLabel}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};
