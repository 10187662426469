import { Typography, Stack, Card, CardContent, Alert } from "@mui/material";
import { useFormikContext } from "formik";
import { type ComplianceSection } from "../yupSchemas/complianceSchema";
import { type NotificationFormSubmitValues } from "../NotificationFormV2";
import { type DropdownMetadata } from "../../../types/types";
import { MultiSelectField } from "../formFields/MultiSelectField";
import { YesNoField } from "../formFields/YesNoField";
import { UseUpdateRiskArgsNew } from "../../../hooks";

interface CompliancePageProps {
  dropdownMetadata: DropdownMetadata;
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function CompliancePage({
  dropdownMetadata,
  autosaveForm,
}: CompliancePageProps) {
  const formik = useFormikContext<NotificationFormSubmitValues>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.compliance || {};
  const errors = formik.errors.compliance || {};

  const multiSelectChangeHandler = (fieldName: keyof ComplianceSection) => {
    return function handleSelectChange(values: Array<string>) {
      formik.getFieldHelpers(`compliance.${fieldName}`).setValue(values);
    };
  };

  const yesNoChangeHandler = (fieldName: keyof ComplianceSection) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`compliance.${fieldName}`).setValue(isYes);
    };
  };

  if (!formik.values.basicInfo.outsideLocation) {
    return (
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Compliance</Typography>

              <Alert severity="info">
                You only need to fill in this section if any production is
                taking place outside of your base location
              </Alert>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Compliance</Typography>

              <MultiSelectField
                version="v2"
                fieldInfo={{ sectionId: "compliance", fieldName: "countries" }}
                label="Which countries will your production be operating in?"
                error={errors.countries}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={multiSelectChangeHandler("countries")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.countries}
                options={dropdownMetadata.countries}
                autosaveForm={autosaveForm}
              />

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "compliance", fieldName: "slavery" }}
                label="Is there a modern slavery risk associated with operating in this country? Modern Slavery Risk is considered to be any country with a score of over 30 for Vulnerability to Modern Slavery on the Global Slavery Index, which can be found here: https://www.globalslaveryindex.org/2018/data/maps/#prevalence"
                error={errors.slavery}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("slavery")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.slavery}
                autosaveForm={autosaveForm}
              />

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "compliance", fieldName: "corruption" }}
                label="Is there a corruption risk associated with operating in this country? Corruption Risk = any country with a CPI score of under 50 on Transparency International's Corruption Perceptions Index https://www.transparency.org/en/cpi/2021/"
                error={errors.corruption}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("corruption")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.corruption}
                autosaveForm={autosaveForm}
              />

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "compliance", fieldName: "sanctions" }}
                label="Is there a sanctions risk associated with operating in this country? Sanctions risk = Any country that appears on the financial sanctions imposed in the UK by country. https://www.gov.uk/government/collections/financial-sanctions-regime-specific-consolidated-lists-and-releases"
                error={errors.sanctions}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("sanctions")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.sanctions}
                autosaveForm={autosaveForm}
              />

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "compliance", fieldName: "tax" }}
                label="Has local tax advice or advice from group tax been sought to date?"
                error={errors.tax}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("tax")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.tax}
                autosaveForm={autosaveForm}
              />

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "compliance", fieldName: "vat" }}
                label="Have you established whether VAT incurred in the overseas territories can be recovered?"
                error={errors.vat}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("vat")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.vat}
                autosaveForm={autosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
