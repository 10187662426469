import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Checkbox,
  CheckboxProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useSeries, useTitles } from "../../hooks";
import { useTriggerBulkImagesDownload } from "../../hooks/mutations/useTriggerBulkImagesDownload";
import { TitleDetailsProps } from "../../types/types";

interface DownloadAssetsOptionsProps {
  seriesNumber: number | null;
  seriesCcid: string;
  episodes: Array<TitleDetailsProps>;
  onClose: () => void;
}

const DownloadAssetsOptions: FC<DownloadAssetsOptionsProps> = ({
  seriesNumber,
  seriesCcid,
  episodes,
  onClose,
}) => {
  const {
    mutate: triggerBulkImagesDownload,
    isLoading: isTriggering,
    isError,
  } = useTriggerBulkImagesDownload();

  const [selectedEpisodes, setSelectedEpisodes] = useState(() =>
    episodes.map((episode) => ({
      ccid: episode.ccid,
      episodeNumber: episode.episodeNumber,
    })),
  );

  const [selectedImageTypes, setSelectedImageTypes] = useState({
    logo: true,
    keyArtImage: true,
    unitStills: true,
    keyArtContributorsSetUp: true,
    keyArtContributorsOnSetGallery: true,
    behindTheScenes: true,
  });

  // at least one must be selected
  const imageTypesError =
    Object.values(selectedImageTypes).filter((checked) => checked).length < 1;

  const handleImageTypeChange: CheckboxProps["onChange"] = (
    event,
    isChecked,
  ) => {
    setSelectedImageTypes((prev) => ({
      ...prev,
      [event.target.name]: isChecked,
    }));
  };

  const handleEpisodeChange: CheckboxProps["onChange"] = (event, isChecked) => {
    const episodeCcid = event.target.value;
    const episodeNumber = Number(event.target.dataset.episodeNumber);

    if (isChecked) {
      setSelectedEpisodes((prev) => [
        ...prev,
        { ccid: episodeCcid, episodeNumber },
      ]);
    } else {
      setSelectedEpisodes((prev) =>
        prev.filter(({ ccid }) => ccid !== episodeCcid),
      );
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const tags = Object.entries(selectedImageTypes)
      .filter(([, isSelected]) => isSelected)
      .map(([tag]) => tag);

    triggerBulkImagesDownload(
      {
        seriesCcid,
        imageTags: tags,
        titles: selectedEpisodes.map(({ ccid, episodeNumber }) => ({
          titleCcid: ccid,
          numberInGroup: episodeNumber,
        })),
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            "Your file is being downloaded. This may take a while",
            { variant: "success" },
          );
          onClose();
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ paddingTop: 0 }}>
        {isError && (
          <Alert severity="error" sx={{ marginBottom: "16px" }}>
            Something went wrong while attempting to initiate the bulk download.
            Please try again or contact us if the issue persists
          </Alert>
        )}
        <Typography>
          Here you can download images for a series in bulk. You can filter out
          certain image types, as well as configure which episodes you'd like to
          include. Please note that for lots of large images, this process can
          take a long time
        </Typography>
        <FormControl
          sx={{ m: 3 }}
          component="fieldset"
          variant="standard"
          required
          error={imageTypesError}
        >
          <FormLabel component="legend">Type</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="logo"
                  checked={selectedImageTypes.logo}
                  onChange={handleImageTypeChange}
                />
              }
              label="Logos"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="keyArtImage"
                  checked={selectedImageTypes.keyArtImage}
                  onChange={handleImageTypeChange}
                />
              }
              label="Series images"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="keyArtContributorsSetUp"
                  checked={selectedImageTypes.keyArtContributorsSetUp}
                  onChange={handleImageTypeChange}
                />
              }
              label="Presenters / Contributors images"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="keyArtContributorsOnSetGallery"
                  checked={selectedImageTypes.keyArtContributorsOnSetGallery}
                  onChange={handleImageTypeChange}
                />
              }
              label="Gallery images"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="behindTheScenes"
                  checked={selectedImageTypes.behindTheScenes}
                  onChange={handleImageTypeChange}
                />
              }
              label="Behind the scenes images"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="unitStills"
                  checked={selectedImageTypes.unitStills}
                  onChange={handleImageTypeChange}
                />
              }
              label="Unit stills"
            />
          </FormGroup>
        </FormControl>

        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">
            {seriesNumber != null ? `Series ${seriesNumber}` : "Series"}
          </FormLabel>
          <FormGroup>
            {episodes.map((episode, index) => (
              <FormControlLabel
                key={episode.ccid}
                value={episode.ccid}
                control={
                  <Checkbox
                    inputProps={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore-next-line
                      "data-episode-number": index + 1,
                    }}
                    name={episode.ccid}
                    onChange={handleEpisodeChange}
                    checked={selectedEpisodes.some(
                      ({ ccid }) => ccid === episode.ccid,
                    )}
                  />
                }
                label={`Episode ${episode.episodeNumber}`}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          autoFocus
          onClick={onClose}
          type="button"
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          loading={isTriggering}
          loadingPosition="end"
          endIcon={<Download />}
          disabled={imageTypesError}
        >
          Download
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

interface DownloadAssetsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  programmeCcid: string;
  seriesCcid: string;
}

export const DownloadAssetsDialog: FC<DownloadAssetsDialogProps> = ({
  isOpen,
  onClose,
  seriesCcid,
  programmeCcid,
}) => {
  const titleId = "download-assets-dialog-title";

  const { data: episodes, isLoading: episodesLoading } = useTitles(
    seriesCcid,
    "series",
  );

  const { data: seriesData, isLoading: seriesLoading } = useSeries(
    programmeCcid,
    false,
  );

  const sortedEpisodes = [...(episodes?.titleDetails || [])].sort(
    (a, b) => a.episodeNumber - b.episodeNumber,
  );

  const currentSeries = seriesData?.seriesDetails.find(
    (series) => series.ccid === seriesCcid,
  );

  const isLoading = episodesLoading || seriesLoading;

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby={titleId}>
      <DialogTitle id={titleId}>Download images</DialogTitle>

      {isLoading ? (
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <DownloadAssetsOptions
          episodes={sortedEpisodes}
          seriesCcid={seriesCcid}
          seriesNumber={currentSeries?.seriesNumber ?? null}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
};
