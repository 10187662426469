/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorHandler: FC<{ error: any; resetErrorBoundary: () => void }> = ({
  error,
  resetErrorBoundary,
}) => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  let errorMessage;

  if (error?.response?.status === 401)
    oktaAuth.signInWithRedirect({ originalUri: window.location.href });
  else
    errorMessage = (
      <Container
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: 20,
        }}
      >
        <Typography sx={{ mt: 2 }} variant="h6">
          Something went wrong!
        </Typography>
        <Typography sx={{ mt: 2 }} variant="body1">
          Please return home and try again. If you still have issues, please
          contact the Studios Portal team.
        </Typography>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          onClick={() => {
            navigate("/");
            resetErrorBoundary();
          }}
        >
          Return Home
        </Button>
      </Container>
    );

  return <>{errorMessage}</>;
};

export default ErrorHandler;
