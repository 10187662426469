import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";

import Video, { BrowseAssets, VideoMetaDataPayload } from "../../../Video";

import {
  VideoAssetsResponsePayload,
  VideoMetadataResponsePayload,
} from "../../../../../types/types";

import {
  getVideoAssets,
  getSelectedVideoUrl,
  getTitleVersions,
} from "../../../../../api/browseassets";

import { PreviewTimeCodeProps, PreviewTimeCode } from "../PreviewTimeCode";

export const TitleVideos: FC<PreviewTimeCodeProps> = ({ tag }) => {
  const { titleCcid, seriesCcid } = useParams<string>();

  const [selectedVideoVersionCcid, setselectedVideoVersionCcid] =
    useState<string>("");

  const {
    data: videoMetadata,
    isLoading: isLoadingVideoMetaData,
    error: videoMetaDataError,
  } = useQuery<VideoMetadataResponsePayload>(
    ["getVideoVersions", titleCcid],
    () => getTitleVersions(titleCcid as string),
    { enabled: Boolean(titleCcid) },
  );

  const { data: videoAssets, isLoading: isLoadingBrowserAssets } =
    useQuery<VideoAssetsResponsePayload>(
      ["getBrowseAssets", titleCcid],
      () => getVideoAssets(titleCcid as string),
      { enabled: Boolean(titleCcid) },
    );

  const { data: selectedVideoUrl } = useQuery<string>(
    ["getPresignedUrl", selectedVideoVersionCcid],
    () =>
      selectedVideoVersionCcid && getSelectedVideoUrl(selectedVideoVersionCcid),
  );

  const handleFetchVideoUrl = (videoTitleCcid: string) =>
    setselectedVideoVersionCcid(videoTitleCcid);

  if (isLoadingBrowserAssets || isLoadingVideoMetaData) {
    return (
      <Stack>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15vh",
          }}
        >
          {" "}
          <CircularProgress />
        </div>
      </Stack>
    );
  }

  if (
    (!videoAssets || videoAssets.assets.length < 1) &&
    (!videoMetadata || videoMetadata.versions.length < 1)
  ) {
    return (
      <Container>
        <Stack flexDirection="column" style={{ marginTop: "12px" }}>
          There are no TX Master assets available for this selection.
        </Stack>
      </Container>
    );
  }
  if (!seriesCcid || seriesCcid === "0") {
    return <PreviewTimeCode tag={tag} />;
  }
  return (
    <>
      <Container sx={{ py: 2 }}>
        {videoMetadata && videoMetadata.versions.length > 0 && (
          <Stack flexDirection="column">
            <Typography variant="h5">View Final TX Masters</Typography>
            <Box display="flex">
              {videoMetadata.versions.map((video: VideoMetaDataPayload) => (
                <Video
                  url={
                    selectedVideoVersionCcid === video.versionCcid
                      ? selectedVideoUrl
                      : undefined
                  }
                  key={video.versionCcid}
                  videoMetaData={{
                    productionId: video.productionId,
                    versionCcid: video.versionCcid,
                    linear: video.linear,
                    editReasons: video.editReasons,
                    editReasonComments: video.editReasonComments,
                  }}
                  handleFetchVideoUrl={handleFetchVideoUrl}
                  hasVideo={videoAssets?.assets.some(
                    (el) => el.versionCcid === video.versionCcid,
                  )}
                />
              ))}
            </Box>
          </Stack>
        )}
        {(videoMetaDataError || !videoMetadata?.versions.length) &&
          videoAssets &&
          videoAssets.assets.length > 0 && (
            <Stack flexDirection="column">
              <Typography variant="h5">View Final TX Masters</Typography>
              <Box display="flex">
                {videoAssets.assets.map((video: BrowseAssets) => (
                  <Video
                    url={
                      selectedVideoVersionCcid === video.versionCcid
                        ? selectedVideoUrl
                        : undefined
                    }
                    key={video.versionCcid}
                    handleFetchVideoUrl={handleFetchVideoUrl}
                    browseAssets={{
                      productionId: video.productionId,
                      versionCcid: video.versionCcid,
                    }}
                    hasVideo
                  />
                ))}
              </Box>
            </Stack>
          )}
      </Container>
      {!seriesCcid && <PreviewTimeCode tag={tag} />}
    </>
  );
};
