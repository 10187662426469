import React from "react";
import { Container, Box } from "@mui/material";

export const ErrorMessage: React.FC<{ message: string | undefined }> = ({
  message,
}) => (
  <Container>
    <Box sx={{ py: "1rem" }}>Some error occured: {message}</Box>
  </Container>
);
