/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Button,
  Box,
  CircularProgress,
  Paper,
  Container,
} from "@mui/material";
import {
  WarningAmber,
  Add,
  FolderOpenOutlined,
  CreateNewFolder,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { TaskItem } from "../TaskItem";
import { useCreateDrive, useListDrives } from "../../../../../hooks";
import { DriveResponse } from "../../../../../types/types";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";

export interface Drive {
  label: string;
  id: string;
}

export interface Level {
  level: string;
  levelCcid: string | undefined;
}

const getLevel = (
  programmeCcidArg: string,
  seriesCcidArg: string,
  titleCcidArg: string,
) => {
  if (programmeCcidArg && !seriesCcidArg && !titleCcidArg)
    return {
      level: "brands",
      levelCcid: programmeCcidArg,
    };
  if (programmeCcidArg && seriesCcidArg && !titleCcidArg)
    return {
      level: "series",
      levelCcid: seriesCcidArg,
    };
  return {
    level: "titles",
    levelCcid: titleCcidArg,
  };
};

export enum DriveIds {
  Confidential = "Confidential",
  NewConfidential = "NewConfidential",
  Common = "Common",
  NewCommon = "NewCommon",
}

export interface CreateNewDriveProps {
  onBack: () => void;
  selectedDriveId: DriveIds;
  seriesCcid?: string;
  titleCcid?: string;
  programmeCcid?: string;
}

export const CreateNewDrive: FC<CreateNewDriveProps> = ({
  onBack,
  selectedDriveId,
  seriesCcid,
  titleCcid,
  programmeCcid,
}) => {
  const navigate = useNavigate();
  const { level, levelCcid } = getLevel(
    programmeCcid || "",
    seriesCcid || "",
    titleCcid || "",
  ) as Level;
  const { mutate: createDrive, isLoading } = useCreateDrive(
    (driveResponse: DriveResponse) => {
      window.open(driveResponse.url, "_blank");
      navigate("../");
    },
    () => {
      enqueueSnackbar("Failed to create confidential", {
        variant: "error",
        action: SnackbarDismiss,
        persist: true,
      });
    },
  );
  const handleButtonClick = useCallback(() => {
    createDrive({
      ccid: levelCcid,
      level,
      driveType:
        selectedDriveId === DriveIds.NewConfidential
          ? "confidential"
          : "production",
    });
  }, [createDrive, selectedDriveId, level, levelCcid]);

  return (
    <Container sx={{ py: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h6">
            Are you sure you want to create a Shared Drive for this production?{" "}
          </Typography>
          <Box>
            <WarningAmber
              color="error"
              sx={{ position: "relative", marginRight: "6px" }}
            />
            By creating this Shared Drive, you'll be the Manager and overall
            'owner' of the Drive, controlling access permissions and the ability
            to permanently delete files. If you're unsure about whether this
            should be you, speak to your Production Manager or Executive.
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            marginTop="1rem"
          >
            <Button variant="text" onClick={onBack}>
              Back
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={handleButtonClick}
              loading={isLoading}
            >
              Create a new drive
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
};

export const ListDrive: FC<{
  onItemClick: (item: any) => void;
  seriesCcid?: string;
  titleCcid?: string;
  programmeCcid?: string;
}> = ({ onItemClick, seriesCcid, titleCcid, programmeCcid }) => {
  const [hasConfidential, setHasConfidential] = useState<boolean>(false);
  const [hasCommon, setHasCommon] = useState<boolean>(false);
  const navigate = useNavigate();
  const { level, levelCcid } = getLevel(
    programmeCcid || "",
    seriesCcid || "",
    titleCcid || "",
  ) as Level;

  const { data: items, isLoading } = useListDrives(levelCcid || "", level);

  useEffect(() => {
    if (items) {
      if (items.privateUrl) setHasConfidential(true);
      if (items.productionUrl) setHasCommon(true);
    }
  }, [items]);

  const handleSelect = useCallback(
    (id: any) => {
      onItemClick(id);
    },
    [onItemClick],
  );

  return (
    <Container sx={{ py: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h6">
            Select the Google Shared Drive you need
          </Typography>

          <Stack direction="row" flexWrap="wrap">
            {isLoading ? (
              <CircularProgress />
            ) : hasCommon ? (
              <TaskItem
                icon={<FolderOpenOutlined fontSize="large" color="primary" />}
                description="Production Google Shared Drive"
                id={DriveIds.Common}
                onItemSelect={() => {
                  window.open(items?.productionUrl, "_blank");
                }}
                taskStatusUrl=""
              />
            ) : (
              <TaskItem
                icon={<CreateNewFolder fontSize="large" color="primary" />}
                description="Create a new Production Shared Drive"
                id={DriveIds.NewCommon}
                onItemSelect={() => handleSelect(DriveIds.NewCommon)}
                taskStatusUrl=""
              />
            )}
            {isLoading ? (
              <CircularProgress />
            ) : hasConfidential ? (
              <TaskItem
                icon={<FolderOpenOutlined fontSize="large" color="primary" />}
                description="Confidential Google Shared Drive"
                id={DriveIds.Confidential}
                onItemSelect={() => {
                  window.open(items?.privateUrl, "_blank");
                }}
                taskStatusUrl=""
              />
            ) : (
              <TaskItem
                icon={<CreateNewFolder fontSize="large" color="primary" />}
                description="Create a new Confidential Shared Drive"
                id={DriveIds.NewConfidential}
                onItemSelect={() => handleSelect(DriveIds.NewConfidential)}
                taskStatusUrl=""
              />
            )}
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("../")}
            >
              Return to tasks
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
};

export const GoogleDrive: FC = () => {
  const { seriesCcid, titleCcid, programmeCcid } = useParams();

  const [showCreateNewDrive, setShowCreateNewDrive] = useState<boolean>(false);
  const [selectedDriveId, setSelectedDriveId] = useState<DriveIds>();
  const handleItemClick = useCallback((driveId: DriveIds) => {
    if ([DriveIds.NewConfidential, DriveIds.NewCommon].includes(driveId)) {
      setShowCreateNewDrive(true);
      setSelectedDriveId(driveId);
    }
  }, []);

  const handleOnBack = useCallback(() => {
    setShowCreateNewDrive(false);
  }, []);

  if (showCreateNewDrive) {
    return (
      <CreateNewDrive
        onBack={handleOnBack}
        selectedDriveId={selectedDriveId as DriveIds}
        seriesCcid={seriesCcid}
        titleCcid={titleCcid}
        programmeCcid={programmeCcid}
      />
    );
  }
  return (
    <ListDrive
      onItemClick={handleItemClick}
      titleCcid={titleCcid}
      seriesCcid={seriesCcid}
      programmeCcid={programmeCcid}
    />
  );
};
