import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { ProductionType } from "../../types/types";

const GetInTouchPage: React.FC<{
  productionType: ProductionType | null;
  nameOfUser: string;
  setNameOfUser: (name: string) => void;
  contactUsMessage: string;
  setContactUsMessage: (message: string) => void;
}> = ({
  productionType,
  nameOfUser,
  setNameOfUser,
  contactUsMessage,
  setContactUsMessage,
}) => {
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameOfUser(event.target.value);
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactUsMessage(event.target.value);
  };

  return (
    <>
      <Typography variant="caption">Subject:</Typography>
      <Typography>
        I can't seem to find the{" "}
        {productionType === "oneOff" ? "one off" : productionType} I'm looking
        for.
      </Typography>
      <Box my={2}>
        <TextField
          label="Enter your name"
          value={nameOfUser}
          onChange={handleNameChange}
        />
      </Box>
      <Box my={2}>
        <TextField
          label="Tell us more about this issue"
          multiline
          rows={4}
          fullWidth
          value={contactUsMessage}
          onChange={handleMessageChange}
        />
      </Box>
    </>
  );
};

export default GetInTouchPage;
