import { useQuery, UseQueryResult } from "react-query";
import { getFilter } from "../../api/contributors";
import { ContributorsFilterProps } from "../../types/types";

export const useContributorsFilter = (
  seriesCcid: string | undefined,
): UseQueryResult<ContributorsFilterProps> =>
  useQuery(["getFilter", seriesCcid], () => getFilter(seriesCcid as string), {
    enabled: Boolean(seriesCcid),
  });
