/* eslint-disable @typescript-eslint/no-empty-function */
import { FC } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { Add } from "@mui/icons-material";

interface AddNewItemProps {
  primaryText?: string;
  secondaryText?: string;
  onAdd?(): void;
}

export const AddNewItem: FC<AddNewItemProps> = ({
  primaryText = "Don't see the programme you are looking for?",
  secondaryText = "Add a new production here",
  onAdd = () => {},
}) => (
  <List
    sx={{
      bgcolor: "#FAFAFA",
      borderTopRightRadius: "0.5rem",
      borderTopLeftRadius: "0.5rem",
      cursor: "pointer",
    }}
    onClick={onAdd}
  >
    <ListItem>
      <ListItemText
        primary={
          <Typography component="div" variant="body1" color="primary">
            {" "}
            {primaryText}
          </Typography>
        }
        secondary={
          <Typography
            component="span"
            variant="body2"
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {secondaryText}
          </Typography>
        }
      />
      <ListItemIcon>
        <Add />
      </ListItemIcon>
    </ListItem>
  </List>
);
