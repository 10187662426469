import { FC } from "react";
import { Box, Card, List, ListItem, Typography, useTheme } from "@mui/material";

const PromptCard: FC = () => {
  const theme = useTheme();
  return (
    <Card sx={{ width: "75%", backgroundColor: theme.palette.athensGray }}>
      <Box sx={{ px: 2, py: 1 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Ask me anything about production!
        </Typography>
        <Typography variant="body1">Here are some ideas:</Typography>
        <List sx={{ listStyleType: "disc", pl: 2, py: 0 }}>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            <Typography variant="body1">
              What do I need to know about using drones abroad?
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            <Typography variant="body1">
              How many hours can a child actor be on set?
            </Typography>
          </ListItem>
        </List>
      </Box>
    </Card>
  );
};

export default PromptCard;
