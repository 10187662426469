import { useQuery, UseQueryResult } from "react-query";
import { getAllDrives } from "../../api/googledrive";
import { ListDriveResponse } from "../../types/types";

export const useListDrives = (
  ccid: string,
  level: string,
): UseQueryResult<ListDriveResponse> =>
  useQuery(["listDrives", ccid, level], () => getAllDrives(ccid, level), {
    enabled: Boolean(ccid),
  });
