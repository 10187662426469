import {
  Typography,
  Stack,
  Card,
  CardContent,
  Box,
  InputAdornment,
} from "@mui/material";
import { useFormikContext } from "formik";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { MailOutline, PersonOutline } from "@mui/icons-material";
import { EmailField } from "../formFields/EmailField";
import { UseUpdateRiskArgsNew } from "../../../hooks";
import { NotificationFormSubmitValuesV3 } from "../NotificationFormV3";

const yesNoTBCOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

interface ParticipantWelfarePageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function ParticipantWelfarePageV3({
  autosaveForm,
}: ParticipantWelfarePageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.participantWelfare || {};
  const errors = formik.errors.participantWelfare || {};

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      participantWelfare: {
        ...formData.participantWelfare,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Participant Welfare</Typography>
              <Stack spacing={2}>
                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "participantWelfare",
                    fieldName: "vulnerability",
                  }}
                  label="Do any of the cast or programme participants meet the OFCOM criteria for vulnerability?"
                  error={errors.vulnerability}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.vulnerability}
                  options={yesNoTBCOptions}
                  autosaveForm={wrappedAutosaveForm}
                />

                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "participantWelfare",
                    fieldName: "participation",
                  }}
                  label="Will any cast or participants experience heightened press, media, or social media attention beyond their usual exposure because of their involvement in the production?"
                  error={errors.participation}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.participation}
                  options={yesNoTBCOptions}
                  autosaveForm={wrappedAutosaveForm}
                />

                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "participantWelfare",
                    fieldName: "conditions",
                  }}
                  label="Will cast or participants be required to live in conditions different from their usual environment, including but not limited to, sustained periods away from home and their support networks?"
                  error={errors.conditions}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.conditions}
                  options={yesNoTBCOptions}
                  autosaveForm={wrappedAutosaveForm}
                />

                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "participantWelfare",
                    fieldName: "environment",
                  }}
                  label="Does the production use staged, constructed, or simulated environments that could affect the mental well-being of cast or program participants?"
                  error={errors.environment}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.environment}
                  options={yesNoTBCOptions}
                  autosaveForm={wrappedAutosaveForm}
                />

                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "participantWelfare",
                    fieldName: "exposure",
                  }}
                  label="Are there expected confrontations, conflicts, or emotionally challenging situations that cast or program participants may encounter during the production?"
                  error={errors.exposure}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.exposure}
                  options={yesNoTBCOptions}
                  autosaveForm={wrappedAutosaveForm}
                />

                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "participantWelfare",
                    fieldName: "sensitiveInformation",
                  }}
                  label="Will cast or programme participants be encouraged or required to share sensitive, private, or life-changing aspects of their lives?"
                  error={errors.sensitiveInformation}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.sensitiveInformation}
                  options={yesNoTBCOptions}
                  autosaveForm={wrappedAutosaveForm}
                />

                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "participantWelfare",
                    fieldName: "mentalHealthAdvisorEngaged",
                  }}
                  label="Will you engage a mental health advisor for this production?"
                  error={errors.mentalHealthAdvisorEngaged}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.mentalHealthAdvisorEngaged}
                  options={yesNoTBCOptions}
                  autosaveForm={wrappedAutosaveForm}
                />

                {values.mentalHealthAdvisorEngaged === "Yes" && (
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Box flex={1}>
                      <SingleLineTextField
                        version="v3"
                        fieldInfo={{
                          sectionId: "participantWelfare",
                          fieldName: "mentalHealthAdvisor",
                        }}
                        label="Mental Health Advisor name"
                        error={errors.mentalHealthAdvisor}
                        shouldDisplayErrors={shouldDisplayErrors}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        isRequired={false}
                        value={values.mentalHealthAdvisor}
                        placeholder="Forename Surname"
                        startAdornment={
                          <InputAdornment position="start">
                            <PersonOutline />
                          </InputAdornment>
                        }
                        autosaveForm={wrappedAutosaveForm}
                      />
                    </Box>

                    <Box flex={1}>
                      <EmailField
                        version="v3"
                        fieldInfo={{
                          sectionId: "participantWelfare",
                          fieldName: "mentalHealthAdvisorEmail",
                        }}
                        label="Mental Health Advisor email"
                        error={errors.mentalHealthAdvisorEmail}
                        shouldDisplayErrors={shouldDisplayErrors}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        isRequired={false}
                        value={values.mentalHealthAdvisorEmail}
                        placeholder="example@itv.com"
                        startAdornment={
                          <InputAdornment position="start">
                            <MailOutline />
                          </InputAdornment>
                        }
                        autosaveForm={wrappedAutosaveForm}
                      />
                    </Box>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
