import { useParams } from "react-router-dom";
import { Container, Skeleton, Stack, Typography } from "@mui/material";
import { useTitleTasks } from "../../hooks";
import TasksSection from "../TasksSection";

const SpecialTasksPage = (): JSX.Element => {
  const { titleCcid } = useParams();
  const { data, isLoading } = useTitleTasks(titleCcid);

  if (data?.tasks === undefined && !isLoading)
    return <Typography>There are no tasks to complete</Typography>;
  return (
    <Container sx={{ py: 4 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Special Title Tasks</Typography>
        {isLoading || data?.tasks === undefined ? (
          <Skeleton height={298} variant="rounded" animation="wave" />
        ) : (
          <TasksSection tasks={data.tasks} productionStage="preProduction" />
        )}
        {isLoading || data?.tasks === undefined ? (
          <Skeleton height={298} variant="rounded" animation="wave" />
        ) : (
          <TasksSection tasks={data.tasks} productionStage="inProduction" />
        )}
        {isLoading || data?.tasks === undefined ? (
          <Skeleton height={298} variant="rounded" animation="wave" />
        ) : (
          <TasksSection tasks={data.tasks} productionStage="postProduction" />
        )}
      </Stack>
    </Container>
  );
};

export default SpecialTasksPage;
