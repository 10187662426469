import { useMutation } from "react-query";
import { patchSynopsis } from "../../api/synopsis";
import { noop } from "../../utils/appHelper";

interface UpdateSynopsisArgs {
  synopsisShort: string;
  synopsisMedium: string;
  synopsisLong: string;
  narrative?: string;
}

export const useUpdateSynopsis = (
  level: string,
  activeCcid: string,
  onSuccess = noop,
  onError = noop,
) =>
  useMutation(
    (synopsisValues: UpdateSynopsisArgs) =>
      patchSynopsis(level, activeCcid, synopsisValues),
    {
      onError,
      onSuccess,
    },
  );
