/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAPI, SERVICES } from "../lib/getApi";
import getEnvironment from "../lib/getEnviroment";
import handleRequest from "../lib/handleRequest";

const API_BASE = getAPI(SERVICES.slack);

export const postError = (
  msg: string,
  userEmail: string | undefined = "No email found",
): any => {
  const env = getEnvironment();

  if (env === "test") {
    return "";
  }

  let messages = [
    `error: ${msg}`,
    `url: ${window.location.href}`,
    `env: ${env}`,
    `user email: ${userEmail}`,
  ];

  if (env === "production") {
    messages = [`@studios-portal-devs`, ...messages];
  }

  const payload = {
    text: messages.join("\n"),
    link_names: 1,
  };

  return handleRequest
    .post(API_BASE, `payload=${JSON.stringify(payload)}`, {
      headers: {
        accept: "*/*",
        "Content-type": "application/x-www-form-urlencoded",
      },
    })
    .then(({ data }) => data);
};
