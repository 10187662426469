import { useQuery, UseQueryResult } from "react-query";
import { getTitleTasks } from "../../api/portal";
import { TasksResponsePayload } from "../../types/types";

export const useTitleTasks = (
  titleCcid: string | undefined,
): UseQueryResult<TasksResponsePayload> =>
  useQuery(["title tasks", titleCcid], () => getTitleTasks(titleCcid), {
    enabled: Boolean(titleCcid),
  });
