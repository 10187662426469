/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getRiskFormDataNew } from "../../api/spotlight";
import {
  ProductionNotificationFormV3,
  type ProductionNotificationForm,
} from "../../types/types";

export function useRiskNew<Version extends "v2" | "v3">({
  ccid,
  version,
}: {
  ccid: string | undefined;
  version: Version;
}): UseQueryResult<
  Version extends "v2"
    ? ProductionNotificationForm
    : ProductionNotificationFormV3,
  AxiosError
> {
  return useQuery(
    ["risk", ccid],
    () => getRiskFormDataNew({ ccid: ccid as string, version }),
    {
      enabled: Boolean(ccid),
    },
  );
}
