/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, SVGProps } from "react";

/** @component */
export const CannotPalyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27 47C38.0457 47 47 38.0457 47 27C47 15.9543 38.0457 7 27 7C15.9543 7 7 15.9543 7 27C7 38.0457 15.9543 47 27 47Z"
      stroke="#22252F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35 27L23 19V35L35 27Z"
      stroke="#22252F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="1.58579"
      y1="49.5858"
      x2="49.5858"
      y2="1.58579"
      stroke="#B6C6C7"
      strokeWidth="4"
    />
    <line
      x1="2.29289"
      y1="50.2929"
      x2="50.2929"
      y2="2.2929"
      stroke="#22252F"
      strokeWidth="2"
    />
  </svg>
);
