import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Dialog, Stack, Button, Typography, Box, Grid } from "@mui/material";
import { Circle } from "@mui/icons-material";
import studiosPortalImg from "../../svg/studios-portal.png";
import studiosPortalVideo from "../../mp4/studios-portal.mp4";
import tasksVideo from "../../mp4/tasks.mp4";
import selectASeriesVideo from "../../mp4/select-a-series.mp4";
import tabsVideo from "../../mp4/tabs.mp4";

export interface OnboardingDialogProps {
  open: boolean;
  closeOnboarding: () => void;
}

export const OnboardingDialog: React.FC<OnboardingDialogProps> = ({
  open,
  closeOnboarding,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loadingVideo, setLoadingVideo] = useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <Box sx={{ bgcolor: "#0F70F5" }}>
          <Carousel
            activeIndex={activeStep}
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Stack
                  direction="column"
                  spacing={4}
                  justifyContent="center"
                  sx={{ minWidth: "40%", color: "white", pl: 5 }}
                >
                  <Typography variant="h4">
                    Welcome to the Studios Portal
                  </Typography>
                  <Typography>
                    Here's where your key production information and assets are
                    captured and delivered.
                  </Typography>
                  <Typography>
                    Before you get started, we thought we'd show you around a
                    little.
                  </Typography>
                </Stack>
                <Box sx={{ height: 500, width: 500 }}>
                  {loadingVideo && (
                    <Box sx={{ height: 500, width: 500, textAlign: "right" }}>
                      <img src={studiosPortalImg} alt="studios portal logo" />
                    </Box>
                  )}
                  <video
                    autoPlay
                    loop
                    muted
                    style={{ margin: 0, width: 500 }}
                    src={studiosPortalVideo}
                    onLoadedData={() => {
                      setLoadingVideo(false);
                    }}
                  />
                </Box>
              </Stack>
            </Carousel.Item>

            <Carousel.Item>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Stack
                  direction="column"
                  spacing={4}
                  justifyContent="center"
                  sx={{ minWidth: "40%", color: "white", pl: 5 }}
                >
                  <Typography variant="h4">
                    Easy access to your production information
                  </Typography>
                  <Typography>
                    Search for your programme and drill into the series and
                    episode you need with the navigation dropdowns.{" "}
                  </Typography>
                  <Typography>
                    You'll find tasks you need to do for each series and
                    episode.
                  </Typography>
                  <Typography>
                    Tip: you can search by programme name or episode number in
                    these dropdowns too!
                  </Typography>
                </Stack>
                <Box sx={{ height: 500, width: 500 }}>
                  <video
                    autoPlay
                    loop
                    muted
                    style={{ margin: 0 }}
                    src={selectASeriesVideo}
                  />
                </Box>
              </Stack>
            </Carousel.Item>

            <Carousel.Item>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Stack
                  direction="column"
                  spacing={4}
                  justifyContent="center"
                  sx={{ minWidth: "40%", color: "white", pl: 5 }}
                >
                  <Typography variant="h4">
                    Starting up your production
                  </Typography>
                  <Typography>
                    Here's where you complete start-up tasks like the New
                    Production Notification Form, Start-Up Form, and create your
                    Google Shared Drive.
                  </Typography>
                </Stack>
                <Box sx={{ height: 500, width: 500 }}>
                  <video
                    autoPlay
                    loop
                    muted
                    style={{ margin: 0, height: 500 }}
                    src={tasksVideo}
                  />
                </Box>
              </Stack>
            </Carousel.Item>

            <Carousel.Item>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Stack
                  direction="column"
                  spacing={4}
                  justifyContent="center"
                  sx={{ minWidth: "40%", color: "white", pl: 5 }}
                >
                  <Typography variant="h4">
                    Accessible production assets
                  </Typography>
                  <Typography>
                    Capture your production assets here and easily access them
                    whenever you need to.
                  </Typography>
                  <Typography>
                    And if you're producing for ITV, your deliverables will flow
                    into ITV's content supply chain.
                  </Typography>
                </Stack>
                <Box sx={{ height: 500, width: 500 }}>
                  <video
                    autoPlay
                    loop
                    muted
                    style={{ margin: 0 }}
                    src={tabsVideo}
                  />
                </Box>
              </Stack>
            </Carousel.Item>
          </Carousel>

          <Grid
            container
            marginX="0"
            marginY="0"
            paddingY="1vh"
            textAlign="center"
            sx={{ width: "100%" }}
          >
            <Grid item xs />
            <Grid item xs>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  sx={{ color: "white" }}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Previous
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={3}
              alignItems="center"
              justifyContent="center"
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Circle
                  sx={
                    activeStep === 0
                      ? { color: "white", fontSize: 20 }
                      : { color: "white", fontSize: "small", opacity: 0.3 }
                  }
                />
                <Circle
                  sx={
                    activeStep === 1
                      ? { color: "white", fontSize: 20 }
                      : { color: "white", fontSize: "small", opacity: 0.3 }
                  }
                />
                <Circle
                  sx={
                    activeStep === 2
                      ? { color: "white", fontSize: 20 }
                      : { color: "white", fontSize: "small", opacity: 0.3 }
                  }
                />
                <Circle
                  sx={
                    activeStep === 3
                      ? { color: "white", fontSize: 20 }
                      : { color: "white", fontSize: "small", opacity: 0.3 }
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                {activeStep !== 3 ? (
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    sx={{
                      background: "white",
                      color: "black",
                      "&:hover": {
                        background: "#e0e0e0",
                        color: "black",
                      },
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    onClick={closeOnboarding}
                    variant="contained"
                    sx={{
                      background: "white",
                      color: "black",
                      "&:hover": {
                        background: "#e0e0e0",
                        color: "black",
                      },
                    }}
                  >
                    Get Started!
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item xs>
              <Button
                onClick={closeOnboarding}
                sx={{
                  color: "white",
                  "&:hover": { background: "#227ded" },
                }}
              >
                Skip tutorial
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
