/* eslint-disable no-underscore-dangle */

import { envConfig } from "./loadEnvConfig";
import getEnvironment from "../lib/getEnviroment";

const commonOktaFields = {
  pkce: true,
  scopes: ["openid", "profile", "email", "groups", "offline_access"],
};

interface OktaConfig {
  issuer: string;
  clientId?: string;
  pkce: boolean;
  scopes: string[];
}

interface EnvVars {
  OKTA_ISSUER?: string;
  OKTA_CLIENT_ID?: string;
}

declare global {
  interface Window {
    _env_: EnvVars;
  }
}

const env = getEnvironment();
const getOktaConfig = (): OktaConfig => {
  if (env === "localhost") {
    return {
      issuer:
        envConfig?.OKTA_ISSUER ||
        "https://itvplayground.oktapreview.com/oauth2/default",
      clientId: envConfig?.OKTA_CLIENT_ID || "0oa4x3pvrbfDqvZcu0x7",
      ...commonOktaFields,
    };
  }

  if (window._env_) {
    return {
      issuer:
        window._env_.OKTA_ISSUER ||
        "https://itvplayground.oktapreview.com/oauth2/default",
      clientId: window._env_.OKTA_CLIENT_ID,
      ...commonOktaFields,
    };
  }

  return {
    issuer:
      envConfig?.OKTA_ISSUER ||
      "https://itvplayground.oktapreview.com/oauth2/default",
    clientId: envConfig?.OKTA_CLIENT_ID,
    ...commonOktaFields,
  };
};

export default getOktaConfig;
