import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { LocationsAndTravelSectionV3 } from "../yupSchemas/locationsAndTravelSchemaV3";
import { type NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { type DropdownMetadata } from "../../../types/types";
import { MultiSelectField } from "../formFields/MultiSelectField";
import { YesNoField } from "../formFields/YesNoField";
import { UseUpdateRiskArgsNew } from "../../../hooks";
import { CheckboxField } from "../formFields/CheckboxField";

const operatingEnvironmentOptions = [
  {
    label: "Modern Slavery and Exploitative Working Practices",
    value: "ModernSlavery",
  },
  {
    label: "Bribery and Corruption",
    value: "BriberyAndCorruption",
  },
  {
    label: "Sanctions",
    value: "Sanctions",
  },
  {
    label: "N/A",
    value: "NotApplicable",
  },
];

interface LocationsAndTravelPageProps {
  dropdownMetadata: DropdownMetadata;
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function LocationsAndTravelPageV3({
  dropdownMetadata,
  autosaveForm,
}: LocationsAndTravelPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.locationsAndTravel || {};
  const errors = formik.errors.locationsAndTravel || {};

  const multiSelectChangeHandler = (
    fieldName: keyof LocationsAndTravelSectionV3,
  ) => {
    return function handleSelectChange(values: Array<string>) {
      formik
        .getFieldHelpers(`locationsAndTravel.${fieldName}`)
        .setValue(values);
    };
  };

  const yesNoChangeHandler = (fieldName: keyof LocationsAndTravelSectionV3) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`locationsAndTravel.${fieldName}`).setValue(isYes);
    };
  };

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      locationsAndTravel: {
        ...formData.locationsAndTravel,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Locations and Travel</Typography>

              <MultiSelectField
                version="v3"
                fieldInfo={{
                  sectionId: "locationsAndTravel",
                  fieldName: "countries",
                }}
                label="Which countries will your production be operating in?"
                error={
                  Array.isArray(errors.countries)
                    ? errors.countries[0]
                    : errors.countries
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={multiSelectChangeHandler("countries")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.countries}
                options={dropdownMetadata.countries}
                autosaveForm={wrappedAutosaveForm}
              />

              <CheckboxField
                version="v3"
                fieldInfo={{
                  sectionId: "locationsAndTravel",
                  fieldName: "operatingEnvironment",
                }}
                label="Are any of the following risks associated with operating in this country?"
                error={
                  Array.isArray(errors.operatingEnvironment)
                    ? errors.operatingEnvironment[0]
                    : errors.operatingEnvironment
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={multiSelectChangeHandler("operatingEnvironment")}
                isRequired={true}
                value={values.operatingEnvironment}
                options={operatingEnvironmentOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "locationsAndTravel",
                  fieldName: "travel",
                }}
                label="Will you be arranging international travel for any cast or crew?"
                error={errors.travel}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("travel")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.travel}
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "locationsAndTravel",
                  fieldName: "travelBooking",
                }}
                label="Will you be using ATPI to book travel for this production?"
                error={errors.travelBooking}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("travelBooking")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.travelBooking}
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "locationsAndTravel",
                  fieldName: "environment",
                }}
                label="Is there any current or historic conflict or community tension, locally or regionally, at your locations?"
                error={errors.environment}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("environment")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.environment}
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "locationsAndTravel",
                  fieldName: "cultural",
                }}
                label="Is there potentially sensitive or culturally inappropriate content in the production for the filming location?"
                error={errors.cultural}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("cultural")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.cultural}
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "locationsAndTravel",
                  fieldName: "disruption",
                }}
                label="Do you expect any disruption to the public or local communities due to your production?"
                error={errors.disruption}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("disruption")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.disruption}
                autosaveForm={wrappedAutosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
