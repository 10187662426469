import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getSynopsis } from "../../api/synopsis";
import { SynopsisResponsePayload } from "../../types/types";

export const useSynopsis = (
  activeCcid: string | undefined,
  synopsisUrlLevel: string,
): UseQueryResult<SynopsisResponsePayload, AxiosError> =>
  useQuery(["synopsis", synopsisUrlLevel, activeCcid], () =>
    getSynopsis(synopsisUrlLevel, activeCcid as string),
  );
