import { styled } from "@mui/system";

export const StyledCheckboxContainer = styled("div")`
  /* margin-right: 4rem;
  display: flex;

  & > div:first-child {
    align-items: flex-start;
  }

  input[type="checkbox"] {
    min-width: 1.125rem;
  }*/

  margin-right: 4rem;

  .tool-tip {
    position: absolute;
    margin-top: -2px;
  }
`;

export const StyledLabelContainer = styled("div")`
  margin-bottom: 0.75rem;
`;
