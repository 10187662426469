/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, SVGProps } from "react";

/** @component */
export const FontIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 10H32V34H8V22"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 16V5M8 5L4 9M8 5L12 9"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 28H20.25M23 28H20.25M20.25 28V16M20.25 16H14V17.5M20.25 16H26V17.5"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
