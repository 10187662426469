import { Typography } from "@mui/material";
import { TEXTAREA, CONTRIBUTORS } from "../../../../../components";

/* TODO:
  Deprecate this file as we no longer user it for loading the start up form component.
  It is only used in the sanitizeFormData method which utilizes the types of each field
  and based on that, it sanitizes the data before passing them to the components.
  The solution here is to either fix the backend to return the data in the right format or
  do the clean up at the component level like we did with the contributors component in ContributorsAndTalent.tsx
*/
export const contributorsAndTalentFormConfig = [
  {
    sectionTitle: <Typography variant="h5">Key series contributors</Typography>,
    description:
      "Please indicate the key contributors that exist across this series:",
    formFields: [
      {
        type: CONTRIBUTORS,
        label: "",
        id: "contributors",
        value: [],
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: (
      <Typography variant="h5">Other contributors considerations</Typography>
    ),
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Are you looking for members of the public to participate?",
        id: "membersOfPublic",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label:
          "Are you using paid for advertising such as Facebook boost to source contributors?",
        id: "paidAdvertising",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Are you using social media to source contributors?",
        id: "socialMediaSourcing",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Are format background checks required?",
        id: "formatBackground",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Visas or Child Licenses Required",
        id: "visaOrChildLicences",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Talent payments",
        id: "talentPayments",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Duty of care",
        id: "dutyOfCare",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Additional notes:",
        id: "additionalNotes",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        excludedFromCompletion: true,
      },
    ],
  },
];
