import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { RadioField } from "../formFields/RadioField";
import { UseUpdateRiskArgsNew } from "../../../hooks";
import { CheckboxField } from "../formFields/CheckboxField";
import { NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { DataPrivacySectionV3 } from "../yupSchemas/dataPrivacySchemaV3";

const radioOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

const activitiesOptions = [
  {
    label: "Covert or secret filming",
    value: "CovertOrSecretFilming",
  },
  {
    label: "Filming of sensitive material or content",
    value: "SensitiveContent",
  },
  {
    label:
      "Inclusion or filming of footage about an individual's sex life, sexual orientation or love life",
    value: "SexLifeOrSexualOrientation",
  },
  {
    label: "None of these",
    value: "NoneOfTheAbove",
  },
];

interface DataPrivacyPageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function DataPrivacyPageV3({ autosaveForm }: DataPrivacyPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.dataPrivacy || {};
  const errors = formik.errors.dataPrivacy || {};

  const checkboxesChangeHandler = (fieldName: keyof DataPrivacySectionV3) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`dataPrivacy.${fieldName}`).setValue(values);
    };
  };

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      dataPrivacy: {
        ...formData.dataPrivacy,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Data Privacy</Typography>
              <Stack spacing={2}>
                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "dataPrivacy",
                    fieldName: "subjectsAware",
                  }}
                  label="Will the subjects of the production know you are making a programme about them?"
                  error={errors.subjectsAware}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.subjectsAware}
                  options={radioOptions}
                  tooltipContent="For example: Did you try to contact them and could not get permission? Are you planning on surprising them (e.g. prize or prank) or otherwise deceiving or misleading them about the production and their involvement in what the programme is about?"
                  autosaveForm={wrappedAutosaveForm}
                />

                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "dataPrivacy",
                    fieldName: "novelData",
                  }}
                  label="Will the production methods and techniques involve new or innovative technology, or any other novel approach?"
                  error={errors.novelData}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.novelData}
                  options={radioOptions}
                  tooltipContent="For example: Virtual production, AI software"
                  autosaveForm={wrappedAutosaveForm}
                />

                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "dataPrivacy",
                    fieldName: "journalisticAims",
                  }}
                  label="Is the production intended to be journalistic or have journalistic aims?"
                  error={errors.journalisticAims}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.journalisticAims}
                  options={radioOptions}
                  tooltipContent="For example: Does the programme involve current affairs content, expose material or catch someone out in a lie or deception? Do you need to do research without alerting the person?"
                  autosaveForm={wrappedAutosaveForm}
                />

                <CheckboxField
                  version="v3"
                  fieldInfo={{
                    sectionId: "dataPrivacy",
                    fieldName: "activities",
                  }}
                  label="Will you carry out any of the following:"
                  error={
                    Array.isArray(errors.activities)
                      ? errors.activities[0]
                      : errors.activities
                  }
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={checkboxesChangeHandler("activities")}
                  isRequired={true}
                  value={values.activities}
                  options={activitiesOptions}
                  autosaveForm={wrappedAutosaveForm}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
