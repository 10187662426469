import { Button, IconButton } from "@mui/material";
import theme from "../../utils/theme";
import { ClickableComponentProps } from "../../types/types";
import HelpIcon from "@mui/icons-material/Help";

export const ContactUsButton: React.FC<ClickableComponentProps> = ({
  onClick,
}) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        color: "white",
        borderColor: "knockedWhite",
        fontWeight: "light",
        backgroundColor: theme.palette.primary.main,
        "&:hover": { borderColor: "white", backgroundColor: "primary.dark" },
      }}
    >
      Contact us
    </Button>
  );
};

export const ContactUsIcon: React.FC<ClickableComponentProps> = ({
  onClick,
}) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        color: "white",
        borderColor: "knockedWhite",
        fontWeight: "light",
        backgroundColor: theme.palette.primary.main,
        "&:hover": { borderColor: "white", backgroundColor: "primary.dark" },
      }}
    >
      <HelpIcon sx={{ color: "white" }} />
    </IconButton>
  );
};
