import { FC } from "react";
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { roleToDisplayName } from "../Contributors/contributor.helper";
import { Attendee } from "../../../../types/types";

export interface AttendeeRowProps {
  attendee: Attendee;
  attendeeRoles: string[];
  onDelete(attendee: Attendee): void;
  onInternalChange(attendee: Attendee): void;
  blurTriggered: () => void;
}

export const AttendeeRow: FC<AttendeeRowProps> = ({
  attendee,
  attendeeRoles,
  onDelete,
  onInternalChange,
  blurTriggered,
}) => {
  const saveData = () => {
    // Only call the backend once they've entered the minimum required fields
    if (
      attendee.role &&
      (attendee.email || attendee.forenames || attendee.surname)
    ) {
      blurTriggered();
    }
  };

  return (
    <>
      <TableRow>
        <TableCell
          style={{
            borderBottom: "none",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <TextField
            label=""
            fullWidth
            placeholder="Enter first name"
            size="small"
            value={attendee.forenames}
            onChange={(event) => {
              const newForenames = event.target.value;
              onInternalChange({ ...attendee, forenames: newForenames });
            }}
            onBlur={saveData}
          />
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <TextField
            fullWidth
            size="small"
            label=""
            placeholder="Enter surname"
            value={attendee.surname}
            onChange={(event) => {
              const newSurname = event.target.value;
              onInternalChange({ ...attendee, surname: newSurname });
            }}
            onBlur={saveData}
          />
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            className="role-container"
            direction="row"
          >
            <Select
              size="small"
              fullWidth
              value={attendee.role}
              displayEmpty
              error={!attendee.role}
              className={!attendee.role ? "Mui-error" : ""}
              label="Select Role"
              onChange={(event) => {
                const newRole = event.target.value;
                onInternalChange({ ...attendee, role: newRole });
              }}
              onBlur={saveData}
              renderValue={(role: string) => {
                if (role.length === 0) {
                  return <em>Select role</em>;
                }
                return roleToDisplayName(role);
              }}
            >
              {attendeeRoles.map((role) => (
                <MenuItem value={role} key={role}>
                  {roleToDisplayName(role)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <TextField
            fullWidth
            size="small"
            label=""
            placeholder="Enter email"
            value={attendee.email}
            onChange={(event) => {
              const newEmail = event.target.value;
              onInternalChange({ ...attendee, email: newEmail });
            }}
            onBlur={saveData}
          />
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <IconButton
            onClick={() => {
              onDelete(attendee);
            }}
          >
            {" "}
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
