import { FC, useCallback, useMemo } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Checkbox } from "@mui/material";
import { User } from "../../../../types/types";
import { noop } from "../../../../utils/appHelper";

const PAGE_SIZE = 15;

export interface UserTableProps {
  users: User[];
  groupId: string;
  onUsersSelect?: (users: string[]) => void;
  onChangePermissionAdmin?: (
    user: User,
    labelIdInput: string,
    permission: string,
  ) => void;
}

export const UsersTable: FC<UserTableProps> = ({
  users,
  groupId,
  onUsersSelect = noop,
  onChangePermissionAdmin = noop,
}) => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "firstName",
        headerName: "First",
      },
      {
        field: "lastName",
        headerName: "Last",
      },
      {
        field: "email",
        headerName: "Email",
        width: 225,
      },
      {
        field: "role",
        headerName: "Role",
        width: 200,
      },
      {
        field: "limitedUser",
        headerName: "Label Access Level",
        width: 160,
        sortable: false,
        description:
          "This shows if the user has access to all of this labels content or just to specific series",
        renderCell: (params: GridRenderCellParams) =>
          params?.value ? (
            <Chip label="Limited Access" size="small" />
          ) : (
            <Chip
              label="Unrestricted Access"
              sx={{ backgroundColor: "#0288D11F" }}
              size="small"
            />
          ),
      },
      {
        field: "accessManager",
        headerName: "Can Grant Access",
        align: "center",
        width: 150,
        sortable: false,
        description:
          "Activating this will enable that person to grant access for other users to the content for this label.",
        renderCell: (params: GridRenderCellParams) => (
          <Checkbox
            checked={params.value}
            disabled={params.row.limitedUser}
            onChange={() =>
              groupId
                ? onChangePermissionAdmin(params.row, groupId, "ACCESS_MANAGER")
                : noop()
            }
            size="small"
          />
        ),
      },
      {
        field: "canEditCCID",
        headerName: "Can Set Up New Production",
        align: "center",
        width: 150,
        sortable: false,
        description:
          "Activating this will enable this user to be able to create new Programmes, Series and Specials in the Catalogue for this label.",
        renderCell: (params: GridRenderCellParams) => (
          <Checkbox
            checked={params.value}
            disabled={params.row.limitedUser}
            onChange={() =>
              groupId
                ? onChangePermissionAdmin(params.row, groupId, "CCID_CREATOR")
                : noop()
            }
            size="small"
          />
        ),
      },
    ],
    [onChangePermissionAdmin, groupId],
  );

  const handleSelectionChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ids: any) => {
      onUsersSelect(ids);
    },
    [onUsersSelect],
  );

  return (
    <DataGrid
      rows={users}
      columns={columns}
      initialState={{
        pagination: { paginationModel: { pageSize: PAGE_SIZE } },
      }}
      checkboxSelection
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      onRowSelectionModelChange={handleSelectionChange}
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal",
        },
        "& .MuiDataGrid-columnHeader": {
          // Forced to use important since overriding inline styles
          height: "unset !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          // Forced to use important since overriding inline styles
          maxHeight: "168px !important",
        },
        ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
          marginTop: "1em",
          marginBottom: "1em",
        },
      }}
    />
  );
};
