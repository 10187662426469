import * as Yup from "yup";

export const cyberSecuritySchema = Yup.object().shape({
  ai: Yup.string().required("This is a required field"),
  aiProducts: Yup.string(),
  aiGuidance: Yup.string().required("This is a required field"),
  appList: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  appListDetail: Yup.string(),
  breach: Yup.string(),
  breachImpact: Yup.string(),
  cyberResponsible: Yup.string(),
  cyberResponsibleEmail: Yup.string().email("Invalid email"),
  dataAccess: Yup.string().required("This is a required field"),
  dataStorageCountry: Yup.string().when(
    "$cyberSecurity.dataAccess",
    ([dataAccess], schema) => {
      if (dataAccess === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  dataLink: Yup.string().required("This is a required field"),
  dataLinkDetail: Yup.string().when(
    "$cyberSecurity.dataLink",
    ([dataLink], schema) => {
      if (dataLink === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  dataStorage: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  dataStorageSupplier: Yup.string(),
  newTech: Yup.string().required("This is a required field"),
  newTechDetail: Yup.string().when(
    "$cyberSecurity.newTech",
    ([newTech], schema) => {
      if (newTech === "Yes") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
});

export type CyberSecuritySection = Yup.InferType<typeof cyberSecuritySchema>;
