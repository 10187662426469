import { useQuery, UseQueryResult } from "react-query";
import { getSeries } from "../../api/portal";
import { SeriesResponsePayload } from "../../types/types";

export const useSeries = (
  programmeCid: string | undefined,
  includeOpportunity: boolean | undefined,
): UseQueryResult<SeriesResponsePayload> =>
  useQuery(
    ["series", programmeCid, includeOpportunity],
    () => getSeries(programmeCid as string, includeOpportunity as boolean),
    {
      enabled: Boolean(programmeCid),
    },
  );
