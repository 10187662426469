import { useMutation } from "react-query";
import { updateRiskFormDataNew } from "../../api/spotlight";
import { NotificationFormValuesV3 } from "../../containers/NotificationForm/getInitialNotificationFormValues";
import { noop } from "../../utils/appHelper";

type PartialFormData = {
  [FormId in keyof NotificationFormValuesV3]?: Partial<
    NotificationFormValuesV3[FormId]
  >;
};

export interface UseUpdateRiskArgsNew {
  riskCcid: string;
  formData: PartialFormData;
  version: "v2" | "v3";
}

export const useUpdateRiskNew = ({ onSuccess = noop, onError = noop }) =>
  useMutation(
    ({ riskCcid, formData, version }: UseUpdateRiskArgsNew) =>
      updateRiskFormDataNew({ ccid: riskCcid, formData, version }),
    { onSuccess, onError },
  );
