import { UseQueryResult, useQuery } from "react-query";
import { AxiosError } from "axios";
import { getCcidInfo } from "../../api/portal";
import { CcidMetadata } from "../../types/types";
import { noop } from "../../utils/appHelper";

export const useCcidInfo = (
  ccid: string,
  onSuccess = noop,
  onError = noop,
): UseQueryResult<CcidMetadata, AxiosError> =>
  useQuery(["ccidinfo", ccid], () => getCcidInfo(ccid), {
    onSuccess,
    onError,
  });
