/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import theme from "./utils/theme";

export const StyledLink = styled(Link)<{ underline?: any }>`
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  color: ${theme.palette.midGray};
  :hover {
    cursor: pointer;
  }
`;

export const StyledAlert = styled("div")`
  padding: 32px;
  width: 100%;
  border: 1px solid ${theme.palette.java};
  border-radius: 8px;
  background-color: ${theme.palette.hummingBird};
`;

export const getErrorStyle = (): string => `
  border-color: ${theme.palette.sunsetOrange};
  &:hover {
    border-color: ${theme.palette.sunsetOrange} !important;
  }
  &:focus-within {
    box-shadow: none;
  }
`;

export const StyledTextAreaContainer = styled("div")<{ hasError?: boolean }>`
  & > div {
    max-width: 100%;

    .tool-tip {
      z-index: 0;
    }

    & > div:nth-child(2) {
      background: white;
      ${({ hasError }) => hasError && getErrorStyle()}
    }
  }
`;

export const StyledInputContainer = styled("div")<{ hasError?: boolean }>`
  & > div > div {
    ${({ hasError }) => hasError && getErrorStyle()}
  }
`;

export const StyledDivider = styled("div")`
  height: 1px;
  background: ${theme.palette.frenchGray};
`;

export const StyledContainer = styled("div")<{ large?: boolean }>`
  width: 90%;
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  margin: 0 auto;
  width: ${({ large }) => (large ? "100%" : "90%")};
  max-width: ${({ large }) => (large ? "none" : "1200px")};
`;

export const StyledInfoContainer = styled("div")`
  max-width: 440px;
  text-align: center;
  margin: 0 auto;

  & > button {
    margin: 1rem auto;
  }
`;

export const StyledSelect = styled("select")<{ hasMaxWidth?: boolean }>`
  width: 100%;
  padding: 0.55rem;
  border: 2px solid ${theme.palette.osloGray};
  border-radius: 0.5rem;
  ${({ hasMaxWidth }) =>
    hasMaxWidth &&
    `
    max-width: 120px;
  `};
  ${({ hasMaxWidth }) =>
    !hasMaxWidth &&
    `
    margin: 0 32px;
  `};
`;

export const StyledHeaderTitle = styled("div")`
  font-size: 2rem;
  padding: 1.5rem 0;
  color: black;
  line-height: 2rem;
`;

export const StyledHeader = styled("div")`
  width: 100%;
  padding-top: 1rem;
  background: ${theme.palette.white};
`;

export const StyledFormCard = styled("div")<{ hasRadius?: boolean }>`
  padding: 2.25rem 1.5rem 11.25rem;
  background: ${theme.palette.white};
  box-shadow:
    0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px rgba(0, 0, 0, 0.3);
  line-height: 1.5rem;
  ${({ hasRadius }) =>
    hasRadius &&
    `
    border-radius: 0.5rem;
  `}
`;

export const StyledListUpperRoman = styled("ul")`
  list-style-type: upper-roman;
  margin: 0.125rem 0;
`;

export const StyledRequired = styled("div")`
  display: inline-block;
  color: ${theme.palette.sunsetOrange};
`;

export const StyledFormSectionTitle = styled("div")`
  font-size: 1.25rem;
`;

export const StyledError = styled("div")`
  color: ${theme.palette.warmRed};
`;

export const StyledH3Header = styled("h3")`
  margin-top: 40px;
`;

export const StyledContentHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const StyledH3 = styled("h3")`
  margin: 6px 0 0;
`;
