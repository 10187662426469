/* eslint-disable @typescript-eslint/no-explicit-any */
import { GroupMenuProps } from "./GroupMenu";
import { SubNavProps } from "./SubNav";

export const setLinkAndActiveStatus = (
  basePath: string,
  groupMenusSetting: GroupMenuProps["items"],
  currentTab: string,
): GroupMenuProps["items"] =>
  groupMenusSetting?.map((item: any) => ({
    ...item,
    active: item.id === currentTab,
    link: item.id === "tasks" ? `${basePath}` : null,
    subNavs: item?.subNavs?.map((subNav: SubNavProps) => ({
      ...subNav,
      items: subNav?.items?.map((subItem) => ({
        ...subItem,
        link: `${basePath}/${subItem.id}`,
        active: subItem.id === currentTab,
      })),
    })),
  }));
