import { FC, ReactNode, useCallback } from "react";
import { CheckCircle, Done, Warning, WarningAmber } from "@mui/icons-material";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { RiskLevel } from "../../types/types";

export enum StepItemStatus {
  // ActiveInComplete = "ActiveInComplete",  // fill grey circle
  // InAactiveComplete = "InAactiveComplete",  // green circle tick
  // InAactiveInComplete = "InAactiveInComplete", // empty grey circle
  // ActiveComplete = "ActiveComplete", // green tick
  // InActivePartialInComplete = "InActivePartialInComplete", // empty green circle
  // ActivePartialInComplete = "ActivePartialInComplete", // green circle
  // ActiveError = "ActiveError", // fill red circle
  // InActiveError = "InActiveError", // empty red circle

  Complete = "Complete",
  InComplete = "InComplete",
  PartialInComplete = "PartialInComplete",
  error = "error",
}

export interface StepItem {
  id: string;
  label: ReactNode;
  status: StepItemStatus | RiskLevel;
  isActive: boolean;
}

export interface StepsProps {
  items: StepItem[];
  onItemClick(item: StepItem): void;
}

const iconSize = {
  width: "1rem",
  height: "1rem",
  position: "relative",
  left: "3px",
};

export const Steps: FC<StepsProps> = ({ items, onItemClick }) => {
  const getStatusIcon = useCallback(
    (status: StepItemStatus | RiskLevel, isActive: boolean) => {
      if (status === RiskLevel.Insignificant) {
        return <ChangeHistoryIcon sx={iconSize} />;
      }
      if (!isActive && status === RiskLevel.Low) {
        return <WarningAmber color="disabled" sx={iconSize} />;
      }
      if (!isActive && status === RiskLevel.Low) {
        return <WarningAmber color="disabled" sx={iconSize} />;
      }

      if (isActive && status === RiskLevel.Medium) {
        return <Warning color="warning" sx={iconSize} />;
      }

      if (!isActive && status === RiskLevel.Medium) {
        return <WarningAmber color="warning" sx={iconSize} />;
      }

      if (isActive && status === RiskLevel.High) {
        return <Warning color="error" sx={iconSize} />;
      }

      if (!isActive && status === RiskLevel.High) {
        return <WarningAmber color="error" sx={iconSize} />;
      }

      if (isActive && status === StepItemStatus.InComplete) {
        // fill gray circle
        return <TimelineDot data-testId="grayCircle" />;
      }

      if (!isActive && status === StepItemStatus.Complete) {
        // green circle tick
        return <CheckCircle color="primary" sx={iconSize} />;
      }

      if (!isActive && status === StepItemStatus.InComplete) {
        // empty grey circle
        return <TimelineDot variant="outlined" data-testId="emptyGreyCircle" />;
      }

      if (isActive && status === StepItemStatus.Complete) {
        // green tick
        return <Done color="primary" sx={iconSize} />;
      }

      if (!isActive && status === StepItemStatus.PartialInComplete) {
        // empty green circle
        return (
          <TimelineDot
            variant="outlined"
            color="primary"
            data-testId="emptyGreenCircle"
          />
        );
      }

      if (isActive && status === StepItemStatus.PartialInComplete) {
        // green circle
        return <TimelineDot color="primary" data-testId="greenCircle" />;
      }

      if (isActive && status === StepItemStatus.error) {
        // fill red circle
        return <TimelineDot color="error" data-testId="fillRedCircle" />;
      }

      if (!isActive && status === StepItemStatus.error) {
        // empty red circle
        return (
          <TimelineDot
            color="error"
            variant="outlined"
            data-testId="emptyRedCircle"
          />
        );
      }

      return <></>;
    },
    [],
  );

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },

        "& .MuiSvgIcon-root": {
          margin: "11px 0 11px -10px",
        },
        "& .MuiTimelineDot-root": {
          position: "relative",
          left: "-5px",
        },
        "& .MuiTimelineSeparator-root": {
          alignItems: "flex-start",
        },
      }}
    >
      {items.map((item, index) => {
        const isLastItem = index >= items.length - 1;

        return (
          <TimelineItem
            key={item.id}
            onClick={() => {
              onItemClick(item);
            }}
            sx={{ cursor: "pointer", minHeight: isLastItem ? 0 : 65 }}
          >
            <TimelineSeparator>
              {getStatusIcon(item.status, item.isActive)}

              {!isLastItem && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent
              color={item.status === StepItemStatus.error ? "error" : ""}
            >
              {index + 1}. {item.label}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
