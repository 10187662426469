import { FC } from "react";
import {
  Card,
  List,
  ListItem,
  Typography,
  Link,
  Box,
  useTheme,
} from "@mui/material";
import ReactMarkdown from "react-markdown";

export interface CardProps {
  chatContent: string;
  sources?: string[];
}

const AiChatResponseCard: FC<CardProps> = ({ chatContent, sources }) => {
  const theme = useTheme();

  return (
    <Card sx={{ maxWidth: "75%", backgroundColor: theme.palette.athensGray }}>
      <Box sx={{ px: 2, py: 1 }}>
        <ReactMarkdown>{chatContent}</ReactMarkdown>
        <List sx={{ listStyleType: "disc", pl: 2, py: 0 }}>
          {sources ? (
            <>
              <Typography sx={{ mt: 1 }}>
                This information was pulled from:{" "}
              </Typography>
              {sources.map((source) => (
                <ListItem key={source} sx={{ display: "list-item" }}>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={source}
                    color="inherit"
                  >
                    {source}
                  </Link>
                </ListItem>
              ))}
            </>
          ) : null}
        </List>
      </Box>
    </Card>
  );
};

export default AiChatResponseCard;
