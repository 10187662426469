import { OktaAuth } from "@okta/okta-auth-js";
import getOktaConfig from "./getOktaConfig";

const oktaConfig = getOktaConfig();

export const oktaAuthClient = new OktaAuth({
  issuer: oktaConfig.issuer,
  clientId: oktaConfig.clientId,
  redirectUri: `${window.location.origin}/login/callback`,
  logoutUrl: `${window.location.origin}/login`,
  pkce: oktaConfig.pkce,
  scopes: oktaConfig.scopes,
});
