import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { enqueueSnackbar } from "notistack";
import { GetBulkImagesDownloadLinkResponse } from "../../api/images";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

export const useBulkImagesDownloadLink = (): UseQueryResult =>
  useQuery<GetBulkImagesDownloadLinkResponse | undefined, AxiosError>({
    queryKey: ["getBulkDownloadLink"],
    // the query function is run manually elsewhere, so we can disable the query here to avoid errors
    enabled: false,
    onSuccess: (data) => {
      if (data) {
        const { uri } = data;
        // downloads the zip
        const link = document.createElement("a");
        link.href = uri;
        link.click();
      }
    },
    onError: () => {
      enqueueSnackbar(
        "Something went wrong while trying to download your images. Please try again",
        { variant: "error", action: SnackbarDismiss },
      );
    },
  });
