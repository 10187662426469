/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { PlayCircleOutline, PlayDisabled } from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import theme from "../../../../../../../utils/theme";

// const theme: any = itvTheme;

export interface VideoMetaDataPayload {
  productionId?: string;
  versionCcid: string;
  editReasons: string[];
}

export interface VideoProps {
  videoMetaData?: VideoMetaDataPayload;
  handleFetchVideoUrl: (titleCcid: string) => void;
  hasVideo?: boolean;
  url?: string;
  className?: string;
}

export const PreviewVideo: FC<VideoProps> = ({
  videoMetaData,
  hasVideo,
  handleFetchVideoUrl,
  url,
  className,
}) => {
  const getMetaData = (
    <Box>
      {videoMetaData?.productionId && (
        <p>
          Production ID:
          <br />
          <span>{videoMetaData?.productionId}</span>
        </p>
      )}

      {videoMetaData?.editReasons && (
        <p>
          Edit Reason: <br />
          <span>
            {videoMetaData.editReasons.length > 0
              ? videoMetaData.editReasons.join(", ")
              : "-"}
          </span>
        </p>
      )}
    </Box>
  );

  if (!hasVideo) {
    return (
      <Stack className={className} flexDirection="row" gap={2} margin={2}>
        <Box
          display="flex"
          justifyContent="center"
          bgcolor={theme.palette.frenchGray}
          alignItems="center"
          height={120}
          width={192}
          borderRadius={2}
        >
          <PlayDisabled color={theme.palette.charade} />
        </Box>
        {getMetaData}
      </Stack>
    );
  }

  return (
    <Stack className={className} flexDirection="row" gap={2} margin={2}>
      {url ? (
        <video
          autoPlay
          controls
          controlsList="nodownload"
          width={240}
          height={140}
          data-testid="titleVideo"
        >
          <track default kind="captions" src="en" />
          Sorry, your browser doesn't support embedded videos.
          <source src={url} />
        </video>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="black"
          height={120}
          width={192}
          borderRadius={2}
          role="button"
          onClick={() => handleFetchVideoUrl(videoMetaData?.versionCcid || "")}
        >
          <PlayCircleOutline fontSize="large" />
        </Box>
      )}
      {getMetaData}
    </Stack>
  );
};
