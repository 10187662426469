/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { updateStartupFormProductionData } from "../../api/storyboard";
import { ProductionCardResponse } from "../../types/types";
import { noop } from "../../utils/appHelper";

type QueryContext = { previousCardDetails: ProductionCardResponse } | undefined;

export const useUpdateStartupProductionDetails = (
  onSuccess = noop,
  onError: (error: any, variables: any, context: QueryContext) => void = noop,
  onMutate?: (variables: any) => Promise<QueryContext>,
): UseMutationResult => {
  return useMutation(
    ({ ccid, formData }: any) =>
      updateStartupFormProductionData(ccid, formData),
    {
      onSuccess,
      onError,
      onMutate,
    },
  );
};
