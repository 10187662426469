/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import { LegalDeliverableData } from "../types/types";

const API_BASE = getAPI(SERVICES.legalDeliverables);

export const getLegalData = (
  ccid: string,
  formType: string,
): Promise<LegalDeliverableData> => {
  return handleRequest
    .get(`${API_BASE}/ccids/${ccid}?formType=${formType}`, { timeout: 60000 })
    .then(({ data }) => data);
};

export const getLegalDeliverableFormStatus = (
  ccid: string,
  formType: string,
): Promise<{ completed: boolean }> => {
  return handleRequest
    .get(`${API_BASE}/ccids/${ccid}/tasks/status?formType=${formType}`, {
      timeout: 60000,
    })
    .then(({ data }) => data);
};

export const legalDeliverablesFormSubmission = (
  ccid: string,
  formData: any,
  autosave: boolean,
  formType: string,
): Promise<Record<string, any>> => {
  const url = autosave
    ? `${API_BASE}/ccids/${ccid}`
    : `${API_BASE}/ccids/${ccid}/submit`;
  return new Promise((resolve, reject) => {
    handleRequest
      .put(
        url,
        {
          data: formData,
          formType: formType,
        },
        { timeout: 60000 },
      )
      .then(({ data }) => resolve(data))
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};

// ^ the above provisional field might need adding to the other endpoints
