import { useState } from "react";
import {
  Card,
  CardActionArea,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DoneOutlined } from "@mui/icons-material";
import { FrontendTask } from "../types/types";
import { useTaskStatus } from "../hooks/queries";

const TaskButton = ({
  task,
  handleClick,
}: {
  task: FrontendTask;
  handleClick: () => void;
}): JSX.Element => {
  const theme = useTheme();
  const [hoverColor, setHoverColor] = useState<string>(
    theme.palette.primary.light,
  );
  const { data: taskStatus } = useTaskStatus(task.taskStatusUrl);

  return (
    <Card variant="outlined" sx={{ m: 1 }}>
      <CardActionArea
        onClick={handleClick}
        onPointerEnter={() => setHoverColor(theme.palette.primary.main)}
        onPointerLeave={() => setHoverColor(theme.palette.primary.light)}
        sx={{ width: "155px", height: "200px" }}
      >
        <Stack alignItems="center" justifyContent="flex-end" height="50%">
          {taskStatus?.completed ? (
            <Stack
              width={72}
              height={72}
              border={2}
              borderRadius="50%"
              borderColor={`${theme.palette.secondary.main} !important`}
              justifyContent="center"
              alignItems="center"
              sx={{ bgcolor: `${theme.palette.secondary.main}` }}
            >
              <DoneOutlined
                color="secondary"
                sx={{ fontSize: 40, strokeWidth: 1.5, color: "white" }}
              />
            </Stack>
          ) : (
            <Stack
              width={72}
              height={72}
              border={2}
              borderRadius="50%"
              borderColor={`${hoverColor} !important`}
              justifyContent="center"
              alignItems="center"
            >
              {task.icon}
            </Stack>
          )}
        </Stack>
        <Stack alignItems="center" justifyContent="center" height="50%" px={2}>
          <Typography align="center">{task.description}</Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default TaskButton;
