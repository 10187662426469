import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { type SustainabilitySectionV3 } from "../yupSchemas/sustainabilitySchemaV3";
import { type NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { CheckboxField } from "../formFields/CheckboxField";
import { DropdownField } from "../formFields/DropdownField";
import { UseUpdateRiskArgsNew } from "../../../hooks";

const calculatorOptions = [
  {
    label: "Albert",
    value: "Albert",
  },
  {
    label: "Ecoprod",
    value: "Ecoprod",
  },
  {
    label: "Green Shooting/MFG",
    value: "GreenShootingMFG",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const hazardOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

const airTravelOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

const naturalHazardOptions = [
  {
    label: "Floods",
    value: "Floods",
  },
  {
    label: "Excessive rain/snow",
    value: "RainSnow",
  },
  {
    label: "Excessive heat",
    value: "Heat",
  },
  {
    label: "Severe Thunderstorms",
    value: "Thunderstorms",
  },
  {
    label: "Hurricanes or Typhoons",
    value: "HurricanesTyphoons",
  },
  {
    label: "Earthquakes",
    value: "Earthquakes",
  },
  {
    label: "Volcanic Eruptions",
    value: "Volcanic",
  },
  {
    label: "Wildfires",
    value: "Wildfires",
  },
  {
    label: "Tsunamis",
    value: "Tsunami",
  },
  {
    label: "Landslides",
    value: "Landslides",
  },
  {
    label: "Drought / Water shortage",
    value: "Drought",
  },
];

const genOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

const generatorTypeOptions = [
  {
    label: "Diesel (or other fuel)",
    value: "Diesel",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  },
  {
    label: "Battery",
    value: "Battery",
  },
];

const flyingOptions = [
  {
    label: "1-24 Flights",
    value: "OneToTwentyFour",
  },
  {
    label: "25-74 Flights",
    value: "TwentyFiveToSeventyFour",
  },
  {
    label: "75-150 Flights",
    value: "SeventyFiveToHundredFifty",
  },
  {
    label: "Over 150 Flights",
    value: "OverHundredFifty",
  },
];

interface SustainabilityPageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function SustainabilityPageV3({
  autosaveForm,
}: SustainabilityPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.sustainability || {};
  const errors = formik.errors.sustainability || {};

  const checkboxesChangeHandler = (
    fieldName: keyof SustainabilitySectionV3,
  ) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`sustainability.${fieldName}`).setValue(values);
    };
  };

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      sustainability: {
        ...formData.sustainability,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Sustainability</Typography>

              <DropdownField
                version="v3"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "calculator",
                }}
                label="Which carbon calculator will be used?"
                error={errors.calculator}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.calculator}
                options={calculatorOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.calculator === "Other" ? (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "sustainability",
                    fieldName: "calculatorDetails",
                  }}
                  label="Other carbon calculator:"
                  error={errors.calculatorDetails}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.calculatorDetails}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <RadioField
                version="v3"
                fieldInfo={{ sectionId: "sustainability", fieldName: "hazard" }}
                label="Will you be working in an area with a significant risk from extreme weather or natural hazards?"
                error={errors.hazard}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.hazard}
                options={hazardOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.hazard === "Yes" ? (
                <CheckboxField
                  version="v3"
                  fieldInfo={{
                    sectionId: "sustainability",
                    fieldName: "naturalHazard",
                  }}
                  label="What natural hazard will the production be exposed to?"
                  error={
                    Array.isArray(errors.naturalHazard)
                      ? errors.naturalHazard[0]
                      : errors.naturalHazard
                  }
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={checkboxesChangeHandler("naturalHazard")}
                  isRequired={true}
                  value={values.naturalHazard}
                  options={naturalHazardOptions}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "gen",
                }}
                label="Will the production require use of a Generator?"
                error={errors.gen}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.gen}
                options={genOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.gen === "Yes" ? (
                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "sustainability",
                    fieldName: "generatorType",
                  }}
                  label="What type of generator/s will be used on your production?"
                  error={errors.generatorType}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.generatorType}
                  options={generatorTypeOptions}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "sustainability",
                  fieldName: "airTravel",
                }}
                label="Will the production require Air Travel?"
                error={errors.airTravel}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.airTravel}
                options={airTravelOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.airTravel === "Yes" ? (
                <RadioField
                  version="v3"
                  fieldInfo={{
                    sectionId: "sustainability",
                    fieldName: "flying",
                  }}
                  label="How many flights will you be booking?"
                  error={errors.flying}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.flying}
                  options={flyingOptions}
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
