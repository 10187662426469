import { FC } from "react";
import { Typography, Box } from "@mui/material";

export const OfcomFormTitleStep1: FC = () => (
  <Box>
    <Typography marginY={2}>
      A Programme will qualify as a ‘European work’ if the producer is
      established in one or more of the European Union member States or from
      European non-member States who are signatories to the European Convention
      on Transfrontier Television (to which the UK is currently a signatory).
    </Typography>
  </Box>
);
