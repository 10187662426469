/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, SVGProps } from "react";

/** @component */
export const ScriptIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 10H32V34H8V22"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 16V5M8 5L4 9M8 5L12 9"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="13"
      y1="29"
      x2="27"
      y2="29"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="13"
      y1="24"
      x2="23"
      y2="24"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="13"
      y1="19"
      x2="27"
      y2="19"
      stroke="#22252f"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
