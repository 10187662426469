import React from "react";
import { Grid, Radio, Typography } from "@mui/material";
import { ProductionType } from "../../types/types";

const ProductionTypePage: React.FC<{
  productionType: ProductionType | null;
  setProductionType: (selectedType: ProductionType) => void;
}> = ({ setProductionType, productionType }) => {
  const handleChange = (selectedType: ProductionType) => {
    setProductionType(selectedType);
  };

  return (
    <>
      <Typography>Start by telling us what you would like to create</Typography>
      <Grid container my={3}>
        <Grid item xs={1}>
          <Radio
            checked={productionType === "series"}
            onChange={() => handleChange("series")}
            value="A Series"
            name="series-radio-button"
            inputProps={{ "aria-label": "A Series" }}
            sx={{ paddingY: 0 }}
          />
        </Grid>
        <Grid item xs={11} display="flex" alignItems="center">
          <Typography>A Series</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="caption" display="flex" alignItems="flex-start">
            This is a collection of episodes which sit under a programme. Only
            create a new programme for this series if an existing programme does
            not exist. e.g love island series 8.
          </Typography>
        </Grid>
      </Grid>

      <Grid container my={3}>
        <Grid item xs={1}>
          <Radio
            checked={productionType === "special"}
            onChange={() => handleChange("special")}
            value="A Special"
            name="special-radio-button"
            inputProps={{ "aria-label": "A Special" }}
            sx={{ paddingY: 0 }}
          />
        </Grid>
        <Grid item xs={11} display="flex" alignItems="center">
          <Typography>Special</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="caption" display="flex" alignItems="flex-start">
            A special episode usually sits under a Programme (existing or new).
            Only create a new programme for this special if there are going to
            be multiple specials or series in the future. e.g The Martin Lewis
            Money Show Live: Mortgage Special.
          </Typography>
        </Grid>
      </Grid>

      <Grid container my={3}>
        <Grid item xs={1}>
          <Radio
            checked={productionType === "oneOff"}
            onChange={() => handleChange("oneOff")}
            value="A One Off"
            name="oneoff-radio-button"
            inputProps={{ "aria-label": "A One Off" }}
            sx={{ paddingY: 0 }}
          />
        </Grid>
        <Grid item xs={11} display="flex" alignItems="center">
          <Typography>One Off</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="caption" display="flex" alignItems="flex-start">
            Does not link to any programme.
          </Typography>
        </Grid>
      </Grid>

      <Grid container my={3}>
        <Grid item xs={1}>
          <Radio
            checked={productionType === "pilot"}
            onChange={() => handleChange("pilot")}
            value="A Pilot or Development"
            name="pilot-radio-button"
            inputProps={{ "aria-label": "A Pilot or Development" }}
            sx={{ paddingY: 0 }}
          />
        </Grid>
        <Grid item xs={11} display="flex" alignItems="center">
          <Typography>A Pilot or Development</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="caption" display="flex" alignItems="flex-start">
            For a pilot or development show, create a single episode series.
          </Typography>
        </Grid>
      </Grid>

      <Grid container my={3}>
        <Grid item xs={1}>
          <Radio
            checked={productionType === "film"}
            onChange={() => handleChange("film")}
            value="A Film"
            name="film-radio-button"
            inputProps={{ "aria-label": "A Film" }}
            sx={{ paddingY: 0 }}
          />
        </Grid>
        <Grid item xs={11} display="flex" alignItems="center">
          <Typography>A Film</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="caption" display="flex" alignItems="flex-start">
            This a standalone, self-contained audiovisual work that tells a
            complete story within a specific duration e.g One For The Road.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductionTypePage;
