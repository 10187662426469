import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Lock as LockIcon } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { ItvLogo } from "../ItvLogo";
import NotificationsContainer from "../../containers/Notifications/NotificationsContainer";
import { ContactUs } from "../../components/ContactUs";
import { useLabelUserEntitlements } from "../../hooks/queries";
import TutorialVideos from "../TutorialVideos/TutorialVideos";
import { NavBarSearch } from "./NavBarSearch";
import { CompassGPT } from "../../compass-gpt/CompassGPT";
import { ContactUsButton } from "../../components/ContactUs/ContactUsButton";
import SwitchUserContainer from "../../containers/OverrideUser/SwitchUserContainer";
import { useGetUserProfile } from "../../hooks/queries/useGetUserProfile";
import NavBarMobileView from "../NavBar/NavBarMobileView";

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const { data: entitlement } = useLabelUserEntitlements();
  const { data: profile } = useGetUserProfile();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isDeskop = !isXs && !isSm && !isMd;
  const desktopSx = {
    bgcolor: "#22252F",
    height: 60,
    display: "flex",
    alignItems: "center",
  };
  const mobileSx = {
    bgcolor: "#22252F",
    height: 60,
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: 0,
  };
  return (
    <Box sx={isDeskop ? desktopSx : mobileSx}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px={4}
      >
        {/* Left hand side */}
        <Link to="/" style={{ textDecoration: "none" }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <ItvLogo />
            {isXs || isSm || isMd ? (
              <></>
            ) : (
              <Typography
                variant="h6"
                color="white"
                fontWeight="regular"
                onClick={() => null}
              >
                Studios Portal
              </Typography>
            )}
          </Stack>
        </Link>
        <NavBarSearch />
        {isXs || isSm || isMd ? <NotificationsContainer /> : <></>}
        {isXs || isSm || isMd ? (
          <NavBarMobileView />
        ) : (
          <>
            {/* Right hand side */}
            <Stack direction="row" spacing={2}>
              {entitlement?.canManageAccess ? (
                <>
                  <Tooltip title="Access Management">
                    <IconButton
                      color="primary"
                      aria-label="access management"
                      onClick={() => navigate(`/access-directory`)}
                      sx={{
                        "&:hover": {
                          backgroundColor: `${theme.palette.primary.dark}`,
                        },
                      }}
                    >
                      <LockIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem color="white" />
                </>
              ) : null}
              <CompassGPT />
              <TutorialVideos />
              <NotificationsContainer />
              {(profile?.userType === "ADMIN" || profile?.viewingAs) && (
                <SwitchUserContainer profile={profile} />
              )}
              <ContactUs ClickableComponent={ContactUsButton} />
              <Button
                onClick={() => oktaAuth.tokenManager.clear()}
                sx={{
                  fontWeight: "light",
                  color: "white",
                  "&:hover": {
                    backgroundColor: `${theme.palette.primary.dark}`,
                  },
                }}
              >
                Logout
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default NavBar;
