import { useParams } from "react-router-dom";
import { Alert, Container, Skeleton, Stack, Typography } from "@mui/material";
import { useSeriesTasks, useTitles } from "../../hooks";
import TasksSection from "../TasksSection";
import { AddMultipleEpisodesDialog } from "../ManageEpisodes/AddMultipleEpisodesDialog";
import SeriesTreatment from "../../componentsV2/SeriesTreatment";
import { useGetUserProfile } from "../../hooks/queries/useGetUserProfile";

const SeriesTasksPage = (): JSX.Element => {
  const { seriesCcid } = useParams();
  const { data, isLoading } = useSeriesTasks(seriesCcid);
  const { data: titlesData } = useTitles(seriesCcid, "series");
  const { data: userProfile } = useGetUserProfile();

  const manageEpisodesTask = data?.tasks.find(
    (item) => item.taskName === "MANAGE-EPISODES",
  );

  return (
    <Container sx={{ py: 4 }}>
      {userProfile?.userType === "ADMIN" && seriesCcid ? (
        <SeriesTreatment ccid={seriesCcid} />
      ) : null}

      {data?.tasks === undefined && !isLoading ? (
        <Typography>There are no tasks to complete</Typography>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h5">Series Tasks</Typography>

          {titlesData?.titleDetails?.length === 0 &&
          manageEpisodesTask !== undefined ? (
            <Alert severity="info" action={<AddMultipleEpisodesDialog />}>
              This series does not have any episodes. Use this button to add new
              episodes to this series.
            </Alert>
          ) : null}

          {isLoading || data?.tasks === undefined ? (
            <Skeleton height={298} variant="rounded" animation="wave" />
          ) : (
            <TasksSection tasks={data.tasks} productionStage="preProduction" />
          )}
          {isLoading || data?.tasks === undefined ? (
            <Skeleton height={298} variant="rounded" animation="wave" />
          ) : (
            <TasksSection tasks={data.tasks} productionStage="inProduction" />
          )}
          {isLoading || data?.tasks === undefined ? (
            <Skeleton height={298} variant="rounded" animation="wave" />
          ) : (
            <TasksSection tasks={data.tasks} productionStage="postProduction" />
          )}
        </Stack>
      )}
    </Container>
  );
};

export default SeriesTasksPage;
