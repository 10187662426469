import { useQuery, UseQueryResult } from "react-query";
import {
  ListImageResponse,
  ListFontResponse,
  ListScriptResponse,
  listDeliverables,
} from "../../api/images";

export const useListDeliverables = (
  activeCcid: string | undefined,
  level: string,
  imageTag: string,
  type: string,
): UseQueryResult<ListImageResponse | ListScriptResponse | ListFontResponse> =>
  useQuery(["uploadedImages", activeCcid, level, imageTag], () =>
    listDeliverables(level, activeCcid as string, imageTag, type),
  );
