/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const noop = (e?: any): void => {};

export const clone = (obj: any) => JSON.parse(JSON.stringify(obj));

export const uuidv4 = () =>
  (1e7 + -1e3 + -4e3 + -8e3 + -1e11)
    .toString()
    .replace(/[018]/g, (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
    );

export const objHasData = (obj: Record<string, string>) =>
  Object.values(obj).some(Boolean);

export const getSynopsisText = (
  value: string | number | undefined,
  prefix: string,
): string => (value ? `${prefix} ${value}` : "");

export const combineTextParts = (part1: string, part2?: string): string =>
  part1.length > 0 && part2 ? `${part1} - ${part2}` : part2 || part1;
