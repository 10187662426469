import { useState, useEffect } from "react";
import {
  Dialog,
  Stack,
  Button,
  Typography,
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export interface DialogProps {
  ccidToRedirect: string;
  seriesNumber: number;
}

export const CreateSeriesDialog: React.FC<DialogProps> = ({
  ccidToRedirect,
  seriesNumber,
}) => {
  const theme = useTheme();
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (ccidToRedirect) setOpen(true);
  }, [ccidToRedirect]);

  return (
    <>
      <Dialog open={isOpen}>
        <Stack
          bgcolor={theme.palette.primary.main}
          color="white"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          We've found an existing series
          <IconButton
            aria-label="close"
            onClick={() => setOpen(!isOpen)}
            color="inherit"
          >
            <Close />
          </IconButton>
        </Stack>
        <Box p={2}>
          <Typography variant="body1">{`A new series ( Series ${seriesNumber} ) has already been created and requires an update, would you like to update this record?`}</Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          style={{ color: "white", padding: "2%" }}
        >
          <Button onClick={() => setOpen(!isOpen)} variant="outlined">
            No, create new series
          </Button>
          <Button
            onClick={() =>
              navigate(`../series/${ccidToRedirect}/update-series`)
            }
            variant="contained"
          >
            Yes, update series
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};
