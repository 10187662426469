/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import {
  TitleContributorsWithData,
  TitleContributors,
} from "./TitleContributors";
import { SeriesContributors } from "./SeriesContributors";

const Contributors: FC = (props: any) => {
  const { titleCcid } = useParams();
  return titleCcid ? (
    props?.inModal ? (
      <TitleContributors {...props} />
    ) : (
      <TitleContributorsWithData />
    )
  ) : (
    <SeriesContributors />
  );
};

export default Contributors;
