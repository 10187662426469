import { useMutation, UseMutationResult } from "react-query";
import { putContributors } from "../../api/contributors";
import { ContributorUpdateProps } from "../../types/types";
import { noop } from "../../utils/appHelper";

export const useUpdateContributors = (
  activeCcid: string | undefined,
  currentContributors: ContributorUpdateProps[],
  newContributors: ContributorUpdateProps[],
  onSuccess = noop,
  onError = noop,
  type?: string,
): UseMutationResult =>
  useMutation(
    () =>
      putContributors(
        activeCcid as string,
        currentContributors,
        newContributors,
        type || "titles",
      ),
    { onSuccess, onError },
  );
