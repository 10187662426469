import { CHECKBOX, TEXTAREA } from "../../../components/DynamicForm";

export const riskLearningFormConfig = [
  {
    sectionTitle: "Risk Learnings",
    key: "Risk Learnings",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "H&S",
        placeholder: "Risks and mitigation. Any accidents?",
        id: "hAndS",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Duty of Care",
        id: "dutyOfCare",
        placeholder:
          "Were there any incidents of note that will change future series procedures? Were there any incidents of note reported on AVA or reported to the Duty of Care team?",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: CHECKBOX,
        label: "Cyber Security",
        id: "cyberSecurity",
        columnCount: 12,
        questionBubble:
          "Have you removed access to data and deleted data no longer required? This should be in according to policy agreed at the start of production. Contact cybersecurity@itv.com if you have any questions around this or need guidance on how to delete data.",
      },
      {
        type: TEXTAREA,
        label: "Compliance",
        id: "compliance",
        placeholder: "Any issues?",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Insurance",
        id: "insurance",
        placeholder:
          "Any incidents of notes, claims, coverage issues related to the format of the show, to consider for future series",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
];
