import { useQuery, UseQueryResult } from "react-query";
import { getTitleVersions } from "../../api/browseassets";
import { VideoMetadataResponsePayload } from "../../types/types";

export const useTitleVersions = (
  titlesCcid: string | undefined,
): UseQueryResult<VideoMetadataResponsePayload> =>
  useQuery(
    ["getVideoVersions", titlesCcid],
    () => getTitleVersions(titlesCcid as string),
    { enabled: Boolean(titlesCcid) },
  );
