import { HomeWorkOutlined } from "@mui/icons-material";
import { Box, Paper, Stack, Typography } from "@mui/material";
import studioLogos from "../logos/logos";
import { UserProfile } from "../types/types";
import theme from "../utils/theme";

interface HomepageGreetingProps {
  userProfile: UserProfile | undefined;
}

const MAX_LABEL_LOGOS = 2;

export function HomepageGreeting({ userProfile }: HomepageGreetingProps) {
  const labels = userProfile?.productionLabels
    ? userProfile.productionLabels.slice(0, MAX_LABEL_LOGOS)
    : [];

  return (
    <Paper sx={{ padding: "24px 16px" }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {labels.length > 0
          ? labels.map((label) => {
              if (label in studioLogos) {
                return (
                  <Box
                    key={label}
                    component="img"
                    maxWidth="120px"
                    src={studioLogos[label]}
                    alt={`${label} logo`}
                    bgcolor="white"
                    padding={1}
                  />
                );
              } else {
                return null;
              }
            })
          : null}

        <Stack direction="column" spacing={0.5} maxWidth="450px">
          <Box component="span">
            <HomeWorkOutlined htmlColor="#0277BD" fontSize="large" />
          </Box>
          <Typography
            component="h1"
            variant="h5"
            fontWeight={400}
            color="primary"
            textTransform="capitalize"
          >
            {userProfile
              ? `Welcome, ${userProfile.firstName} ${userProfile.surname}!`
              : "Welcome!"}
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            letterSpacing="0.17px"
          >
            Let’s get started creating hit content! Check out these options or
            search for a production above to get started:
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
