import { TEXTAREA } from "../../../../../components";
import {} from "../../../../../global.styles";

/* TODO:
  Deprecate this file as we no longer user it for loading the start up form component.
  It is only used in the sanitizeFormData method which utilizes the types of each field
  and based on that, it sanitizes the data before passing them to the components.
  The solution here is to either fix the backend to return the data in the right format or
  do the clean up at the component level like we did with the contributors component in ContributorsAndTalent.tsx
*/
export const riskAndInsuranceFormConfig = [
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label:
          "Is Production and Key Cast Insurance in Place? Are there any other insurance issues?",
        id: "insurances",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Any issues from previous series to be mindful of?",
        id: "previousSeriesIssues",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Any Business Risks to be aware of?",
        id: "businessRisks",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Business Continuity Plan",
        id: "businessContinuityPlan",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Incident and Crisis Management",
        id: "incidentAndCrisisManagement",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Any Working Time Issues?",
        id: "workingTimeIssues",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Smart Working",
        id: "smartWorking",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Health and safety",
        id: "healthAndSafety",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXTAREA,
        label: "Covid-19",
        id: "covid19",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
  {
    sectionTitle: "",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "Additional notes",
        id: "additionalNotes",
        value: undefined,
        placeholder: "Please type your answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        excludedFromCompletion: true,
      },
    ],
  },
];
