import { StepItemStatus } from "../../../../../components/Steps";
import { ContributorUpdateProps } from "../../../../../types/types";

export const getSynopsisItemStatus = (
  latestSynopsisValues: Record<string, string>,
): StepItemStatus => {
  let haveAllSynopsisValues = true;
  let haveNoneSynopsisValues = true;

  // eslint-disable-next-line no-restricted-syntax
  for (const synopsisKey of [
    "synopsisShort",
    "synopsisMedium",
    "synopsisLong",
  ]) {
    if (latestSynopsisValues?.[synopsisKey]) {
      haveNoneSynopsisValues = false;
    } else {
      haveAllSynopsisValues = false;
    }
  }

  if (haveAllSynopsisValues) {
    return StepItemStatus.Complete;
  }

  if (haveNoneSynopsisValues) {
    return StepItemStatus.InComplete;
  }

  return StepItemStatus.PartialInComplete;
};

export const getContributorItemStatus = (
  contributor: ContributorUpdateProps[],
): StepItemStatus => {
  if (!contributor || contributor.length <= 0) {
    return StepItemStatus.InComplete;
  }

  return StepItemStatus.Complete;
};
