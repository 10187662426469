/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, CardContent, FormLabel, Grid, TextField } from "@mui/material";
import { FormikErrors, FormikValues } from "formik";
import { FC } from "react";

export interface RiskAndInsuranceProps {
  values: FormikValues;
  blurHandler: (id: any, newValue: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
}

export const RiskAndInsuranceForm: FC<RiskAndInsuranceProps> = ({
  values,
  blurHandler,
  setFieldValue,
}) => (
  <>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            {/* Is Production and Key Cast Insurance in Place? Are there any other insurance issues? */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Is Production and Key Cast Insurance in Place? Are there any other
              insurance issues?
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.insurances}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "riskAndInsurance.insurances",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler("riskAndInsurance.insurances", event.target.value);
              }}
            />

            {/* Any issues from previous series to be mindful of? */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Any issues from previous series to be mindful of?
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.previousSeriesIssues}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "riskAndInsurance.previousSeriesIssues",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "riskAndInsurance.previousSeriesIssues",
                  event.target.value,
                );
              }}
            />

            {/* Any Business Risks to be aware of? */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Any Business Risks to be aware of?
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.businessRisks}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "riskAndInsurance.businessRisks",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "riskAndInsurance.businessRisks",
                  event.target.value,
                );
              }}
            />

            {/* Business Continuity Plan */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Business Continuity Plan
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.businessContinuityPlan}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "riskAndInsurance.businessContinuityPlan",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "riskAndInsurance.businessContinuityPlan",
                  event.target.value,
                );
              }}
            />

            {/* Incident and Crisis Management */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Incident and Crisis Management
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.incidentAndCrisisManagement}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "riskAndInsurance.incidentAndCrisisManagement",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "riskAndInsurance.incidentAndCrisisManagement",
                  event.target.value,
                );
              }}
            />

            {/* Any Working Time Issues? */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Any Working Time Issues?
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.workingTimeIssues}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "riskAndInsurance.workingTimeIssues",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "riskAndInsurance.workingTimeIssues",
                  event.target.value,
                );
              }}
            />

            {/* Smart Working */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Smart Working
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.smartWorking}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "riskAndInsurance.smartWorking",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "riskAndInsurance.smartWorking",
                  event.target.value,
                );
              }}
            />

            {/* Health and safety */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Health and safety
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.healthAndSafety}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "riskAndInsurance.healthAndSafety",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "riskAndInsurance.healthAndSafety",
                  event.target.value,
                );
              }}
            />

            {/* Covid-19 */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              Covid-19
            </FormLabel>
            <TextField
              value={values.riskAndInsurance.covid19}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue("riskAndInsurance.covid19", event.target.value);
              }}
              onBlur={(event) => {
                blurHandler("riskAndInsurance.covid19", event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        {/* Additional notes */}
        <FormLabel sx={{ display: "block", marginBottom: "0.5rem" }}>
          Additional notes
        </FormLabel>
        <TextField
          value={values.riskAndInsurance.additionalNotes}
          multiline
          fullWidth
          placeholder="Please type your answer here"
          maxRows={8}
          minRows={8}
          onChange={(event) => {
            setFieldValue(
              "riskAndInsurance.additionalNotes",
              event.target.value,
            );
          }}
          onBlur={(event) => {
            blurHandler("riskAndInsurance.additionalNotes", event.target.value);
          }}
        />
      </CardContent>
    </Card>
  </>
);
