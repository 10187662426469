/* eslint-disable @typescript-eslint/no-explicit-any */

import { styled } from "@mui/system";

export const StyledNoAccessDialog = styled("div")`
  background-color: #0f72ec;
`;

export const StyledMessageDiv = styled("div")`
  color: ${({ theme }: any) => theme.palette.white};
  padding-left: 5%;
`;

export const StyledLinearProgress = styled("div")`
  color: #0f72ec;
`;

export const StyledH1 = styled("h1")`
  padding-bottom: 5vh;
`;

export const StyledRequestedAccessImg = styled("img")`
  padding-top: 2vh;
  padding-bottom: 2vh;
`;

export const StyledNoAccessImg = styled("img")`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
