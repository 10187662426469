import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { useDeleteEpisode } from "../../hooks/mutations/useDeleteEpisode";
import { TitleDetailsProps } from "../../types/types";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

export const CanDeleteEpisode = ({
  setOpen,
  title,
}: {
  setOpen: (open: boolean) => void;
  title: TitleDetailsProps;
}): JSX.Element => {
  const { mutateAsync: deleteEpisode, isLoading: deletingEpisode } =
    useDeleteEpisode();

  const handleDeleteClick = () => {
    deleteEpisode({ ccid: title.ccid })
      .then(() => {
        setOpen(false);
        enqueueSnackbar(`You have deleted episode ${title.episodeNumber}!`);
      })
      .catch(() => {
        setOpen(false);
        enqueueSnackbar(
          `There was an error deleting episode ${title.episodeNumber}. Please try again.`,
          { variant: "error", action: SnackbarDismiss },
        );
      });
  };

  return (
    <Stack m={2} spacing={2}>
      <Typography variant="h5">You're about to delete this episode</Typography>
      <Typography>
        Deleting an episode cannot be reversed. Are you sure you want to remove
        episode {title.episodeNumber} of this series?
      </Typography>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton
          variant="contained"
          onClick={handleDeleteClick}
          loading={deletingEpisode}
          disabled={deletingEpisode}
        >
          Yes, Delete episode
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
