import { Help } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormikHandlers } from "formik";
import theme from "../../../utils/theme";
import { NotificationFormFieldProps } from "../../../types/types";
import { UseUpdateRiskArgsNew } from "../../../hooks";
import { DEFAULT_NPNF_VERSION } from "../notificationForm.helper";

interface Props {
  label: string;
  error: string | undefined;
  shouldDisplayErrors: boolean;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  isRequired: boolean;
  value: string | undefined;
  options: Array<{ label: string; value: string }>;
  tooltipContent?: string;
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

type RadioFieldProps = NotificationFormFieldProps<Props>;

export function RadioField({
  version,
  fieldInfo,
  label,
  error,
  shouldDisplayErrors,
  handleChange,
  handleBlur,
  isRequired,
  value,
  options,
  tooltipContent,
  autosaveForm,
}: RadioFieldProps) {
  const inputId = `${fieldInfo.sectionId}.${fieldInfo.fieldName}`;

  return (
    <FormControl required={isRequired}>
      <Box display="inline-flex" alignItems="center">
        <FormLabel
          sx={{
            marginBottom: "16px",
            "& .MuiFormLabel-asterisk": {
              color: theme.palette.error.main,
            },
          }}
          id={`${inputId}-label`}
        >
          {label}
        </FormLabel>

        {tooltipContent ? (
          <Tooltip title={<Typography>{tooltipContent}</Typography>}>
            <IconButton>
              <Help color="primary" />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>

      <RadioGroup
        id={inputId}
        name={inputId}
        value={value || ""}
        aria-labelledby={`${inputId}-label`}
        aria-describedby={`${inputId}-error`}
        sx={{ width: "80%", paddingLeft: "24px" }}
      >
        {options.map((option) => (
          <FormControlLabel
            disabled={version !== DEFAULT_NPNF_VERSION}
            key={option.value}
            label={option.label}
            value={option.value}
            control={
              <Radio
                onChange={(event) => {
                  handleChange(event);
                  autosaveForm({
                    [fieldInfo.sectionId]: {
                      [fieldInfo.fieldName]: option.value,
                    },
                  });
                }}
                onBlur={handleBlur}
              />
            }
          />
        ))}
      </RadioGroup>

      {shouldDisplayErrors && error ? (
        <FormHelperText id={`${inputId}-error`} error={true} sx={{ margin: 0 }}>
          {error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
