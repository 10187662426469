import { FC } from "react";
import { useCcidInfo } from "../../hooks/queries/useCcidInfo";
import {
  NotificationResponsePayload,
  NotificationProps,
  NotificationDetails,
  CcidMetadata,
} from "../../types/types";
import NotificationCard from "../../componentsV2/Notification/NotificationCard";
import {
  AssignmentOutlined,
  CalculateOutlined,
  CloudDoneOutlined,
  LockClockOutlined,
  LockOpen,
  PersonAddAlt1Outlined,
} from "@mui/icons-material";

const NotificationsItemContainer: FC<NotificationResponsePayload> = (
  notificationPayload: NotificationResponsePayload,
) => {
  const { ccid, notificationType, originator, item } = notificationPayload;

  const { data, isSuccess } = useCcidInfo(ccid);

  const getRiskFormLinkUri = (metadata: CcidMetadata): string => {
    switch (metadata.entryType) {
      case "FILM":
      case "SPECIAL":
        return `/special/${ccid}/notification-form-new`;
      case "BRANDED_SPECIAL":
        return `/programmes/${metadata.parents[0]}/series/0/titles/${ccid}/notification-form-new`;
      case "SERIES":
        return `/programmes/${metadata.parents[0]}/series/${ccid}/notification-form-new`;
      default:
        return "/";
    }
  };

  const getTasksPageUri = (metadata: CcidMetadata): string => {
    switch (metadata.entryType) {
      case "FILM":
      case "SPECIAL":
        return `/special/${ccid}`;
      case "BRANDED_SPECIAL":
        return `/programmes/${metadata.parents[0]}/series/0/titles/${ccid}`;
      case "SERIES":
        return `/programmes/${metadata.parents[0]}/series/${ccid}`;
      default:
        return "/";
    }
  };

  const capitalSnakecaseParser = (input: string) => {
    const words = input.split("_");
    const transformedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    );
    return transformedWords.join(" ");
  };

  const getNotificationProps = (): NotificationProps => {
    const notificationDetails: () => NotificationDetails = () => {
      switch (notificationType) {
        case "ACCESS_REQUEST":
          return {
            icon: <LockClockOutlined sx={{ mr: 2 }} />,
            primaryText: "Access Requested",
            secondaryText: `${originator} has requested access to ${item}`,
            getLink: () => {
              return "/access-directory";
            },
          };
        case "RISK_SCORE_COMPLETE":
          return {
            icon: <CalculateOutlined sx={{ mr: 2 }} />,
            primaryText: "Risk Score Calculated",
            secondaryText: `Risk calculation is complete for ${item}`,
            getLink: (metadata: CcidMetadata) => {
              return getRiskFormLinkUri(metadata);
            },
          };
        case "PRODUCTION_LEAD_ASSIGNED":
          return {
            icon: <PersonAddAlt1Outlined sx={{ mr: 2 }} />,
            primaryText: "Production Lead Assigned",
            secondaryText: item,
            getLink: (metadata) => {
              return getTasksPageUri(metadata);
            },
          };
        case "DRIVE_CREATED":
          return {
            icon: <CloudDoneOutlined sx={{ mr: 2 }} />,
            primaryText: "Google Drive Created",
            secondaryText: item,
            getLink: (metadata) => {
              return getTasksPageUri(metadata);
            },
          };
        case "TASK_ASSIGNMENT":
          return {
            icon: <AssignmentOutlined sx={{ mr: 2 }} />,
            primaryText: "Assigned a Task",
            secondaryText: item,
            getLink: (metadata) => {
              return getTasksPageUri(metadata);
            },
          };
        case "ACCESS_GRANTED":
          return {
            icon: <LockOpen sx={{ mr: 2 }} />,
            primaryText: "Access Granted",
            secondaryText: item,
            getLink: (metadata) => {
              return getTasksPageUri(metadata);
            },
          };
        default:
          return {
            icon: <PersonAddAlt1Outlined sx={{ mr: 2 }} />,
            primaryText: capitalSnakecaseParser(notificationType),
            secondaryText: item,
            getLink: (metadata) => {
              return getTasksPageUri(metadata);
            },
          };
      }
    };

    const { primaryText, secondaryText, getLink, icon } = notificationDetails();

    return isSuccess
      ? { primaryText, secondaryText, notificationLink: getLink(data), icon }
      : { primaryText, secondaryText, notificationLink: "#", icon };
  };

  return <NotificationCard {...getNotificationProps()} />;
};

export default NotificationsItemContainer;
