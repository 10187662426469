import { useQuery, UseQueryResult } from "react-query";
import { getTasks } from "../../api/portal";
import { TasksResponsePayload } from "../../types/types";

export const useTasks = (
  programmeCcid: string | undefined,
  seriesCcid: string | undefined,
  titleCcid: string | undefined,
): UseQueryResult<TasksResponsePayload> =>
  useQuery(["tasks", programmeCcid, seriesCcid, titleCcid], () =>
    getTasks(programmeCcid, seriesCcid, titleCcid),
  );
