/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const GO_TO_NEXT_FROM_SYNOPSIS = "GO_TO_NEXT_FROM_SYNOPSIS";
export const SAVE_AND_CLOSE_FROM = "SAVE_AND_CLOSE_FROM";
export const SYNOPSIS_CHANGE = "SYNOPSIS_CHANGE";
export const CONTRIBUTORS_CHANGE = "CONTRIBUTORS_CHANGE";
export const FINISH_CONTRIBUTORS_FORM = "FINISH_CONTRIBUTORS_FORM";
export const FINISH_CONTRIBUTORS_FORM_STARTUP =
  "FINISH_CONTRIBUTORS_FORM_STARTUP";
export const SHOW_TOAST = "SHOW_TOAST";
export const CLOSE = "CLOSE";
export const BRAND_TITLE_UPDATED = "BRAND_TITLE_UPDATED";
export const SERIES_TITLE_UPDATED = "SERIES_TITLE_UPDATED";
export const EPISODE_TITLE_UPDATED = "EPISODE_TITLE_UPDATED";
export const OPEN_DRAWER = "OPEN_DRAWER";
export const HIDE_DRAWER = "HIDE_DRAWER";

interface Callback {
  (data: any): void;
}

const eventMap: Record<string, Callback[]> = {};

export class NotificationService {
  static sub(eventName: string, callback: Callback, replace = false): void {
    if (!eventMap[eventName]) {
      eventMap[eventName] = [];
    }

    if (replace) {
      eventMap[eventName] = [callback];
    } else {
      eventMap[eventName].push(callback);
    }
  }

  static pub(eventName: string, data?: any): void {
    if (eventMap[eventName]) {
      eventMap[eventName].forEach((cb: Callback) => cb(data));
    }
  }

  static remove(eventName: string, callback: Callback): Callback[] | undefined {
    if (eventMap[eventName]?.length) {
      eventMap[eventName] = eventMap[eventName].filter((cb) => cb !== callback);

      if (!eventMap[eventName]?.length) {
        delete eventMap[eventName];
      }
    }

    return eventMap[eventName];
  }

  static destroy(eventNames: string[]) {
    eventNames.forEach((eventName) => {
      delete eventMap[eventName];
    });
  }
}
