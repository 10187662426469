import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { type InsuranceSection } from "../yupSchemas/insuranceSchema";
import { type NotificationFormSubmitValues } from "../NotificationFormV2";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { YesNoField } from "../formFields/YesNoField";
import { MultiLineTextField } from "../formFields/MultiLineTextField";
import { UseUpdateRiskArgsNew } from "../../../hooks";

const cashOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not sure but it's unlikely",
    value: "Unlikely",
  },
  {
    label: "Not sure but it's likely",
    value: "Likely",
  },
];

const procurementOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "Not yet known",
  },
];

interface InsurancePageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function InsurancePage({ autosaveForm }: InsurancePageProps) {
  const formik = useFormikContext<NotificationFormSubmitValues>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.insurance || {};
  const errors = formik.errors.insurance || {};

  const yesNoChangeHandler = (fieldName: keyof InsuranceSection) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`insurance.${fieldName}`).setValue(isYes);
    };
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">
                Cast Insurance (Production Budget Insurance)
              </Typography>

              <Typography variant="body1">
                Cast insurance is in place to provide cover to the budget for
                the additional costs incurred if cast or crew are unable to work
                due to sickness or injury (this does not need to be
                work-related) in extreme cases this could include abandonment of
                a Production due to reliance on key individuals.
              </Typography>

              <Typography variant="body1">
                A higher level of medical confirmation of fitness than the
                standard process is required for more significant financial
                exposure.
              </Typography>

              <Typography variant="body1">
                If you have cast or crew involved in your Production that could
                have a greater financial impact than 500,000 to your production
                if they are unable to work or cause you to abandon you will need
                to declare them below and they will require a medical. The
                insurance team will be in touch to discuss how this will work.
              </Typography>

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "talentLoss" }}
                label="Could the loss of key talent (1 or more individuals) have a greater financial impact than 500,000 ($, £ or €) above the production budget?"
                error={errors.talentLoss}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("talentLoss")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.talentLoss}
                autosaveForm={autosaveForm}
              />

              {values.talentLoss === true ? (
                <SingleLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "insurance",
                    fieldName: "talentLossList",
                  }}
                  label="Please list the names of those key individuals"
                  error={errors.talentLossList}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.talentLossList}
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "shutdown" }}
                label="Could the complete loss of key talent (1 or more individuals) cause the production to be shut down and possibly have the network abandon the project? "
                error={errors.shutdown}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("shutdown")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.shutdown}
                autosaveForm={autosaveForm}
              />

              {values.shutdown === true ? (
                <SingleLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "insurance",
                    fieldName: "shutdownDetails",
                  }}
                  label="Please list the names of the key individuals and confirm their roles"
                  error={errors.shutdownDetails}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.shutdownDetails}
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "age" }}
                label="Will any cast members be over the age of 75 or under 6?"
                error={errors.age}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("age")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.age}
                autosaveForm={autosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">
                Wardrobe, Props And Cash Declaration
              </Typography>

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "jewellery" }}
                label="Will the production be responsible for any jewellery/jewelry, furs, antiques with a total value of more than 100k (or equivalent)?"
                error={errors.jewellery}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.jewellery}
                options={cashOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "insurance",
                  fieldName: "personalCash",
                }}
                label="Will any one person on the production be carrying more than 2.5k (or equivalent) cash at any point in time?"
                error={errors.personalCash}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.personalCash}
                options={cashOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "insurance",
                  fieldName: "productionCash",
                }}
                label="Will the production hold cash to a value greater than 75k (or equivalent) on location?"
                error={errors.productionCash}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.productionCash}
                options={cashOptions}
                autosaveForm={autosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Procurement</Typography>

              <Typography variant="body1">
                Will any spend be over £100k or the local currency equivalent in
                the following categories? Please detail below so that we can get
                in touch with you to give you additional support and risk
                management advice where it’s needed.
              </Typography>

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "post" }}
                label="Post Production"
                error={errors.post}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.post}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "ob" }}
                label="Outside Broadcast (OB)"
                error={errors.ob}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.ob}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "av" }}
                label="Audiovisual (AV) hire"
                error={errors.av}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.av}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "vehicles" }}
                label="Facility Vehicles"
                error={errors.vehicles}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.vehicles}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "sound" }}
                label="Sound Equipment"
                error={errors.sound}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.sound}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "rigging" }}
                label="Rigging or Camera Cranes"
                error={errors.rigging}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.rigging}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "camera" }}
                label="Camera Hire"
                error={errors.camera}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.camera}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "psc" }}
                label="Portable Single Camera (PSC) Crews"
                error={errors.psc}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.psc}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "source" }}
                label="Sourced Material"
                error={errors.source}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.source}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "lighting" }}
                label="Lighting"
                error={errors.lighting}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.lighting}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "studio" }}
                label="Studio Hire"
                error={errors.studio}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.studio}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "set" }}
                label="Set Construction"
                error={errors.set}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.set}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "security" }}
                label="Security"
                error={errors.security}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.security}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "catering" }}
                label="Catering"
                error={errors.catering}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.catering}
                options={procurementOptions}
                autosaveForm={autosaveForm}
              />

              <MultiLineTextField
                version="v2"
                fieldInfo={{ sectionId: "insurance", fieldName: "procOther" }}
                label="Other - please detail"
                error={errors.procOther}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.procOther}
                placeholder="Type answer here"
                autosaveForm={autosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
