import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, Stack } from "@mui/system";
import { Typography } from "@mui/material";
import {
  useEssenceTimecode,
  useSelectedVideoUrl,
} from "../../../../../../hooks";
import { CannotPalyIcon } from "../../../../../../icons/CannotPlay";
import { noop } from "../../../../../../utils/appHelper";
import { TimeRange } from "../TimeCodeEditor";
import { convertSecondsToTimeCode, convertTimeCodeToSeconds } from "../util";
import theme from "../../../../../../utils/theme";

const START_TIME_NEGATIVE = "Start time can not be negative";
const END_TIME_TOO_LARGE = "End time can not be larger than video end time";
const END_TIME_TOO_SMALL = "End time can not be smaller than start time";
const DURATION_TOO_LARGE = "Duration can not be larger than the essence video";

const ERRORS = [
  START_TIME_NEGATIVE,
  END_TIME_TOO_LARGE,
  END_TIME_TOO_SMALL,
  DURATION_TOO_LARGE,
];

interface TimeCodeVideoPlayerProps {
  timeRange: TimeRange;
  selectedVersionId: string;
  timecodeError: string;
  onError?(error: string): void;
}

export const TimeCodeVideoPlayer: FC<TimeCodeVideoPlayerProps> = ({
  timeRange,
  selectedVersionId,
  onError = noop,
  timecodeError,
}) => {
  const { data: selectedVideoUrl } = useSelectedVideoUrl(selectedVersionId);
  const { data: essenceTimecodeData, error: essenceTimecodeError } =
    useEssenceTimecode(selectedVersionId);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);

  const videoRef = useRef<HTMLVideoElement>(null);

  const offsetTime = convertTimeCodeToSeconds(
    essenceTimecodeData?.essenceTimecode?.start || "00:00:00:00",
  );

  useEffect(() => {
    const videoEle = videoRef.current;

    const timeupdateHandler = () => {
      const currentTime = videoEle?.currentTime ?? 0;

      if (currentTime >= parseInt(endTime.toString(), 10)) {
        videoEle?.pause();
      }
    };

    if (videoEle) {
      videoEle.pause();
      const totalTime = videoEle?.duration;
      const intStartTime = parseInt(startTime.toString(), 10);
      const intEndTime = parseInt(endTime.toString(), 10);

      let error = "";

      if (essenceTimecodeError) {
        // skip validation if essence timecode error tactical fix needs to be addressed later
      } else if (intStartTime < 0) {
        error = START_TIME_NEGATIVE;
      } else if (intEndTime > totalTime) {
        error = END_TIME_TOO_LARGE;
      } else if (intEndTime < intStartTime) {
        error = END_TIME_TOO_SMALL;
      } else if (intEndTime - intStartTime > totalTime) {
        error = DURATION_TOO_LARGE;
      }

      if (!error) {
        videoEle.currentTime = startTime;
        videoEle.removeEventListener("timeupdate", timeupdateHandler);
        videoEle.addEventListener("timeupdate", timeupdateHandler);
      }

      if (error) {
        if (timecodeError !== error) {
          onError(error);
        }
      } else if (!timecodeError || ERRORS.includes(timecodeError)) {
        onError("");
      }
    }

    return () => {
      videoEle?.removeEventListener("timeupdate", timeupdateHandler);
    };
  }, [
    startTime,
    endTime,
    onError,
    offsetTime,
    timeRange,
    timecodeError,
    essenceTimecodeError,
  ]);

  useEffect(() => {
    const newStartTime = convertTimeCodeToSeconds(timeRange?.startTime || "");
    const newEndTime = convertTimeCodeToSeconds(timeRange?.endTime || "");

    if (newStartTime && newEndTime) {
      setStartTime(newStartTime - offsetTime);
      setEndTime(newEndTime - offsetTime);
    }
  }, [timeRange, offsetTime, onError]);

  const duration = useMemo(() => {
    const startTimeInSeconds = convertTimeCodeToSeconds(
      timeRange?.startTime || "",
    );
    const endTimeInSeconds = convertTimeCodeToSeconds(timeRange?.endTime || "");

    return convertSecondsToTimeCode(endTimeInSeconds - startTimeInSeconds);
  }, [timeRange]);

  return (
    <div>
      {selectedVideoUrl ? (
        <div>
          <video
            ref={videoRef}
            controls
            controlsList="nodownload"
            width="516"
            height="312"
            data-testid="titleVideo"
          >
            <track default kind="captions" src="en" />
            Sorry, your browser doesn't support embedded videos.
            <source src={`${selectedVideoUrl}#t=${startTime},${endTime}`} />
          </video>
          <Stack>
            <Box
              sx={{
                textAlign: "right",
                fontSize: 14,
              }}
            >
              <Typography variant="subtitle2" color={theme.palette.osloGray}>
                Duration:
              </Typography>
              <div>{duration}</div>
            </Box>
          </Stack>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: 512,
            height: 312,
            border: 1,
            borderRadius: 2,
            justifyContent: "center",
            alignItems: "center",
            borderColor: "#55565E",
            backgroundColor: "#ACB7B9",
          }}
        >
          <CannotPalyIcon />
        </Box>
      )}
    </div>
  );
};
