import React from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import { useGetTreatments } from "../hooks";
import { getAltThumbnail } from "../containers/Synopsis/Edit/Images/Thumbnail/Thumbnail";
import { Treatment } from "../types/types";
import handleRequest from "../lib/handleRequest";
import { useMutation } from "react-query";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "./SnackBarDismiss";

interface SeriesTreatmentProps {
  ccid: string;
}

const SeriesTreatment: React.FC<SeriesTreatmentProps> = ({ ccid }) => {
  const { data: treatment, isLoading, error } = useGetTreatments(ccid);

  if (isLoading) {
    return (
      <>
        <Typography variant="h5">Series Treatment</Typography>
        <Box paddingY={2}>
          <Skeleton
            height="150px"
            width="100%"
            variant="rounded"
            animation="wave"
          />
        </Box>
      </>
    );
  }

  if (error && error?.response?.status !== 404) {
    return (
      <>
        <Typography variant="h5">Series Treatment</Typography>
        <Box padding={2} display="flex" justifyContent="center">
          <Alert severity="error">
            There was an issue retrieving the treatment assets for this
            production
          </Alert>
        </Box>
      </>
    );
  }

  if (!treatment || !treatment.assets.length) {
    return (
      <>
        <Typography variant="h5">Series Treatment</Typography>
        <Box padding={2} display="flex" justifyContent="center">
          <Alert severity="info">
            No treatment assets found for this production
          </Alert>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography variant="h5">Series Treatment</Typography>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          gap: 2,
          paddingY: 4,
          whiteSpace: "nowrap",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for Webkit browsers
          },
          msOverflowStyle: "none", // Hide scrollbar for IE and Edge
          scrollbarWidth: "none", // Hide scrollbar for Firefox
        }}
      >
        {treatment.assets.map((asset, index) => (
          <TreatmentAsset key={asset.url} asset={asset} assetIndex={index} />
        ))}
      </Box>
    </>
  );
};

function TreatmentAsset({
  asset,
  assetIndex,
}: {
  asset: Treatment;
  assetIndex: number;
}) {
  const { mutate: downloadAsset, isLoading } = useMutation({
    mutationFn: async () => {
      // Use Blob method to download when no thumbnails are available
      const response = await handleRequest
        .get(asset.url, {
          responseType: "blob",
          headers: {},
        })
        .then((res) => new Blob([res.data], { type: `${asset.mimeType}` }));
      const url = window.URL.createObjectURL(response);

      // Create an anchor element and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = asset.filename;
      a.click();

      // Revoke the Blob URL after download
      window.URL.revokeObjectURL(url);
    },

    onError: () => {
      enqueueSnackbar(
        "Failed to download asset. Please try again or contact us if the issue persists",
        { variant: "error", action: SnackbarDismiss },
      );
    },
  });

  return (
    <Box
      sx={{
        minWidth: 200,
        maxWidth: 200,
        minHeight: 100,
        textWrap: "wrap",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress size={32} />
        </Box>
      ) : (
        <TreatmentAssetContainer asset={asset} downloadAsset={downloadAsset}>
          <Box
            className="asset-overlay"
            sx={{
              opacity: 0,
              transition: "opacity 500ms ease-in-out",
            }}
            position="absolute"
            width="100%"
            height="100%"
            bottom={0}
            bgcolor="rgba(0, 0, 0, 0.8)"
            borderRadius={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding={1}
          >
            <Typography component="span" variant="caption" color="#fff">
              {asset.filename}
            </Typography>
          </Box>
          <Box
            component="img"
            src={
              asset.thumbnails
                ? asset.thumbnails.large.url
                : getAltThumbnail(asset.filename)
            }
            alt={`Asset ${assetIndex + 1}`}
            width="100%"
            height="auto"
            borderRadius={4}
          />
        </TreatmentAssetContainer>
      )}
    </Box>
  );
}

function TreatmentAssetContainer({
  asset,
  children,
  downloadAsset,
}: {
  asset: Treatment;
  children: React.ReactNode;
  downloadAsset: () => void;
}) {
  if (asset.thumbnails) {
    return (
      <Box
        component="a"
        href={asset.url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`download ${asset.filename}`}
        sx={{
          display: "inline-block",
          position: "relative",
          wordBreak: "break-all",
          padding: 0,
          borderRadius: 4,
          "&:hover, &:focus": {
            "& .asset-overlay": { opacity: 1 },
          },
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Button
      aria-label={`download ${asset.filename}`}
      onClick={downloadAsset}
      disableRipple={true}
      sx={{
        position: "relative",
        wordBreak: "break-all",
        padding: 0,
        borderRadius: 4,
        "&:hover, &:focus": {
          "& .asset-overlay": { opacity: 1 },
        },
      }}
    >
      {children}
    </Button>
  );
}

export default SeriesTreatment;
