import * as Yup from "yup";

export const participantWelfareSchemaV3 = Yup.object().shape({
  spotId: Yup.string(),
  vulnerability: Yup.string().required("This is a required field"),
  participation: Yup.string().required("This is a required field"),
  conditions: Yup.string().required("This is a required field"),
  environment: Yup.string().required("This is a required field"),
  exposure: Yup.string().required("This is a required field"),
  sensitiveInformation: Yup.string().required("This is a required field"),
  mentalHealthAdvisorEngaged: Yup.string().required("This is a required field"),
  mentalHealthAdvisor: Yup.string().when(
    "$participantWelfare.mentalHealthAdvisorEngaged",
    ([mentalHealthAdvisorEngaged], schema) => {
      return mentalHealthAdvisorEngaged && mentalHealthAdvisorEngaged === "Yes"
        ? schema.required("This is a required field")
        : schema.notRequired();
    },
  ),
  mentalHealthAdvisorEmail: Yup.string().when(
    "$participantWelfare.mentalHealthAdvisorEngaged",
    ([mentalHealthAdvisorEngaged], schema) => {
      return mentalHealthAdvisorEngaged && mentalHealthAdvisorEngaged === "Yes"
        ? schema.required("This is a required field")
        : schema.notRequired();
    },
  ),
});

export type ParticipantWelfareSectionV3 = Yup.InferType<
  typeof participantWelfareSchemaV3
>;
