import { FC, useState } from "react";
import {
  Typography,
  Container,
  Stack,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  Tooltip,
  IconButton,
  Dialog,
  Alert,
  Link,
  TextField,
} from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Close } from "@mui/icons-material";
import { useFormikContext, Field } from "formik";
import {
  BooleanAsYesNo,
  ProductionPrinciplesFormDataFormik,
} from "../../types/types";
import { useProductionPrinciplesFormSubmission } from "../../hooks";
import { transformFormDataToServer } from "./transformFormDataToServer";

interface ProductionPrinciplesPageOneFormikProps {
  ccid: string;
  provisional: boolean;
}

export const ProductionPrinciplesPage2: FC<
  ProductionPrinciplesPageOneFormikProps
> = ({ ccid, provisional }) => {
  const [open, setOpen] = useState(false);
  const form = useFormikContext<ProductionPrinciplesFormDataFormik>();
  const { mutate: updateProductionPrinciplesForm } =
    useProductionPrinciplesFormSubmission();

  const handleTextBlur = (
    fieldName: keyof ProductionPrinciplesFormDataFormik,
  ) => {
    const text = form.values[fieldName] as string;
    // eslint-disable-next-line
    const fieldObject = { [fieldName]: text } as any;
    if (!form.errors[fieldName]) {
      updateProductionPrinciplesForm({
        ccid,
        formData: { inclusionPolicies: {}, inclusiveProgramming: fieldObject },
        autosave: true,
        provisional,
      });
    } else {
      form.setFieldTouched(fieldName);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={{ p: 2 }}>
          <Alert severity="info">
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography variant="body2">
                If you would like additional help with this, please email{" "}
                <strong>Nahrein.Kemp@itv.com</strong> or{" "}
                <strong>Sam.Tatlow@itv.com</strong>
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                color="primary"
              >
                <Close />
              </IconButton>
            </Stack>
          </Alert>
        </Box>
      </Dialog>
      <Container sx={{ background: "white", my: 2 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">
            Inclusive programming - On Screen
            <Tooltip title="help">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <FormControl component="fieldset" sx={{ m: 1 }} variant="standard">
            <Stack direction="column" spacing={2} py={2}>
              <FormControl
                component="fieldset"
                sx={{ m: 1 }}
                variant="standard"
              >
                <Stack spacing={4}>
                  <Stack>
                    <FormLabel component="legend">
                      Will you ensure that at least one of your lead characters
                      is played by an actor from an underrepresented group (in
                      particular, People of Colour and, Deaf, Disabled and/or
                      Neurodivergent people)
                    </FormLabel>
                    <RadioGroup
                      name="underrepresentedActor"
                      value={form.values.underrepresentedActor}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            underrepresentedActor: event.target
                              .value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Stack>
                  <Stack>
                    <FormLabel component="legend">
                      Will you ensure there are a broad range of actors featured
                      including those from underrepresented groups (in
                      particular, People of Colour and Deaf, Disabled and/or
                      Neurodivergent people)*
                    </FormLabel>
                    <RadioGroup
                      name="underrepresentedGroup"
                      value={form.values.underrepresentedGroup}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            underrepresentedGroup: event.target
                              .value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Stack>
                  <Stack>
                    <FormLabel component="legend">
                      Does the storyline include subject matter that is about an
                      underrepresented group as the main focus or featured
                      within one episode?*
                    </FormLabel>
                    <RadioGroup
                      name="underrepresentedStory"
                      value={form.values.underrepresentedStory}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            underrepresentedStory: event.target
                              .value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Stack>
                  <Stack>
                    <FormLabel component="legend">
                      If any of your actors are People of Colour, we expect that
                      they will be consulted in relation to their Hair/Make Up
                      and that specialised Hair/Make Up people are hired?*
                    </FormLabel>
                    <RadioGroup
                      name="PoCHairAndMakeup"
                      value={form.values.PoCHairAndMakeup}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            PoCHairAndMakeup: event.target
                              .value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Stack>
                </Stack>
              </FormControl>
            </Stack>
          </FormControl>
        </Stack>
      </Container>
      <Container sx={{ background: "white", my: 2 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">
            Inclusive Workforce - Off Screen
            <Tooltip title="help">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <FormControl component="fieldset" sx={{ m: 1 }} variant="standard">
            <Stack direction="column" spacing={2} py={2}>
              <FormControl
                component="fieldset"
                sx={{ m: 1 }}
                variant="standard"
              >
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <FormLabel component="legend">
                      Will you ensure the lead creative team (i.e. Executive
                      Producer, Series Producer, Writer, Producer and Director)
                      includes people from underrepresented groups (in
                      particular, People of Colour and Deaf, Disabled and/or
                      Neurodivergent people)?
                    </FormLabel>
                    <RadioGroup
                      name="underrepresentedCreative"
                      value={form.values.underrepresentedCreative}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            underrepresentedCreative: event.target
                              .value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                    <Typography>
                      Please provide more detail on how you will achieve this,
                      for example you plan to have three directors and will
                      ensure two of them are from an underrepresented group?
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Please type your reason here"
                      name="underrepresentedCreativeDetails"
                      multiline
                      fullWidth
                      onBlur={() =>
                        handleTextBlur("underrepresentedCreativeDetails")
                      }
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <FormLabel component="legend">
                      Will you ensure there is representation from
                      underrepresented groups in your senior production team
                      (including Production Executive or Production Manager) and
                      Heads of Department?*
                    </FormLabel>
                    <RadioGroup
                      name="underrepresentedProductionTeam"
                      value={form.values.underrepresentedProductionTeam}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            underrepresentedProductionTeam: event.target
                              .value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                    <Typography>
                      Please provide more detail on how you will achieve this*
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Please type your reason here"
                      name="underrepresentedProductionTeamDetails"
                      multiline
                      fullWidth
                      onBlur={() =>
                        handleTextBlur("underrepresentedProductionTeamDetails")
                      }
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <FormLabel component="legend">
                      Will you ensure that 20% of your crew and production team
                      at all levels are People of Colour, Deaf, Disabled and/or
                      Neurodivergent people and/or from a working class
                      background? For example, you might achieve this for more
                      senior roles by broadening your recruitment by advertising
                      through networks that work with people from
                      underrepresented groups or by taking on trainees from
                      schemes for more entry level roles. Resources for where to
                      advertise can found on our{" "}
                      <Link
                        href="https://www.itv.com/inclusion/articles/creating-diverse-and-inclusive-teams"
                        target="_blank"
                        rel="noreferrer"
                        color="secondary"
                      >
                        website
                      </Link>
                    </FormLabel>
                    <RadioGroup
                      name="underrepresentedCrew"
                      value={form.values.underrepresentedCrew}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            underrepresentedCrew: event.target
                              .value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                    <Typography>
                      Please provide more detail on how you will achieve this*
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Please type your reason here"
                      name="underrepresentedCrewDetails"
                      multiline
                      fullWidth
                      onBlur={() =>
                        handleTextBlur("underrepresentedCrewDetails")
                      }
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <FormLabel component="legend">
                      You will take part in our Step Up 60 initiative, which
                      enables those from underrepresented groups (People of
                      Colour and Deaf, Disabled and/or Neurodivergent people) to
                      be elevated to a senior role on our programmes. For
                      example, a shorts or continuing drama director stepping up
                      to Lead Director, or a Production Coordinator stepping up
                      to Production Manager. Please contact Nahrein.Kemp@itv.com
                      for more information.
                    </FormLabel>
                    <RadioGroup
                      name="stepUpSixty"
                      value={form.values.stepUpSixty}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            stepUpSixty: event.target.value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                    <Typography>
                      Please provide more detail on how you will achieve this*
                    </Typography>
                    <Field
                      as={TextField}
                      placeholder="Please type your reason here"
                      name="stepUpSixtyDetails"
                      multiline
                      fullWidth
                      onBlur={() => handleTextBlur("stepUpSixtyDetails")}
                    />
                  </Stack>
                  <Stack spacing={2}>
                    <FormLabel component="legend">
                      Will you hire a paid trainee, paid work experience or
                      Runner from an underrepresented group?*
                    </FormLabel>
                    <RadioGroup
                      name="paidTrainee"
                      value={form.values.paidTrainee}
                      onChange={(event) => {
                        form.handleChange(event);
                        updateProductionPrinciplesForm({
                          ccid,
                          formData: transformFormDataToServer({
                            ...form.values,
                            paidTrainee: event.target.value as BooleanAsYesNo,
                          }),
                          autosave: true,
                          provisional,
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Stack>
                </Stack>
              </FormControl>
            </Stack>
          </FormControl>
        </Stack>
      </Container>
    </>
  );
};
