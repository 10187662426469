export const TITLES = "titles";
export const SERIES = "series";

export const WhoIsThisForEnum = {
  BRITBOX_UK: "BRITBOX_UK",
  BROADCAST: "BROADCAST",
  COMPLIANCE: "COMPLIANCE",
  GLOBAL_DISTRIBUTION: "GLOBAL_DISTRIBUTION",
  OFF_ITV: "OFF_ITV",
  ONLINE: "ONLINE",
};

export const WhoIsThisForLabels = {
  [WhoIsThisForEnum.BRITBOX_UK]: "Britbox UK",
  [WhoIsThisForEnum.BROADCAST]: "ITV Network Broadcast",
  [WhoIsThisForEnum.COMPLIANCE]: "Compliance",
  [WhoIsThisForEnum.GLOBAL_DISTRIBUTION]: "Global Distribution",
  [WhoIsThisForEnum.OFF_ITV]: "Network other than ITV",
  [WhoIsThisForEnum.ONLINE]: "ITVx Exclusive",
};
