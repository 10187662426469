/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { createDrive } from "../../api/googledrive";
import { noop } from "../../utils/appHelper";

export const useCreateDrive = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(
    ({ ccid, level, driveType }: any) => createDrive(ccid, level, driveType),
    { onSuccess, onError },
  );
