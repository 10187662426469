import { Typography } from "@mui/material";
import { TEXTAREA } from "../../../components/DynamicForm";

export const retentionFormConfig = [
  {
    sectionTitle: "WIP & Archive Retention Grid",
    key: "Retention",
    description: "",
    formFields: [
      {
        type: TEXTAREA,
        label: "WIP & Archive Retention Grid",
        subTitle: (
          <Typography variant="body2">
            Paste link to completed retention grid,{" "}
            <a href="https://docs.google.com/document/d/1nU415ErljCyMP7V6ndlx22H1I_asohp5xdRYGiBANJw">
              Template document here
            </a>{" "}
          </Typography>
        ),
        placeholder: "",
        id: "wipAndRetentionGrid",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
];
