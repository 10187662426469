import { FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { FormFieldLabel } from "../../../componentsV2/FormFieldLabel";
import { UseUpdateRiskArgsNew } from "../../../hooks";
import { NotificationFormFieldProps } from "../../../types/types";
import { DEFAULT_NPNF_VERSION } from "../notificationForm.helper";

interface Props {
  label: string;
  error: string | undefined;
  shouldDisplayErrors: boolean;
  handleChange: (value: dayjs.Dayjs | null) => void;
  isRequired: boolean;
  value: dayjs.Dayjs;
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

type DateFieldProps = NotificationFormFieldProps<Props>;

export function DateField({
  version,
  fieldInfo,
  label,
  error,
  shouldDisplayErrors,
  handleChange,
  isRequired,
  value,
  autosaveForm,
}: DateFieldProps) {
  const inputId = `${fieldInfo.sectionId}.${fieldInfo.fieldName}`;

  return (
    <FormFieldLabel inputId={inputId} label={label} required={isRequired}>
      <DatePicker
        disabled={version !== DEFAULT_NPNF_VERSION}
        name={inputId}
        value={value}
        onChange={(newValue) => {
          handleChange(newValue);
          autosaveForm({
            [fieldInfo.sectionId]: {
              [fieldInfo.fieldName]: newValue
                ? newValue.format("YYYY-MM-DD")
                : "",
            },
          });
        }}
        slotProps={{
          textField: {
            id: inputId,
            required: isRequired,
            "aria-describedby": `${inputId}-error`,
            error: shouldDisplayErrors && Boolean(error),
          },
        }}
      />
      {shouldDisplayErrors && error ? (
        <FormHelperText id={`${inputId}-error`} error={true}>
          {error}
        </FormHelperText>
      ) : null}
    </FormFieldLabel>
  );
}
