import { FC, useState } from "react";
import {
  Typography,
  IconButton,
  Button,
  Stack,
  Dialog,
  Alert,
  Divider,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FormikTouched, FormikErrors } from "formik";
import { OfcomFormData, OfcomFormDataFormik } from "../../types/types";

const validationErrorMap: { [key: string]: string } = {
  substantiveBaseAddress: "Substantive Base Address is required",
  substantiveBaseYear: "Year Substantive base became active is required",
  substantiveBaseRegion: "Production Region is Required",
  substantiveBaseOpenPrior: "substantive base opened prior is required",
  substantiveBaseCommission:
    "substantive base opened for the purpose of the commissioned Programme is required",
  productionSpendRegion:
    "nation/macro region in which the Production Spend criterion will be met is required",
  productionSpendPercentage: "Production Spend percentage budget is required",
  productionSpendTotalQualifyingBudget:
    "Qualifying Total Production Budget is requried",
  productionSpendTotalBudget: "Total Production Budget is required",
  talentTotalBudget: "Total Off-Screen Talent Budget is required",
  talentBudgetPercentage:
    "Off-Screen Talent Budget as a percentage is required",
  talentRegion:
    "The nation/macro region in which the Talent Spend criterion will be met is required",
};

export const OfcomFormSteps: FC<{
  onStepChange: (step: number) => void;
  onSubmitForm: () => void;
  onFormValidate: (
    values?: OfcomFormDataFormik,
  ) => Promise<FormikErrors<OfcomFormDataFormik>>;
  setTouched: (
    touched: FormikTouched<OfcomFormData>,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<OfcomFormDataFormik>>;
  modifiedTime?: string;
  submittedBy?: string;
}> = ({
  onStepChange,
  onSubmitForm,
  onFormValidate,
  setTouched,
  modifiedTime,
  submittedBy,
}) => {
  const [step, setStep] = useState<number>(1);
  const [declarationOpen, setDeclarationOpen] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [page2ValidationErrors, setpage2ValidationErrors] = useState<string[]>(
    [],
  );
  const handleSubmitButtonClick = () =>
    onFormValidate().then((errors: FormikErrors<OfcomFormDataFormik>) => {
      if (Object.keys(errors).length === 0) {
        setpage2ValidationErrors([]);
        setDeclarationOpen(true);
      } else {
        const validationErrors = Object.keys(errors).map((key) => key);
        setpage2ValidationErrors(validationErrors);
        setErrorDialogOpen(true);

        setTouched({
          substantiveBaseCriteria: true,
          productionSpendCriteria: true,
          talentCriteria: true,
          substantiveBaseAddress: true,
          substantiveBaseRegion: true,
          substantiveBaseYear: true,
          substantiveBaseOpenPrior: true,
          substantiveBaseCommission: true,
          productionSpendTotalBudget: true,
          productionSpendTotalQualifyingBudget: true,
          productionSpendPercentage: true,
          productionSpendRegion: true,
          talentTotalBudget: true,
          talentBudgetPercentage: true,
          talentRegion: true,
        });
      }
    });

  const handleStepChange = (newStep: number) =>
    onFormValidate().then((errors: FormikErrors<OfcomFormDataFormik>) => {
      if (
        errors.foreignProduction ||
        errors.producerCountry ||
        errors.productionLocation
      ) {
        setTouched({
          foreignProduction: true,
          producerCountry: true,
          productionLocation: true,
        });
      } else {
        setStep(newStep);
        onStepChange(newStep);
      }
    });

  const dotColor = step === 2 ? "green" : "black";

  return (
    <>
      <Timeline sx={{ width: "270px" }}>
        <TimelineItem>
          <TimelineOppositeContent sx={{ display: "none" }} />
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: dotColor }} variant="filled" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleStepChange(1)}
          >
            <Typography component="span">
              European Compliance & Made in the UK qualification
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent sx={{ display: "none" }} />
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              color="primary"
              variant={step === 2 ? "filled" : "outlined"}
            />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => handleStepChange(2)}
          >
            <Stack>
              <Divider sx={{ p: 1 }} />
              <Typography position="relative" component="span">
                Regional Quota
              </Typography>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <Stack spacing={1} direction="row">
        <IconButton
          disabled={step === 1}
          color="primary"
          onClick={() => handleStepChange(step - 1)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Button
          disabled={step === 2}
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => handleStepChange(step + 1)}
        >
          Next Section <ArrowForwardIcon />
        </Button>
      </Stack>
      <Button
        fullWidth
        sx={{ p: 1, m: 1 }}
        disabled={step === 1}
        variant="contained"
        color="primary"
        onClick={handleSubmitButtonClick}
      >
        Submit Form
      </Button>
      <Typography
        variant="caption"
        display="block"
        textAlign="left"
        color="gray"
        sx={{ px: 2 }}
      >
        {`Autosaved at: ${modifiedTime || "Time Unavailable"} ${submittedBy ? `by ${submittedBy}` : ""}`}
      </Typography>
      <Dialog open={declarationOpen} maxWidth="xs">
        <Stack direction="column" justifyContent="center" sx={{ m: 2 }}>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            Provisional Form Declaration
          </Typography>
          <Typography textAlign="center">
            This form is currently in a provisional status and you will be
            required to review all this information before submitting the final
            version post-production. By clicking submit you declare that all the
            information provided are accurate and within ITV’s compliance terms
            & conditions.
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          style={{ color: "white", padding: "2%" }}
        >
          <Button onClick={() => setDeclarationOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={onSubmitForm}
            variant="contained"
            sx={{
              background: "white",
              color: "black",
              "&:hover": {
                background: "#e0e0e0",
                color: "black",
              },
            }}
          >
            Submit Form
          </Button>
        </Stack>
      </Dialog>
      <Dialog open={errorDialogOpen} maxWidth="xs">
        <Stack direction="column" justifyContent="center" sx={{ m: 2 }}>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            There's a few things not quite right....
          </Typography>
          {page2ValidationErrors.map((err) => (
            <Alert key={err} sx={{ py: 2 }} severity="error">
              {validationErrorMap[err]}
            </Alert>
          ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          style={{ color: "white", padding: "2%" }}
        >
          <Button onClick={() => setErrorDialogOpen(false)} color="secondary">
            Back
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};
