import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { deleteEpisode } from "../../api/portal";

export const useDeleteEpisode = (): UseMutationResult<
  unknown,
  unknown,
  { ccid: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation((params: { ccid: string }) => deleteEpisode(params.ccid), {
    onSettled: () => {
      queryClient.invalidateQueries("titles");
    },
  });
};
