import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { SentimentDissatisfiedSharp } from "@mui/icons-material";

export const NoAccess: React.FC = () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <SentimentDissatisfiedSharp sx={{ width: 36, height: 36, margin: 3 }} />
    <Typography variant="h4">
      Unfortunately you don't have access to view this material.
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      If you require access please use the 'Contact Us' button in the bottom
      left of the page.
    </Typography>
  </Box>
);
