import AssignmentIndOutlined from "@mui/icons-material/AssignmentIndOutlined";
import { Box, Paper, Stack, Typography } from "@mui/material";
import theme from "../utils/theme";

interface AssignedProductionsCountCardProps {
  numberOfProductions: number | undefined;
}

export function AssignedProductionsCountCard({
  numberOfProductions,
}: AssignedProductionsCountCardProps) {
  if (typeof numberOfProductions !== "number") {
    return null;
  }

  const mainText = (): string => {
    if (numberOfProductions === 0) {
      return "You’ve not been assigned to any productions, you can still explore content.";
    }

    if (numberOfProductions === 1) {
      return "You’ve been assigned to 1 production, see below for more details.";
    }

    return `You’ve been assigned to ${numberOfProductions} productions, see below for more details.`;
  };

  return (
    <Paper sx={{ padding: "24px 16px", marginLeft: "auto", maxWidth: "300px" }}>
      <Stack spacing={0.5}>
        <Box component="span">
          <AssignmentIndOutlined
            fontSize="large"
            htmlColor="rgba(0, 0, 0, 0.54)"
          />
        </Box>

        <Typography variant="h6" letterSpacing="0.15px">
          {numberOfProductions} assigned{" "}
          {numberOfProductions === 1 ? "production" : "productions"}
        </Typography>

        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          letterSpacing="0.17px"
        >
          {mainText()}
        </Typography>
      </Stack>
    </Paper>
  );
}
