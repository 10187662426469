import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Search } from "../../components/Search";
import { useBrands } from "../../hooks/queries/useBrands";
import { ErrorMessage } from "../../components/ErrorMessage";
import BrandCard from "../../componentsV2/BrandCard/BrandCard";
import OneOffCard from "../../componentsV2/OneOffCard/OneOffCard";
import theme from "../../utils/theme";

const SearchPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("q") || "";
  const {
    data: brandSearch,
    error,
    isLoading,
  } = useBrands({ searchTerm, includeOpportunity: true });

  const hasProgrammes = brandSearch && brandSearch.results?.length > 0;

  if (error && error?.response?.status !== 401)
    return <ErrorMessage message={error.message} />;

  const getContentToRender = () => {
    if (hasProgrammes) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return brandSearch?.results.map((programme: any) => (
        <div key={programme.Brand?.brandCcid || programme.OneOff?.titleCcid}>
          {"Brand" in programme ? (
            <BrandCard programme={programme.Brand} />
          ) : (
            <OneOffCard programme={programme.OneOff} />
          )}
        </div>
      ));
    }

    if (!isLoading && !error) {
      return (
        <Box
          sx={{
            padding: 4,
            border: 1,
            borderRadius: 2,
            borderColor: theme.palette.java,
            bgcolor: theme.palette.hummingBird,
          }}
        >
          <h4>
            <strong>No results were found for "{searchTerm}"</strong>
          </h4>
          <p>
            Try using a different search term or use the 'Contact Us' button at
            the top right of the page for more help
          </p>
        </Box>
      );
    }

    return (
      <Container sx={{ py: 20, textAlign: "center" }}>
        <CircularProgress />
      </Container>
    );
  };

  return (
    <Container sx={{ p: 3 }}>
      <Link
        to="/"
        style={{
          color: theme.palette.midGray,
          textDecoration: "none",
        }}
      >
        <KeyboardArrowLeftIcon sx={{ position: "relative" }} /> Back{" "}
      </Link>
      <Typography variant="h5" marginTop={4} marginBottom={1}>
        Search
      </Typography>
      <Search searchTerm={searchTerm} />
      <Typography marginY={2}>
        <h4>Programmes</h4>
      </Typography>
      {getContentToRender()}
    </Container>
  );
};

export default SearchPage;
