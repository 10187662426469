import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getMetadataIncludingOpportunity } from "../../api/portal";
import { Metadata } from "../../types/types";

export const useMetadataIncludingOpportunity = (
  ccid: string | undefined,
): UseQueryResult<Metadata, AxiosError> =>
  useQuery(
    ["metadataIncludingOpportunity", ccid],
    () => getMetadataIncludingOpportunity(ccid as string),
    {
      enabled: ccid !== undefined,
    },
  );
