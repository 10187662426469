import { useMutation, UseMutationResult } from "react-query";
import { createBrandedSpecial } from "../../api/portal";
import { noop } from "../../utils/appHelper";
import { CcidCreateBrandedSpecialPayload } from "../../types/types";

export const useCreateBrandedSpecial = (
  onSuccess = noop,
  onError = noop,
): UseMutationResult<
  void,
  unknown,
  { ccid: string; formData: CcidCreateBrandedSpecialPayload },
  unknown
> =>
  useMutation(({ ccid, formData }) => createBrandedSpecial(ccid, formData), {
    onSuccess,
    onError,
  });
