import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getContributors } from "../../api/contributors";
import { ContributorsResponsePayload } from "../../types/types";

export const useContributors = (
  ccid: string | undefined,
  isSeries = false,
): UseQueryResult<ContributorsResponsePayload, AxiosError> =>
  useQuery(["getContributors", ccid], () => getContributors(ccid, isSeries), {
    enabled: Boolean(ccid),
  });
