import * as Yup from "yup";

export const sustainabilitySchema = Yup.object().shape({
  calculator: Yup.string().required("This is a required field"),
  calculatorDetails: Yup.string().when(
    "$sustainability.calculator",
    ([calculator], schema) => {
      if (calculator === "Other") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  country: Yup.string(),
  filmloc: Yup.string().required("This is a required field"),
  flying: Yup.string().required("This is a required field"),
  gen: Yup.string().required("This is a required field"),
  hazard: Yup.string().required("This is a required field"),
  naturalHazard: Yup.array()
    .of(Yup.string().required())
    .when("$sustainability.hazard", ([hazard], schema) => {
      if (hazard === "Yes") {
        return schema
          .required("This is a required field")
          .min(1, "This is a required field");
      }
      return schema;
    }),
  lighting: Yup.string().required("This is a required field"),
  mileage: Yup.string().required("This is a required field"),
  setbuild: Yup.boolean().required("This is a required field"),
});

export type SustainabilitySection = Yup.InferType<typeof sustainabilitySchema>;
