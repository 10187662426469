import {
  Typography,
  Stack,
  Card,
  CardContent,
  Box,
  InputAdornment,
} from "@mui/material";
import { useFormikContext } from "formik";
import { type DutyOfCareSection } from "../yupSchemas/dutyOfCareSchema";
import { type NotificationFormSubmitValues } from "../NotificationFormV2";
import { RadioField } from "../formFields/RadioField";
import { CheckboxField } from "../formFields/CheckboxField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { MailOutline, PersonOutline } from "@mui/icons-material";
import { EmailField } from "../formFields/EmailField";
import { UseUpdateRiskArgsNew } from "../../../hooks";

const degreeOfControlOptions = [
  {
    label: "No Control - Observational  Documentary / Actuality",
    value: "Actuality",
  },
  {
    label:
      "Some Control - Actuality that has a degree of being produced or directed",
    value: "ProducedActuality",
  },
  {
    label: "Total Control - Constructed reality",
    value: "ConstructedReality",
  },
  {
    label: "Total Control - Scripted drama / Drama reconstruction",
    value: "TotalControl",
  },
];

const participantDemographicOptions = [
  {
    label:
      "Participants are 'celebrities' or professional actors who already have a public image and have access to personal management/representation",
    value: "Celebrity",
  },
  {
    label:
      "Participants are 'celebrities' or professional actors but may be considered a vulnerable person",
    value: "VulnerableCeleb",
  },
  {
    label:
      "Participants are not widely known by the public and any vulnerabilities are unknown that this stage",
    value: "GeneralPublic",
  },
  {
    label:
      "Participants are not widely known by the public, but have an active social media presence which is important to them, and any vulnerabilities are unknown that this stage",
    value: "Influencer",
  },
  {
    label:
      "Participants are not widely known by the public and are considered a vulnerable person",
    value: "VulnerableGeneralPublic",
  },
];

const exposureOptions = [
  {
    label:
      "Low exposure to emotionally challenging situations or subject matter/material, with no unexpected/surprise elements",
    value: "Low",
  },
  {
    label:
      "Some exposure to emotionally challenging situations or subject matter/material, or an unexpected/surprise element and material that might cause some temporary discomfort",
    value: "Medium",
  },
  {
    label:
      "Exposure to potential confrontation, emotionally challenging and unexpected situations and/or strong subject matter/material",
    value: "High",
  },
];

const participantLocationOptions = [
  {
    label:
      "On-screen participants are not required to stay away from home for any period of time",
    value: "Home",
  },
  {
    label:
      "May be away from home for more than a night, but not in a remote location or in shared accommodation, and will have access to normal support network",
    value: "AwayWithHomeContact",
  },
  {
    label:
      'On-screen participants required to live away from home without access to normal support network, or in a potentially "alien" environment or in shared accommodation for a sustained period of time',
    value: "Away",
  },
];

const livingArrangementsOptions = [
  {
    label: "Participants are not required to live away from home",
    value: "Alone",
  },
  {
    label: "Participants are provided with a private accomodation/hotel",
    value: "Private",
  },
  {
    label: "Participants have a private bedroom within a shared accomodation",
    value: "Shared",
  },
  {
    label: "A shared bedroom with another participant",
    value: "Dormitory",
  },
];

const popularityProfileOptions = [
  {
    label:
      "Low public, press or social media interest in the participants &/or the programme",
    value: "Low",
  },
  {
    label:
      "Some public, press or social media interest in the participants &/or the programme",
    value: "Medium",
  },
  {
    label:
      "High level of public, press or social media interest in the participants &/or the programme",
    value: "High",
  },
  {
    label:
      "Production actively encourages the viewers to engage with the production via social media",
    value: "Active",
  },
];

interface DutyOfCarePageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function DutyOfCarePage({ autosaveForm }: DutyOfCarePageProps) {
  const formik = useFormikContext<NotificationFormSubmitValues>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.dutyOfCare || {};
  const errors = formik.errors.dutyOfCare || {};

  const checkboxesChangeHandler = (fieldName: keyof DutyOfCareSection) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`dutyOfCare.${fieldName}`).setValue(values);
    };
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">
                Risk as a factor of Duty of Care
              </Typography>

              <Typography variant="body1">
                Please indicate whether pre-production or filming will involve
                the following:
              </Typography>

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dutyOfCare",
                  fieldName: "degreeOfControl",
                }}
                label="How much control does the production team have over the participants' situation?"
                error={errors.degreeOfControl}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.degreeOfControl}
                options={degreeOfControlOptions}
                autosaveForm={autosaveForm}
              />

              <CheckboxField
                version="v2"
                fieldInfo={{
                  sectionId: "dutyOfCare",
                  fieldName: "participantDemographic",
                }}
                label="How much control does the production team have over the participants' situation?"
                error={
                  Array.isArray(errors.participantDemographic)
                    ? errors.participantDemographic[0]
                    : errors.participantDemographic
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("participantDemographic")}
                isRequired={true}
                value={values.participantDemographic}
                options={participantDemographicOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "dutyOfCare", fieldName: "exposure" }}
                label="Does the show's content potentially expose on-screen participants to confrontation, emotional challenges, or surprises? For example, if a programme involves emotionally challenging or traumatic story lines. "
                error={errors.exposure}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.exposure}
                options={exposureOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dutyOfCare",
                  fieldName: "participantLocation",
                }}
                label="Is the programme filmed in a remote, mentally challenging or isolating location?"
                error={errors.participantLocation}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.participantLocation}
                options={participantLocationOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dutyOfCare",
                  fieldName: "livingArrangements",
                }}
                label="Are your on-screen participants housed in shared accommodation with other participants, strangers, or potentially incompatible individuals?"
                error={errors.livingArrangements}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.livingArrangements}
                options={livingArrangementsOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dutyOfCare",
                  fieldName: "popularityProfile",
                }}
                label="What is the popularity/profile of the programme?"
                error={errors.popularityProfile}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.popularityProfile}
                options={popularityProfileOptions}
                autosaveForm={autosaveForm}
              />

              <Typography variant="body1">
                Please detail below if you are working with a Mental Health
                Advisor on this production:
              </Typography>

              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Box flex={1}>
                  <SingleLineTextField
                    version="v2"
                    fieldInfo={{
                      sectionId: "dutyOfCare",
                      fieldName: "mentalHealthAdvisor",
                    }}
                    label="Mental Health Advisor name"
                    error={errors.mentalHealthAdvisor}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={false}
                    value={values.mentalHealthAdvisor}
                    placeholder="Forename Surname"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonOutline />
                      </InputAdornment>
                    }
                    autosaveForm={autosaveForm}
                  />
                </Box>

                <Box flex={1}>
                  <EmailField
                    version="v2"
                    fieldInfo={{
                      sectionId: "dutyOfCare",
                      fieldName: "mentalHealthAdvisorEmail",
                    }}
                    label="Mental Health Advisor email"
                    error={errors.mentalHealthAdvisorEmail}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={false}
                    value={values.mentalHealthAdvisorEmail}
                    placeholder="example@itv.com"
                    startAdornment={
                      <InputAdornment position="start">
                        <MailOutline />
                      </InputAdornment>
                    }
                    autosaveForm={autosaveForm}
                  />
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
