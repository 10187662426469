import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getTitleMetadata } from "../../api/portal";
import { TitleMetadata } from "../../types/types";

export const useTitleMetadata = (
  titleCcid: string | undefined,
): UseQueryResult<TitleMetadata, AxiosError> =>
  useQuery(
    ["titleMetadata", titleCcid],
    () =>
      getTitleMetadata(titleCcid as string).then((data) => ({
        ...data,
        titleCcid,
      })),
    { enabled: Boolean(titleCcid) },
  );
