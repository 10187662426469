import { useState, FC, useEffect } from "react";
import {
  Radio,
  FormControl,
  FormLabel,
  Box,
  Typography,
  Container,
  Stack,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Field, useFormikContext } from "formik";
import { CustomTextField } from "../../componentsV2/FormFields/CustomTextField";
import {
  BooleanAsYesNo,
  OfcomFormDataFormik,
  OfcomRegion,
} from "../../types/types";
import { useOfcomFormSubmission } from "../../hooks";
import { transformFieldForServer } from "./transformFormDataToServer";
import { MultipleSelectRegionCheckbox } from "./MultiSelectRegionCheckbox";
import { regionOptions } from "./RegionOptions";
import { YesNoRadio } from "./YesNoRadio";

interface OfcomPageOneFormikProps {
  ccid: string;
  provisional: boolean;
}

export const OfcomPage2: FC<OfcomPageOneFormikProps> = ({
  ccid,
  provisional,
}) => {
  const form = useFormikContext<OfcomFormDataFormik>();
  const [substantiveBaseVisible, setSubstantiveBaseVisible] =
    useState<boolean>(false);
  const [productionSpendVisible, setProductionSpendVisible] =
    useState<boolean>(false);
  const [offScreenTalentVisible, setOffScreenTalent] = useState<boolean>(false);

  const { mutate: updateOfcomForm } = useOfcomFormSubmission();

  useEffect(() => {
    if (form.values.productionSpendCriteria) {
      setProductionSpendVisible(true);
    } else setProductionSpendVisible(false);
    if (form.values.talentCriteria) {
      setOffScreenTalent(true);
    } else setOffScreenTalent(false);
    if (form.values.substantiveBaseCriteria) {
      setSubstantiveBaseVisible(true);
    } else setSubstantiveBaseVisible(false);
  }, [form]);

  const handleBlur = (fieldName: keyof OfcomFormDataFormik) => {
    if (!form.errors[fieldName]) {
      updateOfcomForm({
        ccid,
        formData: transformFieldForServer(fieldName, form.values[fieldName]),
        autosave: true,
        provisional,
      });
    } else {
      form.setFieldTouched(fieldName);
    }
  };

  const handleRegionChange = (
    fieldName: keyof OfcomFormDataFormik,
    event: React.ChangeEvent<HTMLInputElement>,
    currentValue: OfcomRegion[],
  ) => {
    const { checked } = event.target;
    const changedValue = event.target.value as OfcomRegion;
    let newValue: OfcomRegion[] = [...currentValue];
    if (checked) {
      newValue.push(changedValue);
    } else {
      newValue = newValue.filter((v) => v !== changedValue);
    }
    form.setFieldValue(fieldName, newValue);
    updateOfcomForm({
      ccid,
      formData: transformFieldForServer(fieldName, newValue),
      autosave: true,
      provisional,
    });
  };

  return (
    <>
      <Container sx={{ background: "white", my: 1 }}>
        <Stack direction="column" spacing={2} py={2}>
          <Typography variant="h5">Eligibility Work Criteria</Typography>
          <Typography>
            This information includes rudimentary and technical information
            regarding your production
          </Typography>
          <FormLabel id="production-type">
            Please select which of the following eligibility criteria best
            describes your production company
          </FormLabel>
          <FormControl>
            <Stack>
              <Stack direction="row">
                <Box>
                  <Field
                    name="substantiveBaseCriteria"
                    type="checkbox"
                    as={Checkbox}
                    onBlur={() => handleBlur("substantiveBaseCriteria")}
                  />
                </Box>
                <FormLabel component="legend">
                  Substantive Base: the production company must have a
                  substantive business and production based in the UK outside
                  the M25. A base will be taken to be substantive if it is the
                  usual place of employment of (i) executives managing the
                  regional business, (ii) of senior personnel involved in the
                  production in question and (iii) of senior personnel involved
                  in seeking programme commissions.
                </FormLabel>
              </Stack>
              <Stack direction="row">
                <Box>
                  <Field
                    name="productionSpendCriteria"
                    type="checkbox"
                    as={Checkbox}
                    onBlur={() => handleBlur("productionSpendCriteria")}
                  />
                </Box>
                <FormLabel component="legend">
                  Production Spend: at least 70% of the production budget
                  (including third party spend but excluding production fee, the
                  cost of on-screen talent, archive material, sports rights,
                  competition prize money and copyright costs) must be spent
                  inside the UK and outside the M25.
                </FormLabel>
              </Stack>
              <Stack direction="row">
                <Box>
                  <Field
                    name="talentCriteria"
                    type="checkbox"
                    as={Checkbox}
                    onBlur={() => handleBlur("talentCriteria")}
                  />
                </Box>
                <FormLabel component="legend">
                  Off screen talent: at least 50% of the production talent
                  (excluding on screen talent) by cost must have their usual
                  place of employment inside the UK and outside of the M25.
                  Freelancers without a usual place of employment outside the
                  M25 will nonetheless count for this purpose if their home
                  address is outside the M25.
                </FormLabel>
              </Stack>
            </Stack>
          </FormControl>
        </Stack>
      </Container>

      {substantiveBaseVisible ? (
        <Container sx={{ background: "white", my: 1 }}>
          <Stack direction="column" spacing={2} py={2}>
            <Typography variant="h5">Substantive Base</Typography>
            <Typography>
              Please state the full postal address, including postcode, of the
              substantive base of the Production Company
            </Typography>
            <Field
              component={CustomTextField}
              name="substantiveBaseAddress"
              label="Production Company Location"
              required
              onBlur={() => handleBlur("substantiveBaseAddress")}
            />
            <FormControl
              error={!!form.errors.substantiveBaseRegion}
              required
              component="fieldset"
              sx={{ m: 1 }}
              variant="standard"
            >
              <FormLabel component="legend">
                Please state the nation/macro region in which the Substantive
                Base criterion will be met.
              </FormLabel>
              <RadioGroup
                aria-labelledby="substantive-base-region-radio-group"
                name="substantiveBaseRegion"
                value={form.values.substantiveBaseRegion}
                onChange={(event) => {
                  form.handleChange(event);
                  updateOfcomForm({
                    ccid,
                    formData: transformFieldForServer(
                      "substantiveBaseRegion",
                      event.target.value as OfcomRegion,
                    ),
                    autosave: true,
                    provisional,
                  });
                }}
              >
                {regionOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio size="small" sx={{ ml: 1 }} />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <Stack direction="column" spacing={1}>
              <Typography>
                Please state the year from which the substantive base became
                active.
              </Typography>
              <Field
                component={CustomTextField}
                name="substantiveBaseYear"
                label="Enter Year"
                required
                onBlur={() => handleBlur("substantiveBaseYear")}
              />
            </Stack>
            <YesNoRadio
              name="substantiveBaseOpenPrior"
              label="Was the substantive base opened prior to the point of commission?"
              value={form.values.substantiveBaseOpenPrior}
              onChange={(event) => {
                form.handleChange(event);
                updateOfcomForm({
                  ccid,
                  formData: transformFieldForServer(
                    "substantiveBaseOpenPrior",
                    event.target.value as BooleanAsYesNo,
                  ),
                  autosave: true,
                  provisional,
                });
              }}
              error={!!form.errors.substantiveBaseOpenPrior}
            />
            <YesNoRadio
              name="substantiveBaseCommission"
              label="Was the substantive base opened for the purpose of the commissioned Programme?"
              value={form.values.substantiveBaseCommission}
              onChange={(event) => {
                form.handleChange(event);
                updateOfcomForm({
                  ccid,
                  formData: transformFieldForServer(
                    "substantiveBaseCommission",
                    event.target.value as BooleanAsYesNo,
                  ),
                  autosave: true,
                  provisional,
                });
              }}
              error={!!form.errors.substantiveBaseCommission}
            />
          </Stack>
        </Container>
      ) : (
        <></>
      )}
      {productionSpendVisible ? (
        <Container sx={{ background: "white", my: 1 }}>
          <Stack direction="column" spacing={2} py={2}>
            <Typography variant="h5">Production Spend</Typography>

            <Typography>Please state the Total Production Budget</Typography>
            <Field
              component={CustomTextField}
              name="productionSpendTotalBudget"
              label="Enter Total Production Budget"
              startAdornment="£"
              required
              onBlur={() => handleBlur("productionSpendTotalBudget")}
            />

            <Typography>
              Please state the Total Qualifying Production Budget{" "}
              <Tooltip
                title="The Total Production Budget including third party spend but excluding production fee, 
              cost of on-screen talent and archive material."
              >
                <HelpOutlineIcon />
              </Tooltip>
            </Typography>
            <Field
              component={CustomTextField}
              name="productionSpendTotalQualifyingBudget"
              label="Enter amount"
              startAdornment="£"
              required
              onBlur={() => handleBlur("productionSpendTotalQualifyingBudget")}
            />
            <Typography>
              Production Spend within the UK (but outside of the M25), as a % of
              Total Qualifying Production Budget
            </Typography>
            <Field
              component={CustomTextField}
              name="productionSpendPercentage"
              label="Enter Percentage"
              endAdornment="%"
              required
              onBlur={() => handleBlur("productionSpendPercentage")}
            />
            <FormControl
              error={!!form.errors.productionSpendRegion}
              required={form.values.productionSpendCriteria}
              component="fieldset"
              sx={{ m: 1 }}
              variant="standard"
            >
              <FormLabel component="legend">
                Please select the nation(s)/macro region(s) in which the
                Production Spend criterion will be met
              </FormLabel>
              <MultipleSelectRegionCheckbox
                field={{
                  name: "productionSpendRegion",
                  value: form.values.productionSpendRegion,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    handleRegionChange(
                      "productionSpendRegion",
                      event,
                      form.values.productionSpendRegion,
                    );
                  },
                  onBlur: form.handleBlur,
                }}
              />
            </FormControl>
          </Stack>
        </Container>
      ) : (
        <></>
      )}
      {offScreenTalentVisible ? (
        <Container sx={{ background: "white", my: 1 }}>
          <Stack direction="column" spacing={2} py={2}>
            <Typography variant="h5">Off Screen Talent</Typography>
            <Stack direction="column" spacing={1}>
              <Typography>
                Please state the Total Off-Screen Talent Budget (excluding all
                on-screen talent costs)
              </Typography>
              <Field
                component={CustomTextField}
                name="talentTotalBudget"
                label="Enter amount"
                startAdornment="£"
                required
                onBlur={() => handleBlur("talentTotalBudget")}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>
                Out of London Off-Screen Talent Budget, as a % of Total
                Off-Screen Talent Budget
              </Typography>
              <Field
                component={CustomTextField}
                name="talentBudgetPercentage"
                label="Enter Percentage"
                endAdornment="%"
                required
                onBlur={() => handleBlur("talentBudgetPercentage")}
              />
            </Stack>
            <Stack spacing={1}>
              <FormControl
                error={!!form.errors.talentRegion}
                required={form.values.talentCriteria}
                component="fieldset"
                sx={{ m: 1 }}
                variant="standard"
              >
                <FormLabel component="legend">
                  Please select the nation(s)/macro region(s) in which the
                  Talent Spend criterion will be met
                </FormLabel>
                <MultipleSelectRegionCheckbox
                  field={{
                    name: "talentRegion",
                    value: form.values.talentRegion,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      handleRegionChange(
                        "talentRegion",
                        event,
                        form.values.talentRegion,
                      );
                    },
                    onBlur: form.handleBlur,
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};
