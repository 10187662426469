import { FC } from "react";
import {
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
} from "@mui/material";
import { Add } from "@mui/icons-material";

export const AddNewProductionCard: FC = () => (
  <ListItem sx={{ px: 0 }}>
    <ListItemText
      primary={
        <Typography component="div" variant="body1" color="primary">
          Don’t see the series you are looking for?
        </Typography>
      }
      secondary={
        <Typography
          component="span"
          variant="body2"
          sx={{ cursor: "pointer", textDecoration: "underline" }}
        >
          Add a new series here
        </Typography>
      }
    />
    <ListItemIcon>
      <Add />
    </ListItemIcon>
  </ListItem>
);
