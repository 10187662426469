import { useQuery, UseQueryResult } from "react-query";
import { SeriesVersionsResponse } from "../../types/types";
import { getSeriesVersions, getTitleVersions } from "../../api/portal";

export const useSeriesVersions = (
  useSeries: boolean,
  ccid: string | undefined,
  limit: number,
  offset: number,
): UseQueryResult<SeriesVersionsResponse, Error> =>
  useQuery(
    ["seriesVersions", limit, offset],
    () => {
      if (useSeries) return getSeriesVersions(ccid || "", limit, offset);
      return getTitleVersions(ccid || "");
    },
    { keepPreviousData: true },
  );
