import { FC } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  HIDE_DRAWER,
  NotificationService,
} from "../../../../services/notificationService";
import { useGroups } from "../../../../hooks";

export const GroupList: FC = () => {
  const { data: groups, isLoading } = useGroups();
  const navigate = useNavigate();

  return (
    <Box sx={{ minWidth: "320px" }}>
      <Typography
        fontSize="14px"
        fontWeight="bold"
        paddingLeft="1rem"
        marginTop="1rem"
      >
        Your groups
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <List>
          {groups?.map((group) => (
            <div key={group.id}>
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    navigate(`/access-directory/${group.id}`);
                    NotificationService.pub(HIDE_DRAWER);
                  }}
                >
                  <ListItemText>{group.name}</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      )}
    </Box>
  );
};
