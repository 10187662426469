/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";

const API_BASE = getAPI(SERVICES.storyboard);

export const getStartupFormData = (
  ccid: string,
  type = "series",
): Promise<Record<string, string>> =>
  handleRequest
    .get(`${API_BASE}/${type}/${ccid}/startup`)
    .then(({ data }) => data);

export const updateStartupFormData = (
  ccid: string,
  formData: Record<string, any>,
  type = "series",
): Promise<Record<string, any>> =>
  handleRequest
    .put(`${API_BASE}/${type}/${ccid}/startup`, formData)
    .then(({ data }) => data);

export const getStartupFormProductionData = (
  ccid: string,
): Promise<Record<string, string>> =>
  handleRequest
    .get(`${API_BASE}/ccid/${ccid}/productionDetails`)
    .then(({ data }) => data);

export const updateStartupFormProductionData = (
  ccid: string,
  formData: Record<string, any>,
): Promise<Record<string, any>> =>
  handleRequest
    .put(`${API_BASE}/ccid/${ccid}/productionDetails`, formData)
    .then(({ data }) => data);

export const getCloseDownFormData = (
  ccid: string,
  type = "series",
): Promise<Record<string, string>> =>
  handleRequest
    .get(`${API_BASE}/${type}/${ccid}/closedown`)
    .then(({ data }) => data);

export const updateCloseDownFormData = (
  ccid: string,
  formData: Record<string, any>,
  type = "series",
): Promise<Record<string, any>> =>
  handleRequest
    .put(`${API_BASE}/${type}/${ccid}/closedown`, formData)
    .then(({ data }) => data);
