import { CHECKBOX } from "../../../components/DynamicForm";

export const sustainabilityFormConfig = [
  {
    sectionTitle: "Sustainability",
    key: "Sustainability",
    description: "",
    formFields: [
      {
        type: CHECKBOX,
        label: "Has all certification evidence been submitted to albert?",
        id: "finalCarbonAlbert",
        columnCount: 12,
      },
      {
        type: CHECKBOX,
        label:
          "Have the production’s carbon emissions been offset through albert?",
        id: "offsetAlbert",
        columnCount: 12,
      },
      {
        type: CHECKBOX,
        label:
          "Have any green initiatives been shared with the ITV Green team?",
        id: "greenTeam",
        columnCount: 12,
      },
    ],
  },
];
