import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Typography,
  MobileStepper,
  Link,
  Box,
  Button,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { SERIES, TITLES } from "../../../constants";
import { useRiskScoreNew } from "../../../hooks/queries/useRiskScore";
import { ModalService } from "../../../services/modalService";

const CalculatingScore: FC<{
  onEditForm: () => void;
  onGoBackToProduction: () => void;
}> = ({ onEditForm, onGoBackToProduction }) => (
  <Stack spacing={1}>
    <Stack direction="row" spacing={1} p={4}>
      <Stack spacing={3}>
        <Typography variant="h4">Thank you for submitting.</Typography>
        <Typography>
          The Group Risk and Insurance Teams have been notified. We are
          calculating your risk score and will notify you once complete. The
          teams will be in touch to clarify any submitted answers, and to
          mitigate any higher risk areas.
        </Typography>
        <Typography variant="body2">
          See guidance and information for insurance on compass at{" "}
          <Link
            href="https://compass.itv/managing-risk/insurance/eo-insurance"
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            https://compass.itv/managing-risk/insurance/eo-insurance
          </Link>{" "}
          <OpenInNewIcon fontSize="inherit" />
          <br />
          Any questions you can contact the team at insurance@itv.com
        </Typography>
      </Stack>
      <Box>
        <img src="/images/calculating.gif" alt="calculating score" />
      </Box>
    </Stack>

    <Stack direction="row" justifyContent="flex-end" spacing={1}>
      <Button variant="text" onClick={onEditForm} sx={{ color: "white" }}>
        Edit form answers
      </Button>
      <Button
        variant="contained"
        onClick={onGoBackToProduction}
        sx={{
          bgcolor: "white",
          color: "black",
          ml: 4,
          "&:hover": { color: "white" },
        }}
      >
        Go back to home page
      </Button>
    </Stack>
  </Stack>
);

const RiskScoreSlides: FC<{ onSkip: () => void; onStart: () => void }> = ({
  onSkip,
  onStart,
}) => {
  const steps = useMemo(
    () => [
      {
        title: "We've got your risk score!",
        paragraph1:
          "There are a few things to note about the score we have given you and how to get the most out of what you see.",
        img: "SPOT_explainer_slide_1.gif",
      },
      {
        title: "For each section you can receive a score from 1 - 4:",
        paragraph1: (
          <ol>
            <li>Insignificant Risk</li>
            <li>Low Risk</li>
            <li>Medium Risk</li>
            <li>High Risk</li>
          </ol>
        ),
        paragraph2:
          "Reach out to the group risk contact assigned to each section for guidance on how to lower these scores.",
        img: "traffic_lights_2.gif",
      },
      {
        title: "Final tips",
        paragraph1:
          "You can find the score, contact and notes for that section, at the top of the section details.",
        paragraph2:
          "Group risk and insurance will be in touch to clarify any submitted answers, and to mitigate any higher risk areas.",
        img: "comp_10_1.gif",
      },
    ],
    [],
  );

  const [activeStep, setActiveStep] = useState<number>(0);
  const currentStep = useMemo(() => steps[activeStep], [activeStep, steps]);

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} p={4}>
        <Stack spacing={3}>
          <Typography variant="h4">{currentStep.title}</Typography>
          <Typography>{currentStep.paragraph1}</Typography>
          <Typography variant="body2">{currentStep.paragraph2}</Typography>
        </Stack>
        <Box>
          <img src={`/images/${currentStep.img}`} alt={currentStep.title} />
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button variant="text" onClick={onSkip} sx={{ color: "white" }}>
          Skip
        </Button>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          backButton=""
          nextButton={
            activeStep < steps.length - 1 ? (
              <Button
                variant="outlined"
                onClick={() => setActiveStep(activeStep + 1)}
                sx={{ color: "white", borderColor: "white", ml: 4 }}
              >
                Next slide
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={onStart}
                sx={{
                  bgcolor: "white",
                  color: "black",
                  ml: 4,
                  "&:hover": { color: "white" },
                }}
              >
                Get started
              </Button>
            )
          }
          sx={{
            background: "transparent",
            "& .MuiMobileStepper-dotActive": {
              background: "white",
            },
          }}
          position="static"
          activeStep={activeStep}
        />
      </Stack>
    </Stack>
  );
};

export const RiskScoreDialogNew: FC<{ onStart: () => void }> = ({
  onStart,
}) => {
  const { seriesCcid, titleCcid, programmeCcid } = useParams();
  const navigate = useNavigate();
  const type = titleCcid ? TITLES : SERIES;
  const riskId = titleCcid || seriesCcid;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timer = useRef<any>(null);

  const {
    data: riskScore,
    isLoading,
    refetch,
  } = useRiskScoreNew({ ccid: riskId, version: "v3" });

  const hasScore = useMemo(() => {
    if (isLoading) {
      return false;
    }

    const values = Object.values(riskScore || {});

    return Boolean(values.find((value) => Boolean(value?.rating)));
  }, [riskScore, isLoading]);

  useEffect(() => {
    if (!hasScore) {
      if (timer.current) {
        clearInterval(timer.current);
      }

      timer.current = setInterval(() => {
        refetch();
      }, 3000);
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [hasScore, refetch]);

  const handleCloseScoreSlides = useCallback(() => {
    ModalService.getInstance().hideModal();
    onStart();
  }, [onStart]);

  const onGoBackToProduction = () => {
    if (type === "series")
      navigate(`/programmes/${programmeCcid}/${type}/${seriesCcid}`);
    else if (type === "titles") navigate(`/special/${riskId}`);
    else navigate("/");
  };

  return (
    <Box bgcolor="#0f73ef" p={2} color="white">
      {hasScore ? (
        <RiskScoreSlides
          onSkip={handleCloseScoreSlides}
          onStart={handleCloseScoreSlides}
        />
      ) : (
        <CalculatingScore
          onEditForm={() => {
            ModalService.getInstance().hideModal();
          }}
          onGoBackToProduction={onGoBackToProduction}
        />
      )}
    </Box>
  );
};
