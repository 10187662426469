import * as Yup from "yup";

export const dataPrivacySchema = Yup.object().shape({
  childrenFeatured: Yup.string().required("This is a required field"),
  covertActivities: Yup.string().required("This is a required field"),
  journalisticAims: Yup.string().required("This is a required field"),
  novelData: Yup.string().required("This is a required field"),
  personalRelationships: Yup.string().required("This is a required field"),
  riskOfHarm: Yup.string().required("This is a required field"),
  sensitiveMaterial: Yup.string().required("This is a required field"),
  subjectsAware: Yup.string().required("This is a required field"),
});

export type DataPrivacySection = Yup.InferType<typeof dataPrivacySchema>;
