/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { PersonRounded } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RiskAssessor, RiskLevel, RiskMap } from "../../../types/types";
import theme from "../../../utils/theme";

export interface RiskContactProps {
  riskAssessor: RiskAssessor;
  riskLevel?: RiskLevel;
  riskNotes?: string;
  preMitgationLevel?: RiskLevel;
  postMitgationLevel?: RiskLevel;
  preMitgationNotes?: string;
  postMitgationNotes?: string;
}

const riskMap: RiskMap = {
  [RiskLevel.High]: {
    severity: "error",
    text: "High Risk",
    color: theme.palette.error.main,
  },
  [RiskLevel.Medium]: {
    severity: "warning",
    text: "Medium Risk",
    backgroundColor: "#fdf0e6",
    color: theme.palette.warning.main,
  },
  [RiskLevel.Low]: {
    severity: "success",
    text: "Low Risk",
    color: theme.palette.success.main,
  },
  [RiskLevel.Insignificant]: {
    severity: "success",
    text: "Insignificant Risk",
    color: theme.palette.success.main,
  },
};

export const RiskContact: FC<RiskContactProps> = ({
  riskAssessor,
  preMitgationLevel,
  postMitgationLevel,
  preMitgationNotes,
  postMitgationNotes,
}) => {
  const riskDataPre = useMemo(() => {
    if (preMitgationLevel) {
      return riskMap[preMitgationLevel];
    }

    return false;
  }, [preMitgationLevel]);

  const riskDataPost = useMemo(() => {
    if (postMitgationLevel) {
      return riskMap[postMitgationLevel];
    }

    return false;
  }, [postMitgationLevel]);

  return (
    <Grid direction="column" container spacing={2} paddingBottom={2}>
      <Grid item xs={6}>
        <Card sx={{ height: "100%", maxHeight: 140, display: "flex" }}>
          <CardHeader
            avatar={<PersonRounded color="disabled" fontSize="large" />}
            title={`Group Risk Contact: ${riskAssessor.name}`}
            subheader={`Contact for enquiries: ${riskAssessor.email}`}
          />
        </Card>
      </Grid>
      {riskDataPre && preMitgationLevel && (
        <Grid item xs={6}>
          <Accordion
            defaultExpanded
            sx={{ backgroundColor: "#e8e4e4", border: "solid" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight="fontWeightMedium">
                Pre Mitigation Rating
              </Typography>
              <Typography
                color={riskDataPre.color}
                fontWeight="fontWeightMedium"
              >
                {": "}
                {preMitgationLevel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="fontWeightMedium">
                Group Risk Response Summary:
              </Typography>
              <Typography>
                {preMitgationNotes || "No notes to display"}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {riskDataPost && postMitgationLevel && (
        <Grid item xs={6}>
          <Accordion
            defaultExpanded
            sx={{ backgroundColor: "#e8e4e4", border: "solid" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight="fontWeightMedium">
                Post Mitigation Rating
              </Typography>
              <Typography
                sx={{ color: riskDataPost.color }}
                fontWeight="fontWeightMedium"
              >
                {": "}
                {postMitgationLevel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography fontWeight="fontWeightMedium">
                Group Risk Response Summary:
              </Typography>
              <Typography>
                {postMitgationNotes || "No notes to display"}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </Grid>
  );
};
