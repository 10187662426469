/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from "react";
import { HomeOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import {
  GroupMenu,
  GroupMenuProps,
  setLinkAndActiveStatus,
} from "../../../../components/GroupMenu";
import {
  useLabelUserEntitlements,
  useMetadataIncludingOpportunity,
  useTasks,
} from "../../../../hooks/queries";
import { DownloadAssetsDialog } from "../../../../components/DownloadAssetsDialog";
import { useBulkImagesDownloadLink } from "../../../../hooks/queries/useBulkImagesDownloadLink";
import { Metadata } from "../../../../types/types";

type GroupMenuItems = GroupMenuProps["items"];
type GroupMenuItem = GroupMenuItems[0];

const taskTab: GroupMenuItem = {
  label: "Tasks",
  id: "tasks",
  link: "",
  icon: (
    <HomeOutlined
      color="primary"
      // size={24}
      key="home"
      style={{
        marginRight: "10px",
        position: "relative",
        top: "-3px",
      }}
    />
  ),
};

export const getExtraItems = ({
  isOneOff,
  isSeries,
  isEpisode,
  isSpecialEpisode,
  isProgramme,
  openDownloadModal,
  canDownloadImages,
  bulkImageDownloadLoading,
  metadataStatus,
  permissionStatus,
}: {
  isOneOff: boolean;
  isSeries: boolean;
  isEpisode: boolean;
  isSpecialEpisode: boolean;
  isProgramme: boolean;
  openDownloadModal: () => void;
  canDownloadImages: boolean;
  bulkImageDownloadLoading: boolean;
  metadataStatus: Metadata["status"] | undefined;
  permissionStatus: boolean | undefined;
}): GroupMenuItems => {
  if (isOneOff && metadataStatus !== "OPPORTUNITY" && permissionStatus) {
    return [
      {
        label: "Reference information",
        subNavs: [
          {
            sectionTitle: "Forms",
            items: [
              {
                label: "New production notification",
                id: "notification-form",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Synopsis & contributors",
            items: [
              {
                label: "Synopsis descriptions",
                id: "synopsis",
                link: "",
              },
              {
                label: "Contributors",
                id: "contributors",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Production details",
            items: [
              {
                label: "View production numbers",
                id: "productionNumbers",
                link: "",
              },
              {
                label: "View production contacts",
                id: "productionContacts",
                link: "",
              },
            ],
          },
        ],
      },
      {
        label: "Assets",
        subNavs: [
          {
            sectionTitle: "Video assets",
            items: [
              {
                label: "Tx Master(s)",
                id: "txmaster",
                link: "",
              },
              {
                label: "Preview timecodes",
                id: "txmaster",
                link: "",
              },
              {
                label: "Pre-title sequence",
                id: "preTitleSequence",
                link: "",
              },
              {
                label: "Marketing Promo Timecode",
                id: "marketingPromoClip",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Images",
            items: [
              {
                label: "Iconic Key Art - Images",
                id: "iconicKeyArtOneOff",
                link: "",
              },
              {
                label: "Iconic Key Art - Key contributors",
                id: "iconicKeyArtOneOffPresenterContributor",
                link: "",
              },
              {
                label: "Iconic Key Art - Gallery / On set",
                id: "iconicKeyArtOneOffGallery",
                link: "",
              },

              {
                label: "Behind the scenes images",
                id: "oneOffBehindScenes",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Branding elements",
            items: [
              { label: "Logo", id: "logoOneOff", link: "" },
              // { label: "Font", id: "fontOneOff", link: "" },
            ],
          },
        ],
      },
    ];
  } else if (isSeries && metadataStatus !== "OPPORTUNITY" && permissionStatus) {
    return [
      {
        label: "Reference information",
        subNavs: [
          {
            sectionTitle: "Forms",
            items: [
              {
                label: "New production notification",
                id: "notification-form",
                link: "",
              },
              {
                label: "Start up form",
                id: "start-up-form",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Synopsis & contributors",
            items: [
              {
                label: "Synopsis descriptions",
                id: "synopsis",
                link: "",
              },
              {
                label: "Contributors",
                id: "contributors",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Production details",
            items: [
              {
                label: "View production numbers",
                id: "productionNumbers",
                link: "",
              },
              {
                label: "View production contacts",
                id: "productionContacts",
                link: "",
              },
            ],
          },
        ],
      },
      {
        label: "Assets",
        subNavs: [
          {
            sectionTitle: "Video assets",
            items: [
              {
                label: "Series preview timecodes",
                id: "txmaster",
                link: "",
              },
              {
                label: "Series pre-title sequence",
                id: "preTitleSequence",
                link: "",
              },
              {
                label: "Marketing Promo Timecode",
                id: "marketingPromoClip",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Images",
            items: [
              {
                label: "Iconic Key Art - Series",
                id: "iconicKeyArtSeries",
                link: "",
              },
              {
                label: "Iconic Key Art - Key contributors ",
                id: "iconicKeyArtSeriesPresenterContributor",
                link: "",
              },
              {
                label: "Iconic Key Art - Gallery / On set",
                id: "iconicKeyArtSeriesGallery",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Branding elements",
            items: [
              {
                label: "Logo",
                id: "logoSeries",
                link: "",
              },
              // { label: "Font", id: "fontSeries", link: "" },
            ],
          },
          ...(canDownloadImages
            ? [
                {
                  sectionTitle: "Download assets",
                  items: [
                    {
                      label: "Download all images",
                      id: "downloadAssets",
                      type: "DOWNLOAD-BUTTON" as const,
                      link: "",
                      onItemClick: () => {
                        openDownloadModal();
                      },
                      disabled: bulkImageDownloadLoading,
                    },
                  ],
                },
              ]
            : []),
        ],
      },
    ];
  } else if (isSpecialEpisode && permissionStatus) {
    return [
      {
        label: "Reference information",
        subNavs: [
          {
            sectionTitle: "Synopsis & contributors",
            items: [
              {
                label: "Synopsis descriptions",
                id: "synopsis",
                link: "",
              },
              {
                label: "Contributors",
                id: "contributors",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Production details",
            items: [
              {
                label: "View production numbers",
                id: "productionNumbers",
                link: "",
              },
              {
                label: "View production contacts",
                id: "productionContacts",
                link: "",
              },
            ],
          },
        ],
      },
      {
        label: "Assets",
        subNavs: [
          {
            sectionTitle: "Video assets",
            items: [
              {
                label: "View txmaster",
                id: "txmaster",
                link: "",
              },
              {
                label: "Episode clip timecode",
                id: "episodeClipTimecode",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Images",
            items: [
              { label: "Episodic Images", id: "episodic", link: "" },
              {
                label: "Behind the scenes images",
                id: "episodicBehindScenes",
                link: "",
              },
            ],
          },
        ],
      },
    ];
  } else if (isEpisode && permissionStatus) {
    return [
      {
        label: "Reference information",
        subNavs: [
          {
            sectionTitle: "Synopsis & contributors",
            items: [
              {
                label: "Synopsis descriptions",
                id: "synopsis",
                link: "",
              },
              {
                label: "Contributors",
                id: "contributors",
                link: "",
              },
            ],
          },
        ],
      },
      {
        label: "Assets",
        subNavs: [
          {
            sectionTitle: "Video assets",
            items: [
              {
                label: "View txmaster",
                id: "txmaster",
                link: "",
              },
              {
                label: "Episode clip timecode",
                id: "episodeClipTimecode",
                link: "",
              },
            ],
          },
          {
            sectionTitle: "Images",
            items: [
              { label: "Episodic Images", id: "episodic", link: "" },
              {
                label: "Behind the scenes images",
                id: "episodicBehindScenes",
                link: "",
              },
            ],
          },
        ],
      },
    ];
  } else if (isProgramme && permissionStatus) {
    return [
      {
        label: "Reference information",
        subNavs: [
          {
            sectionTitle: "Synopsis & contributors",
            items: [
              {
                label: "Synopsis descriptions",
                id: "synopsis",
                link: "",
              },
            ],
          },
        ],
      },
    ];
  }

  return [];
};

export const Menu: FC<{
  tab: string;
  basePath: string;
  programmeCcid?: string;
  seriesCcid?: string;
  titleCcid?: string;
}> = ({ tab, basePath, programmeCcid, seriesCcid, titleCcid }) => {
  const isOneOff = Boolean(titleCcid && !seriesCcid && !programmeCcid);
  const isSeries = Boolean(programmeCcid && seriesCcid && !titleCcid);
  const isSpecialEpisode = Boolean(
    programmeCcid && seriesCcid && titleCcid && seriesCcid === "0",
  );
  const isEpisode = Boolean(programmeCcid && seriesCcid && titleCcid);
  const isProgram = Boolean(programmeCcid && !seriesCcid && !titleCcid);

  const { data, isLoading: isLoadingTasks } = useTasks(
    programmeCcid,
    seriesCcid,
    titleCcid,
  );
  const tasks = data?.tasks || [];
  const referencePermission = tasks?.length === 0 && !data?.hasSubTasks;

  const navigate = useNavigate();

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const { data: entitlements } = useLabelUserEntitlements();

  const { isLoading: bulkImageDownloadLoading } = useBulkImagesDownloadLink();

  const { data: metadata, isLoading: metadataLoading } =
    useMetadataIncludingOpportunity(
      isSeries ? seriesCcid : isOneOff ? titleCcid : undefined,
    );

  if (metadataLoading || isLoadingTasks) {
    return null;
  }

  const extraItems = getExtraItems({
    isOneOff,
    isSeries,
    isEpisode,
    isSpecialEpisode,
    isProgramme: isProgram,
    openDownloadModal: () => {
      setIsDownloadModalOpen(true);
    },
    canDownloadImages: entitlements?.canBulkDownloadImages || false,
    bulkImageDownloadLoading,
    metadataStatus: metadata?.status,
    permissionStatus: !referencePermission,
  });

  const newGroupMenuSetting = tasks.length
    ? [taskTab, ...extraItems]
    : extraItems;

  const groupMenuConfig = setLinkAndActiveStatus(
    basePath,
    newGroupMenuSetting,
    tab,
  );

  return (
    <>
      {entitlements?.canBulkDownloadImages === true &&
        typeof seriesCcid === "string" &&
        typeof programmeCcid === "string" && (
          <DownloadAssetsDialog
            isOpen={isDownloadModalOpen}
            onClose={() => {
              setIsDownloadModalOpen(false);
            }}
            seriesCcid={seriesCcid}
            programmeCcid={programmeCcid}
          />
        )}
      <GroupMenu
        items={groupMenuConfig}
        smallFontSize
        onItemClick={(item) => navigate(item.link)}
      />
    </>
  );
};
