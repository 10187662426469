import React from "react";
import { Box, Typography } from "@mui/material";
import BrandCard from "../../componentsV2/BrandCard/BrandCard";
import OneOffCard from "../../componentsV2/OneOffCard/OneOffCard";
import { ProgrammeSearchPayload } from "../../types/types";

const ExistingProgrammeFoundPage: React.FC<{
  searchResults: ProgrammeSearchPayload | undefined;
}> = ({ searchResults }) => (
  <>
    <Typography>Did you mean any of these existing productions?</Typography>
    <Box>
      <Box maxHeight="381px" overflow="auto" sx={{ px: 1, mt: 2, mb: 4 }}>
        {searchResults?.results.map((item) => {
          if (item.Brand)
            return (
              <BrandCard
                key={item.Brand.brandCcid}
                programme={item.Brand}
                linkToCreateCcid
              />
            );
          if (item.OneOff)
            return (
              <OneOffCard key={item.OneOff.titleCcid} programme={item.OneOff} />
            );
          return null;
        })}
      </Box>
    </Box>
  </>
);

export default ExistingProgrammeFoundPage;
