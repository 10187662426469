/* eslint-disable @typescript-eslint/no-explicit-any */

import { styled } from "@mui/system";

export const StyledButtonsContainer = styled("div")`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1rem 2rem;
  background: ${({ theme }: any) => theme.palette.white};
  box-shadow: -1px 0rem 0.25rem rgba(0, 0, 0, 0.4);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
`;

export const StyledRightButtonsContainer = styled("div")`
  display: flex;

  & > * {
    margin-right: 0.5rem;
  }
`;
