import { FC } from "react";
import { Typography, Box, Chip, Stack } from "@mui/material";
import { ContactUs } from "../../components";
import { ContactUsTypography } from "../../components/ContactUs/ContactUsTypography";

interface ProductionPrinciplesTitleProps {
  genre?: string;
}

export const ProductionPrinciplesFormTitle: FC<
  ProductionPrinciplesTitleProps
> = ({ genre }) => (
  <Box>
    <Typography marginY={2}>
      ITV is commited to creating an inclusive workforce, and to making a
      lasting impact on both the environment and our wider community. ITV would
      like to work with you to achieve these very important goals which fit in
      with ITV's overall Social Purpose strategy.
    </Typography>
    <Box>
      <Stack direction="row" sx={{ pb: 2 }}>
        <Chip
          sx={{ color: "#22252F", bgcolor: "rgba(0,0,0,0.08)" }}
          label={<Box fontWeight="fontWeightMedium">Genre: {genre}</Box>}
        />
        <Stack direction="row" justifyContent="end" width={1}>
          <Typography sx={{ pt: 0.5 }} variant="body2">
            <ContactUs ClickableComponent={ContactUsTypography} />
          </Typography>
        </Stack>
      </Stack>
    </Box>
  </Box>
);
