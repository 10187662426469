import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { type ProcurementSectionV3 } from "../yupSchemas/procurementSchemaV3";
import { NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { RadioField } from "../formFields/RadioField";
import { YesNoField } from "../formFields/YesNoField";
import { CheckboxField } from "../formFields/CheckboxField";
import { MultiLineTextField } from "../formFields/MultiLineTextField";
import { UseUpdateRiskArgsNew } from "../../../hooks";

const spendOptions = [
  {
    label: "Post production",
    value: "PostProduction",
  },
  {
    label: "Outside broadcast",
    value: "OutsideBroadcast",
  },
  {
    label: "Audiovisual hire",
    value: "AudiovisualHire",
  },
  {
    label: "Facility vehicles",
    value: "FacilityVehicles",
  },
  {
    label: "Sound equipment",
    value: "SoundEquipment",
  },
  {
    label: "Rigging or camera cranes",
    value: "RiggingOrCameraCranes",
  },
  {
    label: "Camera hire",
    value: "CameraHire",
  },
  {
    label: "Portable single camera crews",
    value: "PortableSingleCameraCrews",
  },
  {
    label: "Sourced material",
    value: "SourcedMaterial",
  },
  {
    label: "Lighting",
    value: "Lighting",
  },
  {
    label: "Studio hire",
    value: "StudioHire",
  },
  {
    label: "Set construction",
    value: "SetConstruction",
  },
  {
    label: "Security",
    value: "Security",
  },
  {
    label: "Catering",
    value: "Catering",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "None of These",
    value: "NoneOfTheseCategories",
  },
];

const supplierOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "TBC",
  },
];

const contractingOptions = [
  {
    label: "Security",
    value: "Security",
  },
  {
    label: "Drivers",
    value: "Drivers",
  },
  {
    label: "Caterers",
    value: "Caterers",
  },
  {
    label: "Hotels",
    value: "Hotels",
  },
  {
    label: "Day labour",
    value: "DayLabour",
  },
  {
    label: "Stagehands",
    value: "Stagehands",
  },
  {
    label: "Set construction",
    value: "SetConstruction",
  },
  {
    label: "Cleaners",
    value: "Cleaners",
  },
  {
    label: "Riggers",
    value: "Riggers",
  },
  {
    label: "Facilities providers",
    value: "FacilitiesProviders",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "None of these",
    value: "NoneOfTheAbove",
  },
];

interface procurementPageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function ProcurementPageV3({ autosaveForm }: procurementPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.procurement || {};
  const errors = formik.errors.procurement || {};

  const yesNoChangeHandler = (fieldName: keyof ProcurementSectionV3) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`procurement.${fieldName}`).setValue(isYes);
    };
  };

  const checkboxesChangeHandler = (fieldName: keyof ProcurementSectionV3) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`procurement.${fieldName}`).setValue(values);
    };
  };

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      procurement: {
        ...formData.procurement,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Procurement</Typography>

              <CheckboxField
                version="v3"
                fieldInfo={{
                  sectionId: "procurement",
                  fieldName: "spend",
                }}
                label="Will any spend be over £100k (or equivalent) in the following
                categories?"
                error={
                  Array.isArray(errors.spend) ? errors.spend[0] : errors.spend
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("spend")}
                isRequired={true}
                value={values.spend}
                options={spendOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.spend && values.spend.includes("Other") && (
                <MultiLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "procurement",
                    fieldName: "otherSpend",
                  }}
                  label="Please provide detail on other spend"
                  error={errors.otherSpend}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.otherSpend}
                  placeholder="Please provide detail on other spend"
                  autosaveForm={wrappedAutosaveForm}
                />
              )}

              <RadioField
                version="v3"
                fieldInfo={{ sectionId: "procurement", fieldName: "supplier" }}
                label="Will you engage any non-ITV preferred suppliers with projected
              costs over £100,000 or critical to production/broadcasting?"
                error={errors.supplier}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.supplier}
                options={supplierOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.supplier === "Yes" && (
                <MultiLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "procurement",
                    fieldName: "supplierDetails",
                  }}
                  label="Please specify the suppliers"
                  error={errors.supplierDetails}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.supplierDetails}
                  placeholder="Please write details here"
                  autosaveForm={wrappedAutosaveForm}
                />
              )}

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "procurement",
                  fieldName: "dayLabourers",
                }}
                label="Will the production require any day labourers?"
                error={errors.dayLabourers}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("dayLabourers")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.dayLabourers}
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "procurement",
                  fieldName: "intermediary",
                }}
                label="For international locations do you intend to work through an
                intermediary? (i.e. fixer, local producer, location services
                company)"
                error={errors.intermediary}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("intermediary")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.intermediary}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.intermediary && (
                <CheckboxField
                  version="v3"
                  fieldInfo={{
                    sectionId: "procurement",
                    fieldName: "contracting",
                  }}
                  label="Will the intermediary be responsible for contracting any of the
                following?"
                  error={
                    Array.isArray(errors.contracting)
                      ? errors.contracting[0]
                      : errors.contracting
                  }
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={checkboxesChangeHandler("contracting")}
                  isRequired={true}
                  value={values.contracting}
                  options={contractingOptions}
                  autosaveForm={wrappedAutosaveForm}
                />
              )}

              {values.contracting && values.contracting.includes("Other") && (
                <MultiLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "procurement",
                    fieldName: "otherContracting",
                  }}
                  label="Please detail other contracting"
                  error={errors.otherContracting}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.otherContracting}
                  placeholder="Please write a brief description of the production here"
                  autosaveForm={wrappedAutosaveForm}
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
