export const setRecentlyViewedShows = (
  ccid: string,
  name: string,
  isFilmOrSpecial: boolean,
): null => {
  const recentlyVisitedProductionList = JSON.parse(
    localStorage.getItem("recentProductions") || "[]",
  );

  const recentlyVisitedShow = {
    name,
    ccid,
    dateVisited: new Date().toString(),
    pathname: isFilmOrSpecial ? `/special/${ccid}` : `/programmes/${ccid}`,
  };

  const hasMatchingCCIDs =
    recentlyVisitedProductionList.length > 0 &&
    recentlyVisitedProductionList.find(
      (recentlyVisited: { ccid: string }) => recentlyVisited.ccid === ccid,
    );

  Object.keys(recentlyVisitedProductionList).forEach(
    (recentlyVisited: string, key: number) => {
      if (recentlyVisitedProductionList[recentlyVisited] === hasMatchingCCIDs) {
        recentlyVisitedProductionList.splice(key, 1);
      }
    },
  );

  recentlyVisitedProductionList.push(recentlyVisitedShow);
  localStorage.setItem(
    "recentProductions",
    JSON.stringify(recentlyVisitedProductionList),
  );

  if (recentlyVisitedProductionList.length > 3) {
    recentlyVisitedProductionList.shift();
    localStorage.setItem(
      "recentProductions",
      JSON.stringify(recentlyVisitedProductionList),
    );
  }

  return null;
};
