import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useBrands } from "../../hooks/queries";
import BrandCard from "../../componentsV2/BrandCard/BrandCard";
import OneOffCard from "../../componentsV2/OneOffCard/OneOffCard";

const FindProgrammePage: React.FC = () => {
  const [userInput, setUserInput] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: searchResults, isLoading } = useBrands({ searchTerm });

  useEffect(() => {
    const runSearch = setTimeout(() => {
      setSearchTerm(userInput);
    }, 500);

    return () => clearTimeout(runSearch);
  }, [userInput]);

  const changeHandler = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUserInput(e.target.value);
  };

  return (
    <>
      <Typography>Search to find an existing programme</Typography>
      <TextField
        value={userInput}
        fullWidth
        size="small"
        label="Search for programme"
        id="search-input"
        data-testid="search-input"
        autoComplete="off"
        type="search"
        onChange={changeHandler}
        sx={{ background: "white", mt: 2 }}
      />

      {isLoading ? (
        <Stack justifyContent="center" alignItems="center" spacing={2} m={1}>
          <Typography variant="body2">Searching existing titles...</Typography>
          <CircularProgress color="primary" />
        </Stack>
      ) : null}
      {searchResults !== undefined ? (
        <Box>
          <Box maxHeight="381px" overflow="auto" sx={{ px: 1, mt: 2 }}>
            {searchResults?.results.map((item) => {
              if (item.Brand)
                return (
                  <BrandCard
                    key={item.Brand.brandCcid}
                    programme={item.Brand}
                    linkToCreateCcid
                  />
                );
              if (item.OneOff)
                return (
                  <OneOffCard
                    key={item.OneOff.titleCcid}
                    programme={item.OneOff}
                  />
                );
              return null;
            })}
          </Box>
          <Box mx={2}>
            <Typography variant="caption">
              {searchResults?.results
                ? "Don't see what you're looking for? Use the contact us button below to send us a message."
                : null}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default FindProgrammePage;
