/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { FC, useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Stack,
  Table,
  TableBody,
  Container,
  TableCell,
  Select,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ContributorProps, ContributorListRoleProps } from "../../types/types";
import { noop } from "../../utils/appHelper";
import { useContributorRoles } from "../../hooks/queries";
import { ModalWrapper } from "../ModalWrapper";
import { ModalService } from "../../services/modalService";
import { roleToDisplayName } from "../../containers/Synopsis/Edit/Contributors/contributor.helper";

interface ContributorRowProps {
  contributor: ContributorProps;
  onChange(contributor: ContributorProps, changedAttribute?: string): void;
  disabled: boolean;
  selectable: boolean;
  contributorRoles: ContributorListRoleProps[] | undefined;
  onDelete(contributor: ContributorProps): void;
  onInternalChange(contributor: ContributorProps): void;
}

export const ContributorRow: FC<ContributorRowProps> = ({
  contributor: originalContributor,
  onChange = noop,
  disabled = false,
  selectable = true,
  contributorRoles,
  onDelete = noop,
  onInternalChange = noop,
}) => {
  const [contributor, setContributor] = useState<ContributorProps>();
  const [displayCharacterInput, isCharacterInput] = useState<boolean>(false);

  useEffect(() => {
    setContributor(originalContributor);
  }, [originalContributor]);

  const handleColumnChange = useCallback(
    (columnsName: string, triggerChange = false) =>
      (e: any) => {
        if (contributor) {
          const newContributor = {
            ...contributor,
            [columnsName]:
              columnsName === "selected"
                ? !contributor.selected
                : e.target.value,
          };

          setContributor(newContributor);

          if (triggerChange) {
            onChange(newContributor, columnsName);
          }

          if (columnsName !== "selected") {
            onInternalChange(newContributor);
          }
        }
      },
    [contributor, onChange, onInternalChange],
  );

  const handleBlur = useCallback(
    (columnsName: string) => (e: any) => {
      const originalValue = (originalContributor as any)[columnsName];
      if (contributor && originalValue !== e.target.value) {
        onChange({
          ...contributor,
          [columnsName]: e.target.value,
        });
      }
    },
    [originalContributor, contributor, onChange],
  );

  const contributorInstanceRole =
    typeof contributor?.role === "string"
      ? contributor?.role
      : contributor?.role?.value;
  const roleInstanceValue = contributorInstanceRole || "";
  const filteredArrayInstance = contributorRoles?.filter(
    (instance) => instance.role === roleInstanceValue,
  )[0] || { hasCharacter: false };
  const instanceHasCharacter = filteredArrayInstance.hasCharacter;

  useEffect(() => {
    isCharacterInput(instanceHasCharacter);
  }, [instanceHasCharacter]);

  return (
    <>
      {contributor && (
        <tr>
          {selectable && (
            <td className="short checkbox">
              <Checkbox
                checked={contributor.selected}
                id={contributor.contributorId as string}
                onChange={handleColumnChange("selected", true)}
              />
            </td>
          )}
          <td>
            <Container className="input-box forenames">
              <TextField
                label=""
                fullWidth
                size="small"
                disabled={disabled}
                id={contributor.forenames ?? ""}
                value={contributor.forenames}
                error={!contributor.forenames}
                onChange={handleColumnChange("forenames")}
                onBlur={handleBlur("forenames")}
              />
            </Container>
          </td>
          <td className="input-box">
            <TextField
              fullWidth
              size="small"
              label=""
              disabled={disabled}
              id={contributor.surname ?? ""}
              value={contributor.surname}
              onChange={handleColumnChange("surname")}
              onBlur={handleBlur("surname")}
            />
          </td>
          <td className="role-column">
            <Stack
              justifyContent="center"
              alignItems="center"
              className="role-container"
              direction="row"
            >
              {contributorRoles && (
                <Select
                  size="small"
                  fullWidth
                  value={contributorInstanceRole}
                  error={!contributorInstanceRole}
                  className={!contributorInstanceRole ? "Mui-error" : ""}
                  disabled={disabled}
                  label="Select a Role"
                  onChange={(e: any) => {
                    const matchedRole = contributorRoles.find(
                      (item) => item.role === e.target.value,
                    );
                    if (matchedRole) {
                      isCharacterInput(matchedRole.hasCharacter);
                      handleBlur("role")({
                        target: { value: matchedRole.role },
                      });
                    }
                  }}
                >
                  {contributorRoles.map(({ role }) => (
                    <MenuItem value={role} key={role}>
                      {roleToDisplayName(role)}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {displayCharacterInput && (
                <>
                  -
                  <div className="input-box">
                    <TextField
                      label=""
                      sx={{ width: "240px" }}
                      size="small"
                      value={contributor.character}
                      id={contributor.character || ""}
                      onChange={handleColumnChange("character")}
                      onBlur={handleBlur("character")}
                      disabled={disabled}
                    />
                  </div>
                </>
              )}
            </Stack>
          </td>
          <td className="delete">
            <IconButton
              disabled={disabled}
              onClick={() => {
                if (!disabled) {
                  ModalService.getInstance("contributor")
                    .setShowHeader(false)
                    .setShowFooter(true)
                    .setCentered(true)
                    .setWidth("600px")
                    .setComponent(
                      <div>
                        <div>Delete Contributors</div>
                        <p>
                          Please confirm you want to remove this contributor
                          from this episode.
                        </p>
                      </div>,
                    )
                    .setConfirmationLabel("Delete")
                    .setCancelLabel("Cancel")
                    .setConfirmCallback(() => {
                      onDelete(contributor);
                      ModalService.getInstance("contributor").hideModal();
                    })
                    .setCancelCallback(() => {
                      ModalService.getInstance("contributor").hideModal();
                    })
                    .showModal();
                }
              }}
            >
              {" "}
              <Delete />
            </IconButton>
          </td>
        </tr>
      )}
    </>
  );
};

interface SelectableContributorsProps {
  contributors: ContributorProps[];
  disabled?: boolean;
  selectable?: boolean;
  onChange?(
    contributor: ContributorProps,
    index: number,
    changedAttribute?: string,
  ): void;
  onDelete?(contributor: ContributorProps): void;
  onInternalChange?(contributor: ContributorProps): void;
}

export const SelectableContributors: FC<SelectableContributorsProps> = ({
  contributors,
  disabled = false,
  selectable = true,
  onChange = noop,
  onDelete = noop,
  onInternalChange = noop,
}) => {
  const { data: contributorRoles } = useContributorRoles();
  return (
    <>
      <TableContainer component={Paper}>
        <Table className="titleContributors">
          <TableHead>
            <TableRow>
              {selectable && <TableCell className="short">&nbsp;</TableCell>}
              <TableCell>Forename</TableCell>
              <TableCell>Surname</TableCell>
              <TableCell className="role-column">
                Role - Character name (for actors)
              </TableCell>
              <TableCell className="delete">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contributors &&
              contributors.map((contributor, index) => (
                <ContributorRow
                  contributorRoles={contributorRoles}
                  contributor={contributor}
                  onChange={(
                    newContributor: ContributorProps,
                    changedAttribute,
                  ) => {
                    onChange(newContributor, index, changedAttribute);
                  }}
                  onDelete={() => {
                    onDelete(contributor);
                  }}
                  onInternalChange={(newContributor: ContributorProps) => {
                    onInternalChange(newContributor);
                  }}
                  disabled={disabled}
                  selectable={selectable}
                  key={`${contributor.contributorId}-${contributor.forenames}-${
                    contributor.surname
                  }-${
                    typeof contributor.role === "string"
                      ? contributor.role
                      : contributor?.role?.value
                  }-${index}`}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalWrapper msgPrefix="contributor" />
    </>
  );
};
