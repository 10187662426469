import {
  Typography,
  Stack,
  Card,
  CardContent,
  InputAdornment,
} from "@mui/material";
import { useFormikContext } from "formik";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { YesNoField } from "../formFields/YesNoField";
import { MultiLineTextField } from "../formFields/MultiLineTextField";
import { MailOutline, PersonOutline } from "@mui/icons-material";
import { EmailField } from "../formFields/EmailField";
import { CheckboxField } from "../formFields/CheckboxField";
import { UseUpdateRiskArgsNew } from "../../../hooks";
import { NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { HealthAndSafetySectionV3 } from "../yupSchemas/healthAndSafetySchemaV3";

const trainingOptions = [
  {
    label: "No formal Health and Safety Training",
    value: "NoTraining",
  },
  {
    label: "Health and Safety Training received via an external provider",
    value: "External",
  },
  {
    label: "ITV's Internal Managing Risk Health and Safety Training ",
    value: "Internal",
  },
  {
    label: "Mark Milsome Foundation Film and TV Online Safety Passport Course",
    value: "MarkMilsomeSafetyCourse",
  },
];

const activitiesOptions = [
  {
    label: "Food handling",
    value: "FoodHandling",
  },
  {
    label: "Filming with animals",
    value: "FilmingWithAnimals",
  },
  {
    label: "Underground filming",
    value: "Underground",
  },
  {
    label: "Extreme Sports",
    value: "Extreme",
  },
  {
    label: "The use of jet skis or other high speed water vessels",
    value: "JetSkisOrOther",
  },
  {
    label: "Live or imitation firearms",
    value: "Firearms",
  },
  {
    label: "Use of drones",
    value: "Drones",
  },
  {
    label: "Diving or other waterborne activities",
    value: "DivingOrOther",
  },
  {
    label: "Filming in adverse weather or darkness",
    value: "AdverseWeather",
  },
  {
    label: "Stunts and/or SFX",
    value: "Stunts",
  },
  {
    label: "Other potential high risk activities",
    value: "OtherHighRisk",
  },
  {
    label: "Lone working",
    value: "LoneWorking",
  },
  {
    label: "None of these activities",
    value: "NoneOfAbove",
  },
];

const vulnerableOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "Not yet known", value: "TBC" },
];

interface HealthAndSafetyPageProps {
  autosaveForm: (formData: UseUpdateRiskArgsNew["formData"]) => void;
}

export function HealthAndSafetyPageV3({
  autosaveForm,
}: HealthAndSafetyPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.healthAndSafety || {};
  const errors = formik.errors.healthAndSafety || {};

  const checkboxesChangeHandler = (
    fieldName: keyof HealthAndSafetySectionV3,
  ) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`healthAndSafety.${fieldName}`).setValue(values);
    };
  };

  const yesNoChangeHandler = (fieldName: keyof HealthAndSafetySectionV3) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`healthAndSafety.${fieldName}`).setValue(isYes);
    };
  };

  // the logic for this is inverted - "Yes" means `false` and "No" means `true`.
  // this is more confusing for us but less confusing for the user
  const assignedResponsibilityHandler = (isYes: boolean) => {
    formik.getFieldHelpers("healthAndSafety.responsibleNone").setValue(!isYes);
  };

  const wrappedAutosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    const updatedFormData = {
      ...formData,
      healthAndSafety: {
        ...formData.healthAndSafety,
        spotId: values.spotId,
      },
    };
    autosaveForm(updatedFormData);
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">
                Risk as a factor of Health and Safety
              </Typography>

              <Typography variant="body1">
                Please indicate whether pre-production or filming will involve
                the following:
              </Typography>

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "responsibleNone",
                }}
                label="Has somebody been assigned responsibility for Health and Safety on this production?"
                error={errors.responsibleNone}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={assignedResponsibilityHandler}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={
                  typeof values.responsibleNone === "boolean"
                    ? !values.responsibleNone
                    : undefined
                }
                autosaveForm={wrappedAutosaveForm}
              />

              {values.responsibleNone === false && (
                <>
                  <SingleLineTextField
                    version="v3"
                    fieldInfo={{
                      sectionId: "healthAndSafety",
                      fieldName: "responsible",
                    }}
                    label="Who is the principle production contact that the Health and Safety team can communicate with regarding this production?"
                    error={errors.responsible}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.responsible}
                    placeholder="Forename Surname"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonOutline />
                      </InputAdornment>
                    }
                    helperText="This person is responsible for reporting all Health and Safety incidents to the ITV Incident Reporting Portal or to your Health and Safety risk advisor within 72 hours."
                    autosaveForm={wrappedAutosaveForm}
                  />

                  <EmailField
                    version="v3"
                    fieldInfo={{
                      sectionId: "healthAndSafety",
                      fieldName: "responsibleEmail",
                    }}
                    label="What is the email of the person responsible for Health and Safety on this production?"
                    error={errors.responsibleEmail}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.responsibleEmail}
                    placeholder="example@itv.com"
                    startAdornment={
                      <InputAdornment position="start">
                        <MailOutline />
                      </InputAdornment>
                    }
                    autosaveForm={wrappedAutosaveForm}
                  />
                </>
              )}

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "training",
                }}
                label="What is the highest level of Health and Safety Training this person has received?"
                error={errors.training}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.training}
                options={trainingOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              {values.training === "External" ? (
                <MultiLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "healthAndSafety",
                    fieldName: "trainingCourse",
                  }}
                  label="Please indicate external course and provider"
                  error={errors.trainingCourse}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={false}
                  value={values.trainingCourse}
                  placeholder="Type answer here"
                  autosaveForm={wrappedAutosaveForm}
                />
              ) : null}

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "externalProvider",
                }}
                label="Are you using an external Health and Safety provider?"
                error={errors.externalProvider}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("externalProvider")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.externalProvider}
                autosaveForm={wrappedAutosaveForm}
              />

              <CheckboxField
                version="v3"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "activities",
                }}
                label="Will the production be conducting any of the following activities?"
                error={
                  Array.isArray(errors.activities)
                    ? errors.activities[0]
                    : errors.activities
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("activities")}
                isRequired={true}
                value={values.activities}
                options={activitiesOptions}
                autosaveForm={wrappedAutosaveForm}
              />

              <MultiLineTextField
                version="v3"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "otherActivities",
                }}
                label="Please indicate other potential high risk activities"
                error={errors.otherActivities}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.otherActivities}
                placeholder="Type answer here"
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "setBuild",
                }}
                label="Will set construction be required for this production?"
                error={errors.setBuild}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("setBuild")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.setBuild}
                autosaveForm={wrappedAutosaveForm}
              />

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "filmingLocation",
                }}
                label="Will you be filming outside of a studio (on-location)?"
                error={errors.filmingLocation}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("filmingLocation")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.filmingLocation}
                autosaveForm={wrappedAutosaveForm}
              />

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "vulnerable",
                }}
                label="Will you be working with children or/and vulnerable adults?"
                error={errors.vulnerable}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.vulnerable}
                options={vulnerableOptions}
                autosaveForm={wrappedAutosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
