/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  TextField,
  Stack,
  Typography,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";
import Timecode from "smpte-timecode";

import { FRAME_RATE, isValidTimecode } from "../util";

const placeholder = "HH:MM:SS:FF";
const helperText = `format must be ${placeholder}`;

export interface TimeRange {
  startTime: string;
  endTime: string;
}

interface TimeCodeEditorProps {
  titleCcid: string;
  selectedVersionId: string;
  timeRange: TimeRange;
  hasFixedTimecodeLength: boolean;
  onTimeRangeChange(timeRange: TimeRange): void;
  onError(msg: string): void;
}

const fixedTimecodeLengths = [13, 14, 15];

const timeRangeToSeconds = (timeRange: TimeRange): number => {
  return timeRange &&
    timeRange.startTime &&
    isValidTimecode(timeRange.startTime) &&
    timeRange.endTime &&
    isValidTimecode(timeRange.endTime)
    ? Timecode(timeRange.endTime, FRAME_RATE).subtract(
        Timecode(timeRange.startTime, FRAME_RATE),
      ).seconds
    : 0;
};

export const TimeCodeEditor: FC<TimeCodeEditorProps> = ({
  titleCcid,
  selectedVersionId,
  timeRange,
  hasFixedTimecodeLength,
  onTimeRangeChange,
  onError,
}) => {
  const disabled = !titleCcid || !selectedVersionId;
  const [hasStartTimeError, setHasStartTimeError] = useState(false);
  const [hasEndTimeError, setHasEndTimeError] = useState(false);
  const [fixedTimecodeValue, setFixedTimecodeValue] = useState(
    hasFixedTimecodeLength
      ? fixedTimecodeLengths.find(
          (val) => val === timeRangeToSeconds(timeRange),
        ) || undefined
      : undefined,
  );

  const addSecondsToTimecode = (
    timecode: Timecode.TimecodeInstance,
    secondsToAdd: number,
  ): string => {
    // Extract the hours, minutes, seconds, and frames from the input timestamp
    const hours = timecode.hours;
    const minutes = timecode.minutes;
    const seconds = timecode.seconds;
    const frames = timecode.frames;

    // Convert the entire timestamp to total seconds
    let totalSeconds = hours * 3600 + minutes * 60 + seconds + secondsToAdd;

    // Extract the whole number of seconds and frames remaining
    const newHours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const newMinutes = Math.floor(totalSeconds / 60);
    totalSeconds %= 60;
    const newSeconds = Math.floor(totalSeconds);

    // Return the new timestamp in HH:MM:SS:FF format
    return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(2, "0")}:${String(newSeconds).padStart(2, "0")}:${String(frames).padStart(2, "0")}`;
  };

  const handleChange = useCallback(
    (fieldName: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const validTimecode = isValidTimecode(e.target.value);

      const newTimeRange: any =
        validTimecode && fieldName === "startTime" && fixedTimecodeValue
          ? {
              ...timeRange,
              startTime: e.target.value,
              endTime: addSecondsToTimecode(
                Timecode(e.target.value, FRAME_RATE),
                fixedTimecodeValue,
              ),
            }
          : {
              ...timeRange,
              [fieldName]: e.target.value,
            };

      if (fieldName === "startTime") {
        setHasStartTimeError(!validTimecode);
      } else {
        setHasEndTimeError(!validTimecode);
      }

      if (!validTimecode) {
        onError(helperText);
      } else {
        onError("");
      }

      onTimeRangeChange(newTimeRange);
    },
    [onError, onTimeRangeChange, timeRange, fixedTimecodeValue],
  );

  const handleDropdownChange = (e: SelectChangeEvent<number>) => {
    const targetValue = Number(e.target.value);
    const newTimestamp = addSecondsToTimecode(
      Timecode(timeRange.startTime),
      targetValue,
    );
    const newTimeRange: TimeRange = {
      ...timeRange,
      endTime: newTimestamp,
    };
    if (!hasStartTimeError && !hasEndTimeError)
      setFixedTimecodeValue(targetValue);
    onTimeRangeChange(newTimeRange);
  };

  useEffect(() => {
    // when time range change, it should check if it has error or not again
    if (isValidTimecode(timeRange?.startTime) && hasStartTimeError) {
      setHasStartTimeError(false);
    }

    if (isValidTimecode(timeRange?.endTime) && hasEndTimeError) {
      setHasEndTimeError(false);
    }
  }, [hasEndTimeError, hasStartTimeError, timeRange]);

  return (
    <Stack>
      <Stack
        direction="row"
        spacing={3}
        data-testid="time-code-editor"
        sx={{ padding: "0 0.5rem" }}
        alignItems="center"
      >
        <TextField
          fullWidth
          size="small"
          label="Start of media"
          id="startTime"
          data-testid="startTime"
          disabled={disabled}
          error={hasStartTimeError}
          onChange={handleChange("startTime")}
          placeholder={placeholder}
          value={timeRange?.startTime || ""}
        />

        {!hasFixedTimecodeLength && <Typography variant="h5">-</Typography> && (
          <TextField
            fullWidth
            size="small"
            label="End of media"
            id="endTime"
            data-testid="endTime"
            disabled={disabled}
            error={hasEndTimeError}
            onChange={handleChange("endTime")}
            placeholder={placeholder}
            value={timeRange?.endTime || ""}
          />
        )}
        {hasFixedTimecodeLength && (
          <FormControl disabled={disabled} fullWidth size="small">
            <InputLabel id="end-time-label">Length of promo clip</InputLabel>
            <Select
              value={fixedTimecodeValue}
              onChange={handleDropdownChange}
              disabled={disabled || !timeRange?.startTime || hasStartTimeError}
              label="Length of promo clip"
            >
              {fixedTimecodeLengths.map((val) => (
                <MenuItem key={val} value={val}>
                  {val} Seconds
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};
