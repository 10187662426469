import { FC } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
} from "@mui/material";

import { useContributors } from "../../../../../hooks";
import { ExcludeRolesFromBilling } from "../ExcludeRolesFromBilling";
import { ContributorRoleProps } from "../../../../../types/types";

export const SeriesContributors: FC = () => {
  const { seriesCcid } = useParams<string>();
  const columns = ["Forename", "Surname", "Role - Character name (for actors)"];
  const { data, isLoading } = useContributors(seriesCcid, true);

  if (!seriesCcid) {
    return (
      <Container sx={{ py: 1 }}>
        <p>You only can view contributors on series level</p>
      </Container>
    );
  }

  return (
    <Container sx={{ py: 2 }}>
      <h2>Contributors</h2>
      <p>Below are all of the contributors </p>
      <Card sx={{ padding: 2 }}>
        <Box display="flex" justifyContent="flex-end" marginBottom="1rem">
          <ExcludeRolesFromBilling seriesCcid={seriesCcid} />
        </Box>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column: string) => (
                    <TableCell key={column}>
                      <Typography variant="h6" component="div">
                        {column}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.contributors?.map((row) => {
                  const role = row.role as ContributorRoleProps;

                  return (
                    <TableRow key={row.contributorId}>
                      <TableCell>{row.forenames}</TableCell>
                      <TableCell>{row.surname}</TableCell>
                      <TableCell>
                        {row.character
                          ? `(${role?.displayName}) ${row.character}`
                          : role?.displayName}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
    </Container>
  );
};
