import { FC } from "react";
import { Typography, Container, Stack } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { CustomTextField } from "../../componentsV2/FormFields/CustomTextField";
import { BooleanAsYesNo, OfcomFormDataFormik } from "../../types/types";
import { useOfcomFormSubmission } from "../../hooks";
import {
  transformFieldForServer,
  transformFormDataToServer,
} from "./transformFormDataToServer";
import { YesNoRadio } from "./YesNoRadio";

interface OfcomPageOneFormikProps {
  ccid: string;
  provisional: boolean;
}

export const OfcomPage1: FC<OfcomPageOneFormikProps> = ({
  ccid,
  provisional,
}) => {
  const form = useFormikContext<OfcomFormDataFormik>();
  const { mutate: updateOfcomForm } = useOfcomFormSubmission();

  const handleBlur = (fieldName: keyof OfcomFormDataFormik) => {
    if (!form.errors[fieldName]) {
      updateOfcomForm({
        ccid,
        formData: transformFieldForServer(fieldName, form.values[fieldName]),
        autosave: true,
        provisional,
      });
    } else {
      form.setFieldTouched(fieldName);
    }
  };

  return (
    <>
      <Container sx={{ background: "white", my: 1 }}>
        <Stack direction="column" spacing={2} py={1}>
          <Typography variant="h5">European Work Criteria</Typography>
          <Typography variant="body2">
            If the Producer and Programme fulfill 2 out of the 3 following
            criteria, please answer Yes to the following question and specify
            the location and proceed straight to Part 5:
          </Typography>
          <Typography variant="body2">
            (1) Production location (i.e. the location of the base actually
            managing the production of the Programme) is not in the UK; and/or
          </Typography>
          <Typography variant="body2">
            (2) More than 50% of the budget (excluding the cost of on-screen
            talent, archive material, sports rights, competition prize money and
            copyright costs) spent outside the UK; and/or
          </Typography>
          <Typography variant="body2">
            (3) More than 50% of the talent (excluding on-screen talent) are
            usually employed outside the UK
          </Typography>
          <YesNoRadio
            name="foreignProduction"
            label="As per the criteria listed above, will the Programme be a foreign production?"
            value={form.values.foreignProduction}
            onChange={(event) => {
              form.handleChange(event);
              updateOfcomForm({
                ccid,
                formData: transformFormDataToServer({
                  ...form.values,
                  foreignProduction: event.target.value as BooleanAsYesNo,
                }),
                autosave: true,
                provisional,
              });
            }}
            error={!!form.errors.foreignProduction}
          />
        </Stack>
      </Container>
      <Container sx={{ background: "white", my: 1 }}>
        <Stack direction="column" spacing={2} py={1}>
          <Typography variant="h5">Producer Details</Typography>
          <Typography variant="body2">
            Who is the core team and label working on this production?
          </Typography>
          <Typography variant="body2">
            In which country is the Producer based?
          </Typography>
          <Field
            component={CustomTextField}
            name="producerCountry"
            label="Enter Producer Country"
            required
            onBlur={() => handleBlur("producerCountry")}
          />
        </Stack>
      </Container>
      <Container sx={{ background: "white", my: 1 }}>
        <Stack direction="column" spacing={2} py={1}>
          <Typography variant="h5">Production Company Location</Typography>
          <Typography variant="body2">
            Please specify the location of the foreign production (inc.
            Country).
          </Typography>
          <Typography variant="body2">Production Location</Typography>
          <Field
            component={CustomTextField}
            name="productionLocation"
            label="Enter Production Company Location"
            required
            onBlur={() => handleBlur("productionLocation")}
          />
        </Stack>
      </Container>
    </>
  );
};
