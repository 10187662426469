import { FC } from "react";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { FieldProps } from "formik";

interface TextFieldProps extends FieldProps {
  label: string;
  required: boolean;
  startAdornment?: string;
  endAdornment?: string;
  onBlur?: () => void;
  disabled: boolean;
}

export const CustomTextField: FC<TextFieldProps> = ({
  field,
  form: { touched, errors },
  label,
  required = false,
  startAdornment,
  endAdornment,
  onBlur,
  disabled = false,
}) => (
  <TextField
    {...field}
    required={required}
    fullWidth
    autoComplete="off"
    size="small"
    label={label}
    onBlur={onBlur}
    error={touched[field.name] && !!errors[field.name]}
    helperText={
      touched[field.name] && !!errors[field.name]
        ? (errors[field.name] as string)
        : " "
    }
    disabled={disabled}
    InputProps={{
      startAdornment: startAdornment && (
        <InputAdornment position="start">
          <Typography color="disabled">{startAdornment}</Typography>
        </InputAdornment>
      ),
      endAdornment: endAdornment && (
        <InputAdornment position="end">
          <Typography color="disabled">{endAdornment}</Typography>
        </InputAdornment>
      ),
    }}
  />
);
