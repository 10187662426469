/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import {
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FieldProps, FormikErrors, FormikTouched } from "formik";

interface StartupTextFieldProps extends FieldProps {
  label: string;
  fieldId: string;
  required: boolean;
  startAdornment?: string;
  endAdornment?: string;
  handleAutoSaveBlur?: (id: string, value: any) => void;
}

export const StartupTextField: FC<StartupTextFieldProps> = ({
  field,
  form: { touched, errors, values, handleBlur, setFieldValue },
  label,
  fieldId,
  required = false,
  startAdornment,
  endAdornment,
  handleAutoSaveBlur,
}) => {
  const key = fieldId.split(".")[0];
  const keyValue = fieldId.split(".")[1];

  const errorToDisplay = () => {
    if (
      errors[key] &&
      (errors[key] as FormikErrors<any>)[keyValue] &&
      touched[key] &&
      (touched[key] as FormikTouched<any>)[keyValue]
    ) {
      return (errors[key] as FormikErrors<any>)[keyValue];
    }
    return "";
  };

  return (
    <>
      <FormLabel
        sx={{ display: "block", marginBottom: "0.5rem" }}
        required={required}
      >
        {label}
      </FormLabel>
      <TextField
        {...field}
        value={values[fieldId.split(".")[0]][fieldId.split(".")[1]]}
        required={required}
        fullWidth
        autoComplete="off"
        size="small"
        name={fieldId}
        id={fieldId}
        error={Boolean(errorToDisplay())}
        helperText={errorToDisplay() as string}
        onBlur={(e) => {
          if (handleAutoSaveBlur) handleAutoSaveBlur(fieldId, e.target.value);
          handleBlur(e);
        }}
        onChange={(e) => {
          setFieldValue(fieldId, e.target.value);
        }}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">
              <Typography color="disabled">{startAdornment}</Typography>
            </InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">
              <Typography color="disabled">{endAdornment}</Typography>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
