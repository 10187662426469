import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getCollaborators } from "../../api/accessPermissions";
import { User } from "../../types/types";

export const useCollaborators = (
  ccid: string | undefined,
): UseQueryResult<User[], AxiosError> =>
  useQuery([`useCollaborators`, ccid], () => getCollaborators(ccid as string), {
    enabled: ccid !== "0" && ccid !== undefined,
  });
