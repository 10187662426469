/* eslint-disable no-prototype-builtins */
import React, { FC } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTitleMetadata } from "../../hooks";
import { OneOff } from "../../types/types";
import studioLogos from "../../logos/logos";

const OneOffCard: FC<{ programme: OneOff; onClickFn?: () => void }> = ({
  programme,
  onClickFn,
}) => {
  const navigate = useNavigate();
  const { data: titleData, isLoading } = useTitleMetadata(programme.titleCcid);
  return (
    <>
      <Card sx={{ my: 1 }}>
        <CardActionArea
          onClick={() => {
            if (onClickFn !== undefined) {
              onClickFn();
            }
            if (titleData?.status === "OPPORTUNITY")
              navigate(`/special/${programme.titleCcid}/update-ccid`);
            else navigate(`/special/${programme.titleCcid}`);
          }}
        >
          <CardContent>
            <Grid container spacing="2">
              <Grid item xs={8}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="h6">{programme.titleName}</Typography>
                  <Chip
                    size="small"
                    label={programme.titleType}
                    color="primary"
                  />
                  {titleData?.status === "OPPORTUNITY" ? (
                    <Chip
                      sx={{ margin: 1 }}
                      label="Requires Update"
                      variant="outlined"
                    />
                  ) : (
                    <></>
                  )}
                </Stack>

                {isLoading || !titleData ? (
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={
                      <Divider
                        sx={{ bgcolor: "black" }}
                        orientation="vertical"
                        flexItem
                      />
                    }
                  >
                    <Skeleton variant="text" sx={{ width: 85 }} />
                    <Skeleton variant="text" sx={{ width: 30 }} />
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={
                      <Divider
                        sx={{ bgcolor: "black" }}
                        orientation="vertical"
                        flexItem
                      />
                    }
                  >
                    <Typography variant="caption" display="inline">
                      {titleData.episodeId}
                    </Typography>
                    {titleData.productionYear ? (
                      <Typography variant="caption" display="inline">
                        {titleData.productionYear}{" "}
                      </Typography>
                    ) : null}
                  </Stack>
                )}
              </Grid>
              <Grid
                item
                xs={4}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {titleData?.productionLabels[0] !== undefined &&
                  studioLogos.hasOwnProperty(
                    titleData?.productionLabels[0],
                  ) && (
                    <Box
                      maxHeight={50}
                      component="img"
                      src={studioLogos[titleData?.productionLabels[0]]}
                      alt={titleData?.productionLabels[0]}
                    />
                  )}
                {titleData?.productionLabels[1] !== undefined &&
                  titleData?.productionLabels[0] !==
                    titleData?.productionLabels[1] &&
                  studioLogos.hasOwnProperty(
                    titleData?.productionLabels[1],
                  ) && (
                    <Box
                      maxHeight={50}
                      component="img"
                      src={studioLogos[titleData?.productionLabels[1]]}
                      alt={titleData?.productionLabels[1]}
                      ml={2}
                    />
                  )}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default OneOffCard;
