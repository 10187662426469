import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { School as SchoolIcon, Close } from "@mui/icons-material";

// import video links
import videos from "./tutorial-videos.json";

const TutorialVideos: React.FC = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loadingVideo, setLoadingVideo] = useState(true);
  const [currentVideo, setCurrentVideo] = useState(videos[0]); // Currently just displaying the first video in the list

  return (
    <>
      {/* Button to open dialog */}
      <Tooltip title="Tutorial Videos">
        <IconButton
          onClick={() => setOpen(true)}
          color="primary"
          aria-label="tutorial videos"
          sx={{
            "&:hover": { backgroundColor: `${theme.palette.primary.dark}` },
          }}
        >
          <SchoolIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="tutorial-videos"
      >
        {/* Dialog header */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <SchoolIcon />
            <Typography display="inline" variant="h5">
              Studios Portal tutorials
            </Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            color="primary"
          >
            <Close />
          </IconButton>
        </Stack>

        {/* Dialog content */}
        <Stack sx={{ width: "100%", height: "640px" }} direction="row">
          {/* Video player side - left */}
          <Box
            sx={{ width: "70%", bgcolor: "background.default", py: 2, px: 4 }}
          >
            <Typography variant="h5" sx={{ mb: 1 }}>
              {currentVideo.title}
            </Typography>
            {loadingVideo ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: "85%" }}
              >
                <CircularProgress />
              </Stack>
            ) : null}
            <iframe
              title={currentVideo.title}
              allowFullScreen
              hidden={loadingVideo}
              onLoad={() => setLoadingVideo(false)}
              src={currentVideo.src}
              style={{ width: "100%", height: "85%" }}
            />
            <Typography sx={{ mt: 1 }}>{currentVideo.description}</Typography>
          </Box>

          {/* List side - right */}
          <Box
            sx={{ width: "30%", bgcolor: "background.paper", overflow: "auto" }}
          >
            <List component="div" role="group" sx={{ p: 0 }}>
              <ListSubheader>All videos</ListSubheader>
              {videos.map((video) => (
                <ListItemButton
                  key={video.title}
                  divider
                  onClick={() => {
                    setLoadingVideo(true);
                    setCurrentVideo(video);
                  }}
                  sx={{
                    backgroundColor:
                      currentVideo.title === video.title
                        ? "background.default"
                        : "background.paper",
                  }}
                >
                  <ListItemText
                    primary={video.title}
                    secondary={video.description}
                  />
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
};

export default TutorialVideos;
