import { SyntheticEvent, useCallback, FC, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Typography,
  Avatar,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  useUpdateStartupProductionDetails,
  useSearchLabelUsers,
} from "../../hooks";
import { SearchedUser } from "../../types/types";
import { ListSelectedUsers } from "../ListSelectedUsers/ListSelectedUsers";
import { SnackbarDismiss } from "../SnackBarDismiss";

const AssignProdLead: FC<{ ccid: string }> = ({ ccid }) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const {
    mutate: updateProductionLead,
    isLoading: loadingUpdateProductionStatus,
  } = useUpdateStartupProductionDetails(
    () => {
      queryClient.invalidateQueries(["productionCardDetails", ccid]);
      enqueueSnackbar("Production lead updated", { variant: "success" });
    },
    () => {
      enqueueSnackbar(
        `there was an error saving the production lead for this show`,
        { variant: "error", action: SnackbarDismiss },
      );
    },
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: usersFromSearch, isLoading } = useSearchLabelUsers(
    searchTerm,
    15,
  );
  const [selectedUsers, setSelectedUsers] = useState<SearchedUser[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputChangeHandler = useCallback((event: any) => {
    if (event?.target?.value) {
      setSearchTerm(event.target.value);
    } else {
      setSearchTerm("");
    }
  }, []);

  const handleSelect = (
    _event: SyntheticEvent<Element, Event>,
    value: { label: string; user: SearchedUser } | null,
  ) => {
    if (value?.user) setSelectedUsers([value.user]);
    setSearchTerm("");
  };

  const handleClose = () => {
    setOpen(false);
    setSearchTerm("");
    setSelectedUsers([]);
  };

  const handleAddProdLead = async () => {
    if (ccid) {
      const mutationPromises = await selectedUsers.map((user) =>
        updateProductionLead({
          ccid,
          formData: {
            productionContactName: `${user.firstName} ${user.lastName}`,
            productionContactEmail: user.email,
          },
        }),
      );
      Promise.all(mutationPromises)
        .then(handleClose) // wait for all the mutations to finish before closing the dialog
        .catch(); // don't deal with error here - using the error from the mutation
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => setOpen(true)}
        >
          <PersonAddIcon color="primary" />
        </Avatar>
        <Stack direction="column" alignItems="left">
          <Typography variant="caption" sx={{ opacity: "70%" }}>
            Production Lead
          </Typography>
          <Typography>Assign Production Lead</Typography>
        </Stack>
      </Stack>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="Add collaborator"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography display="inline" variant="h5">
              Add a production lead
            </Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            color="primary"
          >
            <Close />
          </IconButton>
        </Stack>
        <Box px={2} py={1}>
          <Autocomplete
            id="search-users"
            inputValue={searchTerm}
            clearIcon={null}
            popupIcon={null}
            options={
              usersFromSearch
                ? usersFromSearch.map((user) => ({
                    label: `${user.firstName} ${user.lastName} ${
                      user.role ? `(${user.role})` : ""
                    }`,
                    user,
                  }))
                : []
            }
            onInputChange={inputChangeHandler}
            onChange={handleSelect}
            loading={isLoading || loadingUpdateProductionStatus}
            renderInput={(params) => (
              <TextField {...params} label="Search for a user" />
            )}
          />
          <ListSelectedUsers
            selectedUsers={selectedUsers}
            deslectUser={(user) =>
              setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id))
            }
          />
        </Box>
        <Stack direction="row" justifyContent="center" spacing={2} pb={2}>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddProdLead}
          >
            Assign
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default AssignProdLead;
