export const PRODUCTION = "production";
export const STAGING = "staging";
export const TEST = "test";
export const LOCALHOST = "localhost";

export type EnvType = "production" | "staging" | "test" | "localhost";

export default function getEnvironment(): EnvType {
  const hostName = window.location.hostname;
  if (hostName === "studios.itv.com") {
    return "production";
  }

  if (hostName === "studios.stg.ca.itv.com") {
    return "staging";
  }
  if (process.env.NODE_ENV === TEST) {
    return "test";
  }

  return LOCALHOST;
}
