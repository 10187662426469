import React, { FC } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface ItvLogoProps {
  width?: number | string;
}

export const ItvLogo: FC<ItvLogoProps> = ({ width }) => {
  // Use Material-UI's useMediaQuery to adjust logo size based on screen size
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  // Adjust width based on screen size
  const adjustedWidth = isXs ? 40 : isSm ? 48 : isMd ? 56 : 64;

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 566.93 283.46"
      width={adjustedWidth}
      height={adjustedWidth / 2} // Maintain aspect ratio (2:1)
    >
      <g id="One_Colour_Master" data-name="One Colour Master">
        <path
          fill="#fff"
          d="M37.52,74.43C14.32,74.43,0,58,0,37.22S14.32,0,37.52,0,75,16.46,75,37.22,60.72,74.43,37.52,74.43Zm156.89-55c-14.09,0-25.65,2.55-31.36,7.76-3.88,3.53-5.66,8.26-5.66,15.16V183.05c0,23.4-3.76,36.07-12,45-7.27,7.82-18.69,12.05-31.26,12.05-12.27,0-23.14-4.09-29.62-10.93-6.76-7.15-9.92-17.07-9.92-31.72V105.76c0-4-1.5-5.79-5.94-5.79H6.33C2,100,.5,101.7.5,105.79v82.85c0,33.07,8.53,52,23.41,66.84,19.35,19.35,52.41,28,83.82,28s64.09-11.12,83.42-29c19,17.3,53.45,29,91.49,29,50.28,0,87.23-20.79,108.8-56.13,9.58,14.84,16.65,25.87,23.45,35.91,10,14.8,19.13,20.23,39.51,20.23,19.32,0,30.91-4.63,41.35-20.61,27.4-41.89,50.74-91.42,67.57-137,2.11-5.7,3.61-9.86,3.61-12.47s-1.74-4.76-5.88-6.08-14.83-4.58-20.24-6.23c-9-2.74-12.27-3.55-14.22-3.55-3.64,0-5.62,2-6.89,6.24-12.62,42-31.82,89.19-49,118.5-5.62-10.71-16-29.33-25.62-46.12-14.89-26-29.59-52.2-37-64.25s-12.46-15.42-22.27-15.42c-20.25,0-50.23,18.17-50.23,35.1,0,5.75,1.59,10.06,7.56,20.24,6.78,11.59,10.19,21,10.19,32.54,0,28.8-21.51,54.88-63,54.88-19.71,0-34.93-5.59-44.84-15.77-9.46-9.71-14.07-22.77-14.07-44.58V142.3c6.93,3,15.44,4.47,25.69,4.47H290c4.44,0,6-1.79,6-5.79V105.75c0-4-1.55-5.79-6-5.79H231.44V42.33c0-6.9-1.8-11.63-5.67-15.16-5.71-5.21-17.48-7.76-31.36-7.76"
        />
      </g>
    </svg>
  );
};
