import { useQuery, UseQueryResult } from "react-query";
import { getProgrammeTasks } from "../../api/portal";
import { TasksResponsePayload } from "../../types/types";

export const useProgrammeTasks = (
  programmeCcid: string | undefined,
): UseQueryResult<TasksResponsePayload> =>
  useQuery(
    ["programme tasks", programmeCcid],
    () => getProgrammeTasks(programmeCcid),
    { enabled: Boolean(programmeCcid) },
  );
