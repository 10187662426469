/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikValues } from "formik";
import { FC } from "react";
import {
  StyledCheckboxContainer,
  StyledSectionTitle,
} from "../../../../components";

export interface PoliciesAndSustainabilityProps {
  values: FormikValues;
  blurHandler: (id: any, newValue: any) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<any>>;
}

export const PoliciesAndSustainabilityForm: FC<
  PoliciesAndSustainabilityProps
> = ({ values, blurHandler, setFieldValue }) => (
  <>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <StyledSectionTitle>
          <Typography variant="h5">Policies</Typography>
        </StyledSectionTitle>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={10}>
            {/* Policies Shared */}
            <StyledCheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="policiesAndSustainability.policiesShared"
                    checked={values.policiesAndSustainability.policiesShared}
                    name="policiesAndSustainability.policiesShared"
                    value={values.policiesAndSustainability.policiesShared}
                    onChange={(event) => {
                      setFieldValue(
                        "policiesAndSustainability.policiesShared",
                        event.target.checked,
                      );
                      blurHandler(
                        "policiesAndSustainability.policiesShared",
                        event.target.checked,
                      );
                    }}
                  />
                }
                label="Have all policies been shared with the team?"
              />
            </StyledCheckboxContainer>

            {/* Producers Handbook Shared */}
            <StyledCheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="policiesAndSustainability.producersHandbookShared"
                    checked={
                      values.policiesAndSustainability.producersHandbookShared
                    }
                    name="policiesAndSustainability.producersHandbookShared"
                    value={
                      values.policiesAndSustainability.producersHandbookShared
                    }
                    onChange={(event) => {
                      setFieldValue(
                        "policiesAndSustainability.producersHandbookShared",
                        event.target.checked,
                      );
                      blurHandler(
                        "policiesAndSustainability.producersHandbookShared",
                        event.target.checked,
                      );
                    }}
                  />
                }
                label="Has producers handbook been shared with the team?"
              />
            </StyledCheckboxContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={12}>
            <StyledSectionTitle>
              <Typography variant="h5">Sustainability</Typography>
            </StyledSectionTitle>
            {/* Albert */}
            <StyledCheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="policiesAndSustainability.albert"
                    checked={values.policiesAndSustainability.albert}
                    name="policiesAndSustainability.albert"
                    value={values.policiesAndSustainability.albert}
                    onChange={(event) => {
                      setFieldValue(
                        "policiesAndSustainability.albert",
                        event.target.checked,
                      );
                      blurHandler(
                        "policiesAndSustainability.albert",
                        event.target.checked,
                      );
                    }}
                  />
                }
                label="Has the production been set up on Albert?"
              />
            </StyledCheckboxContainer>

            {/* Carbon Action Plan Started */}
            <StyledCheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    id="policiesAndSustainability.carbonActionPlanStarted"
                    checked={
                      values.policiesAndSustainability.carbonActionPlanStarted
                    }
                    name="policiesAndSustainability.carbonActionPlanStarted"
                    value={
                      values.policiesAndSustainability.carbonActionPlanStarted
                    }
                    onChange={(event) => {
                      setFieldValue(
                        "policiesAndSustainability.carbonActionPlanStarted",
                        event.target.checked,
                      );
                      blurHandler(
                        "policiesAndSustainability.carbonActionPlanStarted",
                        event.target.checked,
                      );
                    }}
                  />
                }
                label="Has carbon action plan been started?"
              />
            </StyledCheckboxContainer>

            {/* Sustainable Initiatives */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              <div>
                How can sustainable initatives be incorporated into production?
              </div>
            </FormLabel>
            <TextField
              value={values.policiesAndSustainability.sustainableInitiatives}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "policiesAndSustainability.sustainableInitiatives",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "policiesAndSustainability.sustainableInitiatives",
                  event.target.value,
                );
              }}
            />

            {/* Green Team Contact */}
            <FormLabel
              sx={{
                display: "block",
                marginBottom: "0.5rem",
                paddingTop: "1rem",
              }}
            >
              <div>Green team contact</div>
            </FormLabel>
            <TextField
              value={values.policiesAndSustainability.greenTeamContact}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "policiesAndSustainability.greenTeamContact",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "policiesAndSustainability.greenTeamContact",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={10}>
            {/* Privacy and Data Protection */}
            <FormLabel sx={{ display: "block", marginBottom: "0.5rem" }}>
              <div>Privacy and Data Protection</div>
            </FormLabel>
            <TextField
              value={values.policiesAndSustainability.privacyAndData}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "policiesAndSustainability.privacyAndData",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "policiesAndSustainability.privacyAndData",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    <Card>
      <CardContent sx={{ padding: "1rem" }}>
        <Grid container rowSpacing={2} columnSpacing={2} my={0}>
          <Grid item xs={10}>
            {/* Additional Information */}
            <FormLabel sx={{ display: "block", marginBottom: "0.5rem" }}>
              <div>Additional Information:</div>
            </FormLabel>
            <TextField
              value={values.policiesAndSustainability.additionalNotes}
              multiline
              fullWidth
              placeholder="Please type your answer here"
              maxRows={8}
              minRows={8}
              onChange={(event) => {
                setFieldValue(
                  "policiesAndSustainability.additionalNotes",
                  event.target.value,
                );
              }}
              onBlur={(event) => {
                blurHandler(
                  "policiesAndSustainability.additionalNotes",
                  event.target.value,
                );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>
);
