/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useCallback, useState, useRef, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Button,
  List,
  CardContent,
  Card,
  ListItem,
  IconButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { SearchedUser } from "../../../../../types/types";
import { useSearchLabelUsers } from "../../../../../hooks";

import { Close } from "@mui/icons-material";

const delayTime = 300;

interface AddIconikUserProps {
  onAdd(user: SearchedUser): void;
  onCancel(): void;
}

export const AddIconikUser: FC<AddIconikUserProps> = ({ onAdd, onCancel }) => {
  const [selectedUser, setSelectedUser] = useState<SearchedUser>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: usersFromSearchResult, isLoading } = useSearchLabelUsers(
    searchTerm,
    15,
  );
  const users = usersFromSearchResult || [];

  const timeout = useRef<any>(null);

  const inputChangeHandler = useCallback((e: any) => {
    const { value } = e.target;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      if (!value || value.length < 2) return;
      setSearchTerm(value);
    }, delayTime);
  }, []);

  const selectHandler = (event: any, value: { id: string } | null) => {
    const newUser = users.find((user) => user.id === value?.id);

    if (newUser) {
      setSelectedUser(newUser);
    }
  };

  return (
    <Stack sx={{ minWidth: "444px", marginTop: "1rem", p: 2 }} spacing={2}>
      <Typography variant="h6" id="add-iconik-user">
        Add Iconik User
      </Typography>

      <Autocomplete
        id="collaborators"
        options={users.map((user) => ({
          label: `${user.firstName.trim()} ${user.lastName.trim()} ${user.role ? `(${user.role})` : ""}`,
          id: user.id,
        }))}
        onInputChange={inputChangeHandler}
        onChange={selectHandler}
        loading={isLoading}
        renderInput={(params) => (
          <TextField {...params} label="Search for a user" />
        )}
      />

      {selectedUser && (
        <List>
          <Card key={selectedUser.id} sx={{ marginBottom: "1rem" }}>
            <CardContent
              sx={{
                padding: 0,
                paddingBottom: "0 !important",
              }}
            >
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setSelectedUser(undefined);
                    }}
                  >
                    <Close />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={`${selectedUser.firstName} ${selectedUser.lastName}`}
                  secondary={selectedUser.email}
                />
              </ListItem>
            </CardContent>
          </Card>
        </List>
      )}

      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          color="primary"
          disabled={!selectedUser}
          onClick={() => {
            selectedUser && onAdd(selectedUser);
          }}
        >
          Add
        </Button>
        <Button color="primary" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
