import { FC } from "react";
import { Stack, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export interface ActionBarProps {
  cancelLabel: string;
  cancelCallback?(): void;
  cancelDisabled?: boolean;
  cancelLoading?: boolean;
  cancelIcon?: React.ReactNode;
  confirmLabel: string;
  confirmCallback?(): void;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  confirmIcon?: React.ReactNode;
  extraBtn?: React.ReactNode;
  spaceBetween?: boolean;
  width?: string;
}

export const ActionBar: FC<ActionBarProps> = ({
  cancelLabel,
  cancelCallback,
  cancelDisabled,
  cancelLoading,
  cancelIcon,
  confirmLabel,
  confirmCallback,
  confirmDisabled,
  confirmLoading,
  confirmIcon,
  extraBtn,
  spaceBetween = false,
  width = "auto",
}) => (
  <Box data-testid="action-bar">
    <div>{extraBtn}</div>
    <Stack
      justifyContent={spaceBetween ? "space-between" : "flex-start"}
      spacing={2}
      direction="row"
      sx={{ width }}
    >
      <LoadingButton
        variant="outlined"
        color="secondary"
        endIcon={cancelIcon}
        disabled={cancelDisabled}
        loading={cancelLoading}
        onClick={cancelCallback}
      >
        {cancelLabel}
      </LoadingButton>
      <LoadingButton
        variant="contained"
        color="primary"
        endIcon={confirmIcon}
        disabled={confirmDisabled}
        loading={confirmLoading}
        onClick={confirmCallback}
      >
        {confirmLabel}
      </LoadingButton>
    </Stack>
  </Box>
);
