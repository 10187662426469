import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { SearchedUser } from "../../types/types";
import { ProductionLeadSelect } from "../../componentsV2/ProductionLeadSelect";

export const AddProductionLead: FC<{
  productionLead: SearchedUser | null;
  setProductionLead: (productionLead: SearchedUser | null) => void;
}> = ({ productionLead, setProductionLead }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4">Add a Production Lead</Typography>
      <Typography>
        The production lead would be responsible for completing the new
        production notification form and assigning other collaborators to this
        series.
      </Typography>

      <ProductionLeadSelect
        handleProductionLeadSelect={setProductionLead}
        label="Search for a user"
        productionLead={productionLead}
        size="medium"
      />
    </Stack>
  );
};
