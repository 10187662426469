import { Stack, TextField, Typography } from "@mui/material";

const ProductionContacts = ({
  contactName,
  contactEmail,
  creativeLeadName,
  creativeLeadEmail,
}: {
  contactName?: string;
  contactEmail?: string;
  creativeLeadName?: string;
  creativeLeadEmail?: string;
}): JSX.Element =>
  (contactName === undefined || contactName === "") &&
  (contactEmail === undefined || contactEmail === "") &&
  (creativeLeadName === undefined || creativeLeadName === "") &&
  (creativeLeadEmail === undefined || creativeLeadEmail === "") ? (
    <Typography>No production contacts have been set</Typography>
  ) : (
    <Stack spacing={6}>
      <Stack direction="row" spacing={4}>
        <TextField
          id="standard-read-only-contactName"
          label="Production Contact Name"
          value={contactName || "Not been set"}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          id="standard-read-only-contactEmail"
          label="Production Contact Email"
          value={contactEmail || "Not been set"}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
      </Stack>
      <Stack direction="row" spacing={4}>
        <TextField
          id="standard-read-only-creativeLeadName"
          label="Creative Lead Name"
          value={creativeLeadName || "Not been set"}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          id="standard-read-only-creativeLeadEmail"
          label="Creative Lead Email"
          value={creativeLeadEmail || "Not been set"}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
      </Stack>
    </Stack>
  );

export default ProductionContacts;
