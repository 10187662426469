import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridActionsCellItem,
  GridRowId,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModes,
  GridRowModel,
} from "@mui/x-data-grid";
import { Alert, Container, Paper, Typography } from "@mui/material";
import { Cancel, Delete, Edit, Save } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useTitles } from "../../hooks/queries";
import { AddEpisodeDialog } from "./AddEpisodeDialog";
import { DeleteEpisodeDialog } from "./DeleteEpisodeDialog";
import { TitleDetailsProps, TitleDetailsToUpdate } from "../../types/types";
import theme from "../../utils/theme";
import { useUpdateEpisode } from "../../hooks/mutations/useUpdateEpisode";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

export const ManageEpisodes = (): JSX.Element => {
  const { seriesCcid } = useParams<string>();
  const {
    data: titlesData,
    isLoading: loadingEpisodes,
    error,
  } = useTitles(seriesCcid, "series");
  const [selectedEpisodes, setSelectedEpisodes] = useState<string[]>([]);
  const [titleToDelete, setTitleToDelete] = useState<TitleDetailsProps>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const { mutateAsync: updateEpisode } = useUpdateEpisode();
  const nextEpisodeNumber: () => string = () => {
    let episodeNumber = "";
    if (titlesData) {
      if (titlesData.titleDetails[0]) {
        episodeNumber = (
          titlesData.titleDetails[0].episodeNumber + 1
        ).toString();
      } else {
        episodeNumber = "1";
      }
    }
    return episodeNumber;
  };
  const nextSlotLength: () => string = () => {
    let slotLength = "";
    if (titlesData && titlesData.titleDetails[0])
      slotLength = titlesData?.titleDetails[0].intendedSlotLength.toString();
    return slotLength;
  };
  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setSelectedEpisodes(newSelection as string[]);
  };
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleDeleteClick = (row: TitleDetailsProps) => () => {
    setTitleToDelete(row);
    setDeleteDialogOpen(true);
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    const { ccid, name, productionYear, intendedSlotLength } = newRow;
    const payload: TitleDetailsToUpdate = {
      name,
      productionYear,
      intendedSlotLength,
    };
    updateEpisode({ ccid, payload })
      .then(() => {
        enqueueSnackbar("Episode updated successfully", { variant: "success" });
      })
      .catch((submissionError) => {
        enqueueSnackbar(
          `Updating episode is not possible on this series / title. ${submissionError}`,
          { variant: "error", action: SnackbarDismiss },
        );
      });
    return updatedRow;
  };

  const handleProcessRowUpdateError = (submitError: Error) => {
    throw submitError;
  };
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const columns: GridColDef[] = [
    {
      field: "episodeNumber",
      headerName: "Episode number",
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 200,
      flex: 1,
      editable: false,
    },
    {
      field: "intendedSlotLength",
      headerName: "Intended slot length",
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 200,
      flex: 1,
      editable: true,
      valueFormatter: ({ value }) => `${value} minutes`,
    },
    {
      field: "productionYear",
      headerName: "Production year",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={`${id}-save`}
              icon={<Save />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={`${id}-cancel`}
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            key={`${id}-edit`}
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={id}
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(row)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Container sx={{ py: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="h5" mb={1}>
            Episodes
          </Typography>
          <Typography>
            Episodes appear in order of newest first. Use this table to add,
            remove or edit episodes for this series.
          </Typography>
        </Box>
        <Box>
          {error ? (
            <Alert severity="error">
              There was an error loading the episodes. Please try refreshing the
              page.
            </Alert>
          ) : (
            <AddEpisodeDialog
              newEpisodeNumber={nextEpisodeNumber()}
              newIntendedSlotLength={nextSlotLength()}
            />
          )}
        </Box>
        <Box py={2} sx={{ height: 663, width: "100%" }}>
          <DataGrid
            loading={loadingEpisodes}
            rows={titlesData?.titleDetails || []}
            columns={columns}
            getRowId={(row) => row.ccid}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            rowSelectionModel={selectedEpisodes}
            onRowSelectionModelChange={handleSelectionChange}
            processRowUpdate={processRowUpdate}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            sx={{
              ".MuiDataGrid-cell--editing.MuiDataGrid-cell.MuiDataGrid-cell--editable":
                {
                  bgcolor: theme.palette.background.default,
                  fontStyle: "italic",
                  borderColor: "black",
                },
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  m: 0,
                },
            }}
          />
        </Box>
      </Paper>
      <DeleteEpisodeDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        title={titleToDelete}
      />
    </Container>
  );
};
