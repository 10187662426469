import React, { useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { enqueueSnackbar } from "notistack";
import { useCreateEpisode } from "../../hooks/mutations/useCreateEpisode";
import { Title, CreateTitleForm } from "../../types/types";
import theme from "../../utils/theme";
import { WhoIsThisForEnum, WhoIsThisForLabels } from "../../constants";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

export const AddEpisodeDialog = ({
  newEpisodeNumber = "",
  newIntendedSlotLength = "",
}: {
  newEpisodeNumber?: string;
  newIntendedSlotLength?: string;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { seriesCcid } = useParams<string>();
  const currentYear = new Date().getFullYear();

  const {
    mutateAsync: createEpisode,
    isLoading: creatingEpisode,
    reset: clearCreateEpisodeError,
  } = useCreateEpisode();

  const handleClose = () => {
    setOpen(false);
    clearCreateEpisodeError();
  };

  const handleAddEpisode = (values: CreateTitleForm) => {
    clearCreateEpisodeError();

    const payload: Title = {
      whoIsThisFor: values.whoIsThisFor.value,
      productionYear: Number(values.productionYear),
      intendedSlotLength: Number(values.intendedSlotLength),
      episodeNumber: Number(values.episodeNumber),
    };

    if (seriesCcid !== undefined)
      createEpisode({ ccid: seriesCcid, payload })
        .then(() => {
          handleClose();
          enqueueSnackbar(`You have added a new episode!`, {
            variant: "success",
          });
        })
        .catch(() => {
          handleClose();
          enqueueSnackbar(
            `There was an error creating the title, please try again.`,
            { variant: "error", action: SnackbarDismiss },
          );
        });
  };

  const whoIsThisForOptions = [
    WhoIsThisForEnum.BROADCAST,
    WhoIsThisForEnum.ONLINE,
    WhoIsThisForEnum.GLOBAL_DISTRIBUTION,
    WhoIsThisForEnum.OFF_ITV,
  ];

  const productionYearOptions = () => {
    const listOfYears: string[] = [];
    for (let i = 0; i < 16; i += 1) {
      listOfYears.push((currentYear - 5 + i).toString());
    }
    return listOfYears;
  };

  const episodeSchema = Yup.object().shape({
    whoIsThisFor: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string().required("This is a required field"),
    }),
    productionYear: Yup.number()
      .typeError("Must be only numerical digits")
      .required("This is a required field"),
    episodeNumber: Yup.number()
      .typeError("Must be only numerical digits")
      .required("This is a required field")
      .moreThan(0, "Must be greeater than 0")
      .lessThan(1000, "Must be less than 1000"),
    intendedSlotLength: Yup.number()
      .typeError("Must be only numerical digits")
      .required("This is a required field")
      .moreThan(0, "Must be greeater than 0")
      .lessThan(1000, "Must be less than 1000"),
  });

  const initialFormData = {
    whoIsThisFor: { label: "", value: "" },
    productionYear: currentYear.toString(),
    episodeNumber: newEpisodeNumber,
    intendedSlotLength: newIntendedSlotLength,
  };

  return (
    <>
      {/* Button to open dialog */}
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<AddIcon />}
      >
        Add new episode
      </Button>
      <Dialog fullWidth maxWidth="sm" open={open} aria-labelledby="Add episode">
        {/* Dialog header */}
        <Stack
          bgcolor={theme.palette.primary.main}
          color="white"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography display="inline" variant="h5">
            Add an episode to this series
          </Typography>
        </Stack>
        <Box p={2}>
          <Formik
            initialValues={initialFormData}
            validationSchema={episodeSchema}
            onSubmit={handleAddEpisode}
          >
            {({ submitForm, isSubmitting, errors, touched, setFieldValue }) =>
              isSubmitting ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  m={2}
                >
                  <CircularProgress />
                  <Typography>Creating episode</Typography>
                </Stack>
              ) : (
                <Form>
                  <Typography mb={2}>
                    Please provide details of the episode you're creating
                  </Typography>

                  <Grid container rowSpacing={1} columnSpacing={2} mb={2}>
                    {/* Episode Number */}
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        name="episodeNumber"
                        label="Episode Number"
                        required
                        fullWidth
                        size="small"
                        autoComplete="off"
                        error={touched.episodeNumber && !!errors.episodeNumber}
                        helperText={
                          touched.episodeNumber && !!errors.episodeNumber
                            ? errors.episodeNumber
                            : " "
                        }
                      />
                    </Grid>

                    {/* Who is this for */}
                    <Grid item xs={6}>
                      <Field
                        as={Autocomplete}
                        name="whoIsThisFor"
                        options={whoIsThisForOptions.map((who) => ({
                          label: WhoIsThisForLabels[who],
                          value: who,
                        }))}
                        freeSolo
                        onChange={(
                          _: React.ChangeEvent<HTMLInputElement>,
                          option: { label: string; value: string },
                        ) => {
                          if (option === null)
                            setFieldValue("whoIsThisFor", {
                              label: "",
                              value: "",
                            });
                          else setFieldValue("whoIsThisFor", option);
                        }}
                        renderInput={(
                          params: AutocompleteRenderInputParams,
                        ) => (
                          <TextField
                            {...params}
                            required
                            name="whoIsThisFor"
                            label="Who is this for?"
                            size="small"
                            error={
                              touched.whoIsThisFor &&
                              !!errors.whoIsThisFor?.value
                            }
                            helperText={
                              touched.whoIsThisFor &&
                              !!errors.whoIsThisFor?.value
                                ? errors.whoIsThisFor.value
                                : " "
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* Production Year */}
                    <Grid item xs={6}>
                      <Field
                        as={Autocomplete}
                        name="productionYear"
                        options={productionYearOptions()}
                        freeSolo
                        onChange={(
                          _: React.ChangeEvent<HTMLInputElement>,
                          option: string,
                        ) => setFieldValue("productionYear", option)}
                        renderInput={(
                          params: AutocompleteRenderInputParams,
                        ) => (
                          <TextField
                            {...params}
                            required
                            name="productionYear"
                            label="Production Year"
                            size="small"
                            error={
                              touched.productionYear && !!errors.productionYear
                            }
                            helperText={
                              touched.productionYear && !!errors.productionYear
                                ? errors.productionYear
                                : " "
                            }
                          />
                        )}
                      />
                    </Grid>

                    {/* Intended Slot length */}
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        name="intendedSlotLength"
                        label="Intended Slot Length"
                        required
                        fullWidth
                        size="small"
                        autoComplete="off"
                        error={
                          touched.intendedSlotLength &&
                          !!errors.intendedSlotLength
                        }
                        helperText={
                          touched.intendedSlotLength &&
                          !!errors.intendedSlotLength
                            ? errors.intendedSlotLength
                            : " "
                        }
                        InputProps={{
                          endAdornment: (
                            <Typography color="disabled">minutes</Typography>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Stack direction="row" justifyContent="center" spacing={2}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={creatingEpisode}
                      onClick={submitForm}
                    >
                      Add
                    </Button>
                  </Stack>
                </Form>
              )
            }
          </Formik>
        </Box>
      </Dialog>
    </>
  );
};
