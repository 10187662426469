import * as Yup from "yup";

export const insuranceSchema = Yup.object().shape({
  talentLoss: Yup.boolean().required("This is a required field"),
  talentLossList: Yup.string().when(
    "$insurance.talentLoss",
    ([talentLoss], schema) => {
      if (talentLoss === true) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  shutdown: Yup.boolean().required("This is a required field"),
  shutdownDetails: Yup.string().when(
    "$insurance.shutdown",
    ([shutdown], schema) => {
      if (shutdown === true) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  age: Yup.boolean().required("This is a required field"),
  jewellery: Yup.string().required("This is a required field"),
  personalCash: Yup.string().required("This is a required field"),
  productionCash: Yup.string().required("This is a required field"),
  post: Yup.string().required("This is a required field"),
  ob: Yup.string().required("This is a required field"),
  av: Yup.string().required("This is a required field"),
  vehicles: Yup.string().required("This is a required field"),
  sound: Yup.string().required("This is a required field"),
  rigging: Yup.string().required("This is a required field"),
  camera: Yup.string().required("This is a required field"),
  source: Yup.string().required("This is a required field"),
  psc: Yup.string().required("This is a required field"),
  lighting: Yup.string().required("This is a required field"),
  studio: Yup.string().required("This is a required field"),
  set: Yup.string().required("This is a required field"),
  security: Yup.string().required("This is a required field"),
  catering: Yup.string().required("This is a required field"),
  procOther: Yup.string(),
});

export type InsuranceSection = Yup.InferType<typeof insuranceSchema>;
