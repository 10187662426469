import { useQuery, UseQueryResult } from "react-query";
import { getTimecode } from "../../api/browseassets";
import { TimecodeResponsePayload } from "../../types/types";

export const useTimecode = (
  titlesCcid: string | undefined,
  seriesCcid: string | undefined,
  tag = "preview",
): UseQueryResult<TimecodeResponsePayload> =>
  useQuery(
    ["getTimecode", titlesCcid, seriesCcid, tag],
    () => getTimecode(titlesCcid, seriesCcid, tag),
    { enabled: Boolean(seriesCcid || titlesCcid) },
  );
