import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useParams } from "react-router-dom";
import { useBrandMetadata } from "../../hooks/queries";
import { useUpdateBrandName } from "../../hooks/mutations";

export const BrandNameEditor = (): JSX.Element => {
  const navigate = useNavigate();
  const { programmeCcid } = useParams();
  const {
    mutate: updateTitle,
    isLoading,
    isError,
  } = useUpdateBrandName(programmeCcid || "");
  const { data: brand } = useBrandMetadata(programmeCcid, true);
  const [page, setPage] = useState<"EDIT_TITLE" | "CONFIRM_TITLE" | "OUTCOME">(
    "EDIT_TITLE",
  );
  const [newTitle, setNewTitle] = useState<string>("");
  const [confirmTitle, setConfirmTitle] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);

  const validateTitle = () => {
    const invalid = newTitle !== confirmTitle;
    setHasError(invalid);
    return !invalid;
  };

  const EditTitle = (
    <Stack alignItems="center">
      <Stack spacing={4} width="40%" my={2}>
        <TextField
          disabled
          fullWidth
          size="small"
          id="currentTitle"
          label="Current programme name"
          defaultValue={brand?.brandName}
          variant="filled"
          data-testid="currentTitle"
        />
        <TextField
          fullWidth
          size="small"
          id="newTitle"
          label="New programme name"
          data-testid="newTitle"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
        />
        <TextField
          fullWidth
          size="small"
          id="confirmNewTitle"
          data-testid="confirmNewTitle"
          label="Confirm new programme name"
          value={confirmTitle}
          onChange={(e) => setConfirmTitle(e.target.value)}
          onBlur={validateTitle}
          helperText={
            hasError
              ? "Names do not match, please confirm that the two fields are the same"
              : ""
          }
          error={hasError}
        />
        <Stack direction="row" justifyContent="space-around">
          <Button
            variant="outlined"
            onClick={() => navigate(`/programmes/${programmeCcid}`)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={hasError}
            onClick={() => {
              if (validateTitle()) {
                setPage("CONFIRM_TITLE");
              }
            }}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );

  const ConfirmTitle = (
    <Stack alignItems="center" spacing={1}>
      <Typography variant="h4">Confirm changes</Typography>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        width="100%"
        sx={{ py: 2 }}
      >
        <Stack alignItems="center">
          <Typography>Current programme name</Typography>
          <Typography variant="h6">{brand?.brandName}</Typography>
        </Stack>
        <ArrowForwardIcon />
        <Stack alignItems="center">
          <Typography>New programme name</Typography>
          <Typography fontWeight="bold" variant="h6">
            {newTitle}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-around" width="50%">
        <Button variant="outlined" onClick={() => setPage("EDIT_TITLE")}>
          Back
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => {
            updateTitle(newTitle);
            setPage("OUTCOME");
          }}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </Stack>
  );

  const Outcome = (
    <Stack alignItems="center" spacing={2}>
      {isError ? (
        <Box textAlign="center">
          <Typography>
            There was a problem updating the programme name.
          </Typography>
          <Typography>
            Please try again later and contact us if the problem persists.
          </Typography>
        </Box>
      ) : (
        <Typography>
          You have successfully updated the programme name!
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={() => navigate(`/programmes/${programmeCcid}`)}
      >
        Return to tasks
      </Button>
    </Stack>
  );

  return (
    <Container sx={{ py: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" pb={2}>
          Edit programme name
        </Typography>
        {page === "EDIT_TITLE" ? EditTitle : null}
        {page === "CONFIRM_TITLE" ? ConfirmTitle : null}
        {page === "OUTCOME" ? Outcome : null}
      </Paper>
    </Container>
  );
};
