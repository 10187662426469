import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Autocomplete, TextField, Stack, Chip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { combineTextParts, getSynopsisText } from "../../../../utils/appHelper";

import {
  BrandTitleResponsePayload,
  SeriesResponsePayload,
  TitlesResponsePayload,
} from "../../../../types/types";

import { useLabelUserEntitlements } from "../../../../hooks";
import { AddNewProductionCard } from "../../../../componentsV2/AddNewProductionCard";

const ADD = "add";
interface Props {
  brandTitlesData?: BrandTitleResponsePayload;
  titlesData?: TitlesResponsePayload;
  data?: SeriesResponsePayload;
  currentTab?: string;
}
interface DropdownOption {
  value: string;
  label: string;
  status?: string;
}

const specialOption = {
  value: "0",
  label: "Specials & Promos",
  status: "IN-PRODUCTION",
};

const specialOptionInOppotunity = {
  value: "0",
  label: "Specials & Promos",
  status: "OPPORTUNITY",
};

const Navigation: React.FC<Props> = ({ brandTitlesData, titlesData, data }) => {
  const navigate = useNavigate();
  const { data: entitlement } = useLabelUserEntitlements();
  const { programmeCcid, seriesCcid, titleCcid } = useParams<string>();
  const [selectedSeriesValue, setSelectedSeriesValue] =
    useState<DropdownOption | null>(null);

  const [selectedTitlesValue, setSelectedTitlesValue] =
    useState<DropdownOption | null>(null);

  const [allTitleOptions, setAllTitleOption] = useState<DropdownOption[] | []>(
    [],
  );

  const titleOptions = useMemo(
    () =>
      titlesData?.titleDetails?.map((title) => ({
        value: title.ccid,
        status: title.status,
        label: `${combineTextParts(
          getSynopsisText(title?.episodeNumber, "Episode"),
          title?.name,
        )}`,
      })) || [],
    [titlesData],
  );

  const specialsTitleOptions = useMemo(
    () =>
      brandTitlesData?.titleDetails?.map((title) => ({
        value: title.ccid,
        status: title.status,
        label: `${combineTextParts(
          getSynopsisText(title?.episodeNumber, "Episode"),
          title?.name,
        )}`,
      })) || [],
    [brandTitlesData],
  );
  const seriesOptions = useMemo(
    () =>
      data?.seriesDetails?.map((serie) => ({
        value: serie.ccid,
        label: `${combineTextParts(
          getSynopsisText(serie?.seriesNumber, "Series"),
          serie?.name,
        )}`,
        status: serie.status,
      })) || [],
    [data?.seriesDetails],
  );

  const allSeriesOptions = useMemo(() => {
    const options = [];
    if (entitlement?.canCreateCcid) {
      options.push({
        value: ADD,
        label: "",
      });
    }
    if (specialsTitleOptions.length > 0) {
      const containsOpportunity = specialsTitleOptions.some(
        (instance) => instance.status === "OPPORTUNITY",
      );
      if (containsOpportunity) options.push(specialOptionInOppotunity);
      else options.push(specialOption);
    }
    return [...options, ...seriesOptions];
  }, [specialsTitleOptions, seriesOptions, entitlement]);

  useEffect(() => {
    if (selectedSeriesValue?.value === "0") {
      setSelectedTitlesValue(
        specialsTitleOptions.find((t) => t.value === titleCcid) || null,
      );
    } else {
      setSelectedTitlesValue(
        titleOptions.find((t) => t.value === titleCcid) || null,
      );
    }
  }, [selectedSeriesValue, titleCcid, specialsTitleOptions, titleOptions]);

  useEffect(() => {
    setSelectedSeriesValue(
      allSeriesOptions.find((s) => s.value === seriesCcid) || null,
    );
  }, [seriesCcid, allSeriesOptions]);

  useEffect(() => {
    if (selectedSeriesValue?.value === "0") {
      setAllTitleOption(specialsTitleOptions);
    } else {
      setAllTitleOption(titleOptions);
    }
  }, [
    setAllTitleOption,
    titleOptions,
    selectedSeriesValue,
    specialsTitleOptions,
  ]);

  return (
    <>
      <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
        <Autocomplete
          disablePortal
          data-testid="synopsis-series-dropdown"
          id="synopsis-series-dropdown"
          options={allSeriesOptions}
          value={selectedSeriesValue}
          clearIcon={<ClearIcon />}
          sx={{ flexGrow: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Series"
              placeholder="Search Series"
            />
          )}
          renderOption={(props, option: DropdownOption) => {
            if (option.value === ADD) {
              return (
                <li {...props}>
                  <AddNewProductionCard />
                </li>
              );
            }

            return (
              <li {...props}>
                {option.label}
                {option.status === "OPPORTUNITY" ? (
                  <Chip
                    sx={{ margin: 1 }}
                    label="Requires Update"
                    variant="outlined"
                  />
                ) : (
                  <></>
                )}
              </li>
            );
          }}
          onChange={(e, selectedSeries: DropdownOption | null) => {
            if (selectedSeries?.value === ADD) navigate("./create-series");
            else {
              // default - if there is no selected series, navigate to the programm overview page
              let url = `/programmes/${programmeCcid}`;

              // if there is a selected series and it's not a special, navigate to the series page
              if (selectedSeries?.value && selectedSeries?.value !== "0")
                url += `/series/${selectedSeries?.value}`;

              // if the series is in opportunity status redirect to update series
              if (
                selectedSeries?.status === "OPPORTUNITY" &&
                selectedSeries?.value !== "0"
              )
                url += `/update-series`;

              // if it's a special, navigate to the specials page
              if (selectedSeries?.value === "0")
                url += `/series/0/titles/${specialsTitleOptions[0].value}`;

              navigate(`${url}`);
            }
          }}
        />
        <Autocomplete
          disablePortal
          id="synopsis-titles-dropdown"
          data-testid="synopsis-titles-dropdown"
          options={allTitleOptions}
          sx={{ flexGrow: 1 }}
          clearIcon={<ClearIcon />}
          value={selectedTitlesValue}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select an Episode"
              placeholder="Search Episode"
            />
          )}
          disabled={allTitleOptions.length <= 0}
          renderOption={(props, option: DropdownOption) => (
            <li {...props}>
              {option.label}
              {option.status === "OPPORTUNITY" ? (
                <Chip
                  sx={{ margin: 1 }}
                  label="Requires Update"
                  variant="outlined"
                />
              ) : (
                <></>
              )}
            </li>
          )}
          onChange={(e, selectedEpisode) => {
            // check which episode has been selected or if the episode selection has been cleared
            let url = `/programmes/${programmeCcid}/series`;

            // if selectedEpisode is undefined, then the user has cleared the series selection, otherwise the user has selected a series
            url += !selectedEpisode?.value
              ? `/${selectedSeriesValue?.value}`
              : `/${selectedSeriesValue?.value}/titles/${selectedEpisode?.value}`;

            // if selectedEpisode is 0, then the user has selected the specials series
            if (selectedEpisode?.value === "0")
              url = `/programmes/${programmeCcid}/series/0/titles/${specialsTitleOptions[0].value}`;

            // if selectedEpisode is 0 and has opportunity then take to the special opportunity page
            if (selectedEpisode?.status === "OPPORTUNITY")
              url = `/special/${specialsTitleOptions[0].value}/update-ccid`;
            navigate(`${url}`);
          }}
        />
      </Stack>
    </>
  );
};

export default Navigation;
