import React from "react";
import {
  Tooltip,
  IconButton,
  Popover,
  useTheme,
  Divider,
  Button,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppsIcon from "@mui/icons-material/Apps";
import { useOktaAuth } from "@okta/okta-react";
import TutorialVideos from "../TutorialVideos/TutorialVideos";
import { CompassGPT } from "../../compass-gpt/CompassGPT";
import { Lock as LockIcon } from "@mui/icons-material";
import { ContactUsIcon } from "../../components/ContactUs/ContactUsButton";
import { ContactUs } from "../../components/ContactUs";
import { useLabelUserEntitlements } from "../../hooks/queries";

const NavBarMobileView: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const { data: entitlement } = useLabelUserEntitlements();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Button to open dialog */}
      <Tooltip title="Apps">
        <IconButton
          onClick={handleClick}
          color="primary"
          aria-label="Apps"
          sx={{
            "&:hover": { backgroundColor: `${theme.palette.primary.dark}` },
          }}
        >
          <AppsIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ zIndex: 10004 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ bgcolor: "#22252F" }}
          spacing={2}
        >
          {entitlement?.canManageAccess ? (
            <>
              <Tooltip title="Access Management">
                <IconButton
                  color="primary"
                  aria-label="access management"
                  onClick={() => navigate(`/access-directory`)}
                  sx={{
                    "&:hover": {
                      backgroundColor: `${theme.palette.primary.dark}`,
                    },
                  }}
                >
                  <LockIcon sx={{ color: "white" }} />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" flexItem color="white" />
            </>
          ) : null}
          <CompassGPT />
          <TutorialVideos />
          <ContactUs ClickableComponent={ContactUsIcon} />
          <Button
            onClick={() => oktaAuth.tokenManager.clear()}
            sx={{
              fontWeight: "light",
              color: "white",
              "&:hover": { backgroundColor: `${theme.palette.primary.dark}` },
            }}
          >
            Logout
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default NavBarMobileView;
