import { useQuery, UseQueryResult } from "react-query";
import { getSelectedVideoUrl } from "../../api/browseassets";

export const useSelectedVideoUrl = (
  selectedVideoVersionCcid: string | undefined,
): UseQueryResult<string> =>
  useQuery(
    ["getPresignedUrl", selectedVideoVersionCcid],
    () => getSelectedVideoUrl(selectedVideoVersionCcid as string),
    { enabled: Boolean(selectedVideoVersionCcid) },
  );
