import { FC } from "react";
import { Box, Card, useTheme } from "@mui/material";
import { PulseLoader } from "react-spinners";

const LoadingCard: FC = () => {
  const theme = useTheme();
  return (
    <Card sx={{ backgroundColor: theme.palette.athensGray }}>
      <Box sx={{ px: 2, py: 1 }}>
        <PulseLoader
          size={6}
          speedMultiplier={0.6}
          color={theme.palette.primary.main}
        />
      </Box>
    </Card>
  );
};

export default LoadingCard;
