import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { TitleAssets } from "../../types/types";
import { checkTitleAssets } from "../../api/portal";

export const useCheckTitleAssets = (
  titleCcid: string | undefined,
): UseQueryResult<TitleAssets, AxiosError> =>
  useQuery(
    [`useCheckTitleAssets`, titleCcid],
    () => checkTitleAssets(titleCcid as string),
    { enabled: titleCcid !== "0" && titleCcid !== undefined },
  );
