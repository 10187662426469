import { Typography } from "@mui/material";
import { ClickableComponentProps } from "../../types/types";

export const ContactUsTypography: React.FC<ClickableComponentProps> = ({
  onClick,
}) => {
  return (
    <Typography
      sx={{ pt: 0.5, cursor: "pointer", textDecoration: "underline" }}
      variant="body2"
      component="span"
      onClick={onClick}
    >
      Don't think this genre is correct?
    </Typography>
  );
};
