import React from "react";
import { Box } from "@mui/material";

// This visually hides content without hiding it from a screen reader. Useful for accessibility purposes
// https://www.a11yproject.com/posts/how-to-hide-content/
export function VisuallyHidden({ children }: { children: React.ReactNode }) {
  return (
    <Box
      component="span"
      sx={{
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: "1px",
        overflow: "hidden",
        position: "absolute",
        whiteSpace: "nowrap",
        width: "1px",
      }}
    >
      {children}
    </Box>
  );
}
