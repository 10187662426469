import React, { FC, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate, Outlet } from "react-router-dom";
import NavBar from "../../componentsV2/NavBar/NavBar";

export const ProtectedRoutes: FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState) {
      // lodaing authState so do nothing for now
      return;
    }

    if (!authState.isAuthenticated) {
      // user not logged in, redirect to login
      navigate("/login");
    }
  }, [oktaAuth, authState?.isAuthenticated, authState, navigate]);

  if (authState?.isAuthenticated)
    return (
      <>
        <NavBar />
        <Outlet />
      </>
    ); // user is authenticated, render the route

  return null; // user is not authenticated, render nothing
};
