/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { putFilter } from "../../api/contributors";
import { ContributorsFilterProps } from "../../types/types";
import { noop } from "../../utils/appHelper";

export const useUpdateContributorsFilter = (
  seriesCcid: string | undefined,
  filter: ContributorsFilterProps,
  onSuccess = noop,
  onError = noop,
): UseMutationResult =>
  useMutation(() => putFilter(seriesCcid as string, filter), {
    onSuccess,
    onError,
  });
