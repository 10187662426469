/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult } from "react-query";
import { addUsers } from "../../api/accessPermissions";
import { noop } from "../../utils/appHelper";

export const useAddGroupUsers = (
  onSuccess = noop,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onError = (error: any) => {},
): UseMutationResult =>
  useMutation((payload: any) => addUsers(payload.userIds, payload.groupId), {
    onSuccess,
    onError,
  });
