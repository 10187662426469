/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { updateTitleName } from "../../api/portal";

export const useUpdateTitleName = (id: string): UseMutationResult => {
  const queryClient = useQueryClient();
  const mutation: UseMutationResult = useMutation(
    (newName) => updateTitleName(id, newName as string),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("titleMetadata");
      },
    },
  );

  return mutation;
};
