import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useSearchLabelUsers } from "../../hooks/queries";
import { ProductionType, SearchedUser } from "../../types/types";
import { ListSelectedUsers } from "./ListSelectedUsers";
import { useAddCollaborator } from "../../hooks/mutations/useAddCollaborator";
import { useCollaborators } from "../../hooks/queries/useCollaborators";

export const AddUserDialog = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { seriesCcid, titleCcid } = useParams<string>();
  const productionType: ProductionType =
    seriesCcid === "0" || seriesCcid === undefined ? "special" : "series";
  const ccid = productionType === "series" ? seriesCcid : titleCcid;

  const { data: usersWithAccess } = useCollaborators(ccid);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: usersFromSearch, isLoading } = useSearchLabelUsers(
    searchTerm,
    15,
  );
  const [selectedUsers, setSelectedUsers] = useState<SearchedUser[]>([]);
  const {
    mutateAsync: addCollaborator,
    isLoading: addingCollaborators,
    error,
    reset: clearError,
  } = useAddCollaborator();

  const filteredUsersFromSearch = useMemo(() => {
    if (usersFromSearch && usersWithAccess) {
      return usersFromSearch.filter(
        (userFromSearch) =>
          !selectedUsers.some(
            (selectedUser) => selectedUser.email === userFromSearch.email,
          ) &&
          !usersWithAccess.some(
            (userWithAccess) => userWithAccess.email === userFromSearch.email,
          ),
      );
    }
    return [];
  }, [usersFromSearch, usersWithAccess, selectedUsers]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputChangeHandler = useCallback((event: any) => {
    if (event?.target?.value) {
      setSearchTerm(event.target.value);
    } else {
      setSearchTerm("");
    }
  }, []);

  const handleSelect = (
    _event: SyntheticEvent<Element, Event>,
    value: { label: string; user: SearchedUser } | null,
  ) => {
    if (value?.user) setSelectedUsers([...selectedUsers, value.user]);
    setSearchTerm("");
  };

  const handleClose = () => {
    setOpen(false);
    setSearchTerm("");
    setSelectedUsers([]);
    clearError();
  };

  const handleAddCollaborators = async () => {
    if (ccid) {
      const mutationPromises = await selectedUsers.map((user) =>
        addCollaborator({ ccid, userEmail: user.email }),
      );
      Promise.all(mutationPromises)
        .then(handleClose) // wait for all the mutations to finish before closing the dialog
        .catch(); // don't deal with error here - using the error from the mutation
    }
  };

  return (
    <>
      {/* Button to open dialog */}
      <Button variant="contained" onClick={() => setOpen(true)}>
        Add users
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="Add collaborator"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography display="inline" variant="h5">
              Add a user to access this {productionType}
            </Typography>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            color="primary"
          >
            <Close />
          </IconButton>
        </Stack>
        {error ? (
          <Box px={2} py={1}>
            <Alert severity="error">
              There was an error adding users to the {productionType} - please
              try again.
            </Alert>
          </Box>
        ) : null}
        <Box px={2} py={1}>
          <Autocomplete
            id="search-users"
            inputValue={searchTerm}
            clearIcon={null}
            popupIcon={null}
            options={
              filteredUsersFromSearch
                ? filteredUsersFromSearch.map((user) => ({
                    label: `${user.firstName.trim()} ${user.lastName.trim()} ${user.role ? `(${user.role})` : ""}`,
                    user,
                  }))
                : []
            }
            onInputChange={inputChangeHandler}
            onChange={handleSelect}
            loading={isLoading}
            renderInput={(params) => (
              <TextField {...params} label="Search for a user" />
            )}
          />
          <ListSelectedUsers
            selectedUsers={selectedUsers}
            deslectUser={(user) =>
              setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id))
            }
          />
        </Box>
        <Stack direction="row" justifyContent="center" spacing={2} pb={2}>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedUsers?.length <= 0 || addingCollaborators}
            onClick={handleAddCollaborators}
          >
            Add
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};
