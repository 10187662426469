import { useQuery, UseQueryResult } from "react-query";
import { getTitles } from "../../api/portal";
import {
  BrandTitleResponsePayload,
  TitlesResponsePayload,
} from "../../types/types";

export const useTitles = (
  seriesCcid: string | undefined,
  type: "series" | "brands",
  includeOpportunity?: boolean,
): UseQueryResult<TitlesResponsePayload | BrandTitleResponsePayload> =>
  useQuery(
    [type === "series" ? "titles" : "brandTitles", seriesCcid],
    () =>
      getTitles(type, seriesCcid as string, !!includeOpportunity) as Promise<
        TitlesResponsePayload | BrandTitleResponsePayload
      >,
    { enabled: Boolean(seriesCcid && seriesCcid !== "0") },
  );
