import {
  ProductionPrinciplesFormDataFormik,
  ProductionPrinciplesFormDataServer,
} from "../../types/types";

export const transformFormDataToServer: (
  formDataValues: ProductionPrinciplesFormDataFormik,
) => ProductionPrinciplesFormDataServer = (formDataValues) => {
  const yesNoToBoolean = (val: string) => {
    if (val.toLowerCase() === "yes") return true;
    if (val.toLowerCase() === "no") return false;
    return null;
  };

  const transformedData: ProductionPrinciplesFormDataServer = {
    submitted: formDataValues.submitted,
    submittedBy: formDataValues.submittedBy,
    modifiedTime: formDataValues.modifiedTime,
    inclusionPolicies: {
      diamondDataConfirmation: formDataValues.diamondDataConfirmation
        ? formDataValues.diamondDataConfirmation
        : null,
      speakingUpPolicy: formDataValues.speakingUpPolicy
        ? formDataValues.speakingUpPolicy
        : null,
      codeOfConduct: formDataValues.codeOfConduct
        ? formDataValues.codeOfConduct
        : null,
      antibullyCommitment:
        formDataValues.antibullyCommitment.length === 0
          ? null
          : formDataValues.antibullyCommitment,
      disabilityConfirmation: formDataValues.disabilityConfirmation
        ? formDataValues.disabilityConfirmation
        : null,
      diversityCommissioningFund:
        formDataValues.diversityCommissioningFund === ""
          ? null
          : formDataValues.diversityCommissioningFund,
    },
    inclusiveProgramming: {
      underrepresentedActor: yesNoToBoolean(
        formDataValues.underrepresentedActor,
      ),
      underrepresentedGroup: yesNoToBoolean(
        formDataValues.underrepresentedGroup,
      ),
      underrepresentedStory: yesNoToBoolean(
        formDataValues.underrepresentedStory,
      ),
      PoCHairAndMakeup: yesNoToBoolean(formDataValues.PoCHairAndMakeup),
      underrepresentedCreative: yesNoToBoolean(
        formDataValues.underrepresentedCreative,
      ),
      underrepresentedCreativeDetails:
        formDataValues.underrepresentedCreativeDetails,
      underrepresentedProductionTeam: yesNoToBoolean(
        formDataValues.underrepresentedProductionTeam,
      ),
      underrepresentedProductionTeamDetails:
        formDataValues.underrepresentedProductionTeamDetails,
      underrepresentedCrew: yesNoToBoolean(formDataValues.underrepresentedCrew),
      underrepresentedCrewDetails: formDataValues.underrepresentedCrewDetails,
      stepUpSixty: yesNoToBoolean(formDataValues.stepUpSixty),
      stepUpSixtyDetails: formDataValues.stepUpSixtyDetails,
      paidTrainee: yesNoToBoolean(formDataValues.paidTrainee),
    },
  };

  return transformedData;
};

export const transformFieldForServer: (
  fieldName: string,
  fieldValue: string | string[] | boolean,
  inclusionPolicies: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any = (fieldName, fieldValue, inclusionPolicies) => {
  let returnField;
  switch (fieldName) {
    // Checkboxes - value stored as boolean
    case "diamondDataConfirmation":
    case "speakingUpPolicy":
    case "codeOfConduct":
    case "disabilityConfirmation":
    case "underrepresentedActor":
    case "underrepresentedGroup":
    case "underrepresentedStory":
    case "PoCHairAndMakeup":
    case "underrepresentedCreative":
    case "underrepresentedProductionTeam":
    case "underrepresentedCrew":
    case "stepUpSixty":
    case "paidTrainee":
      returnField = { [fieldName]: Boolean(fieldValue) };
      break;

    // Radio - value stored as yes/no/help string
    case "diversityCommissioningFund":
      returnField = { [fieldName]: fieldValue.toString() };
      break;
    // Multi-select - value stored as array
    case "antibullyCommitment":
      returnField = { [fieldName]: fieldValue as string[] };
      break;

    default:
      returnField = { [fieldName]: fieldValue.toString() };
  }

  return inclusionPolicies
    ? {
        inclusionPolicies: returnField,
        inclusiveProgramming: {},
      }
    : {
        inclusiveProgramming: returnField,
        inclusionPolicies: {},
      };
};
