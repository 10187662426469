/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import dayjs from "dayjs";
import { PlayCircleOutline, PlayDisabled } from "@mui/icons-material";

import { Box, Stack } from "@mui/system";
import { Button } from "@mui/material";
import theme from "../../../utils/theme";

export interface BrowseAssets {
  titleCcid: string;
  versionCcid: string;
  productionId: string;
  format: string;
}

export interface VideoMetaDataPayload {
  productionId?: string;
  versionCcid: string;
  linear?: {
    first?: string;
    next?: string;
  };
  editReasonComments?: string;
  editReasons: string[];
}

export interface VideoProps {
  videoMetaData?: VideoMetaDataPayload;
  browseAssets?: Partial<BrowseAssets>;
  handleFetchVideoUrl: (titleCcid: string) => void;
  hasVideo?: boolean;
  url?: string;
  className?: string;
}

const Video: FC<VideoProps> = ({
  videoMetaData,
  browseAssets,
  hasVideo,
  handleFetchVideoUrl,
  url,
  className,
}) => {
  const getMetaData = (
    <Stack flexDirection="column" width={250}>
      <p />
      {(browseAssets?.productionId || videoMetaData?.productionId) && (
        <p>
          Production ID:
          <br />
          <span>
            {browseAssets?.productionId || videoMetaData?.productionId}
          </span>
        </p>
      )}
      {videoMetaData?.linear && (
        <p>
          First ITV Linear TX:
          <br />
          <span>
            {videoMetaData?.linear.first
              ? dayjs(videoMetaData?.linear.first).format("DD/MM/YYYY, H:mm:ss")
              : "-"}
          </span>
        </p>
      )}
      {videoMetaData?.linear && (
        <p>
          Next ITV Linear TX: <br />
          <span>
            {videoMetaData?.linear.next
              ? dayjs(videoMetaData?.linear.next).format("DD/MM/YYYY,  H:mm:ss")
              : "-"}
          </span>
        </p>
      )}

      {videoMetaData && videoMetaData.editReasons && (
        <p>
          Edit Reason: <br />
          <span>
            {videoMetaData.editReasons.length > 0
              ? videoMetaData.editReasons.join(", ")
              : "-"}
          </span>
        </p>
      )}

      {videoMetaData && videoMetaData.editReasonComments && (
        <p>
          Edit Reason Notes: <br />{" "}
          <span>
            {videoMetaData?.editReasonComments
              ? videoMetaData.editReasonComments
              : "-"}
          </span>
        </p>
      )}
    </Stack>
  );

  if (!hasVideo) {
    return (
      <Stack className={className} marginTop={1}>
        <Box
          bgcolor={theme.palette.osloGray}
          height={140}
          width={240}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <PlayDisabled />
        </Box>
        {getMetaData}
      </Stack>
    );
  }

  return (
    <Stack className={className} marginTop={1}>
      {url ? (
        <video
          autoPlay
          controls
          controlsList="nodownload"
          width={240}
          height={140}
          data-testid="titleVideo"
        >
          <track default kind="captions" src="en" />
          Sorry, your browser doesn't support embedded videos.
          <source src={url} />
        </video>
      ) : (
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "black",
            width: 240,
            height: 140,
            borderRadius: 0,
          }}
          role="button"
          onClick={() =>
            handleFetchVideoUrl(
              browseAssets?.versionCcid || videoMetaData?.versionCcid || "",
            )
          }
        >
          <PlayCircleOutline fontSize="large" />
        </Button>
      )}
      {getMetaData}
    </Stack>
  );
};

export default Video;
