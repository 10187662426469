import { useQuery, UseQueryResult } from "react-query";
import { getSeriesTasks } from "../../api/portal";
import { TasksResponsePayload } from "../../types/types";

export const useSeriesTasks = (
  seriesCcid: string | undefined,
): UseQueryResult<TasksResponsePayload> =>
  useQuery(["series tasks", seriesCcid], () => getSeriesTasks(seriesCcid), {
    enabled: Boolean(seriesCcid),
  });
