import {
  BooleanAsYesNo,
  OfcomFormDataFormik,
  OfcomFormDataServer,
  OfcomRegion,
} from "../../types/types";

export const transformFormDataToServer: (
  formDataValues: OfcomFormDataFormik,
) => OfcomFormDataServer = (formDataValues) => {
  const yesNoToBoolean = (val: string) => {
    if (val === "Yes") return true;
    if (val === "No") return false;
    return null;
  };

  const transformedData: OfcomFormDataServer = {
    foreignProduction: yesNoToBoolean(formDataValues.foreignProduction),
    producerCountry: formDataValues.producerCountry,
    productionLocation: formDataValues.productionLocation,
    substantiveBaseCriteria: Boolean(formDataValues.substantiveBaseCriteria),
    productionSpendCriteria: Boolean(formDataValues.productionSpendCriteria),
    talentCriteria: Boolean(formDataValues.talentCriteria),
    substantiveBaseAddress: formDataValues.substantiveBaseAddress,
    substantiveBaseRegion:
      formDataValues.substantiveBaseRegion === ""
        ? null
        : formDataValues.substantiveBaseRegion,
    substantiveBaseYear:
      formDataValues.substantiveBaseYear === ""
        ? null
        : formDataValues.substantiveBaseYear,
    substantiveBaseOpenPrior: yesNoToBoolean(
      formDataValues.substantiveBaseOpenPrior,
    ),
    substantiveBaseCommission: yesNoToBoolean(
      formDataValues.substantiveBaseCommission,
    ),
    productionSpendTotalBudget:
      formDataValues.productionSpendTotalBudget === ""
        ? null
        : formDataValues.productionSpendTotalBudget,
    productionSpendTotalQualifyingBudget:
      formDataValues.productionSpendTotalQualifyingBudget === ""
        ? null
        : formDataValues.productionSpendTotalQualifyingBudget,
    productionSpendPercentage:
      formDataValues.productionSpendPercentage === ""
        ? null
        : formDataValues.productionSpendPercentage,
    productionSpendRegion:
      formDataValues.productionSpendRegion.length === 0
        ? null
        : formDataValues.productionSpendRegion,
    talentTotalBudget:
      formDataValues.talentTotalBudget === ""
        ? null
        : formDataValues.talentTotalBudget,
    talentBudgetPercentage:
      formDataValues.talentBudgetPercentage === ""
        ? null
        : formDataValues.talentBudgetPercentage,
    talentRegion:
      formDataValues.talentRegion.length === 0
        ? null
        : formDataValues.talentRegion,
    submitted: formDataValues.submitted,
    submittedBy: formDataValues.submittedBy,
    modifiedTime: formDataValues.modifiedTime,
  };

  return transformedData;
};

export const transformFieldForServer: (
  fieldName: string,
  fieldValue: BooleanAsYesNo | string | boolean | number | OfcomRegion[],
) => {
  [fieldName: string]:
    | string
    | boolean
    | null
    | number
    | OfcomRegion[]
    | undefined;
} = (fieldName, fieldValue) => {
  const yesNoToBoolean = (val: string) => {
    if (val.toLowerCase() === "yes") return true;
    if (val.toLowerCase() === "no") return false;
    return null;
  };

  switch (fieldName) {
    // Checkboxes - value stored as boolean
    case "substantiveBaseCriteria":
    case "productionSpendCriteria":
    case "talentCriteria":
      return { [fieldName]: Boolean(fieldValue) };

    // Radio - value stored as yes/no string
    case "foreignProduction":
    case "substantiveBaseOpenPrior":
    case "substantiveBaseCommission":
    case "submitted":
      return { [fieldName]: yesNoToBoolean(fieldValue.toString()) };

    // Text/select
    case "substantiveBaseRegion":
    case "substantiveBaseYear":
    case "productionSpendTotalBudget":
    case "productionSpendTotalQualifyingBudget":
    case "productionSpendPercentage":
    case "talentTotalBudget":
    case "talentBudgetPercentage":
      return { [fieldName]: fieldValue === "" ? null : fieldValue.toString() };

    // Checkboxes - value stored as array of regions
    case "productionSpendRegion":
    case "talentRegion":
      return { [fieldName]: fieldValue };

    default:
      return { [fieldName]: fieldValue.toString() };
  }
};
