/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { rgba } from "polished";
import theme from "../../utils/theme";
import { styled } from "styled-components";

// import styled from "@emotion/styled/macro";

const subNavsZIndex = 2;
const navHeight = 42;

export const StyledSubNavContainer = styled("div")`
  position: absolute;
  width: 100%;
  left: 0;
  top: ${navHeight}px;
  z-index: ${subNavsZIndex};
  padding-bottom: 1rem;
  background: ${theme.palette.white};
  display: none;
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.04);
`;

export const StyledContainer = styled("div")<{ hasItems?: boolean }>`
  width: 100%;
  position: relative;
  height: ${navHeight}px;
  background: ${theme.palette.white};
  &:hover {
    ${StyledSubNavContainer} {
      display: ${({ hasItems }) => (hasItems ? "block" : "none")};
    }
  }
`;

export const StyledNavWrapper = styled("div")<{ fixedHeight?: boolean }>`
  display: flex;
  width: 90%;
  max-width: 75rem;
  margin: 0 auto;
  height: ${({ fixedHeight }) => (fixedHeight ? `${navHeight}px` : "auto")};
`;

export const StyledNav = styled("div")<{
  active?: boolean;
  hasLink?: boolean;
  large?: boolean;
  smallFontSize?: boolean;
}>`
  padding: 0.75rem 1.5rem;
  ${({ smallFontSize }) => smallFontSize && "font-size: 0.75rem;"}
  height: ${navHeight}px;
  position: relative;
  color: ${theme.palette.primary.main};
  box-sizing: border-box;
  white-space: nowrap;
  cursor: ${({ hasLink }) => (hasLink ? "pointer" : "default")};
  background: ${({ active, large }: any) =>
    active
      ? large
        ? rgba(theme.palette.white, 0.8)
        : rgba(theme.palette.primary.main, 0.05)
      : large
        ? "transparent"
        : theme.palette.white};

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: ${subNavsZIndex};
    width: 100%;
    height: 3px;
    background: ${({ active }: any) =>
      active ? theme.palette.secondary.main : "transparent"};
  }

  &:focus,
  &:focus-within {
    color: ${theme.palette.charade};
    text-decoration: underline;
  }

  &:hover {
    background: ${rgba(theme.palette.primary.light, 0.2)};
  }

  &:active {
    background: ${rgba(theme.palette.primary.light, 0.4)};
  }
`;

export const StyledGroupMenu = styled("div")<{ large?: boolean }>`
  position: relative;
  box-shadow: 0px 7px 6px rgb(0 0 0 / 10%);

  ${StyledNavWrapper} {
    ${({ large }) =>
      large &&
      `
      width: 100%;
      max-width: auto;    
    `}
  }

  ${StyledContainer} {
    ${({ large }: any) =>
      large &&
      `
      background: ${rgba(theme.palette.secondary.main, 0.2)};    
    `}
  }

  ${StyledNavWrapper} {
    ${({ large }) =>
      large &&
      `
      width: 100%;
      max-width: none;    
    `}
  }
`;
