/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect } from "react";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useOktaAuth } from "@okta/okta-react";
import {
  Stack,
  TextField,
  Drawer,
  Box,
  Fab,
  Typography,
  Chip,
  useTheme,
  Alert,
  Button,
  IconButton,
  Zoom,
} from "@mui/material";
import ExploreIcon from "@mui/icons-material/Explore";
import getEnvironment from "../lib/getEnviroment";
import AiChatResponseCard from "./AiChatResponseCard";
import PromptCard from "./PromptCard";
import UserChatCard from "./UserChatCard";
import LoadingCard from "./LoadingCard";

type Message = {
  message: string;
  type: string;
  sources?: string[];
};

export const CompassGPT: FC<any> = () => {
  const theme = useTheme();
  const ENV = getEnvironment();
  const endpoint =
    ENV === "production"
      ? "https://studios-portal-cloudfront.prd.ca.itv.com/chatbot/query_docs"
      : "https://studios-chatbot.stg.ca.itv.com/api/public/chatbot/query_docs";
  const { oktaAuth } = useOktaAuth();
  const [inputChatText, setInputChatText] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [displayMessages, setDisplayMessages] = useState(false);

  const handleChatChange = (event: any) => {
    setInputChatText(event.target.value);
  };

  const headers = {
    Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
  };

  const updateMessages = (
    newMessage: string,
    type: string,
    sources?: string[],
  ) => {
    const updatedMessages: any = messages;
    updatedMessages.push({
      message: newMessage,
      type,
      sources,
    });
    setMessages(updatedMessages);
  };
  const bottomRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    setDisplayMessages(false);
  }, [messages, displayMessages, bottomRef]);

  const handleChatSubmit = (event: any) => {
    updateMessages(inputChatText, "user");
    setDisplayMessages(true);
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({ question: inputChatText }),
    };
    setInputChatText("");
    setLoading(true);
    fetch(endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        updateMessages(data.answer, "bot", data.sources);
        setDisplayMessages(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        const errorMessage = `Apologies, an error has occured. \n\n\nPlease try again later or contact the Studios Portal Team.`;
        updateMessages(errorMessage, "bot");
        setDisplayMessages(true);
      });
  };

  const handleKeypressChat = (ev: any) => {
    // it triggers by pressing the enter key
    if (ev.key === "Enter" && !loading && inputChatText !== "")
      handleChatSubmit(ev);
  };

  return (
    <>
      {/* Compass AI Button */}
      <IconButton
        onClick={() => setShowDrawer(true)}
        color="primary"
        aria-label="compass gpt"
        sx={{ "&:hover": { backgroundColor: `${theme.palette.primary.dark}` } }}
      >
        <ExploreIcon sx={{ color: "white" }} />
      </IconButton>
      {/* Close button */}
      <Zoom in={showDrawer} unmountOnExit>
        <Fab
          color="secondary"
          onClick={() => setShowDrawer(false)}
          sx={{
            top: 82,
            right: "41%",
            zIndex: 10002,
            bottom: "auto",
            left: "auto",
            position: "fixed",
          }}
        >
          <CloseIcon />
        </Fab>
      </Zoom>

      {/* Drawer */}
      <Drawer
        sx={{ zIndex: 10001 }}
        PaperProps={{ sx: { width: "40%" } }}
        anchor="right"
        open={showDrawer}
        transitionDuration={750}
      >
        {/* Drawer Header */}
        <Stack
          direction="row"
          spacing={1}
          sx={{
            p: 4,
            background:
              "linear-gradient(151deg, #181D2D 0%, #282F44 45.31%, #5A658A 100%);",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <ExploreOutlinedIcon fontSize="large" sx={{ color: "white" }} />
            <Typography variant="h4" sx={{ color: "white" }} display="inline">
              Compass AI Chatbot
            </Typography>
          </Stack>
          <Stack direction="column">
            <Chip
              label="beta"
              variant="outlined"
              size="small"
              sx={{ color: "white" }}
            />
          </Stack>
        </Stack>

        {/* Chat UI */}
        <Box
          sx={{
            justifyContent: "space-between",
            height: "100vh",
            p: 2,
            overflowY: "scroll",
          }}
        >
          <Alert severity="info" sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: "bold" }}>Beta feature</Typography>
            <Typography variant="body2">
              Please note that answers given may not be fully accurate, navigate
              using the links provided to see the direct Compass reference
              point.
            </Typography>
          </Alert>
          <Stack direction="column" spacing={2}>
            <Stack key="prompt" direction="row">
              <PromptCard />
            </Stack>
            {messages.map((instance) => {
              if (instance?.type === "bot")
                return (
                  <Stack key={instance.message} direction="row">
                    <AiChatResponseCard
                      chatContent={instance.message}
                      sources={instance.sources}
                    />
                  </Stack>
                );
              return (
                <Stack
                  key={instance.message}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <UserChatCard chatContent={instance.message} />
                </Stack>
              );
            })}
            {loading ? (
              <Stack direction="row">
                <LoadingCard />
              </Stack>
            ) : null}
          </Stack>
          <Box ref={bottomRef} />
        </Box>

        {/* Chat Input */}
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{ mx: 2, my: 1 }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <TextField
              autoFocus
              color="secondary"
              autoComplete="off"
              fullWidth
              label="Write your prompt here..."
              helperText="I can refine my results if it is not what you are looking for, just tell me!"
              value={inputChatText}
              onChange={handleChatChange}
              onKeyDown={handleKeypressChat}
            />
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              disabled={inputChatText === ""}
              size="large"
              variant="contained"
              color="secondary"
              sx={{ mt: 1 }}
              onClick={handleChatSubmit}
            >
              Send
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};
