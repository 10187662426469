import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import { NotificationResponsePayload } from "../types/types";

export interface NotificationQuerryResult {
  notifications: NotificationResponsePayload[];
}

export interface PutNotificationPayload {
  read: boolean;
}

const API_BASE = getAPI(SERVICES.notifications);

export const getNotifications = (): Promise<NotificationQuerryResult> =>
  handleRequest.get(API_BASE).then(({ data }: AxiosResponse) => data);

export const putNotifications = (
  notificationId: number,
  payload: PutNotificationPayload,
): Promise<void> =>
  handleRequest
    .put(`${API_BASE}/${notificationId}`, payload)
    .then(() => undefined);
