import { AxiosResponse } from "axios";
import { getAPI, SERVICES } from "../lib/getApi";
import handleRequest from "../lib/handleRequest";
import {
  ContributorsFilterProps,
  ContributorsResponsePayload,
  ContributorUpdateProps,
  ContributorListRoleProps,
} from "../types/types";

const API_BASE = getAPI(SERVICES.synopsis);

export const getContributors = (
  ccid?: string,
  isSeries = false,
): Promise<ContributorsResponsePayload> => {
  if (ccid) {
    // const url = isSeries ? "https://itv-notification.free.beeceptor.com/contributors" : `${API_BASE}/${isSeries ? 'series' : 'titles'}/${ccid}/contributors`;
    return (
      handleRequest
        .get(
          `${API_BASE}/${isSeries ? "series" : "titles"}/${ccid}/contributors`,
        )
        // .get(url)
        .then(({ data }: AxiosResponse) => data)
    );
  }
  return Promise.resolve<ContributorsResponsePayload>({
    contributors: [],
    canEditContributors: false,
  });
};

export const putContributors = (
  ccid: string,
  current: ContributorUpdateProps[],
  replacement: ContributorUpdateProps[],
  type: string,
): Promise<ContributorsResponsePayload> =>
  handleRequest
    .put(
      `${API_BASE}/${type}/${ccid}/contributors`,
      // 'https://itv-notification.free.beeceptor.com/contributors',
      { current, replacement },
    )
    .then(({ data }: AxiosResponse) => data);

export const listContributorRoles = (): Promise<ContributorListRoleProps[]> =>
  handleRequest
    .get(`${API_BASE}/contributors/roles`)
    .then(({ data }: AxiosResponse) => data);

export const getFilter = (
  seriesCcid: string,
): Promise<ContributorsFilterProps> =>
  handleRequest
    .get(`${API_BASE}/series/${seriesCcid}/contributors/filter`)
    .then(({ data }: AxiosResponse) => data);

export const putFilter = (
  seriesCcid: string,
  filter: ContributorsFilterProps,
): Promise<void> =>
  handleRequest
    .put(`${API_BASE}/series/${seriesCcid}/contributors/filter`, filter)
    .then(({ data }: AxiosResponse) => data);
