import { useQuery, UseQueryResult } from "react-query";
import { getEssenceTimecode } from "../../api/browseassets";
import { EssenceTimecodeResponsePayload } from "../../types/types";

export const useEssenceTimecode = (
  versionCcid: string | undefined,
): UseQueryResult<EssenceTimecodeResponsePayload> =>
  useQuery(
    ["getEssenceTimecode", versionCcid],
    () => getEssenceTimecode(versionCcid as string),
    { enabled: Boolean(versionCcid) },
  );
