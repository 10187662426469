/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { ContributorsResponsePayload } from "../../../../../types/types";
import { TitleContributors } from "../../Contributors//TitleContributors";
import { SeriesContributors } from "../../Contributors/SeriesContributors";

interface TitleContributorsPropsBillings {
  inModal?: boolean;
  contributorsData: ContributorsResponsePayload;
  isContributorsDataLoading: boolean;
  hasContributorsChanged?: boolean;
  setElsewhereContributors: (contributors: any) => void;
}

const BillingsContributors: FC<TitleContributorsPropsBillings> = (props) => {
  const { titleCcid } = useParams();
  return titleCcid ? <TitleContributors {...props} /> : <SeriesContributors />;
};

export default BillingsContributors;
