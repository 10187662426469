import React, { useEffect, useState } from "react";
import {
  Button,
  Snackbar,
  Alert,
  TextField,
  Popover,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { sendContactMsg } from "../../api/portal";
import { ContactUsComponentProps } from "../../types/types";
import { useGetUserProfile } from "../../hooks/queries/useGetUserProfile";

export const ContactUs: React.FC<ContactUsComponentProps> = ({
  ClickableComponent,
}) => {
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [hasMessageSent, setHasMessageSent] = useState<boolean>(false);
  const [snackBarIsVisible, setSnackBarIsVisible] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data: profile } = useGetUserProfile();
  const userName = profile ? profile?.firstName + " " + profile?.surname : "";
  const [errorSnackBarIsVisible, setErrorSnackBarIsVisible] =
    useState<boolean>(false);
  const [toSend, setToSend] = useState<{
    msg: string;
  }>({
    msg: "",
  });

  useEffect(() => {
    const timeId = setTimeout(() => {
      setSnackBarIsVisible(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    sendContactMsg({
      name: userName,
      msg: toSend.msg,
    })
      .then(() => {
        setErrorSnackBarIsVisible(false);
        setHasMessageSent(true);
        setSnackBarIsVisible(true);
      })
      .catch(() => {
        setErrorSnackBarIsVisible(true);
        setSnackBarIsVisible(true);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenChat(true);
  };

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    setToSend({
      ...toSend,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <ClickableComponent onClick={handleClick} />
      <Popover
        open={openChat}
        anchorEl={anchorEl}
        onClose={() => setOpenChat(false)}
        sx={{ zIndex: 10004 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
      >
        <Box component="form" onSubmit={onSubmit} sx={{ p: 2, width: 300 }}>
          {hasMessageSent && !errorSnackBarIsVisible ? (
            <Typography>
              Thank you, a member of the Studios Portal development team will
              contact you via email.
            </Typography>
          ) : (
            <TextField
              id="message"
              label="Your message here"
              multiline
              maxRows={10}
              minRows={10}
              fullWidth
              name="msg"
              value={toSend.msg}
              placeholder="Type message..."
              onChange={handleChange}
              required
              color="secondary"
              autoComplete="off"
            />
          )}
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ mt: 2 }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenChat(false);
                setHasMessageSent(false);
              }}
              size="medium"
            >
              Close
            </Button>
            {hasMessageSent ? (
              <Button
                variant="outlined"
                color="secondary"
                type="reset"
                onClick={(e) => {
                  e.preventDefault();
                  toSend.msg = "";
                  setHasMessageSent(false);
                }}
                size="medium"
              >
                Send another?
              </Button>
            ) : (
              <Button
                type="submit"
                color="secondary"
                onClick={onSubmit}
                disabled={toSend.msg === ""}
              >
                Send
              </Button>
            )}
          </Stack>
        </Box>
      </Popover>
      {errorSnackBarIsVisible ? (
        <Snackbar
          open={snackBarIsVisible}
          autoHideDuration={6000}
          onClose={() => setSnackBarIsVisible(false)}
        >
          <Alert
            onClose={() => setSnackBarIsVisible(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Something went wrong, please contact support
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={snackBarIsVisible}
          message="Message sent!"
          onClose={() => setSnackBarIsVisible(false)}
          autoHideDuration={6000}
        />
      )}
    </>
  );
};
