/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { AxiosError } from "axios";
import { useGrantNotificationFormAccess } from "../../hooks";
import { FormError } from "./FormError";

type NotificationFormErrorProps = {
  riskError: AxiosError | null;
};

export const NotificationFormError: FC<NotificationFormErrorProps> = ({
  riskError,
}) => {
  const { mutate: requestAccess } = useGrantNotificationFormAccess();

  return <FormError formError={riskError} requestAccess={requestAccess} />;
};
