/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getSummaryNew } from "../../api/spotlight";
import { Summary } from "../../containers/NotificationForm/FormStatus";

export const useRiskSummaryNew = ({
  ccid,
}: {
  ccid: string | undefined;
}): UseQueryResult<Summary, AxiosError> =>
  useQuery(
    ["risk summary", ccid],
    () => getSummaryNew({ ccid: ccid as string }),
    {
      enabled: Boolean(ccid),
    },
  );
