import React from "react";
import { Typography } from "@mui/material";

interface SynopsisProps {
  sectionTitle?: string;
  title: string;
  toolTip: string;
  lengthLimit: number;
  textAreaHeight: number;
  headerText: React.FunctionComponent<{ userCanEditSynopsis: boolean }>;
  placeHolderDescription: string;
}

export interface SynopsisConfigs {
  synopsisShort: SynopsisProps;
  synopsisMedium: SynopsisProps;
  synopsisLong: SynopsisProps;
}

export const synopsisConfigs: SynopsisConfigs = {
  synopsisShort: {
    title: "Short",
    toolTip: "",
    lengthLimit: 90,
    textAreaHeight: 3,
    placeHolderDescription: "Enter description",
    headerText: ({ userCanEditSynopsis }) => (
      <>
        {" "}
        <Typography variant="h5">Programme billing synopsis</Typography>
        {userCanEditSynopsis && (
          <Typography variant="body1">
            Please ensure that all three billing synopsis are filled in
            accurately. These may be used across print listings, EPG and on
            demand.
          </Typography>
        )}
        <br />
        <br />
      </>
    ),
  },
  synopsisMedium: {
    title: "Medium",
    toolTip: "",
    lengthLimit: 180,
    textAreaHeight: 5,
    placeHolderDescription: "Enter description",
    headerText: () => <></>,
  },
  synopsisLong: {
    title: "Long",
    toolTip: "",
    placeHolderDescription: "Enter press synopsis",
    lengthLimit: 1000,
    textAreaHeight: 8,
    headerText: ({ userCanEditSynopsis }) => (
      <>
        {userCanEditSynopsis && (
          <Typography variant="body1">
            Note: This longer synopsis is typically intended for press-related
            consumption.
          </Typography>
        )}
        <br />
        <br />
      </>
    ),
  },
};
