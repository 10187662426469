import { FC } from "react";
import { Box, Card, Typography, useTheme } from "@mui/material";

const UserChatCard: FC<{ chatContent: string }> = ({ chatContent }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        alignItems: "flex-end",
        maxWidth: "75%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
      }}
    >
      <Box sx={{ px: 2, py: 1 }}>
        <Typography>{chatContent}</Typography>
      </Box>
    </Card>
  );
};

export default UserChatCard;
